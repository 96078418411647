import request from '/@/utils/useAxiosApi';

// 首页滚动Banners
export interface Banner {
  sysAdvertiseLocation: number;
  lang: string;
}
export function getBanner(params: Banner) {
  return request.post('uc/ancillary/system/advertise', params);
}

// 公告
export interface AnnouncementPage {
  pageNo: number;
  pageSize: number;
}
export interface AnnouncementMore {
  id: number;
  lang: string;
}
// 公告page
export function getAnnouncementPage(params: AnnouncementPage) {
  return request.post('uc/announcement/page', params);
}
// 根据ID获取当前公告及上一条和下一条
export function getAnnouncementMore(params: AnnouncementMore) {
  return request.post('uc/announcement/more', params);
}
// 公告detail
export function announcementDetail(id: string) {
  return request.get('uc/announcement/' + id);
}

// 获取新通知
export const oneNotice = () => {
  return request.post('uc/announcement/onenotice');
};
// 取消公告
export const cancelShowNotice = (params) => {
  return request.post('uc/announcement/cancelshow', params);
};

// 获取币种信息
export function getCurrencyInfo() {
  return request.post('swap/overview');
}

// 获取用户信息
export function getUserInfo() {
  return request.post('uc/member/my-info');
}

// 获取用户信息
export function getMyPromotionInfo() {
  return request.get('uc/member/info');
}
