<script lang="ts">
import { defineComponent } from 'vue'
export default defineComponent({
  name: 'HomePage',
})
</script>
<template>
  <div class="homes">
    <!-- 头部 -->
    <van-sticky>
      <div id="home" class="top h-10 py-2 flex justify-between items-center" style="background: #fff">
        <!-- 头部logo标题 -->
        <div class="homeHeaders" v-if="user.token">
          <img @click="$router.push('/user')" style="width: 35px; height: 35px; border-radius: 50%" :src="user.userInfo?.avatar" alt="" />
          <div class="welcomeBox">
            {{ $t('welcomes') }}
          </div>
        </div>
        <div class="homeHeaders" v-else>
          <!-- style="width: 100px;height: 26px;" -->
          <img style="width: 30px; height: 30px" src="/img/homelogo.png" alt="" />
          <div class="welcomeBox" @click="$router.push('/login')">
            {{ $t('goLogin') }}
          </div>
        </div>
        <!-- 右侧图标 -->
        <div style="display: flex">
          <!-- <router-link to="/messageAnnouncement">
          <img style="width: 20px; height: 20px" src="/img/homeImg/message.png" alt="" />
        </router-link> -->
          <!-- <router-link to="/customer"> -->
          <div style="display: flex; align-items: center; text-align: inherit; line-height: 40px; padding-left: 20px">
            <router-link to="/langs">{{ $t('switchLanguage') }}</router-link>
            <!-- <router-link to="/langs">{{ $t('switchLanguage') }}</router-link> -->
          </div>
          <!-- </router-link> -->
        </div>
      </div>
    </van-sticky>
    <!-- 图片轮播/巡演轮播/更多 -->
    <div class="swiperImg">
      <van-swipe class="my-swipe" indicator-color="white">
        <van-swipe-item v-for="(item, index) in slides" :key="index">
          <img class="w-10/10 rounded-md" :src="item.url" alt="" />
        </van-swipe-item>
      </van-swipe>

      <!-- 音量文字轮播+更多页面 -->
      <div class="VouleBack" style="display: none">
        <!-- 音量 -->
        <img style="width: 24.5px; height: 24.5px" src="/img/homeImg/volume.png" alt="" />
        <!-- 文字 -->
        <div class="volueStyle" v-if="announcementPage">
          <span>{{ announcementPage.title }}</span>
        </div>
        <!-- 更多 -->
        <div class="text-yellow-500"
          ><router-link to="/announcement">
            <img style="width: 8px; height: 11px; padding-right: 6px" src="/img/homeImg/back.png" alt="" />
          </router-link>
        </div>
      </div>
    </div>
    <!-- 中部 币种信息 -->
    <div id="messageBi" class="middle" style="margin-top: 10px">
      <!-- 轮播数据--coin -->
      <van-swipe class="swipe-coin pb-3" indicator-color="white">
        <van-swipe-item class="flex text-center" v-for="(item, index) in arr1" :key="index">
          <div class="w-1/3" @click="toContract(item1.symbol)" v-for="(item1, index) in item" :key="index">
            <h3 class="text-white">{{ item1.symbol }}</h3>
            <div v-if="item1.chg >= 0" class="greenColorHome">
              <h2>{{ item1.close.toFixed(2) }}</h2>
              <h4>+{{ (item1.chg * 100).toFixed(2) }}%</h4>
            </div>
            <div v-else class="redColorHome">
              <h2>{{ item1.close.toFixed(2) }}</h2>
              <h4>{{ (item1.chg * 100).toFixed(2) }}%</h4>
            </div>
          </div>
        </van-swipe-item>
      </van-swipe>
    </div>

    <div class="centerbtn">
      <div class="item" @click="toKfu">
        <img src="../../assets/images/Group407.png" />
        <p>{{ $t('service') }}</p>
      </div>
      <div class="item" @click="$router.push('/pay')">
        <img src="../../assets/images/Group406.png" />
        <p>{{ $t('deposit') }}</p>
      </div>
      <div class="item" @click="goInvite">
        <img src="../../assets/images/Group408.png" />
        <p>{{ $t('invite') }}</p>
      </div>
      <div class="item" @click="$router.push('/Intelligent')">
        <img src="../../assets/images/Group409.png" />
        <p>{{ $t('financialManagement') }}</p>
      </div>
    </div>

    <!-- 底部 涨幅榜 -->
    <div class="bottom" style="padding-bottom: 65px">
      <!-- title -->
      <div id="titleBottom" class="py-3">
        <span>{{ $t('riseRanking') }} </span>
        <!-- <span>{{ $t('dropList') }}</span> -->
      </div>
      <div class="coinDescription">
        <span>{{ $t('currency') }}</span>
        <span>{{ $t('recentPrice') }}</span>
        <span>{{ $t('gains') }}</span>
      </div>
      <!-- detail -->
      <div class="detail">
        <div @click="toContract(item.symbol)" class="bot-detail pt-3 flex items-start" v-for="(item, index) in rankData" :key="index">
          <!-- 图片 -->
          <div id="imgBox" class="mr-2 flex items-center justify-center mt-1">
            <img id="imgs" :src="item.logo" alt="" />
          </div>

          <div class="flex flex-1 justify-between items-start">
            <!-- symbol / volume -->
            <div class="w-3/5 align-text-top text-gray-400">
              <div class="text-base font-600 text-black">
                {{ item.symbol }}
              </div>
              <div class="text-xs" v-if="item.turnover >= 1000000000"
                >{{ $t('quantity2') }}{{ (item.turnover / 1000000000).toFixed(2) }}b</div
              >
              <div class="text-xs" v-else-if="item.turnover >= 1000000"
                >{{ $t('quantity2') }}{{ (item.turnover / 1000000).toFixed(2) }}m</div
              >
              <div class="text-xs" v-else>{{ $t('quantity2') }}{{ item.turnover.toFixed(4) }}</div>
            </div>
            <!-- close -->
            <div v-if="item.ul >= 0" class="greenColorHome w-2/5 font-700" style="text-align: left; margin-right: 5px">
              <div class="flex px-4 py-3" style="padding-top: 10px">
                {{ item.close }}
              </div>
            </div>
            <div v-else class="redColorHome w-2/5 font-700" style="text-align: left; margin-right: 5px">
              <div class="flex px-4 py-3">
                {{ item.close }}
              </div>
            </div>
            <!-- chg -->
            <div
              id="percentage"
              v-if="item.chg >= 0"
              style="padding-right: 10px; padding-top: 8px"
              class="w-2/5 h-7 text-white text-sm flex items-center justify-center"
            >
              <div style="padding: 5px 10px 5px 10px" id="greenColorHomes" class="bg-green-500 px-3 py-1 rounded-md"
                >+{{ (item.chg * 100).toFixed(2) }}%</div
              >
            </div>
            <div
              id="percentage"
              v-else
              style="padding-right: 10px; padding-top: 8px"
              class="w-2/5 h-7 text-white text-sm flex items-center justify-center"
            >
              <div id="redColorHomes" class="bg-red-500 px-3 py-1 rounded-md"> {{ (item.chg * 100).toFixed(2) }}% </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- 我的邀请链接弹框 -->
    <van-overlay :show="show" style="z-index: 2000">
      <div id="onerlayTal" class="w-12/12" style="background-color: #000; height: 100%">
        <div class="flex items-center justify-between" style="padding: 10px 10px">
          <!-- <div>{{ $t('sharePoster') }}</div> -->
          <div class="flex items-center justify-between" style="padding: 10px 10px">
            <!-- <div>{{ $t('sharePoster') }}</div> -->
            <div @click="show = false" style="color: #f0a70a; padding-left: 10px">
              <img style="width: 25px; height: 15px" src="/img/jiantou.png" alt="" />
            </div>
          </div>
        </div>
        <div class="flex justify-around items-center">
          <swiper
            v-model="currentSwiperIndex"
            :effect="'coverflow'"
            :grabCursor="true"
            :centeredSlides="true"
            :slidesPerView="'auto'"
            :initialSlide="1"
            :coverflowEffect="{
              rotate: 0,
              stretch: 0,
              depth: 100,
              modifier: 1,
              slideShadows: true,
            }"
            class="swiper"
            @swiper="onSwiper"
            @slideChange="onSlideChange"
          >
            <!-- :modules="modules" -->
            <swiper-slide>
              <div class="relative">
                <img ref="swiperContainer" style="width: 348px; height: 500px; border-radius: 10px" :src="inviteBg" alt="" />
                <img
                  ref="qrcodeContainer"
                  style="width: 65px; height: 67px; position: absolute"
                  :style="qrCodePosition"
                  :src="urlCode"
                  alt=""
                />
              </div>
            </swiper-slide>
          </swiper>
        </div>
        <div class="flex justify-around items-center" style="padding: 10px 10px">
          <div
            class="w-5/6 text-center p-2.5 mr-1"
            style="background-color: #f0a70a; border-radius: 5px; color: #fff"
            @click="copyLink($event)"
          >
            {{ $t('copyLink') }}
          </div>
        </div>
      </div>
    </van-overlay>
    <van-overlay :show="showGonggao" v-if="gonggaoInfo?.title ?? ''" style="background: #000000a3">
      <div
        :style="
          'position: relative;background:url(' +
          ggbg +
          ') no-repeat ;width:285px;height: 240px;background-size: cover;margin: auto;margin: 205px auto;border-radius:10px'
        "
      >
        <div id="title" @click="toDetail(gonggaoInfo)">
          <img :src="ggicon" width="138" height="107" style="margin: -25px 74px 0" />
          <div style="color: #ffb02e; font-weight: bold; text-align: center">{{ $t('announcement') }}</div>
        </div>
        <div style="padding: 25px 20px; text-align: center; word-wrap: break-word; word-break: break-all" @click="toDetail(gonggaoInfo)">
          <span style="">
            {{ gonggaoInfo.title }}
          </span>
        </div>
        <Divider />
        <div
          style="
            border-top: 1px solid #999;
            display: flex;
            align-items: center;
            height: 48px;
            line-height: 48px;
            width: 100%;
            position: absolute;
            bottom: 0;
            border-bottom-left-radius: 10px;
            border-bottom-right-radius: 10px;
          "
        >
          <span style="width: 50%; text-align: center; height: 100%; color: #999; border-right: 0.02rem solid #eee" @click="tempClose">
            {{ $t('disabling') }}</span
          >
          <span style="width: 50%; color: #ffb02e; text-align: center; height: 100%" class="bindSee" @click="closeGonggao(gonggaoInfo.id)">
            {{ $t('notremind') }}
          </span>
        </div>
      </div>
    </van-overlay>
  </div>
  <!-- 二维码 -->
  <!-- <img class="w-8" :src="urlCode" alt="" /> -->
</template>

<script lang="ts" setup name="HomePage">
  //引入
  // import { Swiper, SwiperSlide } from 'swiper/vue';
  import { ref, onActivated, onDeactivated, computed } from 'vue';
  import { getBanner, getCurrencyInfo, getUserInfo } from '/@/api/home';
  import { Banner, UserInfo } from '/@/types/home';
  import { useRouter } from 'vue-router';
  // import html2canvas from 'html2canvas';
  import 'swiper/css';
  import 'swiper/css/effect-coverflow';
  import 'swiper/css/pagination';
  import handleClipboard from '/@/utils/clipboard';
  import QRCode from 'qrcode';
  import { useUserStore } from '/@/store/modules/user';
  import { getAnnouncementPage, oneNotice, cancelShowNotice } from '/@/api/home';
  import axios from 'axios';
  import { useI18n } from 'vue-i18n';
  import { useAppStore } from '/@/store/modules/app';

  import ggicon from '/img/ggicon.png';
  import ggbg from '/img/ggbg.png';
  const appStore = useAppStore();
  const currentSwiperIndex = ref<any>(0);
  // const imgs = ref(['/img/intvs.png', '/img/invite2.jpg', '/img/invite3.jpg']);
  const swiperContainer = ref<any>(null);
  const qrcodeContainer = ref<any>(null);
  const { locale } = useI18n();

  //定义数据
  const user = useUserStore();
  const router = useRouter();
  const slides = ref<Banner[]>([]);
  const recData = ref<any[]>([]);
  const rankData = ref<any[]>([]);
  const announcementPage = ref<any>();
  const qrCodePosition = ref<any>({ right: '35px', bottom: '50px' });
  // const officialUrl = ref<any>('');
  // const superviseUrl = ref<any>('');
  // const SourceUrl = ref<any>('');
  // const reportUrl = ref<any>('');
  const showGonggao = ref(false);
  const gonggaoInfo = ref();
  getData();
  function getData() {}
  // const saveCanvasAsImage = (canvas, filename) => {
  //   const dataURL = canvas.toDataURL('image/png');
  //   const link = document.createElement('a');
  //   link.href = dataURL;
  //   link.download = filename;
  //   link.click();
  // };
  //保存图片
  // const saveImg = async () => {
  //   const htmlCollection = document.getElementsByClassName('swiper-slide-active');

  //   const array: any[] = Array.from(htmlCollection);
  //   const index = array[0].swiperSlideIndex;
  //   imgs.value[index];
  //   console.log(swiperContainer.value);
  //   let img = new Image();

  //   img.src = imgs.value[index];

  //   const [qrcodeCanva] = await Promise.all([html2canvas(qrcodeContainer.value)]);
  //   const canvas = document.createElement('canvas');
  //   canvas.width = img.width;
  //   canvas.height = img.height;
  //   const ctx: any = canvas.getContext('2d');
  //   ctx.drawImage(img, 0, 0);

  //   const qrCodeWidth = 250; // 二维码的宽度
  //   const qrCodeHeight = 250; // 二维码的高度

  //   const qrCodeOffsetX = canvas.width - qrCodeWidth - 10; // 计算二维码的X轴偏移量
  //   const qrCodeOffsetY = canvas.height - qrCodeHeight - 10; // 计算二维码的Y轴偏移量

  //   ctx.drawImage(qrcodeCanva, qrCodeOffsetX, qrCodeOffsetY, qrCodeWidth, qrCodeHeight);
  //   saveCanvasAsImage(canvas, 'merged_image.png');
  // };
  const toKfu = () => {
    if (true || user.token) {
      let url = 'https://chat.sportswear168.com/index/index/home?visiter_id=';
      url += '' + (user.id ?? '');
      url += '&visiter_name=' + (user.username ?? '');
      url += '&avatar=' + (user.avatar ?? '');
      url += '&business_id=1&groupid=1&special=1';
      // url = 'http://chat.menftt.com/chat/index.html?id=53883533';
      window.open(url, '_target');
    } else {
    }
  };

  // to官方跳转
  // function toOffical() {
  //   if (user.token) {
  //     location.href = officialUrl.value;
  //   } else {
  //     // showToast(t('pleaseLoginFirst'));
  //     location.href = officialUrl.value;
  //   }
  // }
  // // to监督跳转
  // function toSupervise() {
  //   if (user.token) {
  //     location.href = superviseUrl.value;
  //   } else {
  //     // showToast(t('pleaseLoginFirst'));
  //     location.href = superviseUrl.value;
  //   }
  // }
  // // to开源代码跳转
  // function toSource() {
  //   if (user.token) {
  //     location.href = SourceUrl.value;
  //   } else {
  //     // showToast(t('pleaseLoginFirst'));
  //     location.href = SourceUrl.value;
  //   }
  // }
  // // to审计跳转
  // function toReport() {
  //   if (user.token) {
  //     location.href = reportUrl.value;
  //   } else {
  //     // showToast(t('pleaseLoginFirst'));
  //     location.href = reportUrl.value;
  //   }
  // }
  const intervalNotice = ref<any>();
  function tempClose() {
    clearInterval(intervalNotice.value);
    showGonggao.value = false;
  }
  function closeGonggao(id) {
    cancelShowNotice({ isShow: 0, annouId: id }).then((res) => {
      console.log('取消公告', res);
      showGonggao.value = false;
    });
  }
  //方法
  getAnnouncementPage({ pageNo: 1, pageSize: 10 }).then((res) => {
    if (res.data.content.length > 0) {
      announcementPage.value = res.data.content[0];
      console.log('announcementPage.value:', res.data.content);
    }
  });

  //获取轮播图
  getBanner({ sysAdvertiseLocation: 0, lang: 'zh_CN' }).then((res) => {
    slides.value = res.data;
  });

  //获取币种信息
  getInfo();
  let timer;
  const timerIdRank = ref<any>();
  function getInfo() {
    getCurrencyInfo().then((res: any) => {
      recData.value = res.recommend;
      rankData.value = res.changeRank;
      // console.log('recData:', recData.value);
      // console.log('convertTo2DArray:', convertTo2DArray(recData.value, 3));
    });
  }
  function tradeInfo() {
    let _index = rankData.value.length - 1;
    oneDetail(_index, rankData.value);
    let __index = recData.value.length - 1;
    oneDetail(__index, recData.value);
  }

  function oneDetail(_index, array) {
    let coin = array[_index];
    listDetailUSDT1(coin, _index, array);
  }

  function listDetailUSDT1(coin, index, array) {
    axios.get('https://api.huobi.pro/market/detail/merged?symbol=' + coin.symbol.replace('/', '').toLowerCase()).then((res: any) => {
      // @ts-ignore
      res = res.data;
      // @ts-ignore
      if (res.status == 'ok') {
        // @ts-ignore
        let _close = coin.close;
        let t_close = res.tick.close;
        coin.close = res.tick.close;
        coin.ul = t_close - _close;
        let t_open = res.tick.open;
        let chg = (t_close - t_open) / t_open;
        coin.chg = chg;
        coin.turnover = res.tick.vol;
        if (index > 0) {
          console.log('index', index);
          index--;
          oneDetail(index, array);
        }
      }
    });
  }

  // function listDetailUSDT(coin) {
  //   axios.get('https://api.huobi.pro/market/detail/merged?symbol=' + coin.symbol.replace('/', '').toLowerCase()).then((res: any) => {
  //     // @ts-ignore
  //     res = res.data;
  //     // @ts-ignore
  //     if (res.status == 'ok') {
  //       // @ts-ignore
  //       let _close = coin.close;
  //       let t_close = res.tick.close;
  //       coin.close = res.tick.close;
  //       coin.ul = t_close - _close;
  //       let t_open = res.tick.open;
  //       let chg = (t_close - t_open) / t_open;
  //       coin.chg = chg;
  //       coin.turnover = res.tick.vol;
  //     }
  //   });
  // }
  onActivated(() => {
    if (timerIdRank.value) {
      clearInterval(timerIdRank.value);
    }
    timerIdRank.value = setInterval(tradeInfo, 3000);
    intervalNotice.value = setInterval(() => {
      oneNotice().then((res) => {
        if (res.data) {
          gonggaoInfo.value = res.data;
          showGonggao.value = true;
        }
      });
    }, 5000);
  });

  function toDetail(item) {
    const list: any = { id: item.id, createTime: item.createTime, title: item.title, content: item.content };
    router.push({ path: '/announcementDetail', query: list });
  }

  onDeactivated(() => {
    clearInterval(timer);
    clearInterval(timerIdRank.value);
    clearInterval(intervalNotice.value);
  });

  //computed
  const arr1 = computed((): Obj[] => {
    return convertTo2DArray(recData.value, 3);
  });

  interface Obj {
    [key: string]: any;
  }
  // 一维数组转二维数组
  const convertTo2DArray = (arr: Obj[], size: number): Obj[][] => {
    const result: Obj[][] = [];

    for (let i = 0; i < arr.length; i += size) {
      result.push(arr.slice(i, i + size));
    }
    return result;
  };
  // 邀请链接
  const show = ref(false);
  // const showInvite = () => {
  //   if (user.token) {
  //     show.value = true;
  //   } else {
  //     router.push('/login');
  //   }
  // };
  const onSwiper = (swiper) => {
    console.log('打开邀请:', swiper);
  };
  const onSlideChange = (e) => {
    console.log('邀请图片change:', e);
  };
  // 复制链接
  const info = ref<UserInfo>();
  if (user.token) {
    getUserInfo()
      .then((res) => {
        const data: UserInfo = res.data;
        // console.log('邀请链接:', res.data.promotionPrefix + res.data.promotionCode);
        info.value = data;

        getQrCode();
      })
      .catch(() => {});
  }

  const copyLink = ($event) => {
    if (info.value) {
      handleClipboard(info.value.promotionPrefix + info.value.promotionCode, $event);
    }
  };

  // 二维码
  const urlCode = ref('');
  const getQrCode = () => {
    if (info.value) {
      QRCode.toDataURL(info.value.promotionPrefix + info.value.promotionCode)
        // QRCode.toDataURL('https://www.sessforex.com/#/register?code=OQXQ')
        .then((url) => {
          // console.log('qrCode:', url);
          urlCode.value = url;
        })
        .catch((err) => {
          console.error(err);
        });
    }
  };
  const inviteBg = ref('');
  function goInvite() {
    console.log(locale.value);
    if (user.token) {
      show.value = true;
      // inviteBg.value = '/img/invite' + locale.value + '.png';
      inviteBg.value = '/img/invite.png';
    } else {
      router.push('/login');
    }
  }

  function toContract(symbol) {
    console.log('sss', symbol);
    appStore.setCSymbol(symbol);
    console.log(appStore.ucSymbol);
    router.push('/contract');
  }
</script>
<style lang="scss" scoped>
  @import './css/index.scss';
</style>
