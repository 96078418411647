import request from '/@/utils/useAxiosApi';

// 查询盘口
export function getDiskport(params: { symbol: string }) {
  return request.post('swap/exchange-plate-mini', params);
}

// 查询盘口
export function getnewactivitypage(params: { pageNo: Number; pageSize: Number }) {
  return request.post('uc/newactivity/page-query', params);
}

export function getKLine(params: { symbol: string; from: Number; to: Number; resolution: Number }) {
  //   return request.post('http://123.58.212.6:6012/swap/history', params);
  return request.post('swap/history', params);
}

// 查询订单详情
export function getnnewactivitydetail(params: { id: Number }) {
  return request.post('uc/newactivity/detail', params);
}

// 查询订单详情
export function detailbyid(params: { id: Number }) {
  return request.post('uc/newactivity/detailbyid', params);
}

// 查询币种
export function getcoinlegal(params: {}) {
  return request.get('uc/coin/legal', params);
}

// // 查询
// export function getnewactivitypage(params: {
//     pageNo: String,
//     pageSize: String
// }) {
//     return request.post('uc/newactivity/page-query', params);
// }

// 我的订单列表
export function getmyorders(params: { pageNo: Number; pageSize: Number; status: string }) {
  return request.post('uc/newactivity/getmyorders', params);
}

export function getrechange(params: { unit: String }) {
  return request.post('uc/transfer/rechange', params);
}

// 统计收益
export function getmemberentrust() {
  return request.post('uc/newactivity/getmemberentrust');
}

// 提交挖矿
export function getnewattend(params: { activityId: number; amount: number; isRedo: number }) {
  return request.post('uc/newactivity/newattend', params);
}

// 提交委托
export interface AddEntrustParams {
  symbol: string;
  amount: string;
  price: string;
  type: string;
  direction: string;
}
export function addEntrust(params: AddEntrustParams) {
  return request.post('exchange/order/add', params);
}
