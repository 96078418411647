// import { Item } from '/@/types/diskportItems';

export function typeCheck(param: any) {
  return Object.prototype.toString.call(param);
}

/**
 * 批量修改stage
 */
export function mutateState(state: Record<string, any>, payload: Record<string, any>) {
  if (typeCheck(state) === '[object Object]' && typeCheck(payload) === '[object Object]') {
    for (const key in payload) {
      state[key] = payload[key];
    }
  } else {
    console.error('expected plain Object');
  }
}

export function getTimezone4K() {
  const curlang = localStorage.getItem('lang');
  if (curlang == 'en_US') {
    return 'America/Los_Angeles';
  }
  if (curlang == 'ja_JP') {
    return 'Asia/Tokyo';
  }
  if (curlang == 'ko_KR') {
    return 'Asia/Seoul';
  }
  if (curlang == 'de_DE') {
    return 'Europe/Berlin';
  }
  if (curlang == 'fr_FR') {
    return 'Europe/Paris';
  }
  if (curlang == 'it_IT') {
    return 'Europe/Rome';
  }
  if (curlang == 'es_ES') {
    return 'Europe/Madrid';
  }
  if (curlang == 'zh_HK') {
    return 'Asia/Hong_Kong';
  }
  if (curlang == 'zh_CN') {
    return 'Asia/Shanghai';
  }
  return curlang;
}

export function getLang4K() {
  const curlang = localStorage.getItem('lang');

  if (curlang == 'en_US') {
    return 'en';
  }
  if (curlang == 'ja_JP') {
    return 'ja';
  }
  if (curlang == 'ko_KR') {
    return 'ko';
  }
  if (curlang == 'de_DE') {
    return 'de_DE';
  }
  if (curlang == 'fr_FR') {
    return 'fr';
  }
  if (curlang == 'it_IT') {
    return 'it';
  }
  if (curlang == 'es_ES') {
    return 'es';
  }
  if (curlang == 'zh_HK') {
    return 'zh_TW';
  }
  if (curlang == 'zh_CN') {
    return 'zh';
  }
  return curlang;
}

/**
 * Parse the time to string
 * @param {(Object|string|number)} time
 * @param {string} cFormat
 * @returns {string | null}
 */
export function parseTime(time: string | number | Date, cFormat?: string): string | null {
  if (arguments.length === 0 || !time) {
    return null;
  }
  const format = cFormat || '{y}-{m}-{d} {h}:{i}:{s}';
  let date: Date;
  if (typeof time === 'object') {
    date = time;
  } else {
    if (typeof time === 'string') {
      if (/^[0-9]+$/.test(time)) {
        // support "1548221490638"
        time = parseInt(time);
      } else {
        // support safari
        // https://stackoverflow.com/questions/4310953/invalid-date-in-safari
        time = time.replace(new RegExp(/-/gm), '/');
      }
    }

    if (typeof time === 'number' && time.toString().length === 10) {
      time = time * 1000;
    }
    date = new Date(time);
  }
  const formatObj = {
    y: date.getFullYear(),
    m: date.getMonth() + 1,
    d: date.getDate(),
    h: date.getHours(),
    i: date.getMinutes(),
    s: date.getSeconds(),
    a: date.getDay(),
  };
  const time_str = format.replace(/{([ymdhisa])+}/g, (_result: any, key: string) => {
    const value = formatObj[key];
    // Note: getDay() returns 0 on Sunday
    if (key === 'a') {
      return ['日', '一', '二', '三', '四', '五', '六'][value];
    }
    return value.toString().padStart(2, '0');
  });
  return time_str;
}

// 计算背景进度
export function getBgWidth(items, item): string {
  const sum = items.reduce((p: number, c): number => {
    p += c[1];
    return p;
  }, 0);
  let res = (item[1] / sum) * 100;
  res > 100 && (res = 100);
  return res + '%';
}
// 生产picker-range值 type=selector
export function getItems(options) {
  const items: { value: string | number; text: string }[] = [];
  Object.keys(options).forEach((v) => {
    const value = parseInt(v);
    items.push({
      value: value || v,
      text: options[v],
    });
  });
  return items;
}

export function formatNumber(num: number) {
  if (num >= 100000000) {
    return (num / 100000000).toFixed(2) + 'b';
  } else if (num >= 100000) {
    return (num / 10000).toFixed(2) + 'm';
  } else {
    return num.toString();
  }
}
