import { Login } from '../types/api';
import request from '/@/utils/useAxiosApi';

/**
 * 账号密码登录
 * @returns UseAxiosReturn
 */
export function loginPassword(params: Login) {
  return request('uc/login', { params });
}
