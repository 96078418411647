// import { number } from 'echarts';
import request from '/@/utils/useAxiosApi';

interface SysbolParams {
  symbol: string;
}
// 获取交易合约列表
export function getContractList(params: any) {
  return request.post('swap/contractfast/fastlist', params);
}

//获取总盈亏
export function allmoney() {
  return request.post('/swap/contractfastorder/allmoney');
}

//获取当前交割
export function currentContract(params: any) {
  return request.post('swap/contractfastorder/current', params);
}

//获取历史交割
export function historyContract(params: any) {
  return request.post('swap/contractfastorder/history', params);
}

//获取钱包
export function walletUSDT() {
  return request.post('uc/asset/wallet/USDT');
}

interface SysbolParams2 extends SysbolParams {
  pageNo: number;
  pageSize: number;
}
// 获取历史交易合约列表
export function getHistoryContractList(params: SysbolParams2) {
  return request.post('swap/contractfastorder/history', params);
}
// 获取交易合约列表
export function getCurrentContractList(params: SysbolParams) {
  return request.post('swap/contractfastorder/current', params);
}
export interface OrderParams extends SysbolParams {
  fastId: number;
  amount: string;
  direction: number;
  optionId: string;
  startPrice:number;
}
// 获取交易合约列表
export function addContractOrder(params: OrderParams) {
  return request.post('swap/contractfastorder/add', params);
}
// 获取交易金额和收益 下单总额和收益
export function getContractAllMoney(params) {
  return request.post('/option/order/allmoney', params);
}

//个人中心-推广收益-团队收益
export function getContracTeamMoney(params) {
  return request.post('/option/order/teammoney', params);
}

interface HistroyParams extends SysbolParams {
  count: number;
}
//往期结果数据
export function getHistory(params: HistroyParams) {
  return request.post('option/option/history', params);
}
//获取历史交割数据
export function getHistoryOrder(params: SysbolParams2) {
  return request.post('/option/order/history', params);
}

//要开始的预测
export function getStarting(params: SysbolParams) {
  return request.post('/option/option/starting', params);
}

//获取合约Coin信息
export function getCoinInfo(params: SysbolParams) {
  return request.post('option/coin/coin-info', params);
}

//当前交割
export function getCurrentOpening(params: SysbolParams) {
  return request.post('/option/option/opening', params);
}
// export function getCurrentOpening(params: SysbolParams) {
//   return request.post('/swap/swap/opening', params);
// }
export interface KlineParams extends SysbolParams {
  from: string;
  to: string;
  resolution: any;
}
//k线历史数据
export function getKlineHistory(params: KlineParams) {
  return request.post('/option/history', params);
}

//手动看涨或者看跌
export function orderByHand(params: OrderParams) {
  return request.post('/option/order/add', params);
}

//自动看涨或者看跌
export function orderByAuto(params: OrderParams) {
  return request.post('/option/order/addautopink', params);
}

//我的开仓数据
export function myOrderCurrent() {
  return request.post('/option/order/current');
}

//当前交割(无vpn当前价格)
export function getKlineList(params: SysbolParams) {
  return request.post('swap/symbolprivce', params);
}

//当前交割(无vpn当前价格)
export function getKlineData(params: any) {
  return request.post('/market/history/kline', params);
}
