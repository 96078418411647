<script lang="ts">
import { defineComponent } from 'vue'
export default defineComponent({
  name: 'records',
})
</script>
<template>
  <div>
    <!-- 行情title -->
    <var-app-bar :title="$t('Myinvestmenthistory')" color="#F7F7F7" title-position="center"
      style="z-index: 10; color: #333333; font-weight: 600">
      <template #left>
        <div @click="$router.back">
          <van-icon class="text-gray-500" name="arrow-left" size="20" />
        </div>
      </template>
    </var-app-bar>
    <div class="content p-40px pt-140px">
      <div class="conTop">
        <!-- 当前投资总额 -->
        <div class="h-230px rounded-xl p-20px bg-gray-100 mb-25px">
          <div class="flex items-center justify-between pb-20px" style="border-bottom: 1px solid #dfdfdf">
            <span class="font-600">{{ $t('Currenttotalinvestmentequivalent') }}</span>
            <span class="font-600 flex">{{ OrderData.entrustMoney }} <span class="ml-10px">USDT</span></span>
          </div>
          <!-- entrustMoney: '', todayIncome: '', totalIncome: '', orderCount: '', -->
          <div class="flex flex-col justify-around h-161px pt-10px">
            <div class="flex items-center justify-between">
              <span class="font-600">{{ $t('TodayEarnings') }}</span>
              <span class="text-gray-500">{{ OrderData.todayIncome }} <span class="ml-5px">USDT</span></span>
            </div>
            <div class="flex items-center justify-between">
              <span class="font-600">{{ $t('accumulatedEarnings') }}</span>
              <span class="text-gray-500">{{ OrderData.totalIncome }}<span class="ml-12px">USDT</span></span>
            </div>
            <div class="flex items-center justify-between">
              <span class="font-600">{{ $t('orderCount') }}</span>
              <span class="text-gray-500">{{ OrderData.orderCount }}</span>
            </div>
          </div>
        </div>
        <!-- <div class="h-270px rounded-xl w100 p-20px bg-gray-100 mb-25px">
          <div class="flex items-center justify-between pb-20px">
            <span class="font-600 text-me">{{ $t('LockedDays') }}</span>
            <span class="font-600">100USDT</span>
          </div>
          <div class="mt-20px">
            <div class="flex items-center justify-between pb-20px">
              <span class="font-600">{{ $t('dailyReturn') }}</span>
              <span class="text-gray-500">100USDT</span>
            </div>
            <div class="flex items-center justify-between pb-20px">
              <span class="font-600">{{ $t('Dailyrevenue') }}</span>
              <span class="text-gray-500">100USDT</span>
            </div>
            <div class="flex items-center justify-between pb-20px">
              <span class="font-600">{{ $t('Redemptionmethod') }}</span>
              <span class="text-gray-500">100USDT</span>
            </div>
            <div class="flex items-center justify-between pb-20px">
              <span class="font-600">{{ $t('Estimatedtotalrevenue') }}</span>
              <span class="text-gray-500">100USDT</span>
            </div>
          </div>
        </div> -->
        <div class="Bottom">
          <!-- 投资订单title -->
          <div class="BottomTitle">
            <span class="text-lg font-600"> {{ $t('InvestmentOrders') }}</span>
          </div>
          <!-- 投资订单详情 -->
          <div class="BottomCon mt-20px" style="height: 100%">
            <div class="w100">
              <van-pull-refresh v-model="refresh.loading" :loading-text="$t('loading')" @refresh="onRefresh">
                <div v-if="InvestmentOrderList?.length == 0">
                  <van-empty />
                </div>
                <van-list :loading-text="$t('loading')" v-if="InvestmentOrderList" v-model:loading="Listconfig.loading"
                  :finished="Listconfig.finished" :finished-text="$t('Nomoreatmoment')" @load="onLoad">
                  <!-- 投资订单详情(单个) -->
                  <div @click="toDetail(item)" v-for="(item, index) in InvestmentOrderList" :key="index"
                    class="TZlistItem h-auto rounded-xl w100 p-20px bg-gray-100 mb-25px">
                    <!-- ETH 7day 5% -->
                    <div class="TZlistItemHeader flex items-center justify-between">
                      <div class="TZlistItemHeaderLeft flex items-center">
                        <span class="mr-10px"><img class="w-40px h-40px" :src="item.url" /></span>
                        <span class="font-600" style="font-size: 16px">{{ item.symbol }}</span>
                      </div>
                      <div class="flex items-center">
                        <!-- <van-tag class="ml-3 rounded-xl" type="primary" size="medium">{{ item.days }}天</van-tag> -->
                        <div class="w-100px h-40px bg-yellow-500 rounded-full flex items-center justify-center mr-20px">
                          <span class="text-white text-sm">{{ item.days }}{{ $t('dayLimit') }}</span>
                        </div>
                        <!-- <van-tag class="ml-2 rounded-xl bg-green-500" type="primary" size="medium">{{ item.releasePercent * 100 }}%</van-tag> -->
                        <div class="w-100px h-40px bg-green-500 rounded-full flex items-center justify-center">
                          <span class="text-white text-sm">{{ Multiply(item.releasePercent, 100) }}%</span>
                        </div>
                        <van-icon class="" name="arrow" size="15" />
                      </div>
                    </div>
                    <!-- Amt & Time -->
                    <div class="TZlistItemCon flex w100 h-auto py-40px border-bottom"
                      style="flex-direction: column; border-bottom: 1px solid #adadad">
                      <div style="width: 75%" class="h-auto">
                        <span class="font-500 text-gray-500" style="">{{ $t('investmentAmount') }}</span>: <span
                          class="text-gray-500">{{ item.amount }}</span> <span class="text-gray-500">{{ item.baseSymbol
                          }}</span>
                      </div>
                      <div class="mt-20px h-auto w100 flex justify-between">
                        <span style="width: 75%" class="font-500 text-gray-500">{{ $t('Subscriptiontime') }}: <span
                            class="text-gray-500">{{ getdate(item.createTime) }}</span></span>
                        <span class="text-yellow-400 font-600">
                          {{
                            item.status == 1
                            ? $t('inProgress')
                            : item.status == 2
                              ? $t('completed')
                              : item.status == 3
                                ? $t('earlyRedemption')
                                : ''
                          }}</span>
                      </div>
                    </div>
                    <!-- 借贷理财 -->
                    <div class="TZlistItemBottom h-80px flex items-center justify-between">
                      <span>
                        {{ $t('Loanfinancing') }}
                        <!-- : -->
                        <!-- {{ item.isRedo == 0 ? $t('Resumptionhasbeenclosed') : $t('Resumptionhasbeenactivated') }} -->
                      </span>
                      <van-switch :data-id="index" :disabled="true" @click.stop="onUpdateValue()"
                        :model-value="item.isRedo == 0 ? false : true" active-color="#ec8920" size="medium" />
                    </div>
                  </div>
                </van-list>
              </van-pull-refresh>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script lang="ts" setup name="records">
import {
  InvestmentOrderListItem,
  ListconfigType,
  getdate,
  OrderDataType,
  getmyordersPamsType,
  refreshType,
  // Static,
} from '/@/types/Intelligent';
import { getmemberentrust, getmyorders } from '/@/api/Intelligent';
// import { showToast } from 'vant';
import { reactive, onMounted } from 'vue';
// import { useI18n } from 'vue-i18n';
import { useRouter } from 'vue-router';
// const { t } = useI18n();
// const { token } = useToken();
const router = useRouter();

function Multiply(...args) {
  if (args.length < 2) return args[0];
  try {
    let m = 0;
    let items = <any>[];
    for (let i = 0; i < args.length; i++) {
      let item = args[i].toString() as any;
      item.split('.')[1] && (m += item.split('.')[1].length); // 计算小数总长度m
      items.push(item.replace('.', '')); // 将数字转为整数
    }
    return items.reduce((prev, curr) => prev * curr) / 10 ** m; // 转换后的整数相乘, 再除以10的m次方
  } catch (e) {
    // console.log();
  }
}

const getmyordersPams = reactive<getmyordersPamsType>({
  pageNo: 1,
  pageSize: 10,
  status: '',
});

const refresh = reactive<refreshType>({
  loading: true,
});

const OrderData = reactive<OrderDataType>({
  entrustMoney: '',
  todayIncome: '',
  totalIncome: '',
  orderCount: '',
});

const InvestmentOrderList = ref<InvestmentOrderListItem[]>();

const Listconfig = reactive<ListconfigType>({
  checked: false,
  finished: false,
  loading: false,
});

const onLoad = () => {
  getmyordersPams.pageNo += 1;
  getList('onLoad');
};

onMounted(() => {
  getList('search');
});

const onRefresh = (): void => {
  getmyordersPams.pageNo = 1;
  getmyordersPams.pageSize = 10;
  Listconfig.finished = false;
  getList('refresh');
};

const onUpdateValue = () => {
  // return showToast({
  //   message: t('Unabletochangeorder'),
  //   duration: 1000,
  // });
};

getmemberentrust().then((res: any) => {
  console.log(res);
  if (res.code == 0) {
    OrderData.entrustMoney = res.data.entrustMoney;
    OrderData.todayIncome = res.data.todayIncome;
    OrderData.totalIncome = res.data.totalIncome;
    OrderData.orderCount = res.data.orderCount;
  }
});

const getList = (type) => {
  getmyorders(getmyordersPams).then((res: any) => {
    if (res.code == 0) {
      if (type == 'refresh') {
        InvestmentOrderList.value = res.data.content;
        refresh.loading = false;
      }
      if (type == 'onLoad') {
        InvestmentOrderList.value = InvestmentOrderList.value?.concat(res.data.content);
        console.log(InvestmentOrderList.value);
      }
      if (type == 'search') {
        InvestmentOrderList.value = res.data.content;
      }
      res.data.totalPages <= getmyordersPams.pageNo
        ? ((Listconfig.finished = true), (Listconfig.loading = false))
        : (Listconfig.finished = false),
        (Listconfig.loading = false);
    }
  });
};

const toDetail = (item) => {
  router.push({ path: '/Intelligent/InvestmentDetails', query: { id: item.id } });
};

// const toDetail = (item) => {
//   router.push({ path: '/Intelligent/InvestmentDetails', query: { id: item } });
// };
</script>

<style lang="scss" scoped>
::v-deep .var-elevation--3 {
  box-shadow: none;
}

.From {
  height: auto;
  padding-left: 40px;
  padding-right: 40px;
  color: #333333;

  .TZmoneyTitle {
    color: #000000;
    font-size: 30px;
    font-family: PingFangSCBold;
    font-weight: 600;
  }

  .TZmoneyC {
    font-size: 25px;
    font-family: PingFangSCBold;
    color: #777777;
  }

  .USDT {
    justify-content: space-between;
    align-items: center;
    background: rgba($color: #00133c, $alpha: 0.05);
    // opacity: 0.05;
    // box-shadow: 4px 0px 4px 0px #00133c;
    border-radius: 7px 7px 7px 7px;
    height: 64px;

    .USDTIcon {
      font-size: 31px;
      font-weight: 600;
      font-family: PingFangSCBold;
      color: #000000;
    }
  }

  .balance {
    border-bottom: 1px solid rgba($color: #333333, $alpha: 0.3);
  }

  .TZlistItemCon {
    border-bottom: 1px solid rgba($color: #999999, $alpha: 0.3);
  }
}
</style>
