<script lang="ts">
import { defineComponent } from 'vue'
export default defineComponent({
  name: 'Member',
})
</script>
<template>
  <div class="user">
    <!-- 顶部 -->
    <div class="clolorTop">
      <!-- 顶部 -->
      <div class="setHeader">
        <span @click="$router.push('/home')"> <var-icon name="chevron-left" :size="30" style="padding-left: 5px" /></span>
        <img @click="toSet" src="/img/shezhi.png" alt="" />
      </div>
      <!-- 登录信息 -->
      <div id="userHeaderCenter" class="flex justify-between items-center">
        <div class="flex justify-between">
          <!-- avatar -->
          <div>
            <van-image round width="50" height="50" :src="user.userInfo?.avatar" />
          </div>
          <!-- username -->
          <div class="flex flex-col justify-between h-12 pl-4">
            <div v-if="info" class="text-sm flex items-center">
              {{ info.username }}
              <img v-if="userLevel !== 0" :src="getLevel(userLevel)" class="w-1/5 pl-1" />
            </div>

            <!-- <div v-else-if="info && info.realName !== ''" class="pb-1.25 text-lg">{{ info.realName }}</div> -->
            <!-- <router-link to="/login" v-else class="pb-1.25 text-lg text-black">{{ $t('notLoggedIn') }}</router-link> -->
            <div class="text-xs" style="color: #959698">{{ $t('welcome') }} </div>
            <div class="text-xs" v-if="info?.promotionCode" style="color: #959698">{{ $t('invitecode') }}: {{ info?.promotionCode }} </div>
          </div>
        </div>
        <!-- to Safe -->
        <div @click="toSafe">
          <van-icon name="arrow" size="20" />
        </div>
      </div>
    </div>
    <div class="centerUser">
      <!-- 资产管理 -->
      <div class="assetManagement">
        <div class="assetH4">
          <h3>{{ $t('assetManagement') }}</h3>
        </div>
        <!-- 我的资产 -->
        <div class="Mentbodys" @click="toBalance">
          <div><img style="width: 18px; height: 18px" src="/img/zic1.png" alt="" /></div>
          <div style="color: #000; letter-spacing: 1px; font-size: 14px"
            >{{ $t('myAssets') }}<span class="icons"> <van-icon name="arrow" size="14" /></span
          ></div>
        </div>
        <!-- 充币记录 -->
        <div class="Mentbodys" @click="toDeposit">
          <div><img style="width: 18px; height: 18px" src="/img/zic2.png" alt="" /></div>
          <div style="color: #000; letter-spacing: 1px; font-size: 14px"
            >{{ $t('depositRecord') }}<span class="icons"> <van-icon name="arrow" size="14" /></span
          ></div>
        </div>
        <!-- 提币记录 -->
        <div class="Mentbodys" @click="toWidthdraw">
          <div><img style="width: 18px; height: 18px" src="/img/zic3.png" alt="" /></div>
          <div style="color: #000; letter-spacing: 1px; font-size: 14px"
            >{{ $t('withdrawalRecords') }}<span class="icons"> <van-icon name="arrow" size="14" /></span
          ></div>
        </div>
        <!-- 资产流水 -->
        <div class="Mentbodys" @click="toUserAssetsRecord">
          <div><img style="width: 18px; height: 18px" src="/img/zic2.png" alt="" /></div>
          <div style="color: #000; letter-spacing: 1px; font-size: 14px"
            >{{ $t('assetFlow') }}<span class="icons"> <van-icon name="arrow" size="14" /></span
          ></div>
        </div>
        <div class="Mentbodys" @click="toUserRecordContract">
          <div><img style="width: 18px; height: 18px" src="/img/zic2.png" alt="" /></div>
          <div style="color: #000; letter-spacing: 1px; font-size: 14px"
            >{{ $t('historyDelivery') }}<span class="icons"> <van-icon name="arrow" size="14" /></span
          ></div>
        </div>
        <!-- 资金密码 -->
        <!-- <div class="Mentbodys" @click="toCapitalPassword">
          <div><img style="width: 18px; height: 18px" src="/img/zic3.png" alt="" /></div>
          <div style="color: #000; letter-spacing: 1px; font-size: 14px"
            >{{ $t('fundsPassword') }}<span class="icons"> <van-icon name="arrow" size="14" /></span
          ></div>
        </div> -->
      </div>
      <!-- 我的贡献值 -->
      <!-- <div class="invitation">
        <div class="assetH4">
          <h3>{{ $t('myBenefits') }}</h3>
        </div>
        <div class="Mentbodys" @click="toAlls()">
          <div><img style="width: 18px; height: 18px" src="/img/g1.png" alt="" /></div>
          <div style="color: #000; letter-spacing: 1px; font-size: 14px"
            >{{ $t('allValue') }} <span class="icons"> <van-icon name="arrow" size="14" /></span
          ></div>
        </div>
        <div class="Mentbodys" @click="toShare()">
          <div><img style="width: 18px; height: 18px" src="/img/g2.png" alt="" /></div>
          <div style="color: #000; letter-spacing: 1px; font-size: 14px"
            >{{ $t('shareValue') }}<span class="icons"> <van-icon name="arrow" size="14" /></span
          ></div>
        </div>
        <div class="Mentbodys" @click="toTeam()">
          <div><img style="width: 18px; height: 18px" src="/img/g3.png" alt="" /></div>
          <div style="color: #000; letter-spacing: 1px; font-size: 14px"
            >{{ $t('teamValue') }}<span class="icons"> <van-icon name="arrow" size="14" /></span
          ></div>
        </div>
      </div> -->
      <!-- 我的邀请 -->
      <div class="invitation">
        <div class="assetH4">
          <h3>{{ $t('myinvitation') }}</h3>
          <!-- <div @click="showInvite" style="line-height: 18px; height: 18px; color: #0773ff; padding-right: 35px; font-weight: 550">
            {{ $t('invitationLink') }}
          </div> -->
        </div>
        <div class="Mentbodys" @click="showInvite">
          <div><img style="width: 18px; height: 18px" src="/img/y1.png" alt="" /></div>
          <div style="color: #000; letter-spacing: 1px; font-size: 14px">
            <span> {{ $t('invitationLink') }} </span>
            <span class="icons">
              <van-icon name="arrow" size="14" />
            </span>
          </div>
        </div>
        <!-- <div class="Mentbodys">
          <div><img style="width: 18px; height: 18px" src="/img/y2.png" alt="" /></div>
          <div style=" color: #000; letter-spacing: 1px;font-size: 14px;"> <span>{{ $t('secondLevelFriends') }} ({{ sl
          }})</span><span class="icons"> <van-icon name="arrow" size="14" /></span></div>
        </div> -->
        <!-- <div class="Mentbodys">
          <div><img style="width: 18px; height: 18px" src="/img/y3.png" alt="" /></div>
          <div style="color: #000; letter-spacing: 1px; font-size: 14px">
            <span>{{ $t('partnerLevel') }} ({{ getLevel() }})</span><span class="icons"> <van-icon name="arrow" size="14" /></span
          ></div>
        </div> -->
      </div>
      <!-- 意见/我们/帮助 -->
      <div class="invitation">
        <div class="assetH4">
          <h3>{{ $t('other') }}</h3>
        </div>
        <div class="Mentbodys" @click="toMis()">
          <div><img style="width: 18px; height: 18px" src="/img/q1.png" alt="" /></div>
          <div style="color: #000; letter-spacing: 1px; font-size: 14px"
            >{{ $t('feedback') }} <span class="icons"> <van-icon name="arrow" size="14" /></span
          ></div>
        </div>
        <div class="Mentbodys" @click="toMy()">
          <div><img style="width: 18px; height: 18px" src="/img/q2.png" alt="" /></div>
          <div style="color: #000; letter-spacing: 1px; font-size: 14px"
            >{{ $t('aboutUs') }}<span class="icons"> <van-icon name="arrow" size="14" /></span
          ></div>
        </div>
        <!-- <div class="Mentbodys" @click="toHelp()">
          <div><img style="width: 18px; height: 18px" src="/img/q3.png" alt="" /></div>
          <div style="color: #000; letter-spacing: 1px; font-size: 14px"
            >{{ $t('helptext') }}<span class="icons"> <van-icon name="arrow" size="14" /></span
          ></div>
        </div> -->
      </div>
      <!-- 我的邀请链接弹框 -->
      <van-overlay :show="show">
        <div id="onerlayTal" class="w-12/12" style="background-color: #000">
          <div class="flex items-center justify-between" style="padding: 10px 10px">
            <!-- <div>{{ $t('sharePoster') }}</div> -->
            <div class="flex items-center justify-between" style="padding: 10px 10px">
              <!-- <div>{{ $t('sharePoster') }}</div> -->
              <div @click="show = false" style="color: #f0a70a; padding-left: 10px">
                <img style="width: 25px; height: 15px" src="/img/jiantou.png" alt="" />
              </div>
            </div>
          </div>
          <div class="flex justify-around items-center">
            <swiper
              v-model="currentSwiperIndex"
              :effect="'coverflow'"
              :grabCursor="true"
              :centeredSlides="true"
              :slidesPerView="'auto'"
              :initialSlide="1"
              :coverflowEffect="{
                rotate: 0,
                stretch: 0,
                depth: 100,
                modifier: 1,
                slideShadows: true,
              }"
              class="swiper"
              @swiper="onSwiper"
              @slideChange="onSlideChange"
            >
              <!-- :modules="modules" -->
              <swiper-slide>
                <div class="relative">
                  <img ref="swiperContainer" style="width: 348px; height: 500px; border-radius: 10px" :src="inviteBg" alt="" />
                  <img
                    ref="qrcodeContainer"
                    style="width: 65px; height: 67px; position: absolute"
                    :style="qrCodePosition"
                    :src="urlCode"
                    alt=""
                  />
                </div>
              </swiper-slide>
              <!-- <swiper-slide>
                <div class="relative">
                  <img ref="swiperContainer" class="inline-block" :src="imgs[1]" alt="" />
                  <img ref="qrcodeContainer" class="absolute bottom-0 right-0" style="width: 47px" :style="qrCodePosition"
                    :src="urlCode" alt="" />
                </div>
              </swiper-slide>
              <swiper-slide>
                <div class="relative">
                  <img ref="swiperContainer" class="inline-block" :src="imgs[2]" alt="" />
                  <img ref="qrcodeContainer" class="absolute bottom-0 right-0" style="width: 47px" :style="qrCodePosition"
                    :src="urlCode" alt="" />
                </div>
              </swiper-slide> -->
            </swiper>
          </div>
          <!-- <div class="flex justify-around items-center" style="padding: 10px 10px">
            <div class="w-1/2 text-right p-5 mr-1" style=" color: #fff;" @click="saveImg()" id="copy">
              <div> <img style="width: 40px;height: 40px;margin-right: 5px;" src="/img/baocun.png" alt="" /> </div>
              <div> <span>{{$t('SavePicture')}}</span></div>
            </div>
            <div  id="copy" @click="copyLink($event)" class="w-1/2 text-left p-5 ml-1" style=" color: #fff;">
              <div> <img style="width: 40px;height: 40px;margin-left: 5px;" src="/img/prictures.png" alt="" /></div>
              <div > <span>{{$t('SaveLink')}}</span></div>
            </div>
          </div> -->
          <div class="flex justify-around items-center" style="padding: 10px 10px">
            <div
              class="w-5/6 text-center p-2.5 mr-1"
              style="background-color: #f0a70a; border-radius: 5px; color: #fff"
              @click="copyLink($event)"
            >
              {{ $t('copyLink') }}
            </div>
            <!-- <div
              @click="saveImg()"
              class="w-2/6 text-center p-2.5 ml-1"
              style="background-color: #f0a70a; border-radius: 5px; color: #fff"
              >{{ $t('saveImage') }}</div
            > -->
          </div>
        </div>
      </van-overlay>
    </div>
  </div>
</template>
<script lang="ts" name="Member" setup>
  import { getUserInfo, getMyPromotionInfo } from '/@/api/home';
  // import { setting } from '/@/api/user';
  import { UserInfo } from '/@/types/home';
  // import { SafeSetting } from '/@/types/setting';
  import { useRouter } from 'vue-router';
  // import { Swiper, SwiperSlide } from 'swiper/vue';

  import 'swiper/css';
  import 'swiper/css/effect-coverflow';
  import 'swiper/css/pagination';
  // import { showToast } from 'vant';
  // import Clipboard from 'clipboard';
  // import { useI18n } from 'vue-i18n';
  import handleClipboard from '/@/utils/clipboard';

  import QRCode from 'qrcode';
  import { useUserStore } from '/@/store/modules/user';
  // import html2canvas from 'html2canvas';
  import { ref } from 'vue';
  // const { locale } = useI18n();
  const router = useRouter();
  const user = useUserStore();
  // const safeSetting = ref<SafeSetting>();
  const swiperContainer = ref<any>(null);
  const qrcodeContainer = ref<any>(null);
  const qrCodePosition = ref<any>({ right: '35px', bottom: '50px' });
  // const imgs = ref(['/img/intvs.png', '/img/invite2.jpg', '/img/invite3.jpg']);
  const currentSwiperIndex = ref<any>(0);
  //保存图片
  // const saveImg = async () => {
  //   const htmlCollection = document.getElementsByClassName('swiper-slide-active');

  //   const array: any[] = Array.from(htmlCollection);
  //   const index = array[0].swiperSlideIndex;
  //   imgs.value[index];
  //   console.log(swiperContainer.value);
  //   let img = new Image();

  //   img.src = imgs.value[index];

  //   const [qrcodeCanva] = await Promise.all([html2canvas(qrcodeContainer.value)]);
  //   const canvas = document.createElement('canvas');
  //   canvas.width = img.width;
  //   canvas.height = img.height;
  //   const ctx: any = canvas.getContext('2d');
  //   ctx.drawImage(img, 0, 0);

  //   const qrCodeWidth = 250; // 二维码的宽度
  //   const qrCodeHeight = 250; // 二维码的高度

  //   const qrCodeOffsetX = canvas.width - qrCodeWidth - 10; // 计算二维码的X轴偏移量
  //   const qrCodeOffsetY = canvas.height - qrCodeHeight - 10; // 计算二维码的Y轴偏移量

  //   ctx.drawImage(qrcodeCanva, qrCodeOffsetX, qrCodeOffsetY, qrCodeWidth, qrCodeHeight);
  //   saveCanvasAsImage(canvas, 'merged_image.png');
  // };

  // const saveCanvasAsImage = (canvas, filename) => {
  //   const dataURL = canvas.toDataURL('image/png');
  //   const link = document.createElement('a');
  //   link.href = dataURL;
  //   link.download = filename;
  //   link.click();
  // };
  // to总贡献值
  // function toAlls() {
  //   if (user.token) {
  //     router.push({ path: '/allValue' });
  //   } else {
  //     // showToast(t('pleaseLoginFirst'));
  //     router.push('/allValue');
  //   }
  // }
  // // to分享贡献值
  // function toShare() {
  //   if (user.token) {
  //     router.push({ path: '/shareValue' });
  //   } else {
  //     // showToast(t('pleaseLoginFirst'));
  //     router.push('/shareValue');
  //   }
  // }
  // // to团队贡献值
  // function toTeam() {
  //   if (user.token) {
  //     router.push({ path: '/teamValue' });
  //   } else {
  //     // showToast(t('pleaseLoginFirst'));
  //     router.push('/teamValue');
  //   }
  // }

  // to我的
  function toMy() {
    if (user.token) {
      router.push({ path: '/about' });
    } else {
      // showToast(t('pleaseLoginFirst'));
      router.push('/about');
    }
  }
  // to
  function toMis() {
    if (user.token) {
      router.push({ path: '/feedback' });
    } else {
      // showToast(t('pleaseLoginFirst'));
      router.push('/feedback');
    }
  }
  // to帮助中心
  // function toHelp() {
  //   if (user.token) {
  //     router.push({ path: 'help' });
  //   } else {
  //     // showToast(t('pleaseLoginFirst'));
  //     router.push('/help');
  //   }
  // }
  // to设置中心
  function toSet() {
    if (user.token) {
      router.push({ path: 'sets' });
    } else {
      // showToast(t('pleaseLoginFirst'));
      router.push('/login');
    }
  }
  // to安全设置
  function toSafe() {
    if (user.token) {
      router.push({ path: 'safe' });
    } else {
      // showToast(t('pleaseLoginFirst'));
      router.push('/login');
    }
  }
  // function toMyincome(index) {
  //   if (user.token) {
  //     router.push('/myIncome/' + index);
  //   } else {
  //     // showToast(t('pleaseLoginFirst'));
  //     router.push('/login');
  //   }
  // }
  // to我的资产
  function toBalance() {
    if (user.token) {
      router.push({ path: 'balance' });
    } else {
      // showToast(t('pleaseLoginFirst'));
      router.push('/login');
    }
  }
  // to充币记录
  function toDeposit() {
    if (user.token) {
      router.push({ path: '/depositRecord' });
    } else {
      // showToast(t('pleaseLoginFirst'));
      router.push('/login');
    }
  }

  // to提币记录
  function toWidthdraw() {
    if (user.token) {
      router.push({ path: '/withdrawalRecords' });
    } else {
      // showToast(t('pleaseLoginFirst'));
      router.push('/login');
    }
  }

  // // to资产流水
  function toUserAssetsRecord() {
    if (user.token) {
      router.push({ path: '/userAssetsRecord' });
    } else {
      // showToast(t('pleaseLoginFirst'));
      router.push('/login');
    }
  }
  function toUserRecordContract() {
    if (user.token) {
      router.push({ path: '/userRecordContract' });
    } else {
      // showToast(t('pleaseLoginFirst'));
      router.push('/login');
    }
  }
  // // 资金密码点击
  // function toCapitalPassword() {
  //   if (user.token) {
  //     setting().then((res) => {
  //       console.log('setting:', res);
  //       safeSetting.value = res.data;
  //       if (safeSetting.value?.fundsVerified) {
  //         router.push('/changePassword');
  //       } else {
  //         router.push('/setPassword');
  //       }
  //     });
  //   } else {
  //     // showToast(t('pleaseLoginFirst'));
  //     router.push('/login');
  //   }
  // }

  // function toCapital() {
  //   if (user.token) {
  //     router.push({ path: 'setPassword' });
  //   } else {
  //     // showToast(t('pleaseLoginFirst'));
  //     router.push('/login');
  //   }
  // }
  const inviteBg = ref('');
  // 邀请链接
  const show = ref(false);
  const showInvite = () => {
    if (user.token) {
      show.value = true;
      // inviteBg.value = '/img/invite' + locale.value + '.png';
      inviteBg.value = '/img/invite.png';
    } else {
      router.push('/login');
    }
  };
  const onSwiper = (swiper) => {
    console.log('打开邀请:', swiper);
  };
  const onSlideChange = (e) => {
    console.log('邀请图片change:', e);
  };
  // 复制链接
  const info = ref<UserInfo>();
  let userLevel = ref(0);
  let fl = ref(0);
  let sl = ref(0);
  if (user.token) {
    getUserInfo().then((res) => {
      const data: UserInfo = res.data;
      // console.log('邀请链接:', res.data.promotionPrefix + res.data.promotionCode);
      info.value = data;
      console.log('info:', info.value);
      getQrCode();
    });
    getMyPromotionInfo().then((res: any) => {
      userLevel.value = res.data.foreignLevel;
      fl.value = res.data.firstLevel;
      sl.value = res.data.secondLevel;
    });
  }
  // 根据用户等级返回不同等级图片
  const getLevel = (level) => {
    switch (level) {
      case 1:
        return '/img/level1.png';
      case 2:
        return '/img/level2.png';
      case 3:
        return '/img/level3.png';
      case 4:
        return '/img/level4.png';
      case 5:
        return '/img/level5.png';
      case 6:
        return '/img/level6.png';
      case 7:
        return '/img/level7.png';
      case 8:
        return '/img/level8.png';
      case 9:
        return '/img/level9.png';
    }
  };

  // function getLevel() {
  //   let _index = parseInt(level.value);
  //   return _index > 0 ? 'V' + _index : '--';
  // }

  // 复制链接
  // function clipboardSuccess() {
  //   showToast(t('LinkCopiedSuccessfully'));
  // }

  // function clipboardError() {
  //   showToast(t('LinkCopiedFailed'));
  // }

  // function handleClipboard(text: string, event) {
  //   const clipboard = new Clipboard(event.target, {
  //     text: () => text,
  //   }) as any;
  //   clipboard.on('success', () => {
  //     clipboardSuccess();
  //     clipboard.destroy();
  //   });
  //   clipboard.on('error', () => {
  //     clipboardError();
  //     clipboard.destroy();
  //   });
  //   clipboard.onClick(event);
  // }

  const copyLink = ($event) => {
    if (info.value) {
      handleClipboard(info.value.promotionPrefix + info.value.promotionCode, $event);
    }
  };

  // 二维码
  const urlCode = ref('');
  const getQrCode = () => {
    if (info.value) {
      QRCode.toDataURL(info.value.promotionPrefix + info.value.promotionCode)
        // QRCode.toDataURL('https://www.sessforex.com/#/register?code=OQXQ')
        .then((url) => {
          console.log('qrCode:', url);
          urlCode.value = url;
        })
        .catch((err) => {
          console.error(err);
        });
    }
  };
</script>

<style scoped lang="scss">
  @import './css/index.scss';
</style>
