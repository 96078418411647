import request from '/@/utils/useAxiosApi';

//意见反馈
export function feedBack(params) {
  return request.post('uc/feedback', params);
}
// 公告
export function messageList(params) {
  return request.post('uc/announcement/page', params);
}
// 帮助中心
export function helpCenter(params) {
  return request.post('uc/ancillary/more/help', params);
}
// 帮助详情
export function helpDetail(params) {
  return request.post('uc/ancillary/more/help/detail', params);
}
// 更多列表
export interface MoreList {
  pageNo: number;
  pageSize: number;
  lang: string;
  cate: string;
}
export function moreList(params: MoreList) {
  return request.post('uc/ancillary/more/help/page', params);
}
//更多详情
// export interface MoreDetail {
//   id: number;
//   lang: string;
// }
// export function moreDetail(params) {
//   return request.post('ancillary/more/help/page', params);
// }

//获取app 最新版本
export function getNewestVersion() {
  return request.post('/uc/ancillary/system/app/version/0');
}

// 退出
export function quit() {
  return request.get('uc/loginout');
}
