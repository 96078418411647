<script lang="ts">
import { defineComponent } from 'vue'
export default defineComponent({
  name: 'QuotationPage',
})
</script>
<template>
  <div style="height: 100vh; background-color: #fff">
    <var-app-bar :title="pot == 0 ? $t('pincome') : $t('tincome')" title-position="center">
      <template #left>
        <var-button color="transparent" text-color="#fff" round text @click="$router.back()">
          <var-icon name="chevron-left" :size="24" />
        </var-button>
      </template>
    </var-app-bar>
    <div style="padding-top: 50px">
      <van-list
        ref="list"
        :loading-text="$t('loading')"
        v-model:loading="historyLoading"
        v-model:error="historyError"
        :error-text="$t('requestFailure')"
        @load="loadData"
        :finished="historyFinished"
      >
        <div class="p-3" v-for="item in (historyList as any[])">
          <div class="flex justify-between">
            <div class="flex-1">
              <div class="text-gray-500 mb-2 text-xs">{{ $t('transactionTime') }}</div>
              <div class="whitespace-nowrap">{{ formatDate(item.createTime) }}</div>
            </div>
            <div class="text-center flex-1">
              <div class="text-gray-500 mb-2 text-xs">{{ $t('type') }}</div>
              <div class="">{{ getType(item.type) }}</div>
            </div>
            <div class="text-right flex-1">
              <div class="text-gray-500 mb-2 text-xs">{{ $t('quantity') }}</div>
              <div class="">{{ item.amount.toFixed(4) }}</div>
            </div>
          </div>
        </div>
      </van-list>

      <var-popup position="bottom" v-model:show="fitterDialog">
        <var-form class="p-4">
          <var-space direction="column" justify="center">
            <var-select :placeholder="$t('currency')" v-model="formData.data.symbol">
              <var-option v-for="item in symbols.data" :label="item" :value="item" />
            </var-select>
            <var-select :placeholder="$t('type')" v-model="formData.data.type">
              <var-option v-for="(item, index) in types" :label="item" :value="index" />
            </var-select>
            <div
              >{{ $t('startEndTime') }}
              <div class="text-xs text-gray-500">{{ $t('feeGenerationTime') }}</div>
            </div>
            <var-input readonly :placeholder="$t('startTime')" v-model="formData.data.startTime" @click="datePopupS = true" />
            <var-input readonly :placeholder="$t('endTime')" v-model="formData.data.endTime" @click="datePopupE = true" />
            <div class="flex">
              <var-button class="flex-1 mr-1" type="default" native-type="reset" @click="resetSearch">{{ $t('reset') }}</var-button>
              <var-button class="flex-1 ml-1" type="primary" native-type="submit" @click="submitSearch">{{ $t('confirm') }}</var-button>
            </div>
          </var-space>
        </var-form>
      </var-popup>
      <var-popup position="bottom" v-model:show="datePopupS">
        <nut-date-picker
          :ok-text="$t('confirm')"
          :cancel-text="$t('cancel')"
          type="datetime"
          :min-date="minDate"
          @confirm="popupConfirmS"
          @cancel="datePopupS = false"
        >
        </nut-date-picker>
      </var-popup>
      <var-popup position="bottom" v-model:show="datePopupE">
        <nut-date-picker
          :ok-text="$t('confirm')"
          :cancel-text="$t('cancel')"
          type="datetime"
          :min-date="minDate"
          @confirm="popupConfirmE"
          @cancel="datePopupE = false"
        >
        </nut-date-picker>
      </var-popup>
    </div>
  </div>
</template>

<script setup lang="ts" name="QuotationPage">
  import { useList } from '/@/hooks/useList';
  import { myIncome } from '/@/api/user';
  import { formatDate } from '/@/utils/tools';
  import { getWalletList } from '/@/api/transaction';
  import { useI18n } from 'vue-i18n';
  import { useToken } from '/@/hooks/useToken';
  const { userInfo } = useToken();
  const { t } = useI18n();
  const fitterDialog = ref(false);
  const datePopupS = ref(false);
  const datePopupE = ref(false);
  const route = useRoute();
  const pot: number = parseInt(route.params.id + '');
  // const list = reactive({ data: [] });
  const symbols = reactive<any>({ data: [] });
  const types = ref([
    t('recharge2'),
    t('withdraw'),
    t('transfer'),
    t('coinTrading'),
    t('fiatCurrencyPurchase'),
    t('fiatCurrencySale'),
    t('activityReward'),
    t('promotionReward'),
    t('dividend'),
    t('vote'),
    t('manualRecharge'),
    t('pairing'),
    t('activityExchange'),
    t('ctcPurchase'),
    t('ctcSale'),
    t('redEnvelopeSending'),
    t('redEnvelopeReceiving'),
    t('withdrawalCodeWithdrawal'),
    t('withdrawalCodeRecharge'),
    t('perpetualContractFee'),
    t('perpetualContractProfit'),
    t('perpetualContractLoss'),
    t('optionContractFailure'),
    t('optionContractFee'),
    t('optionContractBonus'),
    t('contractRebate'),
    t('levelReward'),
    t('platformFeeIncome'),
    t('forexEntrustTrading'),
    t('forexCancelEntrustTrading'),
    t('income'),
    t('promotionIncome'),
    t('optionsPromotionIncome'),
    t('liquidationCompensation'),
    t('lockedMiningIncome'),
    t('optionParityAward'),
    t('speedContractProfit'),
    t('speedContractLoss'),
    t('speedContractCommission'),
  ]);
  const minDate = new Date(2020, 0, 1);
  const popupConfirmS = ({ selectedValue }) => {
    let date = selectedValue.slice(0, 3).join('-');
    let time = selectedValue.slice(3).join(':');
    let realtime = date + ' ' + time;
    formData.data.startTime = realtime;
    datePopupS.value = false;
  };
  const popupConfirmE = ({ selectedValue }) => {
    let date = selectedValue.slice(0, 3).join('-');
    let time = selectedValue.slice(3).join(':');
    let realtime = date + ' ' + time;
    formData.data.endTime = realtime;
    datePopupE.value = false;
  };
  const {
    listError: historyError,
    listLoading: historyLoading,
    listFinished: historyFinished,
    listQuery: historyQuery,
    listData: historyList,
    getList: histroyListHandle,
  } = useList(myIncome);
  historyQuery.pageNo = 0;
  function loadData() {
    let params = { type: pot == 0 ? '32' : '34' };
    Object.keys(params).forEach((v) => {
      historyQuery[v] = params[v];
    });
    histroyListHandle();
  }
  // submitSearch();
  const formData = reactive({
    data: {
      pageNo: 0,
      pageSize: 10,
      memberId: userInfo?.id || '',
      startTime: '',
      endTime: '',
      symbol: '',
      type: pot == 0 ? '32' : '34',
    },
  });
  getSymbols();
  function getSymbols() {
    getWalletList().then((res: any) => {
      if (res.code == 0) {
        res.data.forEach((item: any) => {
          symbols.data.push(item.coin.unit);
        });
      }
    });
  }

  function submitSearch() {
    let params = formData.data;
    Object.keys(params).forEach((v) => {
      console.log(v + '>>' + params[v]);
      historyQuery[v] = params[v];
    });
    histroyListHandle(true);
    fitterDialog.value = false;
  }

  function resetSearch() {
    formData.data = {
      pageNo: 1,
      pageSize: 10,
      memberId: '',
      startTime: '',
      endTime: '',
      symbol: '',
      type: pot == 0 ? '32' : '34',
    };
  }

  function getType(type) {
    return types.value[type];
  }
</script>
<style lang="scss" scoped>
  .nut-picker-roller-mask {
    background-image: null;
    /* background-color: var(--popup-content-background-color) */
  }
</style>
