<template>
  <VantLoading />
  <van-config-provider theme="light">
    <router-view />
  </van-config-provider>
</template>
<script setup lang="ts">
  import VantLoading from './views/loading/index.vue';
  import { useAppStore } from '/@/store//modules/app';
  const appStore = useAppStore();
  let lang = appStore.ulang;
  appStore.setULang(lang);
</script>

<style scoped lang="scss">
  @import './styles/index.scss';
  #app {
    // font-family: Avenir, Helvetica, Arial, sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    /* color: #2c3e50; */
    background-color: var(--color-bg-1);
    --van-field-input-text-color: #333333 !important;
  }

  :deep(.var-app-bar) {
    position: fixed !important;
  }
</style>
