import { defineStore } from 'pinia';
import { loginPassword } from '/@/api';
import { quit } from '/@/api/sets';
import { UserInfo } from '/@/types/home';
import { Login } from '/@/types/api';

import router from '/@/router';

interface StoreUser {
  userInfo: Record<any, any> | null;
}

export const useUserStore = defineStore({
  id: 'app-user',
  state: (): StoreUser => ({
    userInfo: null,
  }),
  getters: {
    token: (state) => state.userInfo?.token,
    id:(state) => state.userInfo?.id,
    username:(state) => state.userInfo?.username,
    avatar:(state)=>state.userInfo?.avatar,
  },
  actions: {
    login(params: Login): Promise<any> {
      return new Promise((resolve, reject) => {
        loginPassword(params)
          .then((res) => {
            const data = <UserInfo>res.data;
            this.userInfo = data;
            localStorage.setItem('userInfo', JSON.stringify(data))
            resolve(data);
          })
          .catch((error) => {
            reject(error);
          });
      });
    },
    logout() {
      return new Promise((resolve, reject) => {
        quit()
          .then((res) => {
            this.userInfo = null;
            localStorage.removeItem('userInfo')
            router.back();

            resolve(res);
          })
          .catch((error) => {
            reject(error);
          });
      });
    },
  },
  persist: true,
});
