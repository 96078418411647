import { langType } from './lang-base';

export const lang: langType = {
  receivingAddress: 'Dirección Retiro',
  second: 'Seg',
  minimum: 'el menos',
  most: 'mayoría',
  minimumSell: 'empezando a comprar',
  ceiling: 'tapado',
  availableBalance: 'Saldo disponible actual',
  Nomoreatmoment: 'no más todavía ',
  updateSet: 'actualización de versión ',
  pleaseEnterTheAmount: 'por favor, introduzca una cantidad ',
  SearchCurrency: 'buscar número de contrato ',
  Unabletochangeorder: 'no se puede cambiar el estado del pedido ',
  Yourbalanceisinsufficient: 'su saldo insuficiente',
  Ordinarysingle: 'Orden regular',
  DailyDistributions: 'ganancias emitidas diariamente y devueltas al principal al final del período de bloqueo ',
  Resumptionhasbeenactivated: 'tras votar ha iniciado',
  Resumptionhasbeenclosed: 'retroinversión está cerrada',
  orderCount: 'Orden de compromiso actual',
  PleaseEnter: 'por favor, introduzca el número de contrato',
  emailNi: 'por favor, introduzca el código de verificación de correo electrónico',
  existingSES: 'cuenta Btrok existente ',
  email: 'correo electrónico ',
  myinvitation: 'Invitar a amigos',
  teamValue: 'valor de contribución del equipo ',
  shareValue: 'compartir el valor de la contribución ',
  allValue: 'valor total de la contribución ',
  myBenefits: 'el valor de mi contribución ',
  contribution: 'valor de la contribución ',
  contributionDetail: 'detalles del valor de la contribución',
  Dailyinterestrate: 'Tasa de interés diaria',
  LendingDays: 'días de préstamo ',
  Financialproductdetails: 'Detalles de los productos financieros participantes',
  Subscriptionwallet: 'cartera de suscripción ',
  Subscriptionamount: 'Monto de la suscripción',
  SubscriptionDetails: 'Método de suscripción',
  Expirationtime: 'Fecha de caducidad',
  Investmenttype: 'Tipo de compromiso',
  InvestmentDetails: 'Firmar el contrato de prenda',
  Thesystemwillautomatically:
    'El sistema comparará automáticamente las nuevas órdenes de compromiso con las órdenes de compromiso vencidas. Los pedidos retroactivos seguirán recibiendo los beneficios que presenta la plataforma. Luego de que los pedidos que no hayan sido reenviados lleguen a la fecha de vencimiento, los beneficios que hayan sido presentados serán destruidos.',
  Reswitchingfunction: 'invertir de nuevo',
  description: 'instrucciones ',
  Investmentquantity: 'Cantidad máxima de Staking',
  Investmentdetermination: 'Empieza a hacer staking para disfrutar de altas ganancias',
  RevenueDetails: 'Detalles de ingresos',
  InvestmentOrders: 'Registros de replanteo',
  Estimatedtotalrevenue: 'Ingresos totales estimados',
  Redemptionmethod: 'Método de redención',
  Dailyrevenue: 'ingreso diario',
  LockedDays: 'ciclo de Staking',
  Currenttotalinvestmentequivalent: 'Monto total de staking actual (equivalente)',
  complete: 'Confirmar',
  Fundinglimit: 'Límite de capital prendario',
  Contractnumber: 'Número de contrato',
  Loanfinancing: 'invertir de nuevo',
  Subscriptiontime: 'Tiempo de empezar',
  Myinvestmenthistory: 'Mi récord de staking',
  Investmentdays: 'Días de inversión',
  Selfselectionarea: 'Área de selección rápida',
  Quickconstructionarea: 'Área de construcción rápida',
  Smartcontractmatching: 'Coincidencia de contratos inteligentes',
  ImmediateInvestment: 'Hacer staking ahora',
  sourceTime: 'tiempo',
  viewCode: 'Ver código',
  goWealh: 'El camino a la riqueza',
  fileGo: 'Ir al archivo',
  videoRequest: 'Solicitud de video',
  gorequest: 'Solicitud de extracción',
  proceeding: 'Procedimientos',
  pwds: 'Contraseña',
  experienceAll: 'Experiencia estrella completa',
  experienceFive: 'Experiencia de cinco estrellas',
  sharing: 'riqueza compartida',
  customer: 'servicio al cliente',
  todoList: 'elementos pendientes',
  systemMessages: 'mensaje del sistema',
  youHave: 'tienes',
  totleWait: 'Agente, trátelo a tiempo',
  totleBook: 'Mensajes del sistema no leídos',
  message: 'mensaje',
  officialNowGo: 'Empezar ahora',
  officialFinace: '¡Disfrutar de las finanzas blockchain nunca ha sido tan fácil!',
  officialTechnologyLeft: 'frontera',
  officialTechnologyRight: 'Tecnología',
  superSESMessage: 'información Btrok',
  superSES: 'Btrok',
  superFomamce: 'Financiación por contrato, disfrute de la financiación',
  superSecure: 'Inversión en blockchain de seguridad de alto rendimiento',
  superParagraphOne:
    'Btrok International Digital Encrypted Asset Trading Platform es una plataforma integral de comercio de activos digitales. Tenemos una solidez técnica líder, un servicio al cliente de alta calidad y una buena experiencia de usuario, y proporcionamos una plataforma de intercambio en línea gratuita para los entusiastas de las monedas digitales.',
  superParagraphTwo:
    'Btrok International Digital Encrypted Asset Trading Platform también proporciona servicios de cadena de bloques integrales para la planificación de documentos técnicos de moneda digital, el ajuste del algoritmo de cifrado, la investigación y el desarrollo de moneda digital y la lista de plataformas de negociación.',
  superParagraphThree:
    'Los centros de operaciones en el extranjero de la plataforma de comercio de activos de cifrado digital internacional Btrok se establecen respectivamente en Singapur, Camboya y Marshall. ',
  Traverser: 'Atravesador',
  announceList: 'Anúncio',
  announceListDetail: 'Detalles del anuncio',
  official: 'oficial',
  supervise: 'nodo de supervisión',
  Source: 'Código fuente abierto',
  report: 'Informe de auditoría',
  more: 'mas',
  deposit: 'Depositar criptomonedas',
  invite: 'Invitar a amigos',
  lockMining: 'Minería de bloqueo',
  riseRanking: 'lista de clasificación en ascenso',
  currency: 'Moneda',
  recentPrice: 'Último precio',
  gains: 'Cambio en 24h',
  welcomes: 'Bienvenido!',
  home: 'página delantera',
  messageList: 'lista de mensajes',
  noData: 'No Récords',
  market: 'Mercados',
  financialManagement: 'Staking',
  optional: 'Favorites',
  buy: 'compra',
  sell: 'vender',
  completed: 'Finalizar',
  limitOrder: 'Orden límite',
  marketOrder: 'orden de mercado',
  call: 'alcista',
  put: 'bajista',
  success: 'éxito',
  failure: 'fallar',
  transaction: 'transacción',
  contract: 'Trading Spot',
  forex: 'cambio de divisas',
  mine: 'mi',
  notNull: 'no puede estar vacío',
  optionContract: 'contrato de opción',
  currentDelivery: 'Órdenes Abiertas',
  historyDelivery: 'Historia de órdenes',
  fee: 'Tarifa de transacción',
  openAmount: 'monto de apertura',
  bonusQuantity: 'Cantidad de bonificación',
  predictionDirection: 'Dirección de la predicción',
  requestFailure: 'Solicitud fallida, haga clic para recargar',
  predictionResult: 'resultado de la predicción',
  submitSuccess: 'Enviar con éxito',
  sharePoster: 'Compartir cartel',
  cancel: 'Cancell',
  copyLink: 'Copiar link',
  saveImage: 'Guardar imagen',
  pleaseLoginFirst: 'Inicie sesión primero',
  pleaseUseTronWallet: 'Utilice la billetera TRON para abrir el enlace de recarga',
  currentInterest: 'Interés actual',
  fundsSecurity: 'Seguridad de fondos',
  guarantee: 'Garantía',
  holidayEarnings: 'Las ganancias de vacaciones son ininterrumpidas',
  warning: 'No deposite activos no monetarios en la dirección anterior, de lo contrario, los activos no se recuperarán. ',
  confirmation:
    'Después de recargar a la dirección anterior, debe confirmar todo el nodo de la red de cadena de bloques. Generalmente, la red principal de cadena de bloques se acreditará después de 3 confirmaciones de red. ',
  addressChange:
    'Su dirección de recarga no cambiará con frecuencia, y puede recargar repetidamente; si hay algún cambio, haremos todo lo posible para notificarle a través de anuncios en el sitio web o correos electrónicos. ',
  safetyReminder: 'Confirme que el entorno operativo es seguro para evitar que la información se altere o se filtre. ',
  rechargeUSDT: 'Recargar USDT',
  details: 'Detalles',
  dayLimit: 'días',
  mining: 'La minería gana sin parar',
  upcoming: 'próximamente',
  inProgress: 'en curso',
  distribution: 'distribuir',
  progress: 'progreso',
  totalAmount: 'actividad total',
  startTime: 'hora de inicio',
  endTime: 'hora de finalización',
  lockingMining:
    'Lock mining es obtener ingresos mineros en el grupo de minería de la plataforma al confiar USDT a la máquina de minería de potencia de supercomputación de la plataforma',
  productHighlights: 'Productos destacados',
  withdrawal: 'Withdraw Crypto',
  dividendCycle: 'Ciclo de dividendos',
  successfulDepositOnDay: 'El día después del depósito exitoso',
  interestAccrual: 'inicio',
  example: 'Dame un ejemplo',
  profitCalculation: 'Cálculo de ganancias',
  simplestProfitFormula: 'La fórmula de cálculo de ganancias más simple es',
  profitRateCalculationExample: 'Por ejemplo, el ingreso es 2W, el principal es 10W, luego la tasa de retorno',
  calculationOfPrincipal:
    'Para una cuenta de valores, si no recuerda el capital invertido, puede calcularlo en función del valor de mercado total actual y las pérdidas y ganancias actuales',
  currentMarketValueExample:
    'Por ejemplo, el valor de mercado total actual es 11W, y la ganancia flotante actual es 2W, entonces el principal es 11W-2W=9W, y la tasa de rendimiento=(2W/9W)',
  penalty: 'Daños liquidados',
  participate: 'Quiero participar',
  entrustedOrder: 'Orden encomendada',
  pledgingInProgress: 'Prometiendo',
  pledgingComplete: 'Promesas completadas',
  earlyRedemption: 'canje anticipado',
  loading: 'Cargando',
  pledgeAmount: 'cantidad de la promesa',
  dailyEarnings: 'Ganancias diarias',
  earlyRedemptionDeductionHandlingFee: 'Tarifa de manejo de deducción de redención anticipada',
  earlyRedemptionAgain: 'canje anticipado',
  endOfContent: 'Se acabó',
  financialProduct: 'producto financiero',
  fundsUnderManagement: 'fondos bajo gestión',
  expectedTodayEarnings: 'Ganancias estimadas hoy',
  TodayEarnings: 'Las ganancias de hoy',
  accumulatedEarnings: 'Ganancia acumulada',
  trustOrder: 'orden en fideicomiso',
  buy2: 'Ir a comprar',
  dailyReturn: 'Tasa de rendimiento diaria',
  period: 'período',
  switchLanguage: 'Cambio de idioma',
  username: 'nombre de usuario',
  emailPlaceholder: 'Por favor ingrese su correo electrónico',
  forgotPassword: '¿Has olvidado tu contraseña?',
  passwordPlaceholder: 'Por favor ingrese una contraseña',
  submit: 'enviar',
  registerNow: 'regístrate ahora',
  search: 'Busca',
  high: '24H Alto',
  low: '24h Mínimo',
  depth: 'Libro de',
  deal: 'Real-Time',
  buyQuantity2: 'cantidad de oferta comprada ',
  price: 'precio ',
  quantity: 'Volumen',
  sellQuantity: 'número de platos vendidos ',
  time: 'tiempo ',
  direction: 'Tipo',
  inputPrice: 'por favor, introduzca un precio ',
  tradingVolume: 'volumen de transacciones ',
  entrust: 'comisión',
  entrustPrice: 'precio de encargo ',
  entrustQuantity: 'cantidad delegada ',
  totalEntrustment: 'total de la comisión ',
  category: 'categoría ',
  confirm: 'Confirm',
  inputCorrectQuantity: 'por favor, introduzca la cantidad correcta ',
  operationSuccessful: 'éxito de la operación ',
  revoke: 'deshacer ',
  entrustmentCancellationConfirmation: 'encargó la confirmación de anulación',
  areYouSureToCancel: '¿Confirma la retirada? ',
  all: 'todos ',
  currentEntrustment: 'mandato actual ',
  completed2: 'trato hecho ',
  aboutSES: 'Acerca Btrok',
  freeExchange: 'una plataforma para el libre intercambio en línea para la mayoría de los amantes de las monedas digitales',
  modify: 'Modificar',
  bind: 'enlace ',
  alipayAccount: 'número de cuenta alipay ',
  wechatAccount: 'cuenta de wechat ',
  unbound: 'no enlazado ',
  sesPlatformOverview3:
    'Btrok Digital Asset Exchange es una plataforma global de comercio de activos digitales invertida y desarrollada por la Fundación Internacional MAI de Singapur. Su oficina central está ubicada en Singapur y está regulada por Singapore MAS. Se han llevado a cabo una serie de trabajos de construcción de infraestructura en la industria global de blockchain. Como intercambio internacional de activos digitales para inversores globales, Btrok Digital Asset Exchange combina las ventajas actuales de la industria con un equipo senior de gestión financiera bancaria, un equipo profesional de desarrollo de big data, el mejor equipo de desarrollo de blockchain del mundo y un equipo líder de servicios de tecnología Blockchain. Su objetivo es proporcionar a los usuarios servicios de comercio de activos digitales eficientes y seguros.',
  sesPlatformOverview4:
    'Como la primera plataforma integral de comercio de activos digitales del mundo, es una de las principales plataformas comerciales en el campo global de blockchain. Cubre el diseño ecológico global de los activos digitales, posee licencias internacionales y está reconocido por agencias gubernamentales. Btrok seguirá mejorando en términos de innovación tecnológica, iteración de productos, sistema de control de riesgos de seguridad, operación y sistema de servicio al cliente. Al tiempo que proporciona a los usuarios globales servicios de comercio de derivados y al contado de divisas digitales convencionales como Bitcoin y Ethereum, Btrok se mantendrá al día y ayudará a construir un ecosistema blockchain completo.',
  sesPlatformOverview5:
    'Establecer una plataforma de comercio de activos digitales segura, experimentada y gratuita y una infraestructura financiera para satisfacer las necesidades de la mayoría global de entusiastas de las monedas digitales, y construir verdaderamente una comunidad ideal para el intercambio de activos digitales virtuales en la era de la interconexión digital. mayoría de los inversores para proporcionar una plataforma de comercio de servicios integrados segura, confiable y estable.',
  bankCardNumber: 'número de cuenta de la tarjeta ',
  accountSettings: 'configuración de la cuenta ',
  modifyAlipayAccount: 'cambiar cuenta de alipay ',
  receiveQRCode: 'código qr para el cobro ',
  fundPassword: 'Contraseña del fondo',
  pleaseEnterCorrectFundPassword: 'Por favor complete la contraseña de la transacción',
  pleaseEnterAlipayAccount: 'por favor apostando por cuenta de entrada',
  myAssets: 'Resumen de la billetera',
  details2: 'detalles ',

  withdrawalRecords: 'Historial de retiro',
  totalAssetsApprox: 'Balance estimado(USDT)',
  spotAccount: 'cuenta binance coin ',
  searchAssets: 'buscar activos ',
  hideZeroBalances: 'monedas ocultas como 0 ',
  frozen: 'No disponible temporalmente',
  toRelease: 'Esperando ser liberado',
  modifyBankCardNumber: 'cambiar el número de cuenta de la tarjeta ',
  save: 'enviar',
  bankName: 'banco que abre la cuenta ',
  bankCardNumberConfirm: 'confirmar el número de la tarjeta ',
  pleaseEnterBankCardNumberAgain: 'por favor nuevamente su tarjeta bancaria',
  fundPasswordPrompt: 'Contraseña del fondo',
  pleaseEnterFundPassword: 'por favor, introduzca la contraseña de los fondos ',
  modifyFundPassword: 'Cambiar contraseña de transacción',
  modifyingFundPassword: 'Ahora estás cambiando tu contraseña de transacción',
  oldFundPassword: 'Contraseña de transacción antigua',
  newFundPassword: 'Nueva contraseña de transacción',
  confirmFundPassword: 'confirmar la contraseña de fondos ',
  pleaseEnterSetPassword: 'por favor, introduzca la contraseña de configuración ',
  pleaseEnterConfirmFundPassword: 'los su contraseña, por favor',
  passwordsDoNotMatch: 'contraseñas inconsistentes ',
  pleaseEnterYourOldFundPassword: 'Por favor ingrese la contraseña de transacción arriba',
  pleaseEnterYourNewFundPassword: 'Por favor, ingresa la nueva contraseña de transacción',
  pleaseEnterA6DigitPassword: '6 dígitos su contraseña, por favor',
  pleaseConfirmNewFundPassword: 'Por favor, confirme de nuevo la contraseña de negociación.',
  theNewFundPasswordCannotBeTheSameAsTheOldFundPassword:
    'La nueva contraseña del fondo no puede ser la misma que la contraseña del fondo anterior',
  pleaseEnterTheVerificationCode: 'por favor ingrese captchas',
  feedback: 'Comentario',
  yourOpinionIsVeryImportantToUs: '¡Su opinión es muy importante para nosotros!',
  leaveYourValuableOpinionToHelpUsImprove: 'Deje sus valiosos comentarios para ayudarnos a hacerlo mejor.',
  pleaseLeaveYourValuableOpinion: 'deja su valiosa opinión',
  submissionSuccessfulThankYouForYourFeedback: 'al éxito, gracias a su retroalimentación',
  submissionFailed: 'el envío falló ',
  setFundPassword: 'Establecer la contraseña de fondos',
  setYourPaymentMethod: 'por favor, establezca su método de cobro. Asegúrese de usar mi cuenta de nombre real ',
  newPassword: 'nueva contraseña ',
  repeatPassword: 'repetir contraseña ',
  notLoggedIn: 'no ha iniciado sesión ',
  welcome: 'bienvenido a Btrok',
  assetManagement: 'Gestión de activos',
  assetFlow: 'Historial de transacciones de la cuenta principal',
  myInvitation: 'mi invitación ',
  invitationLink: 'Mi enlace de invitación',
  firstLevelFriends: 'amigos de primer nivel ',
  secondLevelFriends: 'amigos de segundo nivel ',
  partnerLevel: 'nivel individual ',
  myEntrustment: 'mis comisiones ',
  coinTrading: 'intercambio de monedas ',
  securitySettings: 'Configuraciones de seguridad',
  pleaseLogInFirst: 'por favor, inicie sesión primero',
  announcement: 'Anúncio',
  changeLoginPassword: 'Cambiar contraseña',
  oldLoginPassword: 'Vecchia password',
  newLoginPassword: 'Contraseña nueva',
  pleaseEnterOldLoginPassword: 'Por favor ingrese la contraseña original',
  pleaseEnterNewLoginPassword: 'Por favor ingrese la contraseña nueva',
  confirmLoginPassword: 'confirmar inicio de sesión ',
  emailVerificationCode: 'código de verificación de correo electrónico ',
  emailBound: 'te has vinculado un buzón de correo ',
  verificationCode: 'código de verificación',
  sendVerificationCode: 'enviar código de verificación ',
  pleaseConfirmInformation: 'por favor confirme si bien completar toda la información',
  pleaseConfirmPassword: 'por favor, asegúrese de que las contraseñas introducidas sean las mismas ',
  makeSureTheOldAndNewPasswordsAreDifferent: 'asegúrese de no disponerse de contraseña como',
  pleaseConfirmNewLoginPassword: 'Por favor, ingrese la nueva contraseña',
  teamPerformance: 'rendimiento del equipo ',
  currentLevel: 'nivel actual ',
  nextLevel: 'nivel siguiente ',
  teamNumber: 'número de personas en el equipo ',
  totalPerformance: 'rendimiento total ',
  onlyForUSDT: 'solo se admite la recarga de USDT para esta dirección ',
  walletAddress: 'dirección de billetera ',
  securityReminder: 'Para proteger la seguridad de su cuenta, completar la autenticación de nombre real antes de operar.',
  personalAvatar: 'avatares personales',
  isVerified: 'Controlar',
  realNameAuthentication: 'Verificación de identidad',
  notVerified: 'No verificado',
  receivingAccount: 'Cuenta de Beneficiario',
  loginPassword: 'Contraseña para acceder',
  fundsPassword: 'Contraseña del fondo',
  settingsCenter: 'Centro de configuración',
  aboutUs: 'Perfil de la empresa',
  helptext: 'Ayuda',
  help: 'Ayuda',
  logout: 'logout',
  confirmLogout: '¿Estás seguro de que quieres cerrar sesión?',
  bindWeChatAccount: 'vincular la cuenta de wechat ',
  withdrawalAddress: 'Dirección de retiro',
  available: 'Saldo disponible actual',
  minimumWithdrawalAmount: 'Monto mínimo de retiro',
  amountReceived: 'Cantidad recibida',
  transactionTime: 'Fecha',
  payableHandlingFee: 'Tarifa de transacción',
  deductibleHandlingFee: 'tarifa de manejo deducible',
  actualHandlingFee: 'pago efectivo de la comisión',
  feeGenerationTime: 'comienza y termina cuando se genera la tarifa de procesamiento ',
  takeIDPhoto: 'tomar para subir su foto de identificación ',
  identityAuthentication: 'identityAuthentication',
  uploadIDCardFront: 'subir la imagen frontal de la tarjeta de identificación ',
  uploadIDCardBack: 'Subir imagen del reverso de la tarjeta de identificación',
  photoAlbum: 'Álbum de fotos',
  IDCardNumber: 'Número ID',
  enterIDCardNumber: 'Por favor ingresa tu número de DNI',
  name: 'Nombre/Apellido',
  idea: 'Visión Btrok',
  goal: 'Objetivo de Btrok',
  depositRecord: 'Historial de Depósito',
  withdrawal2: 'Retirar',
  accountOpeningBranch: 'Sucursal de apertura de cuenta',
  quantityInput: 'Ingrese la cantidad',
  singleLimit: 'Límite por transacción',
  investmentAmount: 'Monto de la Staking',
  incomePrincipal: 'Ingresos / Principal',
  password: 'Contraseña',
  historicalCommission: 'Comisión histórica',
  type: 'Tipo',
  commissionAmount: 'Monto de comisión',
  filter: 'Filtrar',
  pleaseSelect: 'Por favor, seleccione',
  tradingPair: 'Par de negociación',
  reset: 'Restablecer',
  startEndTime: 'Fecha de inicio / fin',
  recharge2: 'Depositar',
  withdraw: 'Retirar',
  transfer: 'Transferir',
  fiatCurrencyPurchase: 'Compra de moneda fiduciaria',
  fiatCurrencySale: 'Venta de moneda fiduciaria',
  activityReward: 'Recompensa de actividad',
  promotionReward: 'Recompensa de promoción',
  dividend: 'Dividendo',
  vote: 'Votar',
  manualRecharge: 'Obtenga ganancias a través de actividades.',
  pairing: 'Asociación',
  activityExchange: 'Intercambio de actividad',
  ctcPurchase: 'Compra de CTC',
  ctcSale: 'Venta de CTC',
  redEnvelopeSending: 'Envío de sobre rojo',
  redEnvelopeReceiving: 'Recepción de sobre rojo',
  withdrawalCodeWithdrawal: 'Retirada de código de retiro',
  withdrawalCodeRecharge: 'Recarga de código de retiro',
  perpetualContractFee: 'Tarifa de contrato perpetuo',
  perpetualContractProfit: 'Ganancia de contrato perpetuo',
  perpetualContractLoss: 'Pérdida de contrato perpetuo',
  optionContractFailure: 'Fallo de contrato de opción',
  optionContractFee: 'Tarifa de contrato de opción',
  optionContractBonus: 'Bono de contrato de opción',
  contractRebate: 'Reembolso de contrato',
  levelReward: 'Recompensa de nivel',
  platformFeeIncome: 'Ingresos por tarifas de plataforma',
  income: 'ingresos',
  promotionIncome: 'ingresos de promoción',
  optionsPromotionIncome: 'ingresos de promoción de opciones',
  optionTeamIncome: 'ingresos de equipo de opciones',
  optionParityAward: 'premio de igualación de opciones',
  investmentTeamReturn: 'retorno de equipo de inversión',
  investmentParityReturn: 'retorno de igualación de inversión',
  investmentPromotionIncome: 'ingresos de promoción de inversión',
  forexEntrustTrading: 'comercio de fideicomiso de Forex',
  forexCancelEntrustTrading: 'cancelación de fideicomiso de Forex',
  liquidationCompensation: 'compensación de liquidación',
  lockedMiningIncome: 'ingresos de minería bloqueada',
  NEWACTIVITY_IN: 'Ingresos por apuestas',
  NEWACTIVITY_DEDUCT: 'Redención de promesas',
  NEWACTIVITY_FREEZE: 'Costo de apuesta',
  NEWACTIVITY_UNFREEZE: 'Opción Descongelar',
  NEWACTIVITY_REDEEM_UNFREEZE: 'Dégel du rachat d’options',
  speedContractProfit: 'Ganancia del contrato spot',
  speedContractLoss: 'Pérdidas de contratos al contado',
  speedContractCommission: 'Tarifa de gestión del segundo contrato',
  CONTRACT_FAST_FREEZE: 'Bloqueo de contrato al contado',
  CONTRACT_FAST_UNFREEZE: 'Contrato spot descongelado',
  confirmUploadAllImages: 'Por favor, confirma si todas las imágenes han sido cargadas',
  inputRealName: 'Por favor ingrese Nombre/Apellido',
  idCardPhoto: 'foto de identificación',
  congratulations: 'Felicitaciones, pasó la revisión y puede operar con confianza.',
  quantity2: 'Volume 24h(USDT)',
  wechatAccount2: 'Por favor, ingrese su cuenta de WeChat',
  realNameIdentityVerified: 'nombre real de validación de identidad',
  withdrawalTime: 'Fecha',
  withdrawalAmount: 'Volumen',
  creditting: 'bajo revisión',
  waitingmoney: 'siendo procesado',
  dztime: 'Fecha',
  rushaddress: 'Dirección de depósito',
  rushnumber: 'Volumen',
  regisertitle: 'registro de correo electrónico',
  inputemailcode: 'por favor, introduzca el código de verificación de correo electrónico ',
  inputloginpassword: 'por favor, introduzca una contraseña (un número de más de 6 dígitos, combinación de letras mayúsculas y minúsculas)',
  inputreloginpassword: 'por favor, introduzca la contraseña de nuevo',
  inputinvite: 'por favor, introduzca el código de invitación',
  iagree: 'he leído y acepto',
  useragreement: 'acuerdo de usuario',
  nowlogin: 'iniciar sesión ahora',
  sendcode: 'enviar código de verificación',
  rightemailformat: 'por favor, introduzca el formato de correo electrónico correcto',
  confirmPassword: 'Confirmar la contraseña nueva',
  passwordtip: 'la contraseña debe contener al menos 6 dígitos',
  nosamepassword: 'Das neue Login-Passwort ist inkonsistent. Bitte bestätigen Sie es erneut',
  noagree: 'aceptar el acuerdo de usuario sin marcar',
  emailverification: 'verificación de correo electrónico',
  amount: 'cantidad ',
  overturn: 'giro ',
  overturntitle: 'transferencia de activos ',
  from: 'de ',
  to: 'a ',
  swapcurrency: 'moneda del contrato ',
  overturnplaceholder: 'por favor, introduzca el número de remitentes ',
  selectaccount: 'seleccionar cuenta ',
  balance: 'Saldo disponible actual',
  contractaccount: 'cuenta de contrato ',
  invitecode: 'código de invitación',
  notset: 'Ir a la configuración',
  day: 'días',
  contactservice: 'contactar con el servicio al cliente ',
  appname: 'Btrok APP',
  download: 'descargar APP',
  orderByHand: 'hacer un pedido manualmente ',
  orderByAuto: 'orden inteligente',
  trevenue: 'ingresos totales ',
  pincome: 'ganancias promocionales ',
  tincome: 'ganancias de equipo ',
  dropList: 'lista desplegable ',
  uploaded: 'subido ',
  youare: 'Estás ',
  disabling: 'deshabilitando',
  enabling: 'habilitando ',
  theAutomaticReinvestmentFeatur: 'la renovación automática',
  nth: 'primero ',
  phase: 'período ',
  currentForecastPeriod: 'período de predicción actual ',
  maximum: 'cantidad máxima ',
  openingPrice: 'precio de apertura ',
  currentPrice: 'precio actual ',
  profitSharing: 'ganancia de dividendos ',
  CurrentTradingIsRestricted: 'transacción límite actual',
  ToEnsureTheSsecurityOfYourAssetsPleaseCarefullyVerifyTheReceivingWalletAddressBeforeMakingAnyDeposits:
    'Para garantizar la seguridad de todos los activos, la recarga por favor verifique cuidadosamente la dirección de la cartera de cobr',
  PleaseProceedWithTheDepositOnlyAfterVerifyingThatEverythingIsCorrect: '. Verifique todo antes de cargar. ',
  PleaseDoNotDepositAnyAssetsOtherThanTheSpecifiedCryptocurrencyToTheAddressMentionedAboveOtherwiseYourAssetsWillNotBeRecoverable:
    'no recargar ningún activo no monetario a la dirección anterior, de lo contrario, el activo no será recuperable. ',
  AfterYouMakeADepositToTheMentionedAddressItRequiresConfirmationFromTheEntireBlockchainNetworkTypicallyItTakesAround30SecondsForTheFundsToBeCreditedToYourAccountAfterThreeConfirmationOnTheBlockchainMainnet:
    'después de recargar a la dirección anterior, se requiere la confirmación de todo el nodo de la red blockchain. En general, la red principal de blockchain llegará a la cuenta después de 3 veces la confirmación de la red, y tomará alrededor de 30 segundos. ',
  PleaseEnsureTheSecurityOfYourElectronicDevicesToPreventAnyTamperingOrLeakageOfInformation:
    'asegúrese de que los dispositivos electrónicos estén seguros para evitar que la información sea manipulada o filtrada. ',
  WithdrawalsAreCurrentlyDisabled: 'la prohibición actual de rs',
  CurrentWithdrawalAddress: 'por favor confirme cuando la premisa de rs',
  isYourWalletAddress: 'si es su dirección de billetera o no ',
  LinkCopiedSuccessfully: 'éxito de copia ',
  LinkCopiedFailed: 'error de copia ',
  goLogin: 'ir a login ',
  other: 'otro',
  SavePicture: 'guardar imagen ',
  SaveLink: 'guardar enlace ',
  languageSettings: 'configuración de idioma ',
  simplifiedChinese: '简体中文',
  traditionalChinese: '繁体中文',
  arabic: 'عربي',
  english: 'English',
  japanese: '日本語',
  French: 'Français',
  Spanish: 'Español',
  German: 'Deutsch',
  Italiano: 'Italiano',
  TotalDailyLoss: 'Ganancia total',
  TotalExpenditureToday: 'Monto total de órdenes hoy',
  TotalIncomeToday: 'Ganancia total hoy',
  CurrentDelivery: 'Entrega actual',
  HistoricalDelivery: 'Historia de órdenes',
  InitialAmount: 'Monto del pedido',
  ForecastDirection: 'Dirección de pronóstico',
  BonusAmount: 'Cantidad de bonificación',
  TransactionFee: 'Tarifa de transacción',
  OrderDuration: 'Duración del pedido',
  Status: 'Estado',
  SelectCurrency: 'Seleccione el tipo de moneda',
  EnterAmount: 'Ingresar monto',
  BUY: 'COMPRAR (LARGO)',
  SELL: 'VENDER (CORTO)',
  WIN: 'Éxito',
  LOSE: 'Fracaso',
  Amount: 'Cantidad',
  expectedReturn: 'Rendimiento Esperado',
  service: 'Centro de Atención al Clientec',
  untrade: 'actualmente intransferible',
  netError: '¡La red está fuera del negocio!',
  questError: 'Hubo un error en la solicitud, ¡inténtelo de nuevo más tarde!',
  failVerified: 'Error de autenticación',
  tiprealverified: 'Verificación de nombre real, comuníquese con el servicio al cliente',
  uploadImg: 'subir credenciales',
  collected: 'recogido',
  canceld: 'Cancelado',
  placeholderAddress: 'Introduce/pega la dirección o el nombre del ENS',
  bindAccountTitle: 'Dirección de Blockchain',
  marketTitle: 'Resumen de mercados',
  regiserSuccess: 'El registro fue exitoso',
  setSuccess: 'configurado exitosamente',
  updateAddress: 'Quiero cambiar la dirección de retiro',
  doingRealName: 'La autenticación de nombre real está en progreso, espere pacientemente',
  updateSuccess: 'Modificado con éxito',
  uploadSuccess: 'Cargado correctamente',
  forgetLpass:
    'Si olvida su contraseña, comuníquese con el servicio de atención al cliente para verificar la información y restablecer su contraseña de inicio de sesión.',
  forgetApass:
    'Si olvida su contraseña, comuníquese con el servicio de atención al cliente para verificar la información y procesarla para restablecer la contraseña de la transacción.',
  updateAddressTip:
    'Para modificar la dirección de pago, comuníquese con el servicio de atención al cliente para verificar la información personal y restablecer la dirección de pago.',
  a48h: 'El retiro ha sido aceptado, la solicitud de retiro se procesará dentro de las 48 horas, espere pacientemente',
  r48h: 'La recarga ha sido aceptada, la orden de recarga se procesará dentro de las 48 horas, espere pacientemente',
  accepted: 'aceptando',
  notremind: 'no lo vuelvas a recordar',
  serviceCode: {
    0: 'éxito',
    500: 'fracaso',
    501: 'registro fallado',
    502: 'error al enviar',
    503: 'error al agregar',
    504: 'la consulta falló',
    600: 'esta moneda no admite monedas',
    1001: 'captcha no existe',
    1003: 'el número de monedas retiradas es mayor que el máximo',
    1004: 'el número de monedas retiradas es inferior al mínimo',
    1005: 'saldo disponible insuficiente',
    1006: 'la billetera está bloqueada',
    1007: 'La autenticación de nombre real se encuentra actualmente bajo revisión. Espere pacientemente hasta que se apruebe la revisión antes de continuar.',
    1008: 'Primero establezca una contraseña de transacción',
    1009: 'contraseña incorrecta de fondos',
    1010: 'no se puede bloquear el activo',
    1011: 'este buzón ya está vinculado',
    1012: 'este nombre de usuario ya existe',
    1013: 'sin este usuario',
    1014: 'el correo electrónico de activación del registro ha sido enviado a su correo electrónico. No repita la solicitud',
    1015: 'por favor, vincule el buzón primero',
    1016: 'el importe de la orden no puede ser 0',
    1017: 'su cuenta actual está desactivada. Por favor, póngase en contacto con el servicio al cliente!',
    1018: 'actividad no existe',
    1019: 'el importe de la compra es inferior al mínimo',
    1020: 'el importe de la compra es mayor que el máximo',
    1021: 'moneda no soportada!',
    1022: 'pares de trading ya favoritos',
    1023: 'no repetir configuración',
    1024: 'longitud de contraseña de fondos no es legal',
    1025: 'el contrato no existe',
    1026: 'la dirección prevista no es correcta',
    1027: 'el monedero del usuario no existe',
    1028: 'cuenta o contraseña incorrecta',
    1029: 'esta cuenta está inactiva/desactivada, póngase en contacto con el servicio al cliente',
    1030: 'Estimado usuario, las apuestas sólo están disponibles para las cuentas con verificación de identidad. Complétala primero.',
    1031: 'Por favor establezca una contraseña de transacción',
    1032: 'Se ha realizado la autenticación de nombre real, no se puede volver a autenticar',
    1033: 'El mismo número de identificación sólo puede autenticarse una vez',
    1035: 'La contraseña anterior es incorrecta',
    1036: 'Por favor, cargue las credenciales de recarga',
    1037: 'Por favor, introduzca el importe correcto de la recarga',
    1038: 'La cuenta no existe',
    1039: 'La contraseña es incorrecta',
    1040: 'La antigua contraseña de la transacción es incorrecta',
    1041: 'La contraseña de la transacción es incorrecta, vuelva a ingresarla',
    1042: 'error en el código de invitación',
    1043: 'Dirección de retiro anormal',
    1065: 'El pedido actual está bajo revisión, no lo envíe nuevamente.',
    3000: 'tiempo límite de aterrizaje',
    888: 'Para acceder a toda la gama de productos y servicios de, todos los usuarios, tanto nuevos como actuales, deben completar la verificación de identidad.',
  },
  //new
  rate: 'ROI',
  mode: 'Modo de transacción',
  num: 'Cantidad de apertura',
  openTime: 'tiempo abierto',
  phoneNi: 'Ingrese el código de verificación del teléfono móvil',
  phonePlaceholder: 'Ingrese su número de teléfono',
  inputphonecode: 'Ingrese el código de verificación del teléfono móvil',
  phone: 'Número de teléfono',
  phoneVcode: 'Código de verificación del teléfono móvil',
  sendSucc: 'Enviado correctamente',
  cScore: 'Puntaje de crédito',
  wallet: 'Billetera',
  searchMp: 'Buscar moneda',
  clickUploadId: 'Haz clic para subir tu foto de identificación',
  modifyFp: 'Ajustes',
  confirmFPassword: 'Confirmar contraseña de negociación',
  pleaseSetYourNewFundPassword: 'Por favor, ajuste una contraseña de fondos',
  nosamefpassword: 'Contraseña de negociación no es correcta, por favor, confirmar de nuevo.',
  forgotFPassword: '¿Olvidó su contraseña comercial?',
  korean: '한국어',
  turkish: 'Türkçe',
};
