import pako from 'pako';
export default class HuobiWebSocket {
  constructor(url) {
    this.ws = new WebSocket(url);
    this.ws.onmessage = (event) => {
      let reader = new FileReader();
      reader.onload = () => {
        let data = pako.inflate(reader.result, { to: 'string' });

        let msg = JSON.parse(data);
        // console.log(msg);

        if (msg.ping) {
          let pong = { pong: msg.ping };
          this.ws.send(JSON.stringify(pong));
          return;
        }

        if (this.ws[msg.ch]) {
          return this.ws[msg.ch](data);
        }

        this.callback(JSON.parse(data));
      };
      reader.readAsBinaryString(event.data);
    };

    this.ws.onclose = () => {
      console.log('Connection closed.');
    };
  }

  onMessage(callback) {
    this.callback = callback;
  }

  onOpen(callback) {
    this.ws.onopen = callback;
  }

  sub(param) {
    console.log(`sub: ${param}`);
    this.ws.send(JSON.stringify({ sub: param }));
  }

  unsub(param) {
    console.log(`unsub: ${param}`);
    this.ws.send(JSON.stringify({ unsub: param }));
  }

  close() {
    this.ws.close();
  }
}
