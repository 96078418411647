import { langType } from './lang-base';

export const lang: langType = {
  receivingAddress: "Ritirare l'indirizzo",
  second: 'Sec',
  minimum: 'minimo',
  most: 'maggior parte',
  minimumSell: 'comincia a vendere',
  ceiling: 'soffitto',
  availableBalance: 'Saldo disponibile corrente',
  Nomoreatmoment: 'Non più al momento',
  updateSet: 'Aggiornamento',
  pleaseEnterTheAmount: "Inserisci l'importo",
  emailNi: 'Inserisci il codice e -mail',
  existingSES: 'Hai già un account',
  email: 'E-mail',
  myinvitation: 'Invita gli amici',
  teamValue: 'Contributo della squadra',
  shareValue: 'Contributo condiviso',
  allValue: 'Contributo totale',
  myBenefits: 'I miei benefici',
  contribution: 'Contributo',
  contributionDetail: 'Dettaglio del contributo',
  Dailyinterestrate: 'Tasso di interesse giornaliero',
  LendingDays: 'Durata del picchettamento',
  Financialproductdetails: 'Dettagli del prodotto finanziario di staking',
  Subscriptionwallet: 'Portafoglio in abbonamento',
  Subscriptionamount: "Importo dell'abbonamento",
  SubscriptionDetails: 'Metodo di abbonamento',
  RevenueDetails: 'Dettagli del reddito',
  Expirationtime: 'Data di scadenza',
  Investmenttype: 'Tipo di pegno',
  Contractnumber: 'Contratto numero',
  InvestmentDetails: 'Firmare un contratto di impegno',
  Thesystemwillautomatically:
    'Il sistema abbinerà automaticamente i nuovi ordini di pegno con gli ordini di pegno scaduti. Gli ordini retroattivi continueranno a ricevere i vantaggi presentati dalla piattaforma. Dopo che gli ordini che non sono stati ripresentati raggiungono la data di scadenza, i benefici presentati verranno distrutti.',
  Reswitchingfunction: 'funzione di reinvestimento',
  description: 'Descrizione',
  Investmentquantity: 'Quantità massima di Staking',
  Investmentdetermination: 'Dettagli di punta',
  InvestmentOrders: 'Lista di controllo per lo staking',
  Estimatedtotalrevenue: 'Entrate totali stimate',
  Redemptionmethod: 'Modalità di picchettamento',
  Dailyrevenue: 'reddito giornaliero',
  LockedDays: 'Ciclo di Staking',
  Currenttotalinvestmentequivalent: 'Ammontare totale attuale dello staking (equivalente)',
  complete: 'Confermare',
  Fundinglimit: 'Limite del capitale in pegno',
  Loanfinancing: 'funzione di reinvestimento',
  Subscriptiontime: 'Ora di inizio',
  Myinvestmenthistory: 'Il mio record di staking',
  Investmentdays: 'Giorni di investimento',
  Selfselectionarea: 'Area di selezione di sé',
  Quickconstructionarea: 'Area di costruzione rapida',
  Smartcontractmatching: 'Corrispondenza con contratto intelligente',
  sourceTime: 'Tempo',
  viewCode: 'Visualizza codice',
  goWealh: 'Il modo per la ricchezza',
  fileGo: 'Vai al file',
  videoRequest: 'Richiesta video',
  gorequest: 'Richiesta di pull',
  proceeding: 'Procedimento',
  pwds: "Parola d'ordine",
  experienceAll: 'Esperienza a stella completa',
  experienceFive: 'Esperienza a cinque stelle',
  sharing: 'Condivisione della ricchezza',
  customer: 'Cliente',
  todoList: 'Lista di cose da fare',
  systemMessages: 'Messaggi di sistema',
  youHave: 'Hai',
  totleWait: 'articoli da fare, gestirli in modo tempestivo',
  totleBook: 'Messaggi di sistema non letto',
  message: 'Messaggio',
  officialNowGo: 'A partire da adesso',
  officialFinace: 'Godere di Blockchain Finance non è mai stato così semplice',
  officialTechnologyLeft: 'Bordo tagliente',
  officialTechnologyRight: 'tecnologia',
  superParagraphOne:
    'La piattaforma di trading di risorse di criptovaluta digitale internazionale di Btrok è una piattaforma di trading di risorse digitali completa, abbiamo una forza tecnica leader, un servizio clienti di alta qualità e una buona esperienza utente, fornendo una piattaforma di scambio online gratuita per la maggior parte degli appassionati di valuta digitale.',
  superParagraphTwo:
    "La piattaforma di trading di risorse di criptovaluta digitale internazionale di Btrok fornisce anche servizi blockchain per la pianificazione del white paper in valuta digitale, l'adeguamento dell'algoritmo di crittografia, la ricerca e lo sviluppo delle valute digitali e l'elenco della piattaforma di trading.",
  superParagraphThree:
    'I centri operativi Overseas di Btrok International Digital Crypto Asset Trading sono istituiti a Singapore, Cambogia e Marshall.',
  superSESMessage: 'Messaggio btrok',
  superSES: 'Btrok',
  superFomamce: 'Finanza del contratto, goditi la finanza',
  superSecure: 'Investimento blockchain sicuro ad alto rendimento',
  TodayEarnings: 'I guadagni di oggi',
  ImmediateInvestment: 'Metti in stake',
  financialManagement: 'Staking',
  Traverser: 'Traverser',
  announceList: 'Annuncio',
  announceListDetail: "Dettaglio dell'annuncio",
  official: 'Ufficiale',
  supervise: 'Nodo di supervisione',
  Source: 'Open source',
  report: 'Rapporto',
  more: 'Di più',
  deposit: 'Deposita criptovalute',
  invite: 'Invita gli amici',
  lockMining: "Blocca l'estrazione",
  riseRanking: 'La classifica è in rialzo',
  dropList: 'Elenco di drop',
  home: 'prima pagina',
  welcomes: 'Benvenuto',
  gains: 'Variazione 24h',
  recentPrice: 'Ultimo prezzo',
  messageList: 'Elenco dei messaggi',
  noData: 'No info',
  market: 'Mercati',
  optional: 'Favorites',
  buy: 'acquistare',
  sell: 'vendere',
  completed: 'finito',
  cancel: 'Annulla',
  limitOrder: 'Ordine limite',
  marketOrder: 'Ordine di mercato',
  call: 'Rialzista',
  put: 'Ribassista',
  success: 'successo',
  failure: 'Fallimento',
  transaction: 'Transazione',
  contract: 'Spot di trading',
  forex: 'forex',
  mine: 'Account',
  notNull: 'Non può essere vuoto',
  optionContract: 'Contratto di opzione',
  currentDelivery: 'Ordini Aperti',
  historyDelivery: 'Cronologia ordini',
  fee: 'Costo della transazione',
  openAmount: 'Monte di apertura',
  bonusQuantity: 'Importo bonus',
  predictionDirection: 'Direzione di previsione',
  requestFailure: 'Richiesta non riuscita, fare clic per ricaricare',
  predictionResult: 'Risultato previsto',
  submitSuccess: 'Invia con successo',
  sharePoster: 'Condividi poster',
  copyLink: 'Copia link',
  saveImage: 'Salva immagine',
  pleaseLoginFirst: 'Per favore fai prima il log in',
  pleaseUseTronWallet: 'Si prega di utilizzare il portafoglio Tron per aprire il collegamento di ricarica',
  currentInterest: 'Interesse attuale',
  fundsSecurity: 'Sicurezza dei fondi',
  guarantee: 'Garanzia',
  holidayEarnings: 'I guadagni delle vacanze sono ininterrotti',
  warning: "Non depositare alcuna attività non valuta all'indirizzo di cui sopra, altrimenti le attività non verranno recuperate.",
  confirmation:
    "Dopo aver ricaricato l'indirizzo sopra, è necessario confermare l'intero nodo di rete blockchain. In generale, la rete principale blockchain verrà accreditata dopo 3 conferme di rete.",
  addressChange:
    'Il tuo indirizzo di ricarica non cambierà frequentemente e puoi ricaricare ripetutamente; In caso di modifiche, faremo del nostro meglio per avvisarti tramite annunci o e -mail del sito Web.',
  safetyReminder: "Conferma che l'ambiente operativo è sicuro per evitare che le informazioni vengano manomesse o trapelate.",
  rechargeUSDT: 'Ricarica USDT',
  details: 'Dettagli',
  dayLimit: 'giorni',
  mining: 'Il mining guadagna senza sosta',
  upcoming: 'prossimamente',
  inProgress: 'in corso',
  distribution: 'distribuzione',
  progress: 'progresso',
  totalAmount: 'attività totale',
  startTime: 'Ora di inizio',
  endTime: 'Tempo scaduto',
  lockingMining:
    'Il mining di blocco è quello di guadagnare entrate minerarie nel pool di estrazione della piattaforma affidando USDT alla macchina per estrazione di alimentazione super computing della piattaforma',
  productHighlights: 'Punti salienti del prodotto',
  withdrawal: 'Withdraw Crypto',
  dividendCycle: 'Ciclo di dividendo',
  successfulDepositOnDay: 'Il giorno dopo un deposito riuscito',
  interestAccrual: 'inizio',
  example: 'Fammi un esempio',
  profitCalculation: 'Calcolo degli utili',
  simplestProfitFormula: 'La formula di calcolo del profitto più semplice è',
  profitRateCalculationExample: 'Ad esempio, il reddito è 2W, il principale è 10 W, quindi il tasso di rendimento',
  calculationOfPrincipal: 'Per un conto in tito',
  currentMarketValueExample:
    "Ad esempio, l'attuale valore di mercato totale è 11 W e l'attuale vittoria fluttuante è 2W, quindi il principale è 11W-2W = 9W e il tasso di rendimento = (2W/9W)",
  penalty: 'Danni liquidati',
  participate: 'voglio partecipare',
  entrustedOrder: 'Ordine affidato',
  pledgingInProgress: 'Impegnarsi',
  pledgingComplete: 'Impegnarsi completato',
  earlyRedemption: 'Redenzione precoce',
  loading: 'Caricamento',
  pledgeAmount: 'Importo della promessa',
  dailyEarnings: 'Guadagni giornalieri',
  earlyRedemptionDeductionHandlingFee: 'Commissione di gestione della detrazione di rimborso precoce',
  earlyRedemptionAgain: 'Redenzione precoce',
  endOfContent: 'È finita',
  financialProduct: 'prodotto finanziario',
  fundsUnderManagement: 'fondi sotto gestione',
  expectedTodayEarnings: 'Guadagni stimati oggi',
  accumulatedEarnings: 'Profitto accumulato',
  trustOrder: 'Ordine in fiducia',
  buy2: 'Vai a comprare',
  dailyReturn: 'Tasso di rendimento giornaliero',
  period: 'periodo',
  switchLanguage: 'cambio di lingua',
  username: 'Nome utente',
  emailPlaceholder: 'Inserisci la tua email',
  forgotPassword: 'Password dimenticata?',
  passwordPlaceholder: 'per favore inserisci LA TUA password',
  submit: 'Invia',
  registerNow: 'Iscriviti ora',
  search: 'Cercare',
  high: '24H Alto',
  low: '24H Basso',
  depth: 'Profondità',
  deal: 'Affare',
  buyQuantity2: 'Acquista il volume',
  price: 'Prezzo',
  quantity: 'Importo',
  sellQuantity: 'Vendi il volume',
  time: 'tempo',
  direction: 'direzione',
  inputPrice: 'Prezzo di input Qing',
  tradingVolume: 'Volume commerciale',
  entrust: 'Affidare',
  entrustPrice: 'Prezzo di affidamento',
  entrustQuantity: 'Quantità affidata',
  totalEntrustment: 'Affidamento totale',
  category: 'categoria',
  confirm: 'Confermare',
  inputCorrectQuantity: 'Inserisci la quantità corretta',
  operationSuccessful: 'Operazione riuscita',
  revoke: 'Revocare',
  entrustmentCancellationConfirmation: "Conferma di cancellazione dell'impostazione",
  areYouSureToCancel: "Sei sicuro di annullare l'ordine?",
  all: 'Tutto',
  currentEntrustment: 'Affidamento corrente',
  completed2: 'Completato',
  aboutSES: 'A proposito di Btrok',
  freeExchange: 'Fornire una piattaforma di scambio online gratuita per gli appassionati di valuta digitale',
  modify: 'Modifica',
  bind: 'Legamento',
  alipayAccount: 'Account Alipay',
  wechatAccount: 'Account WeChat',
  unbound: 'Impostarsi',
  sesPlatformOverview3:
    'Btrok Digital Asset Exchange è una piattaforma globale di scambio di risorse digitali investita e sviluppata dalla MAI International Foundation di Singapore. La sua sede principale si trova a Singapore ed è regolamentata da Singapore MAS. Una serie di lavori di costruzione di infrastrutture sono stati eseguiti nel settore globale della blockchain. In quanto scambio internazionale di risorse digitali per investitori globali, Btrok Digital Asset Exchange combina gli attuali vantaggi del settore con un team senior di gestione finanziaria bancaria, un team professionale di sviluppo di big data, il miglior team di sviluppo blockchain al mondo e un team leader di servizi tecnologici Blockchain. Ha lo scopo di fornire agli utenti servizi di trading di asset digitali efficienti e sicuri.',
  sesPlatformOverview4:
    'Essendo la prima piattaforma di trading di asset digitali completa al mondo, è una delle migliori piattaforme di trading nel campo globale della blockchain. Coprendo il layout ecologico globale delle risorse digitali, detiene licenze internazionali ed è riconosciuto dalle agenzie governative. Btrok continuerà a migliorare in termini di innovazione tecnologica, iterazione del prodotto, sistema di controllo dei rischi per la sicurezza, funzionamento e sistema di servizio clienti. Fornendo agli utenti globali i principali servizi di scambio di valute digitali e derivati come Bitcoin ed Ethereum, Btrok manterrà il passo con i tempi e aiuterà a costruire un ecosistema blockchain completo.',
  sesPlatformOverview5:
    'Creare una piattaforma di scambio di asset digitali sicura, esperta e gratuita e un’infrastruttura finanziaria per soddisfare le esigenze della maggioranza globale degli appassionati di valuta digitale e costruire veramente una comunità ideale per lo scambio di asset digitali virtuali nell’era dell’interconnessione digitale. maggior parte degli investitori per fornire una piattaforma di trading di servizi integrata sicura, affidabile e stabile.',
  bankCardNumber: 'Numero di conto della carta di banca',
  accountSettings: "Impostazioni dell'account",
  modifyAlipayAccount: 'Modifica account Alipay',
  receiveQRCode: 'Ricevi codice bidimensionale',
  fundPassword: 'Trading Password',
  pleaseEnterCorrectFundPassword: 'Inserisci la password della transazione',
  pleaseEnterAlipayAccount: 'Inserisci il numero di conto Alipay',
  myAssets: 'Panoramica wallet',
  details2: 'Dettagli',
  withdrawalRecords: 'Storia dei prelievi',
  totalAssetsApprox: 'Saldo stimato（USDT）',
  spotAccount: 'Account Crypto',
  searchAssets: 'Asset di ricerca',
  hideZeroBalances: 'Valuta nascosta come 0',
  frozen: 'temporaneamente non disponibile',
  toRelease: 'Warten auf Veröffentlichung',
  modifyBankCardNumber: 'Modifica il numero di conto della carta di banca',
  save: 'Salva',
  bankName: 'banca del conto',
  bankCardNumberConfirm: 'Conferma il numero della carta',
  pleaseEnterBankCardNumberAgain: 'Si prega di inserire di nuovo il numero della carta di banco',
  fundPasswordPrompt: 'Password del fondo',
  pleaseEnterFundPassword: 'Inserisci la password del fondo',
  modifyFundPassword: 'Cambia password di transazione',
  modifyingFundPassword: 'Ora stai modificando la password della transazione',
  oldFundPassword: 'Vecchia password di transazione',
  newFundPassword: 'Nuova trading password',
  confirmFundPassword: 'Conferma la password del fondo',
  pleaseEnterSetPassword: 'Immettere la password di configurazione',
  pleaseEnterConfirmFundPassword: 'Immettere la password del fondo di conferma',
  passwordsDoNotMatch: 'le passwords non corrispondono',
  pleaseEnterYourOldFundPassword: 'Inserisci la vecchia password della transazione',
  pleaseEnterYourNewFundPassword: 'Inserisci la nuova trading password',
  pleaseEnterA6DigitPassword: 'Immettere una password a 6 cifre',
  pleaseConfirmNewFundPassword: 'Conferma nuovamente la tua trading password',
  theNewFundPasswordCannotBeTheSameAsTheOldFundPassword:
    'La nuova password del fondo non può essere la stessa della password del vecchio fondo',
  pleaseEnterTheVerificationCode: 'Inserisci il codice di verifica',
  feedback: 'Feedback',
  yourOpinionIsVeryImportantToUs: 'La tua opinione è molto importante per noi!',
  leaveYourValuableOpinionToHelpUsImprove: 'Lascia il tuo prezioso feedback per aiutarci a fare meglio',
  pleaseLeaveYourValuableOpinion: 'Si prega di lasciare la tua preziosa opinione',
  submissionSuccessfulThankYouForYourFeedback: 'Invio di successo, grazie per il tuo feedback',
  submissionFailed: 'Sottomissione non riuscita',
  setFundPassword: 'Imposta la trading password',
  setYourPaymentMethod: 'Si prega di impostare il tuo metodo di pagamento, assicurati di utilizzare il tuo account reale',
  newPassword: 'nuova password',
  repeatPassword: 'Ripeti la password',
  notLoggedIn: 'accesso non effettuato',
  welcome: 'Benvenuti a Btrok',
  assetManagement: 'Gestione patrimoniale',
  assetFlow: 'Cronologia delle transazioni del conto principale',
  myInvitation: 'Il mio invito',
  invitationLink: 'Il mio collegamento di invito',
  firstLevelFriends: 'Amici di primo livello',
  secondLevelFriends: 'Amici di secondo livello',
  partnerLevel: 'Livello personale',
  myEntrustment: 'Il mio affidamento',
  coinTrading: 'commerciazione di monete',
  securitySettings: 'Impostazioni di sicurezza',
  pleaseLogInFirst: 'Per favore fai prima il log in',
  announcement: 'Annuncio',
  changeLoginPassword: 'Modifica la password',
  oldLoginPassword: 'Vecchia password',
  newLoginPassword: 'Nuova password',
  confirmPassword: 'Conferma la nuova password',
  emailVerificationCode: 'Codice di verifica e -mail',
  emailBound: "Hai un'e -mail legata",
  pleaseEnterOldLoginPassword: 'Inserisci la vecchia password',
  pleaseEnterNewLoginPassword: 'Inserisci la nuova password',
  makeSureTheOldAndNewPasswordsAreDifferent: 'Assicurati che le password vecchie e nuove siano diverse',
  pleaseConfirmNewLoginPassword: 'Inserisci nuovamente la password',
  verificationCode: 'codice di verifica',
  sendVerificationCode: 'Invia codice di verifica',
  pleaseConfirmInformation: 'Si prega di confermare se tutte le informazioni sono riempite',
  pleaseConfirmPassword: 'Si prega di confermare se le password immesse due volte sono le stesse',
  teamPerformance: 'prestazioni di squadra',
  currentLevel: 'livello attuale',
  nextLevel: 'livello successivo',
  teamNumber: 'Numero di squadra',
  totalPerformance: 'Prestazioni totali',
  onlyForUSDT: 'Solo per USDT',
  walletAddress: 'Indirizzo del portafoglio',
  securityReminder: "Per proteggere la sicurezza del tuo account, completa l'autenticazione del nome reale prima di fare trading!",
  personalAvatar: 'Avatar Personale',
  isVerified: 'Controllo',
  realNameAuthentication: 'Verifica ID',
  notVerified: 'verifica ora',
  receivingAccount: 'Conto del beneficiario',
  loginPassword: 'Password',
  fundsPassword: 'Trading-Passwort',
  settingsCenter: 'Centro impostazioni',
  aboutUs: 'Profilo Aziendale',
  helptext: 'Aiuto',
  help: 'Aiuto',
  logout: 'logout',
  confirmLogout: 'Sei sicuro di voler uscire?',
  bindWeChatAccount: 'Account WeChat Bind',
  withdrawalAddress: 'Indirizzo di ritiro',
  available: 'Saldo disponibile corrente',
  minimumWithdrawalAmount: 'Importo minimo di prelievo',
  amountReceived: 'Quantità ricevuta',
  transactionTime: 'Data',
  payableHandlingFee: 'Costo della transazione',
  deductibleHandlingFee: 'Detrazione delle commissioni',
  actualHandlingFee: 'Commissione effettiva',
  feeGenerationTime: "L'ora di inizio e fine è il tempo di generazione delle tasse",
  takeIDPhoto: 'Prendi e carica la tua foto di identificazione',
  identityAuthentication: 'Verifica personale',
  uploadIDCardFront: "Carica immagine frontale della carta d'identità",
  uploadIDCardBack: "Carica immagine indietro della scheda d'identità",
  photoAlbum: 'Album di foto',
  IDCardNumber: 'Numero ID',
  enterIDCardNumber: "Inserisci il numero della tua carta d'identità",
  name: 'Nome / Cognome',
  idea: 'Visione Btrok',
  goal: 'Obiettivo Btrok',
  depositRecord: 'Cronologia dei depositi',
  withdrawal2: 'Preleva',
  accountOpeningBranch: "Filiale di apertura dell'account",
  quantityInput: 'Inserisci la quantità',
  singleLimit: 'Limite singolo',
  investmentAmount: 'Importo della Staking',
  incomePrincipal: 'reddito/capitale',
  password: "Parola d'ordine",
  historicalCommission: 'Commissione storica',
  type: 'Tipo',
  commissionAmount: 'importo commissionato',
  filter: 'Filtro',
  pleaseSelect: 'Seleziona',
  tradingPair: 'Coppia di trading',
  reset: 'Ripristina',
  goLogin: 'Vai a login',
  currency: 'Moneta',
  startEndTime: 'Ora di inizio e fine',
  recharge2: 'Deposita',
  withdraw: 'Ritirare',
  transfer: 'Trasferimento',
  fiatCurrencyPurchase: 'Acquisto di valuta Fiat',
  fiatCurrencySale: 'Vendita di valute fiat',
  activityReward: 'Ricompensa delle attività',
  promotionReward: 'Ricompensa della promozione',
  dividend: 'dividendo',
  vote: 'votazione',
  manualRecharge: 'Restituire il profitto dalle attività',
  pairing: 'accoppiamento',
  activityExchange: 'Scambio di attività',
  ctcPurchase: 'CTC Buy',
  ctcSale: 'CTC Vendi',
  redEnvelopeSending: 'Invio di buste rossa',
  redEnvelopeReceiving: 'Busta rossa che riceve',
  withdrawalCodeWithdrawal: 'Prelievo del codice di prelievo',
  withdrawalCodeRecharge: 'Ricarica del codice di prelievo in contanti',
  perpetualContractFee: 'Commissione del contratto perpetuo',
  perpetualContractProfit: 'Profitto del contratto perpetuo',
  perpetualContractLoss: 'perdita di contratto perpetuo',
  optionContractFailure: 'Contratto di opzione non riuscita',
  optionContractFee: 'Commissione del contratto di opzione',
  optionContractBonus: 'Bonus a contratto di opzione',
  contractRebate: 'Sconto contrattuale',
  levelReward: 'Ricompensa a livello',
  platformFeeIncome: 'Reddito a pagamento',
  income: 'reddito',
  promotionIncome: 'Reddito promozionale',
  optionsPromotionIncome: 'Reddito di promozione delle opzioni',
  optionTeamIncome: 'Opzione Reddito del team',
  optionParityAward: 'Option Party Award',
  investmentTeamReturn: 'Reddito del team di investimento',
  investmentParityReturn: 'Reddito da parità di investimento',
  investmentPromotionIncome: 'Reddito di promozione degli investimenti',
  forexEntrustTrading: 'Exchange Exchange ha affidato il trading',
  forexCancelEntrustTrading: 'La cancellazione dei cambi affidati ha affidato la transazione',
  liquidationCompensation: 'Compensazione della liquidazione',
  lockedMiningIncome: 'Reddito da mining bloccato',
  speedContractProfit: 'Profitto del contratto spot',
  speedContractLoss: 'Spot perdite contrattuali',
  speedContractCommission: 'Commissione per il contratto di velocità',
  CONTRACT_FAST_FREEZE: 'Blocco del contratto spot',
  CONTRACT_FAST_UNFREEZE: 'Contratto spot sbloccato',
  NEWACTIVITY_IN: 'Reddito da puntata',
  NEWACTIVITY_DEDUCT: 'Redenzione del pegno',
  NEWACTIVITY_FREEZE: 'Costo di puntata',
  NEWACTIVITY_UNFREEZE: 'Opzione scongelata',
  NEWACTIVITY_REDEEM_UNFREEZE: "Sblocco del riscatto dell'opzione",
  confirmUploadAllImages: 'Si prega di confermare se tutte le immagini sono state caricate',
  inputRealName: 'Per favore inserisci Nome/Cognome',
  idCardPhoto: "Foto d'identità",
  congratulations: 'Congratulazioni, hai superato la revisione e puoi fare trading con sicurezza',
  quantity2: 'Volume 24h(USDT)',
  wechatAccount2: "Inserisci l'account WeChat",
  realNameIdentityVerified: 'Vero nome identità verificata',
  withdrawalTime: 'Data',
  withdrawalAmount: 'Importo',
  creditting: 'in corso di revisione',
  waitingmoney: 'in lavorazione',
  dztime: 'Data',
  rushaddress: 'Indirizzo di deposito',
  rushnumber: 'Importo',
  regisertitle: 'registrazione email',
  inputemailcode: 'Inserisci il codice di verifica e -mail',
  inputloginpassword: 'Password (6+ caratteri, numeri, lettere superiori/inferiori)',
  inputreloginpassword: 'Si prega di inserire di nuovo la password',
  inputinvite: 'Immettere il codice di invito',
  iagree: "Ho letto e sono d'accordo",
  useragreement: 'Accordo per gli utenti',
  nowlogin: 'Login',
  sendcode: 'Invia codice di verifica',
  rightemailformat: 'Inserisci il formato e -mail corretto',
  passwordtip: 'La password deve contenere almeno 6 cifre',
  nosamepassword: 'Das neue Login-Passwort ist inkonsistent. Bitte bestätigen Sie es erneut',
  noagree: "Accordo dell'utente non accettato",
  emailverification: 'verifica email',
  amount: 'Quantità',
  overturn: 'Trasferimento',
  overturntitle: 'Trasferimento di attività',
  from: 'Da',
  to: 'A',
  swapcurrency: 'Valuta del contratto perpetua',
  overturnplaceholder: "Inserisci l'importo del trasferimento",
  selectaccount: 'Seleziona account',
  balance: 'Saldo attualmente disponibile',
  contractaccount: 'Conto del contratto',
  invitecode: 'Codice di invito',
  notset: 'Vai alle impostazioni',
  day: 'Giorni',
  contactservice: 'Contattare il Servizio Clienti',
  appname: 'App btrok',
  download: "Scarica l'app",
  orderByHand: 'Ordine mannuale',
  orderByAuto: 'Ordine intelligente',
  trevenue: 'Entrate totali',
  pincome: 'Beneficio di promozione',
  tincome: 'Reddito della squadra',
  SearchCurrency: 'Numero del contratto di ricerca',
  uploaded: 'Caricato',
  confirmLoginPassword: 'Confermare',
  PleaseEnter: 'Inserisci il numero del contratto',
  Ordinarysingle: 'Ordine regolare',
  Unabletochangeorder: "Impossibile modificare lo stato dell'ordine",
  Yourbalanceisinsufficient: 'Il tuo equilibrio è insufficiente',
  Resumptionhasbeenactivated: 'Il reinvestimento è ora attivo',
  orderCount: "Quantità dell'ordine di picchettamento",
  Resumptionhasbeenclosed: 'Il reinvestimento è ora disabilitato',
  DailyDistributions: 'Distribuzione giornaliera degli utili, rendimento del capitale dopo il periodo di blocco',
  youare: 'Sei',
  disabling: 'disabilitazione',
  enabling: 'abilitare',
  theAutomaticReinvestmentFeatur: 'La funzione di reinvestimento automatico',
  nth: 'NO.',
  phase: '',
  currentForecastPeriod: 'Periodo di previsione attuale',
  maximum: 'max',
  openingPrice: 'Prezzo di apertura',
  currentPrice: 'Prezzo attuale',
  profitSharing: 'Partecipazione agli utili',
  CurrentTradingIsRestricted: 'Il trading attuale è limitato',
  ToEnsureTheSsecurityOfYourAssetsPleaseCarefullyVerifyTheReceivingWalletAddressBeforeMakingAnyDeposits:
    "Per garantire la sicurezza delle risorse, verificare attentamente l'indirizzo del portafoglio ricevente prima di effettuare qualsiasi deposito:",
  PleaseProceedWithTheDepositOnlyAfterVerifyingThatEverythingIsCorrect:
    '. Si prega di procedere con il deposito solo dopo aver verificato che tutto è corretto.',
  PleaseDoNotDepositAnyAssetsOtherThanTheSpecifiedCryptocurrencyToTheAddressMentionedAboveOtherwiseYourAssetsWillNotBeRecoverable:
    "Si prega di non depositare attività diverse dalla criptovaluta specificata all'indirizzo sopra menzionato. Altrimenti, le tue risorse non saranno recuperabili.",
  AfterYouMakeADepositToTheMentionedAddressItRequiresConfirmationFromTheEntireBlockchainNetworkTypicallyItTakesAround30SecondsForTheFundsToBeCreditedToYourAccountAfterThreeConfirmationOnTheBlockchainMainnet:
    "Dopo aver effettuato un deposito all'indirizzo menzionato, richiede la conferma dell'intera rete blockchain. In genere, ci vogliono circa 30 secondi perché i fondi vengano accreditati sul tuo account dopo tre conferme sul mainnet blockchain.",
  PleaseEnsureTheSecurityOfYourElectronicDevicesToPreventAnyTamperingOrLeakageOfInformation:
    'Assicurati che la sicurezza dei tuoi dispositivi elettronici prevenga eventuali manomissioni o perdite di informazioni.',
  WithdrawalsAreCurrentlyDisabled: 'I prelievi sono attualmente disabilitati',
  CurrentWithdrawalAddress: "Si prega di confermare se l'indirizzo di prelievo corrente",
  isYourWalletAddress: 'è il tuo indirizzo del portafoglio',
  LinkCopiedSuccessfully: 'Link copiato con successo',
  LinkCopiedFailed: 'Copia del collegamento non riuscita',
  other: 'Altro',
  SavePicture: 'Salva immagine',
  SaveLink: 'Salva il collegamento',
  languageSettings: 'Lingua',
  simplifiedChinese: '简体 中文',
  traditionalChinese: '繁体 中文',
  arabic: 'عربي',
  english: 'English',
  japanese: '日本語',
  French: 'Français',
  Spanish: 'Español',
  German: 'Deutsch',
  Italiano: 'Italiano',
  TotalDailyLoss: 'Profitto totale',
  TotalExpenditureToday: "Importo totale dell'ordine oggi",
  TotalIncomeToday: 'Profitto totale oggi',
  CurrentDelivery: 'Ordine attuale',
  HistoricalDelivery: 'Cronologia ordini',
  InitialAmount: "Ammontare dell'ordine",
  ForecastDirection: 'Direzione di previsione',
  BonusAmount: 'Importo bonus',
  TransactionFee: 'Costo della transazione',
  OrderDuration: "Durata dell'ordine",
  Status: 'Stato',
  SelectCurrency: 'Seleziona Valuta',
  EnterAmount: "Inserire l'importo",
  BUY: 'Acquista (LONG)',
  SELL: 'Vendere (Short)',
  WIN: 'Successo',
  LOSE: 'Fallimento',
  Amount: 'Quantità',
  expectedReturn: 'ritorno previsto',
  service: 'Servizio clienti',
  untrade: 'Attualmente non rintracciabile',
  netError: 'La rete è fuori dal mercato!',
  questError: "C'è stato un errore nella richiesta, riprova più tardi!",
  failVerified: 'Autenticazione fallita',
  tiprealverified: 'Verifica del nome reale, contattare il servizio clienti',
  uploadImg: 'caricare le credenziali',
  collected: 'raccolto',
  canceld: 'Annullato',
  placeholderAddress: "Inserire/incollare l'indirizzo o il nome ENS",
  bindAccountTitle: 'Indirizzo blockchain',
  marketTitle: 'Panoramica mercati',
  regiserSuccess: 'Register ha avuto successo',
  setSuccess: 'Impostare correttamente',
  updateAddress: "Voglio cambiare l'indirizzo di Ritirare",
  doingRealName: "L'autenticazione del nome reale è in corso, attendi pazientemente",
  updateSuccess: 'Modificato correttamente',
  uploadSuccess: 'Caricato con successo',
  forgetLpass:
    'Se dimentichi la tua password, contattare il servizio clienti per verificare le informazioni ed elaborarla per ripristinare la password di accesso',
  forgetApass:
    'Se dimentichi la password, contattare il servizio clienti per verificare le informazioni e ripristinare la password della transazione.',
  updateAddressTip:
    "Per modificare l'indirizzo di pagamento, contattare il servizio clienti per verificare le informazioni personali e ripristinare l'indirizzo di pagamento.",
  a48h: 'Il ritiro è stato accettato, la domanda di prelievo verrà elaborata entro 48 ore, si prega di attendere pazientemente',
  r48h: "La ricarica è stata accettata, l'ordine di ricarica verrà elaborato entro 48 ore, si prega di attendere pazientemente",
  accepted: 'Accettazione',
  notremind: 'Non ricordartelo più',
  serviceCode: {
    '0': 'Successo',
    '500': 'Fallimento',
    '501': 'Fallimento della registrazione',
    '502': 'Invio fallito',
    '503': 'Aggiungi fallito',
    '504': 'La domanda non è riuscita',
    '600': 'Questa valuta non supporta il ritiro',
    '1001': 'Il codice di verifica non esiste',
    '1003': 'Il numero di prelievi è maggiore del massimo',
    '1004': 'La quantità di denaro ritirata è inferiore al minimo',
    '1005': 'Equilibrio disponibile insufficiente',
    '1006': 'Il portafoglio è bloccato',
    '1007':
      "L'autenticazione reale è attualmente in fase di revisione. Si prega di attendere pazientemente fino a quando la revisione non viene approvata prima di procedere.",
    '1008': 'Si prega di impostare prima una password per la transazione',
    '1009': 'Password del fondo errata',
    '1010': 'Impossibile bloccare le risorse',
    '1011': 'Questa casella di posta è stata rilegata',
    '1012': 'Questo nome utente esiste già',
    '1013': 'Nessun utente',
    '1014': 'E -mail di attivazione della registrazione è stata inviata alla tua e -mail, non ripetere la richiesta',
    '1015': 'Si prega di vincolare prima la posta elettronica',
    '1016': "L'importo dell'ordine non può essere 0",
    '1017': 'Il tuo account corrente è disabilitato, contatta il servizio clienti!',
    '1018': "L'attività non esiste",
    '1019': "L'importo dell'acquisto è inferiore all'importo minimo",
    '1020': "Importo dell'acquisto maggiore dell'importo massimo",
    '1021': 'Valuta non supportata!',
    '1022': 'Sono state raccolte coppie di trading',
    '1023': 'Non ripetere le impostazioni',
    '1024': 'La lunghezza della password del fondo non è legale',
    '1025': 'Il contratto non esiste',
    '1026': 'Direzione di previsione sbagliata',
    '1027': "Il portafoglio dell'utente non esiste",
    '1028': 'Numero di conto o password errato',
    '1029': 'Questo account è inattivo/disabilitato, contattare il servizio clienti',
    '1030': 'Gentile utente, lo staking è disponibile soltanto per i conti con identità verificata. Completa la verifica.',
    '1031': 'Si prega di impostare una password di transazione',
    '1032': "È stata fatta l'autenticazione reale, nessuna riabilitazione",
    '1033': 'Lo stesso numero ID può essere autenticato solo una volta',
    '1035': 'La vecchia password è sbagliata',
    '1036': 'Carica le credenziali di ricarica',
    '1037': "Inserisci l'importo della ricarica corretta",
    '1038': "l'account non esiste",
    '1039': 'La password non è corretta',
    '1040': 'La vecchia password della transazione non è corretta',
    '1041': 'La password della transazione è sbagliata, si prega di rientrare',
    '1042': 'Errore del codice di invito',
    '1043': 'Indirizzo di ritiro anormale',
    1065: "L'ordine attuale è in fase di revisione, ti invitiamo a non inviarlo nuovamente.",
    '3000': 'Timeout di accesso',
    888: "Tutti gli utenti nuovi e già registrati devono completare la verifica dell'identità per accedere all'intera offerta di prodotti e servizi",
  },
  rate: 'ROI',
  mode: 'Modalità di transazione',
  num: 'Quantità di apertura',
  openTime: 'Tempo aperto',
  phoneNi: 'Inserisci il codice di verifica del telefono cellulare',
  phonePlaceholder: 'Per favore immetti il ​​tuo numero di telefono',
  inputphonecode: 'Inserisci il codice di verifica del telefono cellulare',
  phone: 'Numero di telefono',
  phoneVcode: 'CODICE DE Vérification du Téléphone portatile',
  sendSucc: 'Bien Inviayé',
  cScore: 'Punteggio di credito',
  wallet: 'Wallet',
  searchMp: 'Cerca il nome della moneta',
  clickUploadId: 'Fai clic per caricare la tua foto identificativa',
  modifyFp: 'Impostazioni',
  confirmFPassword: 'Conferma la trading password',
  pleaseSetYourNewFundPassword: 'Imposta la tua trading password',
  nosamefpassword: 'Le due trading password sono diverse. Si prega di controllare',
  forgotFPassword: 'Recupera la password della transazione?',
  korean: '한국어',
  turkish: 'Türkçe',
};
