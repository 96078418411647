import { createRouter, createWebHashHistory, Router } from 'vue-router';
import routes from './routes';
// import { useUserStore } from '../store/modules/user';

// const whiteRouter = ['/home', '/Intelligent', '/contract', '/quotation', '/login', '/register', '/app','/langs'];
const whiteRouter = ['/home', '/login', '/register', '/app', '/langs', '/help/detail'];
const router: Router = createRouter({
  history: createWebHashHistory('/'),
  routes: routes,
});

router.beforeEach((_to, _from, next) => {
  const userInfo = localStorage.getItem('userInfo') as string;
  console.log(_to.path);
  const Booule = whiteRouter.some((res) => {
    return _to.path.indexOf(res) >= 0;
  });
  if (JSON.parse(userInfo) != null || Booule) {
    next();
  } else {
    next({ path: '/login' });
  }
});

export default router;
