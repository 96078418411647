import request from '/@/utils/useAxiosApi';

export function vEmail(params){
  return request.post('/uc/register/email/isok', params);
}

// 安全设置
export function setting() {
  return request('/uc/approve/security/setting');
}

//上传图片
export function upload(params, headers = {}) {
  return request.post('/uc/upload/oss/base64', params, { headers });
}
// 换头像
export function changeAvatar(params) {
  return request.post('/uc/approve/change/avatar', params);
}

// 换头像
export function getbasemoney(params) {
  return request.post('option/option/getbasemoney', params);
}

// 绑定USDT
export interface BindCurrency {
  realName: string;
  usdt: string;
  jyPassword: string;
  qrUsdtCodeUrl: string;
}
export function bindUSDT(params: BindCurrency) {
  return request.post('uc/approve/bind/usdt', params);
}

export function bindWeChat(params: any) {
  return request.post('uc/approve/bind/wechat', params);
}
export function modifyWeChat(params: any) {
  return request.post('uc/approve/update/wechat', params);
}

// 修改收款账户USDT
export interface ModifyUSDT {
  realName: string;
  usdt: string;
  jyPassword: string;
  qrUsdtCodeUrl: string;
}
export function modifyUSDT(params: ModifyUSDT) {
  return request.post('uc/approve/update/usdt', params);
}
// 实名认证
export interface Verify {
  realName: string;
  idCard: string;
  // idCardFront: string;
  // idCardBack: string;
  // handHeldIdCard: string;
}
export function verifyIdcard(params: Verify) {
  console.log('verifyIdcard', params);
  return request.post('/uc/approve/real/name', params);
}
//
// 修改登录密码
export interface ResetLogin {
  oldPassword: string;
  password: string;
}
export function resetLoginPassword(params: ResetLogin) {
  return request.post('/uc/email/reset/login/password', params);
}

// 设置资金密码
export function setCapitalPassword(params) {
  return request.post('/uc/approve/transaction/password', params);
}
// 修改资金密码
export interface UpdatePassword {
  oldPassword: string;
  newPassword: string;
}
export function updateTransaction(UpdatePassword) {
  return request.post('uc/approve/update/transaction/password', UpdatePassword);
}

// 忘记资金密码
export interface ForgetCapitalPassword {
  newPassword: string;
  code: string;
}
export function forgetCapitalPassword(params: ForgetCapitalPassword) {
  return request.post('uc/approve/reset/transaction/password', params);
  // return request.post('uc/reset/transaction/password', params);
}
// 获取验证码
export function getCode() {
  return request.post('/uc/reset/password/email/code');
}
//
export function supportCoin(params) {
  return request.post('/uc/withdraw/support/coin/info', params);
}
//
export function withdraw(params) {
  return request.post('/uc/withdraw/apply/code', params);
}
// 充值记录
export function transaction(params) {
  // return request.post('/uc/asset/transaction/all', params);
  return request.post('/uc/recharge/page-query', params);
}
// 流水
export function transactionAll(params) {
  return request.post('/uc/asset/transaction/all', params);
}

// 提币记录
export function withdrawlRecord(params) {
  return request.post('/uc/withdraw/record', params);
}

// 邮箱注册
export function registerByEmail(params) {
  return request.post('/uc/register/email', params);
}

// 邮箱注册验证码 uc/reg/email/code
export function registerCodeEmail(params) {
  return request.post('/uc/reg/email/code', params);
}

// 邮箱忘记密码验证码 uc/reset/email/code
export function forgetCodeEmail(params) {
  return request.post('/uc/reset/email/code', params);
}

// 邮箱忘记密码 uc/reset/login/password
export function resetCodeEmail(params) {
  return request.post('/uc/reset/login/password', params);
}

// 账户设置 uc/reset/login/password
export function accountSetting() {
  return request.post('/uc/approve/account/setting');
}
//https://thishou.sessforex.com/uc/ico/chatmsg
// 注册是否需要邀请码
export function registerInvite(params) {
  return request.get('/uc/ico/chatmsg', params);
}

// 我的收益流水
export function myIncome(params) {
  return request.get('uc/asset/transaction?pageNo=' + params['pageNo'] + '&pageSize=' + params['pageSize'] + '&type=' + params['type']);
}

// 获取真实的提现金额
export function getRelWithdrawAmount() {
  return request.post('uc/withdraw/totalwithdraw');
}

export function getCanWithdraw() {
  return request.get('uc/ico/switchcontandwith');
}

export function getowalletall() {
  return request.get('uc/conwallet/getwalletall');
}

export function getobalance() {
  return request.get('uc/conwallet/conwallet/usdt');
}

// 划转 swap/wallet/trans
export const overturnConstract = (params) => {
  return request.post('uc/conwallet/trans', params);
};

// 贡献值
export const allValue = () => {
  return request.post('option/order/teammoney');
};

export interface ValueDetailParams {
  pageNo: number;
  pageSize: number;
  types: string;
}
export type ValueDetailList = {
  address: string;
  amount: number;
  createTime: string;
  discountFee: string;
  exchangeCode: null;
  fee: number;
  flag: number;
  id: number;
  memberId: number;
  realFee: null;
  symbol: string;
  type: number;
};
export type ValueDetailRes = {
  code: number;
  data: {
    content: ValueDetailList[];
    first: boolean;
    last: boolean;
    number: number;
    numberOfElements: number;
    size: number;
    sort: any[];
    totalElements: number;
    totalPages: number;
  };
  message: string;
  totalElement: null;
  totalPage: null;
};
// 贡献明细
export const allValueDetail = (params: ValueDetailParams) => {
  return request.post('uc/asset/shareincome', params);
};
