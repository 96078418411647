<script lang="ts">
import { defineComponent } from 'vue'
export default defineComponent({
  name: 'Favorite',
})
</script>
<template>
  <van-icon
    :class="isFavorite ? 'favorite' : 'favorite-icon'"
    :name="isFavorite ? 'star' : 'star-o'"
    :color="isFavorite ? '#f0a70a' : ''"
    :size="23"
    @click="favoriteHandle"
    style="padding-right: 15px"
  />
</template>

<script lang="ts" setup name="Favorite">
import { showToast } from 'vant';
import { addFavor, deleteFavor, getFavorList } from '/@/api/transaction';
import { useToken } from '/@/hooks/useToken';
import { useRouter } from 'vue-router';
import { useI18n } from 'vue-i18n';
const { t } = useI18n();
const router = useRouter();
const { token } = useToken();

// 定义数据

const props = defineProps<{
  symbol: string;
}>();

const emit = defineEmits(['update']);

const isFavorite = ref(false);

function getHanle() {
  // 获取我的自选
  getFavorList().then((res: any): void => {
    isFavorite.value = res.some((v) => v.symbol === props.symbol) || false;
  });
}

watch(
  () => props.symbol,
  () => {
    token && getHanle();
  },
);
token && getHanle();

// 收藏
const favoriteHandle = () => {
  if (!token) {
    router.push('/login');
    return;
  }

  const params = { symbol: props.symbol };
  (isFavorite.value ? deleteFavor : addFavor)(params).then(() => {
    isFavorite.value = !isFavorite.value;

    if (isFavorite.value) {
      showToast({
        message: t('collected'),
        position: 'top',
        duration: 2000,
      });
    } else {
      showToast({
        message: t('canceld'),
        position: 'top',
        duration: 2000,
      });
    }
    emit('update');
  });
};
</script>
