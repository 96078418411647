<script lang="ts">
import { defineComponent } from 'vue'
export default defineComponent({
  name: 'QuotationPage',
})
</script>
<template>
  <!-- 充币记录 -->
  <div style="height: 100vh; background-color: #fff">
    <!-- 头部tabbar -->
    <div class="depositTabbar">
      <div class="header">
        <var-icon @click="$router.back()" name="chevron-left" :size="30" style="padding-left: 5px" />
        <span style="font-weight: 600; font-size: 16px">{{ $t('depositRecord') }}</span>
      </div>
    </div>

    <div style="margin-top: 30px">
      <van-list
        ref="list"
        :loading-text="$t('loading')"
        v-model:loading="historyLoading"
        v-model:error="historyError"
        :error-text="$t('requestFailure')"
        @load="histroyListHandle"
        :finished="historyFinished"
      >
        <div v-if="historyList.length">
          <div
            class="listVant"
            v-for="item in (historyList)"
            :key="item.id"
            style="border-bottom: 1px solid #f1f1f1; margin-top: 20px"
          >
            <!-- 币种 -->
            <div class="flex mb-3">
              <div class="mb-2" style="font-size: 18px; width: 35%"> {{ item.symbol }} </div>
            </div>
            <!-- 到账时间 -->
            <div class="flex mb-2">
              <div class="text-gray-500 text-xs" style="width: 35%"> {{ $t('dztime') }} </div>
              <span style="text-align: right; word-wrap: break-word; width: 65%">{{ handleTime(item.createTime) }}</span>
            </div>
            <!-- 充币地址 -->
            <div class="flex mb-2">
              <div class="text-gray-500 text-xs" style="width: 35%"> {{ $t('Status') }} </div>
              <span
                style="text-align: right; word-wrap: break-word; width: 65%"
                >{{ item.status == 0?$t('waitingmoney'):
                  item.status == 1?$t('waitingmoney'):
                  item.status == 2?$t('LOSE'):
                  item.status == 3?$t('WIN'):''}}</span>
            </div>
            <!-- 充值数量 -->
            <div class="flex mb-2">
              <div class="text-gray-500 text-xs" style="width: 35%"> {{ $t('rushnumber') }} </div>
              <span style="text-align: right; word-wrap: break-word; width: 65%">{{ item.amount }}</span>
            </div>
          </div>
        </div>
        <div v-else class="emptyImg">
          <img src="/img/symbol/empry.png" alt="" />
        </div>
      </van-list>
    </div>
  </div>
</template>

<script setup lang="ts" name="QuotationPage">
  import { useList } from '/@/hooks/useList';
  import { transaction } from '/@/api/user';
  import dayjs from 'dayjs';
  // import { toRaw } from 'vue';
  // import { useI18n } from 'vue-i18n';
  // const { t } = useI18n();

  const {
    listError: historyError,
    listLoading: historyLoading,
    listFinished: historyFinished,
    listQuery: historyQuery,
    listData: historyList,
    getList: histroyListHandle,
  } = useList(transaction);

  historyQuery.pageSize = 10;
  historyQuery.type = 0;
  historyQuery.type = 0;

  function handleTime(timer: any) {
    return dayjs(timer).format('YYYY-MM-DD HH:mm:ss ')
  }
</script>
<style lang="scss" scoped>
  .emptyImg {
    width: 100%;
    background: rgb(255, 255, 255);
    height: 100vh;
    position: relative;

    img {
      position: absolute;
      left: 50%;
      top: 20%;
      margin-left: -30%;
      width: 402px;
      height: 302px;
    }
  }
  //导航头部
  .depositTabbar {
    height: 90px;
    display: flex;
    align-items: center;
    background: #f5f5f5;

    .header {
      display: flex;
      align-items: center;
      span {
        position: absolute;
        left: 50%;
        transform: translateX(-50%);
      }
    }
  }
  //列表
  .listVant {
    width: 93%;
    margin: 0 auto;
  }
  .nut-picker-roller-mask {
    background-image: null;
    /* background-color: var(--popup-content-background-color) */
  }
</style>
