import { langType } from './lang-base';

export const lang: langType = {
  receivingAddress: '收款地址 ',
  second: '秒',
  minimum: '最少',
  most: '最多',
  minimumSell: '起買',
  ceiling: '封頂',
  availableBalance: '當前餘額',
  Nomoreatmoment: '暫無更多',
  updateSet: '版本更新',
  pleaseEnterTheAmount: '請輸入金額',
  SearchCurrency: '搜尋合同編號',
  Unabletochangeorder: '無法改變訂單狀態',
  Yourbalanceisinsufficient: '您的餘額不足',
  Ordinarysingle: '一般訂單',
  DailyDistributions: '每日發放收益，鎖定期結束後返回本金',
  Resumptionhasbeenactivated: '复投已開啟',
  Resumptionhasbeenclosed: '复投已關閉',
  orderCount: '訂單數量',
  PleaseEnter: '請輸入合同編號',
  emailNi: '請輸入郵箱驗證碼',
  existingSES: '已有Btrok賬戶',
  email: '郵箱',
  myinvitation: '我的邀請',
  teamValue: '團隊貢獻值',
  shareValue: '分享貢獻值',
  allValue: '總貢獻值',
  myBenefits: '我的貢獻值',
  contribution: '貢獻值',
  contributionDetail: '貢獻值明細',
  Dailyinterestrate: '日利率',
  LendingDays: '借貸天數',
  Financialproductdetails: '理財產品詳情',
  Subscriptionwallet: '認購錢包',
  Subscriptionamount: '認購金額',
  SubscriptionDetails: '認購詳情',
  Expirationtime: '到期時間',
  Investmenttype: '投資類型',
  InvestmentDetails: '投資詳情',
  Thesystemwillautomatically: '投資到期的訂單，系統將會自動匹配新的理財訂單。',
  Reswitchingfunction: '复投功能',
  description: '說明',
  Investmentquantity: '投資數量',
  Investmentdetermination: '投資確定',
  RevenueDetails: '收益明細',
  InvestmentOrders: '投資訂單',
  Estimatedtotalrevenue: '預計總收益',
  Redemptionmethod: '贖回方式',
  Dailyrevenue: '每天收益',
  LockedDays: '鎖定天數',
  Currenttotalinvestmentequivalent: '當前投資總額（折合）',
  complete: '完成',
  Fundinglimit: '籌資額度',
  Contractnumber: '合同編號',
  Loanfinancing: '借貸理財',
  Subscriptiontime: '認購時間',
  Myinvestmenthistory: '我的投資記錄',
  dailyReturn: '日收益率',
  Investmentdays: '投資天數',
  Selfselectionarea: '快選區',
  Quickconstructionarea: '快建區',
  Smartcontractmatching: '智能合約匹配',
  ImmediateInvestment: '立即投資',
  sourceTime: '時間',
  viewCode: '查看代碼',
  goWealh: '財富之路',
  fileGo: '轉到文件',
  videoRequest: '視頻請求',
  gorequest: '拉取请求',
  proceeding: '訴訟',
  pwds: '密碼',
  experienceAll: '滿星體驗感',
  experienceFive: '五星體驗感',
  sharing: '財富共享',
  customer: '客服',
  todoList: '代辦事項',
  systemMessages: '系統消息',
  youHave: '您有',
  totleWait: '條代辦，請及時處理',
  totleBook: '條未讀系統消息',
  message: '消息',
  officialNowGo: '現在開始',
  officialFinace: '享受區塊鏈金融，從未如此簡單!',
  officialTechnologyLeft: '前沿',
  officialTechnologyRight: '技術',
  superSESMessage: 'Btrok信息',
  superSES: 'Btrok',
  superFomamce: '合約金融，享受金融',
  superSecure: '高收益安全區塊鏈投資',
  superParagraphOne:
    'Btrok國際數字加密資產交易平台是一個綜合數字資產交易平台,我們擁有領先的技術實力、優質的客戶服務和良好的用戶體驗，為廣大數字貨幣愛好者提供一個自由的網上交換的平台。',
  superParagraphTwo:
    'Btrok國際數字加密資產交易平台同時還提供數字貨幣白皮書規劃、加密算法調整、數字貨幣研發、交易平台上架一條龍的區塊鏈服務。',
  superParagraphThree: 'Btrok國際數字加密資產交易平台海外運營中心分別設立在新加 坡、柬埔寨、馬紹爾。',
  Traverser: 'Traverser',
  announceList: '公告列表',
  announceListDetail: '公告詳情',
  official: '官方',
  supervise: '監督節點',
  Source: '開源代碼',
  report: '審計報告',
  more: '更多',
  deposit: '充幣',
  invite: '邀請',
  lockMining: '鎖倉挖礦',
  riseRanking: '漲幅榜',
  currency: '幣種',
  recentPrice: '最近價格',
  gains: '24小時漲幅',
  welcomes: '歡迎您!',
  home: '首頁',
  messageList: '消息列表',
  noData: '暫無數據',
  market: '行情',
  financialManagement: '理財',
  optional: '自選',
  buy: '買入',
  sell: '賣出',
  completed: '已完成',
  limitOrder: '限價委託',
  marketOrder: '市價委託',
  call: '看漲',
  put: '看跌',
  success: '成功',
  failure: '失敗',
  transaction: '交易',
  contract: '期權',
  forex: '外匯',
  mine: '我的',
  notNull: '不能為空',
  optionContract: '期權合約',
  currentDelivery: '當前交割',
  historyDelivery: '歷史交割',
  fee: '手續費',
  openAmount: '開倉金額',
  bonusQuantity: '獎金數量',
  predictionDirection: '預測方向',
  requestFailure: '請求失敗，點擊重新加載',
  predictionResult: '預測結果',
  submitSuccess: '提交成功',
  sharePoster: '分享海報',
  cancel: '取消',
  copyLink: '複製鏈接',
  saveImage: '保存圖片',
  pleaseLoginFirst: '請先登錄',
  pleaseUseTronWallet: '請使用波場錢包打開充值鏈接',
  currentInterest: '活期利息',
  fundsSecurity: '資金安全',
  guarantee: '保障',
  holidayEarnings: '節假日收益不間斷',
  warning: '請勿向上述地址充任何非幣種資產, 否則資產將不可找回。',
  confirmation: '您充值至上述地址後，需要整個區塊鍊網絡節點的確認，一般區塊鍊主網3次網絡確認後到賬。',
  addressChange: '您的充值地址不會經常改變，可以重複充值；如有更改，我們會盡量通過網站公告或郵件通知您。',
  safetyReminder: '請務必確認操作環境安全，防止信息被篡改或洩露。',
  rechargeUSDT: '充值USDT',
  details: '詳情',
  dayLimit: '天',
  mining: '挖礦賺不停',
  upcoming: '即將開始',
  inProgress: '進行中',
  distribution: '派發中',
  progress: '進度',
  totalAmount: '活動總量',
  startTime: '開始時間',
  endTime: '結束時間',
  lockingMining: '鎖倉挖礦是通過將USDT託管給平台超算力礦機在平台礦池中進行挖礦收益',
  productHighlights: '產品亮點',
  withdrawal: '隨存隨取',
  dividendCycle: '派息週期',
  successfulDepositOnDay: '成功存入後當天',
  interestAccrual: '起息',
  example: '舉個例子',
  profitCalculation: '收益計算',
  simplestProfitFormula: '最簡單的收益計算公式為',
  profitRateCalculationExample: '例如收益是2W，本金是10W，則收益率',
  calculationOfPrincipal: '對於證券賬戶，如果不記得投入的本金，可以通過當前總市值和當前盈虧來計算',
  currentMarketValueExample: '例如當前總市值是11W，當前啊浮贏是2W，則本金為11W-2W=9W，收益率=(2W/9W)',
  penalty: '違約金',
  participate: '我要參與',
  entrustedOrder: '委托订单',
  pledgingInProgress: '正在質押',
  pledgingComplete: '質押完成',
  earlyRedemption: '提前贖回',
  loading: '正在加載',
  pledgeAmount: '質押金額',
  dailyEarnings: '每日收益',
  earlyRedemptionDeductionHandlingFee: '提前贖回扣除手續費',
  earlyRedemptionAgain: '提前贖回',
  endOfContent: '已經到底了',
  financialProduct: '理財產品',
  fundsUnderManagement: '正在託管的資金',
  expectedTodayEarnings: '預計今日收益',
  TodayEarnings: '今日收益',
  accumulatedEarnings: '累計收益',
  trustOrder: '託管中的訂單',
  buy2: '去買入',
  period: '週期',
  switchLanguage: '語言切換',
  username: '用戶名',
  emailPlaceholder: '請輸入郵箱',
  forgotPassword: '忘記密碼',
  passwordPlaceholder: '請輸入密碼',
  submit: '提交',
  registerNow: '立即註冊',
  search: '搜索',
  high: '高',
  low: '低',
  depth: '深度',
  deal: '成交',
  buyQuantity2: '買盤數量',
  price: '價格',
  quantity: '數量',
  sellQuantity: '賣盤數量',
  time: '時間',
  direction: '方向',
  inputPrice: '請輸入價格',
  tradingVolume: '交易額',
  entrust: '委託',
  entrustPrice: '委託價格',
  entrustQuantity: '委託量',
  totalEntrustment: '委託總額',
  category: '類別',
  confirm: '確定',
  inputCorrectQuantity: '請輸入正確的數量',
  operationSuccessful: '操作成功',
  revoke: '撤銷',
  entrustmentCancellationConfirmation: '委託撤銷確認',
  areYouSureToCancel: '您是否確認撤單？',
  all: '全部',
  currentEntrustment: '當前委託',
  completed2: '已成交',
  aboutSES: '關於Btrok',
  freeExchange: '為廣大數字貨幣愛好者提供一個自由的網上交換的平台',
  modify: '修改',
  bind: '綁定',
  alipayAccount: '支付寶賬號',
  wechatAccount: '微信賬號',
  unbound: '未綁定',
  sesPlatformOverview3:
    'Btrok數字資產交易所是由英國MAI國際基金會投資開發的全球性的數字資產交易平臺、總部位於英國、受英國NFC的監管、在歐州區塊鏈行業做了一系列基礎建設工作，以英國爲基礎，遍佈歐洲及亞洲、東南亞、美洲、澳洲、打造數字資產區塊鏈的行業生態圈。作爲一家面向全球投資者的國際化數字資產交易所，Btrok數字資產交易所結合當下行業優勢、擁有銀行金融管理資深團隊、專業大數據開發團隊、全球頂尖區塊鏈開發團隊及領先的區塊鏈技術服務團隊。旨在爲用戶提供高效，安全的數字資產交易服務。',
  sesPlatformOverview4:
    '作爲全球首家綜合性數字資產交易平臺，在世界範圍內是區塊鏈領域的頂尖交易平臺之一，覆蓋全世界的數字資產生態佈局，持有國際牌照及政府機構認可。Btrok將在技術創新、產品迭代、安全風控體系、運營及客戶服務體系等方面持續改進，在爲全球用戶提供比特幣、以太坊等主流數字貨幣現貨和衍生品交易服務的同時，Btrok與時俱進，助力全方位區塊鏈生態系統搭建。',
  sesPlatformOverview5:
    '建立一個安全、體驗、自由的數字資產交易平臺以及滿足全球廣大數字貨幣愛好者需求的金融基礎設施，真正建設數字互聯時代之下虛擬數字資產的交易交流的理想社區。爲廣大投資者提供一個安全，可靠，穩定的綜合服務交易平臺。',
  bankCardNumber: '銀行卡帳號',
  accountSettings: '賬戶設置',
  modifyAlipayAccount: '修改支付寶賬號',
  receiveQRCode: '收款二維碼',
  fundPassword: '資金密碼',
  pleaseEnterCorrectFundPassword: '請輸入正確的資金密碼',
  pleaseEnterAlipayAccount: '請輸入支付寶賬號',
  myAssets: '我的資產',
  details2: '明細',
  withdrawalRecords: '提幣記錄',
  totalAssetsApprox: '總資產約(合USDT)',
  spotAccount: '幣幣賬戶',
  searchAssets: '搜索資產',
  hideZeroBalances: '隱藏為0的幣種',
  frozen: '凍結',
  toRelease: '待釋放',
  modifyBankCardNumber: '修改銀行卡帳號',
  save: '保存',
  bankName: '開戶銀行',
  bankCardNumberConfirm: '確認卡號',
  pleaseEnterBankCardNumberAgain: '請再次輸入銀行卡號',
  fundPasswordPrompt: '資金密碼',
  pleaseEnterFundPassword: '請輸入資金密碼',
  modifyFundPassword: '修改資金密碼',
  modifyingFundPassword: '您正在修改資金密碼',
  oldFundPassword: '舊資金密碼',
  newFundPassword: '新資金密碼',
  confirmFundPassword: '確認資金密碼',
  pleaseEnterSetPassword: '請輸入設置密碼',
  pleaseEnterConfirmFundPassword: '請輸入確認資金密碼',
  passwordsDoNotMatch: '密碼不一致',
  pleaseEnterYourOldFundPassword: '請輸入舊資金密碼',
  pleaseEnterYourNewFundPassword: '請輸入新資金密碼',
  pleaseEnterA6DigitPassword: '請輸入6位數字密碼',
  pleaseConfirmNewFundPassword: '請確認新資金密碼',
  theNewFundPasswordCannotBeTheSameAsTheOldFundPassword: '新資金密碼不能與舊資金密碼相同',
  pleaseEnterTheVerificationCode: '請輸入驗證碼',
  feedback: '意見反饋',
  yourOpinionIsVeryImportantToUs: '您的意見對我們來說特別重要！',
  leaveYourValuableOpinionToHelpUsImprove: '留下您寶貴的意見,以幫助我們做的更好!',
  pleaseLeaveYourValuableOpinion: '請留下您寶貴的意見',
  submissionSuccessfulThankYouForYourFeedback: '提交成功，感謝您的反饋',
  submissionFailed: '提交失敗',
  setFundPassword: '設置資金密碼',
  setYourPaymentMethod: '請設置您的收款方式，請務必使用本人的實名帳號',
  newPassword: '新密碼',
  repeatPassword: '重複密碼',
  notLoggedIn: '未登錄',
  welcome: '歡迎來到Btrok',
  assetManagement: '資產管理',
  assetFlow: '資產流水',
  myInvitation: '我的邀請',
  invitationLink: '我的邀請鏈接',
  firstLevelFriends: '一級好友',
  secondLevelFriends: '二級好友',
  partnerLevel: '個人級別',
  myEntrustment: '我的委託',
  coinTrading: '幣幣交易',
  securitySettings: '安全設置',
  pleaseLogInFirst: '請先登錄',
  announcement: '公告',
  changeLoginPassword: '更改登錄密碼',
  oldLoginPassword: '舊登錄密碼',
  newLoginPassword: '新登錄密碼',
  pleaseEnterOldLoginPassword: '請輸入舊登錄密碼',
  pleaseEnterNewLoginPassword: '請輸入新登錄密碼',
  confirmLoginPassword: '確認登錄',
  emailVerificationCode: '郵箱驗證碼',
  emailBound: '您已經綁定郵箱',
  verificationCode: '驗證碼',
  sendVerificationCode: '發送驗證碼',
  pleaseConfirmInformation: '請確定是否填寫好所有信息',
  pleaseConfirmPassword: '請確認兩次輸入的密碼是否一致',
  makeSureTheOldAndNewPasswordsAreDifferent: '請確保新舊密碼不一樣',
  pleaseConfirmNewLoginPassword: '請確認新登錄密碼',
  teamPerformance: '團隊業績',
  currentLevel: '當前級別',
  nextLevel: '下一級別',
  teamNumber: '團隊人數',
  totalPerformance: '總業績',
  onlyForUSDT: '僅支持對該地址充值USDT',
  walletAddress: '錢包地址',
  securityReminder: '為保障您的賬戶安全，請完成實名認證後方可交易操作！',
  personalAvatar: '個人頭像',
  isVerified: '已認證',
  realNameAuthentication: '實名認證',
  notVerified: '未認證',
  receivingAccount: '收款賬戶',
  loginPassword: '登錄密碼',
  fundsPassword: '資金密碼',
  settingsCenter: '設置中心',
  aboutUs: '關於我們',
  helptext: '幫助說明',
  help: '幫助',
  logout: '退出當前賬號',
  confirmLogout: '你確定要退出登錄？',
  bindWeChatAccount: '綁定微信賬號',
  withdrawalAddress: '提幣地址',
  available: '可用',
  minimumWithdrawalAmount: '最小提幣數量',
  amountReceived: '到賬數量',
  transactionTime: '交易時間',
  payableHandlingFee: '應付手續費',
  deductibleHandlingFee: '抵扣手續費',
  actualHandlingFee: '實付手續費',
  feeGenerationTime: '起止時間為手續費生成時間',
  takeIDPhoto: '拍攝上傳您的身份證照片',
  identityAuthentication: '身份認證',
  uploadIDCardFront: '上傳身份證正面圖片',
  uploadIDCardBack: '上傳身份證反面圖片',
  photoAlbum: '相冊',
  IDCardNumber: '身份證號碼',
  enterIDCardNumber: '請輸入身份證號碼',
  name: '姓名',
  idea: 'Btrok理念',
  goal: 'Btrok目標',
  depositRecord: '充值記錄',
  withdrawal2: '提幣',
  accountOpeningBranch: '開戶支行',
  quantityInput: '請輸入數量',
  singleLimit: '單筆限額',
  investmentAmount: '投資金額',
  incomePrincipal: '收益/本金',
  password: '密碼',
  historicalCommission: '歷史委託',
  type: '類型',
  commissionAmount: '委託金額',
  filter: '篩選',
  pleaseSelect: '請選擇',
  tradingPair: '交易對',
  reset: '重置',
  startEndTime: '起止時間',
  recharge2: '充值',
  withdraw: '提現',
  transfer: '轉賬',
  fiatCurrencyPurchase: '法幣買入',
  fiatCurrencySale: '法幣賣出',
  activityReward: '活動獎勵',
  promotionReward: '推廣獎勵',
  dividend: '分紅',
  vote: '投票',
  manualRecharge: '人工充值',
  pairing: '配對',
  activityExchange: '活動兌換',
  ctcPurchase: 'CTC買入',
  ctcSale: 'CTC賣出',
  redEnvelopeSending: '紅包發出',
  redEnvelopeReceiving: '紅包領取',
  withdrawalCodeWithdrawal: '提現碼提現',
  withdrawalCodeRecharge: '提現碼充值',
  perpetualContractFee: '合約手續費',
  perpetualContractProfit: '合約盈利',
  perpetualContractLoss: '合約虧損',
  optionContractFailure: '期權合約失敗',
  optionContractFee: '期權合約手續費',
  optionContractBonus: '期權合約獎金',
  contractRebate: '合約返佣',
  levelReward: '平級獎勵',
  platformFeeIncome: '平台手續費收入',
  income: '收益',
  promotionIncome: '推廣收益',
  optionsPromotionIncome: '期權推廣收益',
  optionTeamIncome: '期權團隊收益',
  optionParityAward: '期權平級獎勵',
  investmentTeamReturn: '投資團隊收益',
  investmentParityReturn: '投資平級收益',
  investmentPromotionIncome: '投資推廣收益',
  NEWACTIVITY_IN: '投资收益',
  NEWACTIVITY_DEDUCT: '投资赎回',
  NEWACTIVITY_FREEZE: '期权冻结',
  NEWACTIVITY_UNFREEZE: '期权解冻',
  NEWACTIVITY_REDEEM_UNFREEZE: '期权赎回解冻',
  forexEntrustTrading: '外匯委託交易',
  forexCancelEntrustTrading: '外匯取消委託交易',
  liquidationCompensation: '爆倉賠付',
  lockedMiningIncome: '團隊收益',
  speedContractProfit: '秒合約盈利',
  speedContractLoss: '秒合約虧損',
  speedContractCommission: '秒合約手續費',
  CONTRACT_FAST_FREEZE: '秒合约冻结',
  CONTRACT_FAST_UNFREEZE: '秒合约解冻',
  confirmUploadAllImages: '請確認是否已經上傳圖片',
  inputRealName: '請輸入您的真實姓名',
  idCardPhoto: '身份證照片',
  congratulations: '恭喜你，審核通過，可以安心交易了',
  quantity2: '量',
  wechatAccount2: '請輸入微信賬號',
  realNameIdentityVerified: '實名身份已驗證',
  withdrawalTime: '提現時間',
  withdrawalAmount: '提現數量',
  creditting: '審核中',
  waitingmoney: '等待放幣',
  dztime: '充值時間',
  rushaddress: '充幣地址',
  rushnumber: '充值數量',
  //邮箱注册
  regisertitle: '郵箱註冊',
  inputemailcode: '請輸入郵箱驗證碼',
  inputloginpassword: '請輸入密碼（大於6位的數字、大小寫字母組合）',
  inputreloginpassword: '請再次輸入密碼',
  inputinvite: '請輸入邀請碼',
  iagree: '我已閱讀並同意',
  useragreement: '用戶協議',
  nowlogin: '立即登錄',
  sendcode: '發送驗證碼',
  rightemailformat: '請輸入正確的郵箱格式',
  confirmPassword: '確認密碼',
  passwordtip: '密碼必須至少包含6位數',
  nosamepassword: '兩次密碼不一致',
  noagree: '未勾選同意用戶協議',
  emailverification: '郵箱驗證',
  // 划转
  amount: '數量',
  overturn: '劃轉',
  overturntitle: '資產劃轉',
  from: '從',
  to: '到',
  swapcurrency: '合約幣種',
  overturnplaceholder: '請輸入劃轉數量',
  selectaccount: '選擇賬戶',
  balance: '資產',
  contractaccount: '合約賬戶',
  //
  invitecode: '邀請碼',
  notset: '未設置',
  day: '天',
  contactservice: '聯繫客服',
  appname: 'Btrok APP',
  download: '下載APP',
  orderByHand: '手動下單',
  orderByAuto: '智能下單',
  trevenue: '總收益',
  pincome: '推廣收益',
  tincome: '團隊收益',
  dropList: '下拉列表',
  uploaded: '已上傳',
  youare: '你正在',
  disabling: '關閉',
  enabling: '開啟',
  theAutomaticReinvestmentFeatur: '自動复投功能',
  nth: '第',
  phase: '期',
  currentForecastPeriod: '本期預測週期',
  maximum: '最大量',
  openingPrice: '開盤價',
  currentPrice: '當前價',
  profitSharing: '盈利分紅',
  CurrentTradingIsRestricted: '當前限制交易',
  ToEnsureTheSsecurityOfYourAssetsPleaseCarefullyVerifyTheReceivingWalletAddressBeforeMakingAnyDeposits:
    '為了保證大家資產的安全，充值請認真核對收款錢包地址：',
  PleaseProceedWithTheDepositOnlyAfterVerifyingThatEverythingIsCorrect: '。核對無誤方可進行充值。',
  PleaseDoNotDepositAnyAssetsOtherThanTheSpecifiedCryptocurrencyToTheAddressMentionedAboveOtherwiseYourAssetsWillNotBeRecoverable:
    '請勿向上述地址充值任何非幣種資產，否則資產將不可找回。',
  AfterYouMakeADepositToTheMentionedAddressItRequiresConfirmationFromTheEntireBlockchainNetworkTypicallyItTakesAround30SecondsForTheFundsToBeCreditedToYourAccountAfterThreeConfirmationOnTheBlockchainMainnet:
    '您充值至上述地址後，需要整個區塊鍊網絡節點的確認，一般區塊鍊主網3次網絡確認後到賬，大概需要30秒左右。',
  PleaseEnsureTheSecurityOfYourElectronicDevicesToPreventAnyTamperingOrLeakageOfInformation:
    '請務必確認電子設備安全，防止信息被篡改或洩露。',
  WithdrawalsAreCurrentlyDisabled: '當前禁止提幣',
  CurrentWithdrawalAddress: '請確認當前提幣地址 ',
  isYourWalletAddress: ' 是否是您的錢包地址',
  LinkCopiedSuccessfully: '複製成功',
  LinkCopiedFailed: '複製失敗',
  goLogin: '去登錄',
  other: '其他',
  SavePicture: '保存圖片',
  SaveLink: '保存連結',
  languageSettings: '語言設置',
  simplifiedChinese: '简体中文',
  traditionalChinese: '繁体中文',
  arabic: 'عربي',
  english: 'English',
  japanese: '日本語',
  French: 'Français',
  Spanish: 'Español',
  German: 'Deutsch',
  Italiano: 'Italiano',
  TotalDailyLoss: '總盈利',
  TotalExpenditureToday: '今日總下單金額',
  TotalIncomeToday: '今日總獲利',
  CurrentDelivery: '當前交割',
  HistoricalDelivery: '歷史交割',
  InitialAmount: '下單金額',
  ForecastDirection: '預測方向',
  BonusAmount: '獎金數量',
  TransactionFee: '手續費',
  OrderDuration: '訂單持續時間',
  Status: '狀態',
  SelectCurrency: '選擇貨幣',
  EnterAmount: '輸入金額',
  BUY: '買漲',
  SELL: '買跌',
  WIN: '成功',
  LOSE: '失敗',
  Amount: '金額',
  expectedReturn: '預期收益',
  service: '客服',
  untrade: '當前不能交易',
  netError: '網絡出小差啦!',
  questError: '請求出錯了，請稍後再試!',
  failVerified: '認證失敗',
  tiprealverified: '實名審核中，請聯繫客服',
  uploadImg: '上傳憑證',
  collected: '已收藏',
  canceld: '已取消',
  placeholderAddress: '請輸入收款地址',
  bindAccountTitle: '收款地址',
  marketTitle: '行情',
  regiserSuccess: '註冊成功',
  setSuccess: '設置成功',
  updateAddress: '我要修改錢包地址',
  doingRealName: '實名正在審核當中，請耐心等待',
  updateSuccess: '修改成功',
  uploadSuccess: '上传成功',
  forgetLpass: '忘記密碼請聯繫客服核對信息進行處理重置登錄密碼',
  forgetApass: '忘記密碼請聯繫客服核對信息進行處理重置交易密碼',
  updateAddressTip: '收款地址修改請聯繫客服核對個人信息進行處理重置收款地址',
  a48h: '提幣已受理，提幣申請48小時內處理完成，請耐心等待',
  r48h: '充值已受理，充值訂單48小時內處理完成，請耐心等待',
  accepted: '受理中',
  notremind: '不再提醒',
  serviceCode: {
    0: '成功',
    500: '失敗',
    501: '註冊失敗',
    502: '發送失敗',
    503: '添加失敗',
    504: '查詢失敗',
    600: '該幣種不支持提幣',
    1001: '驗證碼不存在  ',
    1003: '提幣數量大於最大值',
    1004: '提幣數量小於最小值',
    1005: '可用餘額不足',
    1006: '錢包已鎖定',
    1007: '當前實名認證正在審核中，請耐心等候，審核通過再進行操作',
    1008: '請先設置資金密碼',
    1009: '資金密碼錯誤',
    1010: '無法鎖定資產',
    1011: '該郵箱已經被綁定',
    1012: '該用戶名已存在',
    1013: '無此用戶',
    1014: '註冊激活郵件已經發送至您郵箱，請勿重複請求',
    1015: '請先綁定郵箱',
    1016: '下單金額不能為0',
    1017: '您當前賬戶已禁用，請聯繫客服！ ',
    1018: '活動不存在',
    1019: '購買金額小於最低金額',
    1020: '購買金額大於最高金額',
    1021: '不支持的幣種！ ',
    1022: '交易對已收藏',
    1023: '請勿重複設置',
    1024: '資金密碼長度不合法',
    1025: '合約不存在',
    1026: '預測方向不對',
    1027: '用戶錢包不存在',
    1028: '賬號或密碼錯誤',
    1029: '該帳號處於未激活/禁用狀態，請聯繫客服',
    1030: '請先完成實名認證',
    1031: '請設置交易密碼',
    1032: '已實名認證過，不可再次認證',
    1033: '同一個身份證號只能認證一次',
    1035: '舊密碼錯誤',
    1036: '请上传充值凭证',
    1037: '请输入正确的充值金额',
    1038: '賬號不存在',
    1039: '密碼不正確',
    1040: '舊密碼不正確',
    1041: '交易密碼錯誤，請重新輸入',
    1042: '邀請碼錯誤',
    1043: '提現地址異常',
    1065: '当前订单正在审核中，请勿重复提交',
    3000: '登陸超時',
    888: '請先完成實名認證',
  },
  //new
  rate: '盈利率',
  mode: '交易模式',
  num: '開倉數量',
  openTime: '開倉時間',
  phoneNi: '請輸入手機驗證碼',
  phonePlaceholder: '請輸入手機號',
  inputphonecode: '請輸入手機驗證碼',
  phone: '手機號',
  phoneVcode: '手機驗證碼',
  sendSucc: '發送成功',
  cScore: '信用分',
  wallet: '錢包',
  searchMp: '搜索加密貨幣的名稱',
  clickUploadId: '點擊上傳您的身份照片',
  modifyFp: '調整',
  confirmFPassword: '確認資產密碼',
  pleaseSetYourNewFundPassword: '請設置資金密碼',
  nosamefpassword: '資金密碼不正確，請再次確認。',
  forgotFPassword: '忘記你的交易密碼?',
  korean: '한국어',
  turkish: 'Türkçe',
};
