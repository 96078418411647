import { setLang } from '/@/i18n';
export const useAppStore = defineStore({
  id: 'app',
  state: () => ({
    lang: 'en_US',
    loading: false,
    cSymbol: localStorage.getItem('cSymol') ?? 'BTC/USDT',
  }),
  getters: {
    ulang: (state) => state.lang ?? 'en_US',
    ucSymbol: (state) => state.cSymbol,
  },
  actions: {
    setULang(_lang) {
      this.lang = _lang;
      setLang(_lang);
    },
    setCSymbol(_symbol) {
      this.cSymbol = _symbol;
      localStorage.setItem('cSymol', _symbol);
    },
  },
  persist: true,
});
