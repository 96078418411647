<script lang="ts">
import { defineComponent } from 'vue'
export default defineComponent({
  name: 'Transaction',
})
</script>
<template>
  <!-- 买入卖出 -->
  <div class="page">
    <!-- 头部tabbar -->
    <VanSticky>
      <div class="pageHeader">
        <!-- 左侧菜单文字 -->
        <div style="padding-left: 10px; display: flex; align-items: center">
          <img style="color: black" src="/img/symbol/menu.png" width="20" @click="openLeftPop" />
          <span style="margin-left: 10px">BTC/USDT </span>
        </div>
        <div style="display: flex; align-items: center">
          <!-- 收藏 -->
          <van-icon
            @click="toggleFavorite()"
            :class="isFavorite ? 'favorite' : 'favorite-icon'"
            :name="isFavorite ? 'star' : 'star-o'"
            :size="16"
          />
          <!-- 返回上一个页面 -->
          <!-- <Favorite :symbol="currentCoin.symbol" @update" /> -->
          <router-link :to="`/transaction/detail?symbol=${currentCoin.symbol}`">
            <img style="margin-right: 15px; margin-left: 15px" src="/img/symbol/datas.png" width="16" />
          </router-link>
        </div>
      </div>
    </VanSticky>
    <!-- 中部卖出买入/数量支付内容 -->
    <div class="pageCenter">
      <!-- 左侧数据 -->
      <div class="pageCenterLeft">
        <var-form ref="form" scroll-to-error="start">
          <!-- 买入卖出按钮 -->
          <div class="buttonBuy">
            <var-chip
              class="buyLeft"
              plain
              size="small"
              :round="false"
              :type="isBuy ? 'success' : 'default'"
              block
              @click="formData.direction = 'BUY'"
              >{{ $t('buy') }}</var-chip
            >
            <var-chip
              class="buyRight"
              plain
              size="small"
              :round="false"
              :type="isBuy ? 'default' : 'danger'"
              block
              @click="formData.direction = 'SELL'"
              >{{ $t('sell') }}</var-chip
            >
          </div>
          <!-- 限时委托-->
          <div class="Limited">
            <!-- 委托 -->
            <var-select v-model="formData.type">
              <var-option :label="$t('limitOrder')" value="LIMIT_PRICE" />
              <var-option :label="$t('marketOrder')" value="MARKET_PRICE" />
            </var-select>
            <!-- 数量 -->
            <van-stepper v-if="formData.type === 'LIMIT_PRICE'" :placeholder="$t('inputPrice')" v-model="formData.price" :step="0.01" />
          </div>
          <!-- 数量 -->
          <div class="numbers">
            <VanField v-model="formData.amount" type="number" :placeholder="$t('quantity')" @update:model-value="amountChange">
              <template #button> {{ fromCoin }} </template>
            </VanField>
          </div>
          <!-- 可用数据 -->
          <div class="givHave">
            {{ $t('available') }}：{{ isBuy ? balanceUsdt?.balance.toFixed(4) : balanceInfo?.balance.toFixed(4) }}
            {{ isBuy ? toCoin : fromCoin }}
            <!-- 可用余额 -->
            <var-slider
              v-model="scale"
              track-height="4"
              :thumb-color="isBuy ? '#10c48f' : '#F15056'"
              :active-color="isBuy ? '#10c48f' : '#F15056'"
              @change="sliderChange"
            />
            <!-- 进度条 -->
            <div class="flex justify-between mb-3">
              {{ formData.amount || '-' }}
              <div>{{ scale }}%</div>
            </div>

            <!-- 百分比 -->
            <div class="mb-2">{{ $t('tradingVolume') }}：{{ transactionAmount || 0 }} {{ toCoin }}</div>
          </div>
          <!-- 买入卖出 -->
          <div class="buyOut">
            <VanButton block square size="small" :color="isBuy ? '#00B276' : '#F15056'" @click="confirmHandel">
              {{ isBuy ? $t('buy') : $t('sell') }} （{{ toCoin }}）
            </VanButton>
          </div>
        </var-form>
      </div>
      <!-- 右侧数据 -->
      <div class="pageCenterRight">
        <template v-if="diskportItems?.asks && coninInfo">
          <!-- 价格数量标题 -->
          <div class="flex justify-between w-full mt-2" style="font-weight: 600">
            <div class="">{{ $t('price') }}<br />({{ fromCoin }})</div>
            <div class="">{{ $t('quantity') }}<br />({{ toCoin }})</div>
          </div>
          <!-- 价格数量 -->
          <div
            style="line-height: 30px"
            class="flex justify-between relative w-full"
            v-for="(item, index) in diskportItems.asks"
            :key="index"
            @click="setPrice(item[0])"
          >
            <i
              class="absolute right-0 top-0 bottom-0"
              style="background-color: rgba(249, 187, 187, 0.2)"
              :style="{ width: getBgWidth(diskportItems.asks, item) }"
            ></i>
            <div class="text-red-500 relative">{{ item[0] }}</div>
            <div class="relative">{{ item[1].toFixed(4) }}</div>
          </div>
          <!-- 下部数字标题 -->
          <div style="font-size: 20px" class="text-green-500 text-xl flex-1 mt-4" @click="setPrice(currentCoin.close - 0)">{{
            currentCoin.close.toFixed(4)
          }}</div>
          <!-- 价格内容 -->
          <div
            style="line-height: 30px"
            class="flex justify-between relative w-full"
            v-for="(item, index) in diskportItems.bids"
            :key="index"
            @click="setPrice(item[0])"
          >
            <i
              class="absolute right-0 top-0 bottom-0"
              style="background-color: rgba(117, 219, 168, 0.2)"
              :style="{ width: getBgWidth(diskportItems.bids, item) }"
            ></i>
            <div class="text-green-600 relative">{{ item[0] }}</div>
            <div class="relative">{{ item[1].toFixed(4) }}</div>
          </div>
        </template>
        <div v-else class="h-full w-full flex justify-center items-center">
          <VanLoading />
        </div>
      </div>
    </div>

    <!-- 委托内容 -->
    <div class="commission">
      <EntryList v-if="currentCoin.symbol" ref="entryList" :symbol="currentCoin.symbol" :base="toCoin" :coin="fromCoin" />
    </div>

    <!-- 左侧弹出交易按钮 -->
    <var-popup position="left" class="h-full" v-model:show="leftMenu">
      <div class="w-80 h-full">
        <!-- 左侧关闭弹框按钮 -->
        <div class="flex p-3 justify-between">
          <span class="text-base">{{ $t('trade') }}</span>

          <div>
            <img src="/img/icon/icon_side_close.jpg" width="20" class="mr-2" @click="leftMenu = !leftMenu" />
          </div>
        </div>
        <!-- tab切换栏 -->
        <van-tabs v-model:active="pupActive" animated>
          <!-- 自选切换按钮 -->
          <van-tab :title="$t('optional')" v-if="isPopul">
            <VanRow v-for="item in favorList" :key="item.symbol" @click="selectItem(item)">
              <VanCol :span="8" class="p-3">
                <div class="font-bold">{{ item.symbol.split('/')[0] }}</div>
              </VanCol>
              <VanCol :span="8" class="p-3">
                <div class="text-sm" :class="item.chg > 0 ? 'text-green-600' : 'text-red-600'">{{ item.close.toFixed(4) }} </div>
              </VanCol>
              <VanCol :span="8" class="p-3 text-right">
                <van-tag class="w-16 justify-center" size="medium" :type="item.chg > 0 ? 'success' : 'danger'"
                  >{{ (item.chg * 100).toFixed(2) }}%
                </van-tag>
              </VanCol>
            </VanRow>
          </van-tab>
          <div class="emptyImg" v-else>
            <img src="/img/symbol/empry.png" alt="" />
          </div>
          <!-- USDT按钮 -->
          <van-tab title="USDT">
            <VanRow v-for="item in coinList" :key="item.symbol" @click="selectItem(item)">
              <VanCol :span="8" class="p-3">
                <div class="font-bold">{{ item.symbol.split('/')[0] }}</div>
              </VanCol>
              <VanCol :span="8" class="p-3">
                <div class="text-sm" :class="item.chg > 0 ? 'text-green-600' : 'text-red-600'">{{ item.close.toFixed(4) }} </div>
              </VanCol>
              <VanCol :span="8" class="p-3 text-right">
                <van-tag class="w-16 justify-center" size="medium" :type="item.chg > 0 ? 'success' : 'danger'"
                  >{{ (item.chg * 100).toFixed(2) }}%
                </van-tag>
              </VanCol>
            </VanRow>
          </van-tab>
        </van-tabs>
        <!-- <van-tabs-items v-model:active="pupActive">
          <van-tab-item v-if="coinList" class="!bg-transparent"> </van-tab-item>
          <van-tab-item v-if="favorList" class="!bg-transparent"> </van-tab-item>
        </van-tabs-items> -->
      </div>
    </var-popup>
    <!-- 点击买入弹出框 -->
    <van-popup position="bottom" v-model:show="confirmDialog">
      <div class="text-center text-lg pt-4">{{ $t('entrust') }}买入</div>
      <van-divider />
      <!-- 内容 -->
      <div class="text-sm px-3 pb-3">
        <div class="flex mb-2">
          <div class="flex-1"
            >{{ $t('entrustPrice') }}：<span :class="isBuy ? 'text-green-600' : 'text-red-600'"
              >{{ formData.price }}{{ currentCoin.base }} {{ toCoin }}</span
            >
          </div>
          <div class="flex-1"
            >{{ $t('entrustQuantity') }}：<span :class="isBuy ? 'text-green-600' : 'text-red-600'">{{ formData.amount }} {{ toCoin }}</span>
          </div>
        </div>
        <!-- 订单 -->
        <div class="flex">
          <div class="flex-1"
            >{{ $t('totalEntrustment') }}：<span :class="isBuy ? 'text-green-600' : 'text-red-600'"
              >{{ transactionAmount }}{{ currentCoin.base }} {{ toCoin }}</span
            >
          </div>
          <!-- <div class="flex-1"
            >类别：<span class="text-green-600">{{ isBuy ? '买入' : '卖出' }}</span>
          </div> -->
        </div>
        <van-divider />
        <!-- <div class="mb-3">您是否确认撤单？</div> -->
        <!-- 确定取消按钮 -->
        <van-button :type="isBuy ? 'success' : 'danger'" size="small" :loading="loading" block @click="submitEntrust">{{
          $t('confirm')
        }}</van-button>
        <van-button
          style="margin-top: 10px; background: #ccc; border: 0px"
          :type="isBuy ? 'success' : 'danger'"
          size="small"
          :loading="loading"
          block
          @click="submitOut"
          >{{ $t('cancel') }}</van-button
        >
      </div>
    </van-popup>
  </div>
</template>

<script lang="ts" setup name="Transaction">
import { addEntrust, AddEntrustParams } from '/@/api/transaction';
import { CoinList, DeepTick } from '/@/types/api';
import EntryList from './components/entrus.vue';
// import { OrderType } from '/@/enum';
// import Favorite from '/@/components/Favorite/index.vue';
import { getBgWidth } from '/@/utils';
// import useSocket from '/@/hooks/useSocket';
import useBalance from '/@/hooks/useBalance';
import { showToast } from 'vant';
import { useColinList } from '/@/hooks/useColin';
import { useKline } from '/@/hooks/useKline';
import { useToken } from '/@/hooks/useToken';
import { useI18n } from 'vue-i18n';
const { t } = useI18n();
const { token } = useToken();
const { coninInfo, coinName } = useKline({
  isSubDeep: true,
  isSubDetail: true,
  isKline: false,
  wsCallback,
});
// const { plates, coinList, currentCoin, favorList } = useSocket({ subPlate: true });
const route = useRoute();
const _symbol = <string>route.query.symbol;
const { currentCoin, toCoin, fromCoin, coinList, favorList } = useColinList(_symbol, true);
const { balanceInfo, balanceUsdt, getWalletListHanler } = useBalance(fromCoin.value);

const pupActive = ref(1);
const leftMenu = ref(false);
const confirmDialog = ref(false);
const scale = ref(0);
const loading = ref(false);
const entryList = ref();
const formData = reactive({
  direction: route.query.direction || 'BUY',
  amount: '',
  type: 'LIMIT_PRICE',
  symbol: '',
} as AddEntrustParams);
const isPopul = ref<any>(false); //弹框
// 定义数据
const isFavorite = ref<any>(false); //收藏控制按钮
//取消收藏和打开收藏
const toggleFavorite = () => {
  isFavorite.value = !isFavorite.value;
  if (isFavorite.value) {
    showToast({
      message: t('collected'),
      position: 'top',
      duration: 2000,
    });
  } else {
    showToast({
      message: t('canceld'),
      position: 'top',
      duration: 2000,
    });
  }
};

watch(leftMenu, (val) => {
  if (!val) {
    pupActive.value = 0;
  }
});

watchEffect(() => {
  formData.price = currentCoin.value.close + '';
  formData.symbol = currentCoin.value.symbol;
});

function wsCallback({ deepData }) {
  diskportItems.value = deepData as DeepTick;
  if (diskportItems.value) {
    diskportItems.value.asks.splice(5);
    diskportItems.value.bids.splice(5);
  }
}

const isBuy = computed((): boolean => {
  return formData.direction == 'BUY';
});

// const isTypeText = computed((): string => {
//   return OrderType[formData.direction];
// });

let diskportItems = ref<DeepTick>();

const transactionAmount = computed((): number => {
  return parseFloat(formData.price) * parseFloat(formData.amount);
});

function setPrice(price) {
  formData.price = price;
}

function sliderChange(val) {
  if (isBuy.value) {
    formData.amount = (maxNum.value * (val / 100)).toFixed(4) + '';
  } else {
    formData.amount = ((balanceInfo.value?.balance || 0) * (val / 100)).toFixed(4) + '';
  }
}
function amountChange() {
  if (isBuy.value) {
    scale.value = parseInt((parseFloat(formData.amount) / maxNum.value) * 100) || 0;
  } else {
    scale.value = parseInt((parseFloat(formData.amount) / (balanceInfo.value?.balance || 0)) * 100) || 0;
  }
}
const maxNum = computed(() => {
  return (balanceUsdt.value?.balance || 0) / parseFloat(formData.price);
});

function confirmHandel() {
  const amount = parseFloat(formData.amount) || 0;
  const balance = balanceInfo.value?.balance || 0;
  console.log(amount, '111');
  console.log(balance, '222');
  // const useBalance = balanceUsdt.value?.balance || 0;
  // if (amount > 0 || (isBuy.value ? amount > maxNum.value : amount > balance)) {
  //   showToast(t('inputCorrectQuantity'));
  //   return;
  // }
  confirmDialog.value = true;
}

// 提交委托
function submitEntrust() {
  loading.value = true;
  addEntrust(formData)
    .then((): void => {
      confirmDialog.value = false;
      formData.amount = '';
      entryList.value.orderListHandler(true);
      showToast(t('operationSuccessful'));
    })
    .finally(() => {
      loading.value = false;
    });
}
//关闭委托购买
const submitOut = () => {
  confirmDialog.value = false;
}; // 选择币种
function selectItem(item: CoinList): void {
  currentCoin.value = item;
  leftMenu.value = false;
  formData.symbol = item.symbol;
  formData.price = item.close + '';
  console.log(fromCoin.value);
  coinName.value = fromCoin.value.toLocaleLowerCase();
  token && getWalletListHanler(fromCoin.value);
}

// 打开侧边
function openLeftPop() {
  pupActive.value = 1;
  leftMenu.value = !leftMenu.value;
}
</script>
<style lang="scss" scoped>
@import './css/index.scss';
</style>
