import { langType } from './lang-base';

export const lang: langType = {
  receivingAddress: '收款地址 ',
  second: '秒',
  minimum: '最少',
  most: '最多',
  minimumSell: '起买',
  ceiling: '封顶',
  availableBalance: '当前余额',
  Nomoreatmoment: '暂无更多',
  updateSet: '版本更新',
  pleaseEnterTheAmount: '请输入金额',
  SearchCurrency: '搜索合同编号',
  Unabletochangeorder: '无法改变订单状态',
  Yourbalanceisinsufficient: '您的余额不足',
  Ordinarysingle: '普通单',
  DailyDistributions: '每日发放收益，锁定期结束后返回本金',
  Resumptionhasbeenactivated: '复投已开启',
  Resumptionhasbeenclosed: '复投已关闭',
  orderCount: '订单数量',
  PleaseEnter: '请输入合同编号',
  emailNi: '请输入邮箱验证码',
  existingSES: '已有Btrok账户',
  email: '邮箱',
  myinvitation: '我的邀请',
  teamValue: '团队贡献值',
  shareValue: '分享贡献值',
  allValue: '总贡献值',
  myBenefits: '我的贡献值',
  contribution: '贡献值',
  contributionDetail: '贡献值明细',
  Dailyinterestrate: '日利率',
  LendingDays: '借贷天数',
  Financialproductdetails: '质押详情',
  Subscriptionwallet: '认购钱包',
  Subscriptionamount: '认购金额',
  SubscriptionDetails: '认购详情',
  Expirationtime: '到期时间',
  Investmenttype: '投资类型',
  InvestmentDetails: '投资详情',
  Thesystemwillautomatically: '投资到期的订单，系统将会自动匹配新的质押订单。',
  Reswitchingfunction: '复投功能',
  description: '说明',
  Investmentquantity: '投资数量',
  Investmentdetermination: '投资确定',
  RevenueDetails: '收益明细',
  InvestmentOrders: '投资订单',
  Estimatedtotalrevenue: '预计总收益',
  Redemptionmethod: '赎回方式',
  Dailyrevenue: '每天收益',
  LockedDays: '锁定天数',
  Currenttotalinvestmentequivalent: '当前投资总额（折合）',
  complete: '完成',
  Fundinglimit: '筹资额度',
  Contractnumber: '合同编号',
  Loanfinancing: '借贷理财',
  Subscriptiontime: '认购时间',
  Myinvestmenthistory: '我的投资记录',
  dailyReturn: '日收益率',
  Investmentdays: '投资天数',
  Selfselectionarea: '快选区',
  Quickconstructionarea: '快建区',
  Smartcontractmatching: '智能合约匹配',
  ImmediateInvestment: '立即投资',
  sourceTime: '时间',
  viewCode: '查看代码',
  goWealh: '财富之路',
  fileGo: '转到文件',
  videoRequest: '视频请求',
  gorequest: '拉取请求',
  proceeding: '诉讼',
  pwds: '密码',
  experienceAll: '满星体验感',
  experienceFive: '五星体验感',
  sharing: '财富共享',
  customer: '客服',
  todoList: '代办事项',
  systemMessages: '系统消息',
  youHave: '您有',
  totleWait: '条待办，请及时处理',
  totleBook: '条未读系统消息',
  message: '消息',
  officialNowGo: '现在开始',
  officialFinace: '享受区块链金融，从未如此简单!',
  officialTechnologyLeft: '前沿',
  officialTechnologyRight: '技术',
  superSESMessage: 'Btrok信息',
  superSES: 'Btrok',
  superFomamce: '合约金融，享受金融',
  superSecure: '高收益安全区块链投资',
  superParagraphOne:
    'Btrok国际数字加密资产交易平台是一个综合数字资产交易平台,我们拥有领先的技术实力、优质的客户服务和良好的用户体验，为广大数字货币爱好者提供一个自由的网上交换的平台。',
  superParagraphTwo:
    'Btrok国际数字加密资产交易平台同时还提供数字货币白皮书规划、加密算法调整、数字货币研发、交易平台上架一条龙的区块链服务。',
  superParagraphThree: 'Btrok国际数字加密资产交易平台海外运营中心分别设立在新加坡、柬埔寨、马绍尔。',
  Traverser: 'Traverser',
  announceList: '公告列表',
  announceListDetail: '公告详情',
  official: '官方',
  supervise: '监督节点',
  Source: '开源代码',
  report: '审计报告',
  more: '更多',
  deposit: '充币',
  invite: '邀请',
  lockMining: '锁仓挖矿',
  riseRanking: '涨幅榜',
  currency: '币种',
  recentPrice: '最近价格',
  gains: '24小时涨幅',
  welcomes: '欢迎您!',
  home: '首页',
  messageList: '消息列表',
  noData: '暂无数据',
  market: '行情',
  financialManagement: '质押',
  optional: '自选',
  buy: '买入',
  sell: '卖出',
  completed: '已完成',
  limitOrder: '限价委托',
  marketOrder: '市价委托',
  call: '看涨',
  put: '看跌',
  success: '成功',
  failure: '失败',
  transaction: '交易',
  contract: '期权',
  forex: '外汇',
  mine: '我的',
  notNull: '不能为空',
  optionContract: '期权合约',
  currentDelivery: '当前交割',
  historyDelivery: '历史交割',
  fee: '手续费',
  openAmount: '开仓金额',
  bonusQuantity: '奖金数量',
  predictionDirection: '预测方向',
  requestFailure: '请求失败，点击重新加载',
  predictionResult: '预测结果',
  submitSuccess: '提交成功',
  sharePoster: '分享海报',
  cancel: '取消',
  copyLink: '复制链接',
  saveImage: '保存图片',
  pleaseLoginFirst: '请先登录',
  pleaseUseTronWallet: '请使用波场钱包打开充值链接',
  currentInterest: '活期利息',
  fundsSecurity: '资金安全',
  guarantee: '保障',
  holidayEarnings: '节假日收益不间断',
  warning: '请勿向上述地址充任何非币种资产, 否则资产将不可找回。',
  confirmation: '您充值至上述地址后，需要整个区块链网络节点的确认，一般区块链主网3次网络确认后到账。',
  addressChange: '您的充值地址不会经常改变，可以重复充值；如有更改，我们会尽量通过网站公告或邮件通知您。',
  safetyReminder: '请务必确认操作环境安全，防止信息被篡改或泄露。',
  rechargeUSDT: '充值USDT',
  details: '详情',
  dayLimit: '天',
  mining: '挖矿赚不停',
  upcoming: '即将开始',
  inProgress: '进行中',
  distribution: '派发中',
  progress: '进度',
  totalAmount: '活动总量',
  startTime: '开始时间',
  endTime: '结束时间',
  lockingMining: '锁仓挖矿是通过将USDT托管给平台超算力矿机在平台矿池中进行挖矿收益',
  productHighlights: '产品亮点',
  withdrawal: '随存随取',
  dividendCycle: '派息周期',
  successfulDepositOnDay: '成功存入后当天',
  interestAccrual: '起息',
  example: '举个例子',
  profitCalculation: '收益计算',
  simplestProfitFormula: '最简单的收益计算公式为',
  profitRateCalculationExample: '例如收益是2W，本金是10W，则收益率',
  calculationOfPrincipal: '对于证券账户，如果不记得投入的本金，可以通过当前总市值和当前盈亏来计算',
  currentMarketValueExample: '例如当前总市值是11W，当前啊浮赢是2W，则本金为11W-2W=9W，收益率=(2W/9W)',
  penalty: '违约金',
  participate: '我要参与',
  entrustedOrder: '委托订单',
  pledgingInProgress: '正在质押',
  pledgingComplete: '质押完成',
  earlyRedemption: '提前赎回',
  loading: '正在加载',
  pledgeAmount: '质押金额',
  dailyEarnings: '每日收益',
  earlyRedemptionDeductionHandlingFee: '提前赎回扣除手续费',
  earlyRedemptionAgain: '提前赎回',
  endOfContent: '已经到底了',
  financialProduct: '质押',
  fundsUnderManagement: '正在托管的资金',
  expectedTodayEarnings: '预计今日收益',
  TodayEarnings: '今日收益',
  accumulatedEarnings: '累计收益',
  trustOrder: '托管中的订单',
  buy2: '去买入',
  period: '周期',
  switchLanguage: '语言切换',
  username: '用户名',
  emailPlaceholder: '请输入邮箱',
  forgotPassword: '忘记密码',
  passwordPlaceholder: '请输入密码',
  submit: '提交',
  registerNow: '立即注册',
  search: '搜索',
  high: '高',
  low: '低',
  depth: '深度',
  deal: '成交',
  buyQuantity2: '买盘数量',
  price: '价格',
  quantity: '数量',
  sellQuantity: '卖盘数量',
  time: '时间',
  direction: '方向',
  inputPrice: '请输入价格',
  tradingVolume: '交易额',
  entrust: '委托',
  entrustPrice: '委托价格',
  entrustQuantity: '委托量',
  totalEntrustment: '委托总额',
  category: '类别',
  confirm: '确定',
  inputCorrectQuantity: '请输入正确的数量',
  operationSuccessful: '操作成功',
  revoke: '撤销',
  entrustmentCancellationConfirmation: '委托撤销确认',
  areYouSureToCancel: '您是否确认撤单？',
  all: '全部',
  currentEntrustment: '当前委托',
  completed2: '已成交',
  aboutSES: '关于Btrok',
  freeExchange: '为广大数字货币爱好者提供一个自由的网上交换的平台',
  modify: '修改',
  bind: '绑定',
  alipayAccount: '支付宝账号',
  wechatAccount: '微信账号',
  unbound: '未绑定',
  sesPlatformOverview3:
    'Btrok数字资产交易所是由英国MAI国际基金会投资开发的全球性的数字资产交易平台、总部位于英国、受英国NFC的监管、在欧州区块链行业做了一系列基础建设工作，以英国为基础，遍布欧洲及亚洲、东南亚、美洲、澳洲、打造数字资产区块链的行业生态圈。作为一家面向全球投资者的国际化数字资产交易所，Btrok数字资产交易所结合当下行业优势、拥有银行金融管理资深团队、专业大数据开发团队、全球顶尖区块链开发团队及领先的区块链技术服务团队。旨在为用户提供高效，安全的数字资产交易服务。',
  sesPlatformOverview4:
    '作为全球首家综合性数字资产交易平台，在世界范围内是区块链领域的顶尖交易平台之一，覆盖全世界的数字资产生态布局，持有国际牌照及政府机构认可。Btrok将在技术创新、产品迭代、安全风控体系、运营及客户服务体系等方面持续改进，在为全球用户提供比特币、以太坊等主流数字货币现货和衍生品交易服务的同时，Btrok与时俱进，助力全方位区块链生态系统搭建。',
  sesPlatformOverview5:
    '建立一个安全、体验、自由的数字资产交易平台以及满足全球广大数字货币爱好者需求的金融基础设施，真正建设数字互联时代之下虚拟数字资产的交易交流的理想社区。为广大投资者提供一个安全，可靠，稳定的综合服务交易平台。',
  bankCardNumber: '银行卡帐号',
  accountSettings: '账户设置',
  modifyAlipayAccount: '修改支付宝账号',
  receiveQRCode: '收款二维码',
  fundPassword: '资金密码',
  pleaseEnterCorrectFundPassword: '请输入正确的资金密码',
  pleaseEnterAlipayAccount: '请输入支付宝账号',
  myAssets: '我的资产',
  details2: '明细',
  withdrawalRecords: '提币记录',
  totalAssetsApprox: '总资产约(合USDT)',
  spotAccount: '币币账户',
  searchAssets: '搜索资产',
  hideZeroBalances: '隐藏为0的币种',
  frozen: '冻结',
  toRelease: '待释放',
  modifyBankCardNumber: '修改银行卡帐号',
  save: '保存',
  bankName: '开户银行',
  bankCardNumberConfirm: '确认卡号',
  pleaseEnterBankCardNumberAgain: '请再次输入银行卡号',
  fundPasswordPrompt: '资金密码',
  pleaseEnterFundPassword: '请输入资金密码',
  modifyFundPassword: '修改资金密码',
  modifyingFundPassword: '您正在修改资金密码',
  oldFundPassword: '旧资金密码',
  newFundPassword: '新资金密码',
  confirmFundPassword: '确认资金密码',
  pleaseEnterSetPassword: '请输入设置密码',
  pleaseEnterConfirmFundPassword: '请输入确认资金密码',
  passwordsDoNotMatch: '密码不一致',
  pleaseEnterYourOldFundPassword: '请输入旧资金密码',
  pleaseEnterYourNewFundPassword: '请输入新资金密码',
  pleaseEnterA6DigitPassword: '请输入6位数字密码',
  pleaseConfirmNewFundPassword: '请确认新资金密码',
  theNewFundPasswordCannotBeTheSameAsTheOldFundPassword: '新资金密码不能与旧资金密码相同',
  pleaseEnterTheVerificationCode: '请输入验证码',
  feedback: '意见反馈',
  yourOpinionIsVeryImportantToUs: '您的意见对我们来说特别重要！',
  leaveYourValuableOpinionToHelpUsImprove: '留下您宝贵的意见,以帮助我们做的更好!',
  pleaseLeaveYourValuableOpinion: '请留下您宝贵的意见',
  submissionSuccessfulThankYouForYourFeedback: '提交成功，感谢您的反馈',
  submissionFailed: '提交失败',
  setFundPassword: '设置资金密码',
  setYourPaymentMethod: '请设置您的收款方式，请务必使用本人的实名帐号',
  newPassword: '新密码',
  repeatPassword: '重复密码',
  notLoggedIn: '未登录',
  welcome: '欢迎来到Btrok',
  assetManagement: '资产管理',
  assetFlow: '资产流水',
  myInvitation: '我的邀请',
  invitationLink: '邀请链接',
  firstLevelFriends: '一级好友',
  secondLevelFriends: '二级好友',
  partnerLevel: '个人级别',
  myEntrustment: '我的委托',
  securitySettings: '安全设置',
  pleaseLogInFirst: '请先登录',
  announcement: '公告',
  changeLoginPassword: '更改登录密码',
  oldLoginPassword: '旧登录密码',
  newLoginPassword: '新登录密码',
  pleaseEnterOldLoginPassword: '请输入旧登录密码',
  pleaseEnterNewLoginPassword: '请输入新登录密码',
  confirmLoginPassword: '确认登录',
  emailVerificationCode: '邮箱验证码',
  emailBound: '您已经绑定邮箱',
  verificationCode: '验证码',
  sendVerificationCode: '发送验证码',
  pleaseConfirmInformation: '请确认是否填写好所有信息',
  pleaseConfirmPassword: '请确认两次输入的密码是否一致',
  makeSureTheOldAndNewPasswordsAreDifferent: '请确保新旧密码不一样',
  pleaseConfirmNewLoginPassword: '请确认新登录密码',
  teamPerformance: '团队业绩',
  currentLevel: '当前级别',
  nextLevel: '下一级别',
  teamNumber: '团队人数',
  totalPerformance: '总业绩',
  onlyForUSDT: '仅支持对该地址充值USDT',
  walletAddress: '钱包地址',
  securityReminder: '为保障您的账户安全，请完成实名认证后方可交易操作！',
  personalAvatar: '个人头像',
  isVerified: '已认证',
  realNameAuthentication: '实名认证',
  notVerified: '未认证',
  receivingAccount: '提币地址',
  loginPassword: '登录密码',
  fundsPassword: '资金密码',
  settingsCenter: '设置中心',
  aboutUs: '关于我们',
  helptext: '帮助说明',
  help: '帮助',
  logout: '退出当前账号',
  confirmLogout: '你确定要退出登录？',
  bindWeChatAccount: '绑定微信账号',
  withdrawalAddress: '提币地址',
  available: '可用',
  minimumWithdrawalAmount: '最小提币数量',
  amountReceived: '到账数量',
  transactionTime: '交易时间',
  payableHandlingFee: '应付手续费',
  deductibleHandlingFee: '抵扣手续费',
  actualHandlingFee: '实付手续费',
  feeGenerationTime: '起止时间为手续费生成时间',
  takeIDPhoto: '拍摄上传您的身份证照片',
  identityAuthentication: '身份认证',
  uploadIDCardFront: '上传身份证正面图片',
  uploadIDCardBack: '上传身份证反面图片',
  photoAlbum: '相册',
  IDCardNumber: '身份证号码',
  enterIDCardNumber: '请输入身份证号码',
  name: '姓名',
  idea: 'Btrok理念',
  goal: 'Btrok目标',
  depositRecord: '充值记录',
  withdrawal2: '提币',
  accountOpeningBranch: '开户支行',
  quantityInput: '请输入数量',
  singleLimit: '单笔限额',
  investmentAmount: '投资金额',
  incomePrincipal: '收益/本金',
  password: '密码',
  historicalCommission: '历史委托',
  type: '类型',
  commissionAmount: '委托金额',
  filter: '筛选',
  pleaseSelect: '请选择',
  tradingPair: '交易对',
  reset: '重置',
  startEndTime: '起止时间',
  recharge2: '充值',
  withdraw: '提现',
  transfer: '转账',
  coinTrading: '币币交易',
  fiatCurrencyPurchase: '法币买入',
  fiatCurrencySale: '法币卖出',
  activityReward: '活动奖励',
  promotionReward: '推广奖励',
  dividend: '分红',
  vote: '投票',
  manualRecharge: '人工充值',
  pairing: '配对',
  activityExchange: '活动兑换',
  ctcPurchase: 'CTC买入',
  ctcSale: 'CTC卖出',
  redEnvelopeSending: '红包发出',
  redEnvelopeReceiving: '红包领取',
  withdrawalCodeWithdrawal: '提现码提现',
  withdrawalCodeRecharge: '提现码充值',
  perpetualContractFee: '合约手续费',
  perpetualContractProfit: '合约盈利',
  perpetualContractLoss: '合约亏损',
  optionContractFailure: '期权合约失败',
  optionContractFee: '期权合约手续费',
  optionContractBonus: '期权合约奖金',
  contractRebate: '合约返佣',
  levelReward: '平级奖励',
  platformFeeIncome: '平台手续费收入',
  forexEntrustTrading: '外汇委托交易',
  forexCancelEntrustTrading: '外汇取消委托交易',
  income: '收益',
  promotionIncome: '推广收益',
  optionsPromotionIncome: '期权推广收益',
  liquidationCompensation: '爆仓赔付',
  lockedMiningIncome: '团队收益',
  optionTeamIncome: '期权团队收益',
  optionParityAward: '期权平级奖励',
  investmentTeamReturn: '投资团队收益',
  investmentParityReturn: '投资平级收益',
  investmentPromotionIncome: '投资推广收益',
  NEWACTIVITY_IN: '投资收益',
  NEWACTIVITY_DEDUCT: '投资赎回',
  NEWACTIVITY_FREEZE: '期权冻结',
  NEWACTIVITY_UNFREEZE: '期权解冻',
  NEWACTIVITY_REDEEM_UNFREEZE: '期权赎回解冻',
  speedContractProfit: '秒合约盈利',
  speedContractLoss: '秒合约亏损',
  speedContractCommission: '秒合约手续费',
  CONTRACT_FAST_FREEZE: '秒合约冻结',
  CONTRACT_FAST_UNFREEZE: '秒合约解冻',
  confirmUploadAllImages: '请确认是否已经上传全部图片',
  inputRealName: '请输入您的真实姓名',
  idCardPhoto: '身份证照片',
  congratulations: '恭喜你，审核通过，可以安心交易了',
  quantity2: '量',
  wechatAccount2: '请输入微信账号',
  realNameIdentityVerified: '实名身份已验证',
  withdrawalTime: '提现时间',
  withdrawalAmount: '提现数量',
  creditting: '审核中',
  waitingmoney: '等待放币',
  dztime: '充值时间',
  rushaddress: '充币地址',
  rushnumber: '充值数量',
  regisertitle: '邮箱注册',
  inputemailcode: '请输入邮箱验证码',
  inputloginpassword: '请输入密码（大于6位的数字、大小写字母组合）',
  inputreloginpassword: '请再次输入密码',
  inputinvite: '请输入邀请码',
  iagree: '我已阅读并同意',
  useragreement: '用户协议',
  nowlogin: '立即登录',
  sendcode: '发送验证码',
  rightemailformat: '请输入正确的邮箱格式',
  confirmPassword: '确认密码',
  passwordtip: '密码必须至少包含6位数',
  nosamepassword: '两次密码不一致',
  noagree: '未勾选同意用户协议',
  emailverification: '邮箱验证',
  amount: '数量',
  overturn: '划转',
  overturntitle: '资产划转',
  from: '从',
  to: '到',
  swapcurrency: '合约币种',
  overturnplaceholder: '请输入划转数量',
  selectaccount: '选择账户',
  balance: '资产',
  contractaccount: '合约账户',
  invitecode: '邀请码',
  notset: '未设置',
  day: '天',
  contactservice: '联系客服',
  appname: 'Btrok APP',
  download: '下载APP',
  orderByHand: '手动下单',
  orderByAuto: '智能下单',
  trevenue: '总收益',
  pincome: '推广收益',
  tincome: '团队收益',
  dropList: '下拉列表',
  uploaded: '已上传',
  youare: '你正在',
  disabling: '关闭',
  enabling: '开启',
  theAutomaticReinvestmentFeatur: '自动复投功能',
  nth: '第',
  phase: '期',
  currentForecastPeriod: '本期预测周期',
  maximum: '最大量',
  openingPrice: '开盘价',
  currentPrice: '当前价',
  profitSharing: '盈利分红',
  CurrentTradingIsRestricted: '当前限制交易',
  ToEnsureTheSsecurityOfYourAssetsPleaseCarefullyVerifyTheReceivingWalletAddressBeforeMakingAnyDeposits:
    '为了保证大家资产的安全，充值请认真核对收款钱包地址：',
  PleaseProceedWithTheDepositOnlyAfterVerifyingThatEverythingIsCorrect: '。核对无误方可进行充值。',
  PleaseDoNotDepositAnyAssetsOtherThanTheSpecifiedCryptocurrencyToTheAddressMentionedAboveOtherwiseYourAssetsWillNotBeRecoverable:
    '请勿向上述地址充值任何非币种资产，否则资产将不可找回。',
  AfterYouMakeADepositToTheMentionedAddressItRequiresConfirmationFromTheEntireBlockchainNetworkTypicallyItTakesAround30SecondsForTheFundsToBeCreditedToYourAccountAfterThreeConfirmationOnTheBlockchainMainnet:
    '您充值至上述地址后，需要整个区块链网络节点的确认，一般区块链主网3次网络确认后到账，大概需要30秒左右。',
  PleaseEnsureTheSecurityOfYourElectronicDevicesToPreventAnyTamperingOrLeakageOfInformation:
    '请务必确认电子设备安全，防止信息被篡改或泄露。',
  WithdrawalsAreCurrentlyDisabled: '当前禁止提币',
  CurrentWithdrawalAddress: '请确认当前提币地址 ',
  isYourWalletAddress: ' 是否是您的钱包地址',
  LinkCopiedSuccessfully: '复制成功',
  LinkCopiedFailed: '复制失败',
  goLogin: '去登录',
  other: '其他',
  SavePicture: '保存图片',
  SaveLink: '保存链接',
  languageSettings: '语言设置',
  simplifiedChinese: '简体中文',
  traditionalChinese: '繁体中文',
  arabic: 'عربي',
  english: 'English',
  japanese: '日本語',
  French: 'Français',
  Spanish: 'Español',
  German: 'Deutsch',
  Italiano: 'Italiano',
  TotalDailyLoss: '总盈利',
  TotalExpenditureToday: '今日总下单金额',
  TotalIncomeToday: '今日总获利',
  CurrentDelivery: '当前交割',
  HistoricalDelivery: '历史交割',
  InitialAmount: '下单金额',
  ForecastDirection: '预测方向',
  BonusAmount: '奖金数量',
  TransactionFee: '手续费',
  OrderDuration: '订单持续时间',
  Status: '状态',
  SelectCurrency: '选择货币',
  EnterAmount: '输入金额',
  BUY: '买涨',
  SELL: '买跌',
  WIN: '成功',
  LOSE: '失败',
  Amount: '金额',
  expectedReturn: '预期收益',
  service: '客服',
  untrade: '当前不能交易',
  netError: '网络出小差啦!',
  questError: '请求出错了，请稍后再试!',
  failVerified: '认证失败',
  tiprealverified: '实名审核中，请联系客服',
  uploadImg: '上传凭证',
  collected: '已收藏',
  canceld: '已取消',
  placeholderAddress: '请输入收款地址',
  bindAccountTitle: '收款地址',
  marketTitle: '行情',
  regiserSuccess: '注册成功',
  setSuccess: '设置成功',
  updateAddress: '我要修改钱包地址！',
  doingRealName: '实名正在审核当中，请耐心等待',
  updateSuccess: '修改成功',
  uploadSuccess: '上传成功',
  forgetLpass: '忘记密码请联系客服核对信息进行处理重置登录密码',
  forgetApass: '忘记密码请联系客服核对信息进行处理重置交易密码',
  updateAddressTip: '收款地址修改请联系客服核对个人信息进行处理重置收款地址',
  a48h: '提币已受理，提币申请48小时内处理完成，请耐心等待',
  r48h: '充值已受理，充值订单48小时内处理完成，请耐心等待',
  accepted: '受理中',
  notremind: '不再提醒',
  //new
  rate: '盈利率',
  mode: '交易模式',
  num: '开仓数量',
  openTime: '开仓时间',
  phoneNi: '请输入手机验证码',
  phonePlaceholder: '请输入手机号',
  inputphonecode: '请输入手机验证码',
  phone: '手机号',
  phoneVcode: '手机验证码',
  sendSucc: '发送成功',
  cScore: '信用分',
  wallet: '钱包',
  searchMp: '搜索加密货币的名称',
  clickUploadId: '点击上传您的身份照片',
  modifyFp: '调整',
  confirmFPassword: '确认资产密码',
  pleaseSetYourNewFundPassword: '请设置资金密码',
  nosamefpassword: '资金密码不正确，请再次确认。',
  forgotFPassword: '忘记您的交易密码?',
  serviceCode: {
    0: '成功',
    500: '失败',
    501: '注册失败',
    502: '发送失败',
    503: '添加失败',
    504: '查询失败',
    600: '该币种不支持提币',
    1001: '验证码不存在  ',
    1003: '提币数量大于最大值',
    1004: '提币数量小于最小值',
    1005: '可用余额不足',
    1006: '钱包已锁定',
    1007: '当前实名认证正在审核中，请耐心等候，审核通过再进行操作',
    1008: '请先设置资金密码',
    1009: '资金密码错误',
    1010: '无法锁定资产',
    1011: '该邮箱已经被绑定',
    1012: '该用户名已存在',
    1013: '无此用户',
    1014: '注册激活邮件已经发送至您邮箱，请勿重复请求',
    1015: '请先绑定邮箱',
    1016: '下单金额不能为0',
    1017: '您当前账户已禁用，请联系客服！',
    1018: '活动不存在',
    1019: '购买金额小于最低金额',
    1020: '购买金额大于最高金额',
    1021: '不支持的币种！',
    1022: '交易對已收藏',
    1023: '请勿重复设置',
    1024: '资金密码长度不合法',
    1025: '合约不存在',
    1026: '预测方向不对',
    1027: '用户钱包不存在',
    1028: '账号或密码错误',
    1029: '该帐号处于未激活/禁用状态，请联系客服',
    1030: '请先完成实名认证',
    1031: '请设置交易密码',
    1032: '已实名认证过，不可再次认证',
    1033: '同一个身份证号只能认证一次',
    1035: '旧密码错误',
    1036: '请上传充值凭证',
    1037: '请输入正确的充值金额',
    1038: '账号不存在',
    1039: '密码不正确',
    1040: '旧密码不正确',
    1041: '交易密码错误，请重新输入',
    1042: '邀请码错误',
    1043: '提现地址异常',
    1065: '当前订单正在审核中，请勿重复提交',
    3000: '登陆超时',
    888: '请先完成实名认证',
  },
  korean: '한국어',
  turkish: 'Türkçe',
};
