import { langType } from './lang-base';

export const lang: langType = {
  receivingAddress: 'Adresse de retrait',
  second: 'Sec',
  minimum: 'moins',
  most: 'la plupart',
  minimumSell: 'commencer à acheter',
  ceiling: 'Plafonné',
  availableBalance: 'Solde disponible actuel',
  Nomoreatmoment: 'pas plus',
  updateSet: 'mise à jour de la nouvelle version',
  pleaseEnterTheAmount: 'Veuillez entrer le montant',
  SearchCurrency: 'Rechercher un numéro de contrat',
  Unabletochangeorder: 'Impossible de modifier le statut de la commande',
  Yourbalanceisinsufficient: 'Votre solde est insuffisant',
  Ordinarysingle: 'ordre permanent',
  DailyDistributions: 'Les bénéfices sont distribués quotidiennement et le capital est restitué après la période de mise.',
  Resumptionhasbeenactivated: 'Le réinvestissement est en cours',
  Resumptionhasbeenclosed: 'le réinvestissement est désormais désactivé',
  orderCount: 'Quantité de commande de jalonnement',
  PleaseEnter: 'Veuillez entrer le numéro de contrat',
  emailNi: "Veuillez saisir le code de vérification de l'e-mail",
  existingSES: 'Compte Btrok existant',
  email: 'Mail',
  myinvitation: 'Inviter des amis',
  teamValue: "Cotisation d'équipe",
  shareValue: 'cotisation',
  allValue: 'valeur totale des cotisations',
  myBenefits: 'ma contribution',
  contribution: 'Contribution',
  contributionDetail: 'Détails de la valeur de la contribution',
  Dailyinterestrate: "Taux d'intérêt quotidien",
  LendingDays: 'Jours de jalonnement',
  Financialproductdetails: 'Détails du produit de gage financier',
  Subscriptionwallet: "Portefeuille d'abonnement",
  Subscriptionamount: "Montant de l'abonnement",
  SubscriptionDetails: "Mode d'abonnement",
  Expirationtime: "Date d'expiration",
  Investmenttype: 'Type de gage',
  InvestmentDetails: 'Signez le contrat de gage',
  Thesystemwillautomatically:
    "Le système fera automatiquement correspondre les nouvelles commandes de promesses de don avec les commandes de promesses de don expirées. Les commandes rétroactives continueront de bénéficier des avantages présentés par la plateforme. Une fois que les commandes non soumises à nouveau atteignent la date d'expiration, les avantages présentés seront détruits.",
  Reswitchingfunction: 'fonction de réinvestissement',
  description: 'illustrer',
  Investmentquantity: 'quantité Staking maximale',
  Investmentdetermination: 'Détails du jalonnement',
  RevenueDetails: 'Détails des revenus',
  InvestmentOrders: 'Enregistrements de jalonnement',
  Estimatedtotalrevenue: 'Bénéfice total estimé',
  Redemptionmethod: 'Méthode de gage',
  Dailyrevenue: 'Gains quotidiens',
  LockedDays: 'Cycle de jalonnement',
  Currenttotalinvestmentequivalent: 'Montant total actuel de la mise (équivalent)',
  complete: 'Confirm',
  Fundinglimit: 'Plafond du capital mis en gage',
  Contractnumber: 'Numéro de contrat',
  Loanfinancing: 'fonction de réinvestissement',
  Subscriptiontime: 'Heure de début',
  Myinvestmenthistory: 'Mon record de staking',
  dailyReturn: 'Taux de rendement journalier',
  Investmentdays: "Journées d'investissement",
  Selfselectionarea: 'Zone de sélection rapide',
  Quickconstructionarea: 'Zone de construction rapide',
  Smartcontractmatching: 'Appariement intelligent des contrats',
  ImmediateInvestment: 'Staker maintenant',
  sourceTime: 'temps',
  viewCode: 'afficher le code',
  goWealh: 'route vers la richesse',
  fileGo: 'aller au dossier',
  videoRequest: 'demande de vidéo',
  gorequest: "demande d'extraction",
  proceeding: 'litige',
  pwds: 'mot de passe',
  experienceAll: "Expérience d'étoile complète",
  experienceFive: 'Expérience cinq étoiles',
  sharing: 'partage des richesses',
  customer: 'service client',
  todoList: 'faire',
  systemMessages: 'informations système',
  youHave: 'Vous avez ',
  totleWait: 'choses à faire, veuillez les traiter à temps',
  totleBook: 'message(s) système non lus',
  message: 'information',
  officialNowGo: 'Commencez maintenant',
  officialFinace: "Profiter de la finance blockchain n'a jamais été aussi simple!",
  officialTechnologyLeft: 'avant-gardiste',
  officialTechnologyRight: 'technologie',
  superSESMessage: 'Informations Btrok',
  superSES: 'Btrok',
  superFomamce: 'Financement contractuel, profitez du financement',
  superSecure: 'Investissement dans la blockchain de sécurité à haut rendement',
  superParagraphOne:
    "La plate-forme internationale d'échange d'actifs cryptés numériques Btrok est une plate-forme complète d'échange d'actifs numériques.Nous avons une force technique de pointe, un service client de haute qualité et une bonne expérience utilisateur, et fournissons une plate-forme d'échange en ligne gratuite pour les amateurs de monnaie numérique.",
  superParagraphTwo:
    "Btrok International Digital Encrypted Asset Trading Platform fournit également la planification du livre blanc sur la monnaie numérique, l'ajustement de l'algorithme de chiffrement, la recherche et le développement de la monnaie numérique et des services de blockchain à guichet unique sur la plateforme de trading.",
  superParagraphThree:
    "Les centres d'opérations à l'étranger de la plate-forme internationale d'échange d'actifs de cryptage numérique Btrok sont respectivement établis à Singapour, au Cambodge et à Marshall.",
  Traverser: 'Traverser',
  announceList: 'Annonce',
  announceListDetail: "Détails de l'annonce",
  official: 'officiel',
  supervise: 'noeud de supervision',
  Source: 'code source ouvert',
  report: "Rapport d'audit",
  more: 'Plus',
  deposit: 'Déposer des cryptos',
  invite: 'Inviter des amis',
  lockMining: "Verrouiller l'exploitation minière",
  riseRanking: 'classement en hausse',
  currency: 'Coin',
  recentPrice: 'Dernier prix',
  gains: 'Variation sur 24 h',
  welcomes: 'Bienvenu!',
  home: 'page de garde',
  messageList: 'liste de messages',
  noData: 'Aucun historique',
  market: 'Marchés',
  financialManagement: 'Staking',
  optional: 'Favorites',
  buy: 'acheter',
  sell: 'vendre',
  completed: 'terminé',
  limitOrder: 'ordre limité',
  marketOrder: 'ordre du marché',
  call: 'haussier',
  put: 'baissier',
  success: 'succès',
  failure: 'Échec',
  transaction: 'commerce',
  contract: 'Trading Spot',
  forex: 'change',
  mine: 'le mien',
  notNull: 'Ne peux pas être vide',
  optionContract: "contrat d'option",
  currentDelivery: 'Ordres en cours',
  historyDelivery: 'Historique des ordres',
  fee: 'Les frais de transaction',
  openAmount: "Montant d'ouverture",
  bonusQuantity: 'montant de la prime',
  predictionDirection: 'Direction des prévisions',
  requestFailure: 'La demande a échoué, cliquez pour recharger',
  predictionResult: 'résultat prévisionnel',
  submitSuccess: 'Soumis avec succès',
  sharePoster: 'partager une affiche',
  cancel: 'Annuler',
  copyLink: 'Copier le lien',
  saveImage: "enregistrer l'image",
  pleaseLoginFirst: "s'il vous plait Connectez-vous d'abord",
  pleaseUseTronWallet: 'Veuillez utiliser le portefeuille TRON pour ouvrir le lien de recharge',
  currentInterest: 'Intérêt actuel',
  fundsSecurity: 'Sécurité financière',
  guarantee: 'Assurer',
  holidayEarnings: 'Revenu de vacances ininterrompu',
  warning: "Veuillez ne pas facturer d'actifs non monétaires à l'adresse ci-dessus, sinon les actifs ne seront pas récupérés.",
  confirmation:
    "Après avoir rechargé à l'adresse ci-dessus, la confirmation de l'ensemble du nœud du réseau blockchain est requise.Généralement, le réseau principal blockchain sera crédité après 3 confirmations du réseau.",
  addressChange:
    "Votre adresse de recharge ne changera pas fréquemment et vous pouvez recharger à plusieurs reprises ; en cas de changement, nous ferons de notre mieux pour vous en informer par le biais d'annonces sur le site Web ou d'e-mails.",
  safetyReminder:
    "Assurez-vous de confirmer que l'environnement d'exploitation est sûr pour éviter que des informations ne soient falsifiées ou divulguées.",
  rechargeUSDT: 'Dépôt USDT',
  details: 'détails',
  dayLimit: 'jours',
  mining: "L'exploitation minière continue de gagner",
  upcoming: 'Sur le point de commencer',
  inProgress: 'en cours',
  distribution: 'Distribuer',
  progress: 'calendrier',
  totalAmount: 'activité totale',
  startTime: 'Heure de départ',
  endTime: 'Heure de fin',
  lockingMining:
    "L'exploitation minière verrouillée consiste à gagner des revenus miniers dans le pool minier de la plate-forme en confiant l'USDT à la machine minière super-informatique de la plate-forme",
  productHighlights: 'Produits phares',
  withdrawal: 'Withdraw Crypto',
  dividendCycle: 'Période de dividende',
  successfulDepositOnDay: 'Le lendemain du dépôt réussi',
  interestAccrual: 'commencer',
  example: 'Par exemple',
  profitCalculation: 'Calcul des revenus',
  simplestProfitFormula: 'La formule de calcul de revenu la plus simple est',
  profitRateCalculationExample: 'Par exemple, le revenu est de 2W, le principal est de 10W, puis le taux de rendement',
  calculationOfPrincipal:
    'Pour un compte-titres, si vous ne vous souvenez pas du capital investi, vous pouvez le calculer sur la base de la valeur marchande totale actuelle et des profits et pertes actuels',
  currentMarketValueExample:
    'Par exemple, la valeur marchande totale actuelle est de 11 W, et le gain flottant actuel est de 2 W, puis le principal est de 11 W-2 W = 9 W, et le taux de rendement = (2 W/9 W)',
  penalty: 'Dommages-intérêts',
  participate: 'Je veux participer',
  entrustedOrder: 'Ordonnance de mandat',
  pledgingInProgress: 'Mettre en gage',
  pledgingComplete: 'Engagement terminé',
  earlyRedemption: 'rachat anticipé',
  loading: 'chargement',
  pledgeAmount: "Montant de l'engagement",
  dailyEarnings: 'Revenu quotidien',
  earlyRedemptionDeductionHandlingFee: 'Commission de remboursement anticipé',
  earlyRedemptionAgain: 'rachat anticipé',
  endOfContent: "C'est fini",
  financialProduct: 'Produit financier',
  fundsUnderManagement: 'Fonds gérés',
  expectedTodayEarnings: "Bénéfices estimés aujourd'hui",
  TodayEarnings: "Le bénéfice d'aujourd'hui",
  accumulatedEarnings: 'Bénéfice accumulé',
  trustOrder: 'Commandes en entiercement',
  buy2: 'acheter',
  period: 'faire du vélo',
  switchLanguage: 'changer de langue',
  username: "nom d'utilisateur",
  emailPlaceholder: "merci d'entrer votre email",
  forgotPassword: 'Mot de passe oublié?',
  passwordPlaceholder: 'veuillez entrer le mot de passe',
  submit: 'soumettre',
  registerNow: "S'inscrire maintenant",
  search: 'Chercher',
  high: '24 h Haut',
  low: '24 h Bas',
  depth: 'Carnet',
  deal: 'Dernières',
  buyQuantity2: "Quantité d'achat",
  price: 'prix',
  quantity: 'Montant',
  sellQuantity: "nombre d'ordres de vente",
  time: 'temps',
  direction: 'direction',
  inputPrice: 'Veuillez entrer un prix',
  tradingVolume: 'Montant de la transaction',
  entrust: 'confier',
  entrustPrice: 'prix des commissions',
  entrustQuantity: 'Montant de la commande',
  totalEntrustment: 'Montant total confié',
  category: 'catégorie',
  confirm: 'Confirmer',
  inputCorrectQuantity: 'Veuillez entrer la bonne quantité',
  operationSuccessful: 'Opération réussie',
  revoke: 'révoquer',
  entrustmentCancellationConfirmation: 'Confirmation de la révocation de la délégation',
  areYouSureToCancel: "Êtes-vous sûr d'annuler la commande ?",
  all: 'tous',
  currentEntrustment: 'commission actuelle',
  completed2: 'Affaire conclue',
  aboutSES: 'À propos Btrok',
  freeExchange: "Fournir une plateforme d'échange en ligne gratuite pour la majorité des passionnés de monnaie numérique",
  modify: 'Changer',
  bind: 'lier',
  alipayAccount: 'Compte Alipay',
  wechatAccount: 'Compte WeChat',
  unbound: 'non lié',
  sesPlatformOverview3:
    "Btrok Digital Asset Exchange est une plateforme mondiale d'échange d'actifs numériques investie et développée par la MAI International Foundation de Singapour. Son siège social est situé à Singapour et est réglementé par Singapore MAS. Une série de travaux de construction d’infrastructures ont été réalisés dans l’industrie mondiale de la blockchain. En tant qu'échange international d'actifs numériques pour les investisseurs mondiaux, Btrok Digital Asset Exchange combine les avantages actuels du secteur avec une équipe de direction financière bancaire senior, une équipe professionnelle de développement de Big Data, la meilleure équipe de développement de blockchain au monde et une équipe de services technologiques Blockchain de premier plan. Il vise à fournir aux utilisateurs des services de trading d'actifs numériques efficaces et sûrs.",
  sesPlatformOverview4:
    "En tant que première plateforme complète de trading d'actifs numériques au monde, elle est l'une des meilleures plateformes de trading dans le domaine mondial de la blockchain. Couvrant la configuration écologique mondiale des actifs numériques, il détient des licences internationales et est reconnu par les agences gouvernementales. Btrok continuera à s'améliorer en termes d'innovation technologique, d'itération de produits, de système de contrôle des risques de sécurité, d'exploitation et de système de service client. Tout en fournissant aux utilisateurs du monde entier des services de trading au comptant et de produits dérivés de monnaie numérique grand public tels que Bitcoin et Ethereum, Btrok suivra le rythme de son temps et contribuera à construire un écosystème blockchain complet.",
  sesPlatformOverview5:
    "Établissez une plateforme d'échange d'actifs numériques sûre, expérimentée et gratuite ainsi qu'une infrastructure financière pour répondre aux besoins de la majorité mondiale des passionnés de monnaie numérique, et construisez véritablement une communauté idéale pour l'échange d'actifs numériques virtuels à l'ère de l'interconnexion numérique. majorité des investisseurs à fournir une plateforme de trading de services intégrés sûre, fiable et stable.",
  bankCardNumber: 'compte carte bancaire',
  accountSettings: 'paramètres du compte',
  modifyAlipayAccount: 'Modifier le compte Alipay',
  receiveQRCode: 'Code QR de paiement',
  fundPassword: 'Mots de passe de Trading',
  pleaseEnterCorrectFundPassword: 'Veuillez remplir le mot de passe de la transaction',
  pleaseEnterAlipayAccount: 'Veuillez entrer le numéro de compte Alipay',
  myAssets: 'Aperçu des actifs',
  details2: 'Détails',
  withdrawalRecords: 'Historique des retraits',
  totalAssetsApprox: 'Solde estimé（USDT）',
  spotAccount: 'compte en devises',
  searchAssets: 'rechercher des actifs',
  hideZeroBalances: 'Devise masquée sous la forme 0',
  frozen: 'temporairement indisponible',
  toRelease: "En attendant d'être libéré",
  modifyBankCardNumber: 'Modifier le numéro de compte de la carte bancaire',
  save: 'soumettre',
  bankName: 'Banque de dépôt',
  bankCardNumberConfirm: 'Confirmer le numéro de carte',
  pleaseEnterBankCardNumberAgain: 'Veuillez saisir à nouveau le numéro de carte bancaire',
  fundPasswordPrompt: 'Mot de passe du fonds',
  pleaseEnterFundPassword: 'Veuillez entrer le mot de passe du fonds',
  modifyFundPassword: 'Changer le mot de passe de la transaction',
  modifyingFundPassword: 'Vous êtes en train de changer votre mot de passe de transaction',
  oldFundPassword: 'Ancien mot de passe de transaction',
  newFundPassword: 'Nouveau mot de passe des actifs',
  confirmFundPassword: 'Confirmer le mot de passe du fonds',
  pleaseEnterSetPassword: 'Veuillez entrer le mot de passe de configuration',
  pleaseEnterConfirmFundPassword: 'Veuillez entrer le mot de passe du fonds de confirmation',
  passwordsDoNotMatch: 'Les mots de passe ne correspondent pas',
  pleaseEnterYourOldFundPassword: "Veuillez saisir l'ancien mot de passe de transaction",
  pleaseEnterYourNewFundPassword: 'Veuillez saisir un nouveau mot de passe de trading',
  pleaseEnterA6DigitPassword: 'Veuillez entrer un mot de passe à 6 chiffres',
  pleaseConfirmNewFundPassword: 'Veuillez confirmer à nouveau votre mot de passe de trading',
  theNewFundPasswordCannotBeTheSameAsTheOldFundPassword:
    "Le nouveau mot de passe du fonds ne peut pas être le même que l'ancien mot de passe du fonds",
  pleaseEnterTheVerificationCode: 'veuillez entrer le code de vérification',
  feedback: 'Rétroaction',
  yourOpinionIsVeryImportantToUs: 'Votre avis est très important pour nous !',
  leaveYourValuableOpinionToHelpUsImprove: 'Laissez vos précieux commentaires pour nous aider à faire mieux',
  pleaseLeaveYourValuableOpinion: 'Merci de laisser vos précieux commentaires',
  submissionSuccessfulThankYouForYourFeedback: 'Soumis avec succès, merci pour vos commentaires',
  submissionFailed: 'Échec de la soumission',
  setFundPassword: 'Définir le mot de passe de Trading',
  setYourPaymentMethod: "Veuillez configurer votre méthode de paiement, assurez-vous d'utiliser votre compte au nom réel",
  newPassword: 'nouveau mot de passe',
  repeatPassword: 'Répéter le mot de passe',
  notLoggedIn: 'pas connecté',
  welcome: 'Bienvenue sur Btrok',
  assetManagement: "La gestion d'actifs",
  assetFlow: 'Historique des transactions du compte principal',
  myInvitation: 'mon invitation',
  invitationLink: "Mon lien d'invitation",
  firstLevelFriends: 'ami au premier degré',
  secondLevelFriends: 'Deuxième ami',
  partnerLevel: 'niveau personnel',
  myEntrustment: 'ma commission',
  coinTrading: 'Négociation de devises',
  securitySettings: 'Les paramètres de sécurité',
  pleaseLogInFirst: "s'il vous plait Connectez-vous d'abord",
  announcement: 'Annonce',
  changeLoginPassword: 'Changer le mot de passe',
  oldLoginPassword: 'Ancien mot de passe',
  newLoginPassword: 'Nouveau mot de passe',
  pleaseEnterOldLoginPassword: 'Veuillez saisir votre ancien mot de passe',
  pleaseEnterNewLoginPassword: 'Veuillez saisir le nouveau mot de passe',
  confirmLoginPassword: 'Confirmer la connexion',
  emailVerificationCode: 'Code de vérification de courrier électronique',
  emailBound: 'Vous avez un e-mail lié',
  verificationCode: 'le code de vérification',
  sendVerificationCode: 'Envoyer le code de vérification',
  pleaseConfirmInformation: 'Veuillez confirmer si toutes les informations sont remplies',
  pleaseConfirmPassword: 'Veuillez confirmer si les mots de passe saisis deux fois sont les mêmes',
  makeSureTheOldAndNewPasswordsAreDifferent: "Veuillez vous assurer que l'ancien et le nouveau mots de passe sont différents",
  pleaseConfirmNewLoginPassword: 'Please enter your new login password again',
  teamPerformance: "rendement de l'équipe",
  currentLevel: 'niveau actuel',
  nextLevel: 'niveau suivant',
  teamNumber: "Taille de l'équipe",
  totalPerformance: 'performances totales',
  onlyForUSDT: 'Ne prend en charge que la recharge USDT à cette adresse',
  walletAddress: 'adresse de portefeuille',
  securityReminder: 'To protect your account security, please complete real-name authentication before trading!',
  personalAvatar: 'Avatars personnels',
  isVerified: 'Vérifier',
  realNameAuthentication: "Vérification d'identité",
  notVerified: 'Vérifier maintenant',
  receivingAccount: 'Compte du bénéficiaire',
  loginPassword: 'Mots de Passe',
  fundsPassword: 'Mots de passe de Trading',
  settingsCenter: 'Centre de paramètres',
  aboutUs: "Profil de l'entreprise",
  helptext: 'Aider',
  help: 'Aider',
  logout: 'Se déconnecter',
  confirmLogout: 'Êtes-vous sûr de vouloir vous déconnecter?',
  bindWeChatAccount: 'Lier le compte WeChat',
  withdrawalAddress: 'Adresse de retrait',
  available: 'Solde disponible',
  minimumWithdrawalAmount: 'Montant minimum de retrait',
  amountReceived: 'Montant reçu',
  transactionTime: 'Date',
  payableHandlingFee: 'Les frais de transaction',
  deductibleHandlingFee: 'Frais de déduction',
  actualHandlingFee: 'Frais réels',
  feeGenerationTime: "L'heure de début et de fin est l'heure à laquelle les frais sont générés",
  takeIDPhoto: "Prenez et téléchargez une photo de votre carte d'identité",
  identityAuthentication: 'Personal Verification',
  uploadIDCardFront: "Téléchargez une photo du recto de la carte d'identité",
  uploadIDCardBack: "Télécharger une photo du verso de la carte d'identité",
  photoAlbum: 'album photo',
  IDCardNumber: "Numéro d'identité",
  enterIDCardNumber: "Veuillez entrer votre numéro de carte d'identité",
  name: 'Prénom/Nom de famille',
  idea: 'Vision Btrok',
  goal: 'Btrok Target',
  depositRecord: 'Histoire des dépôts',
  withdrawal2: 'Retrait',
  accountOpeningBranch: "Branche d'ouverture de compte",
  quantityInput: 'Veuillez entrer la quantité',
  singleLimit: 'limite unique',
  investmentAmount: 'Montant de la Staking',
  incomePrincipal: 'revenu/principal',
  password: 'mot de passe',
  historicalCommission: 'commission historique',
  type: 'Type',
  commissionAmount: 'montant confié',
  filter: 'Filtrer',
  pleaseSelect: "Choisissez s'il vous plaît",
  tradingPair: 'paire commerciale',
  reset: 'réinitialiser',
  startEndTime: 'Heure de début et de fin',
  recharge2: 'Dépôt',
  withdraw: 'retirer',
  transfer: 'transfert',
  fiatCurrencyPurchase: 'Fiat Acheter',
  fiatCurrencySale: 'Fiat vend',
  activityReward: "Prix ​​d'activité",
  promotionReward: 'récompense promotionnelle',
  dividend: 'dividendes',
  vote: 'vote',
  manualRecharge: 'Bénéfice de retour des activités',
  pairing: 'paire',
  activityExchange: "Rachat d'activité",
  ctcPurchase: 'CTC acheter',
  ctcSale: 'CTC vendre',
  redEnvelopeSending: 'enveloppe rouge émise',
  redEnvelopeReceiving: "Collection d'enveloppes rouges",
  withdrawalCodeWithdrawal: "Code de retrait d'espèces",
  withdrawalCodeRecharge: "Recharge du code de retrait d'espèces",
  perpetualContractFee: 'Frais de contrat',
  perpetualContractProfit: 'bénéfice du contrat',
  perpetualContractLoss: 'Perte de contrat',
  optionContractFailure: "Contrat d'option échoué",
  optionContractFee: "Frais de contrat d'option",
  optionContractBonus: "Bonus du contrat d'option",
  contractRebate: 'Remboursement contrat',
  levelReward: 'récompense de niveau',
  platformFeeIncome: 'Revenu des frais de plateforme',
  income: 'Revenu',
  promotionIncome: 'Revenus de la promotion',
  optionsPromotionIncome: "Revenu de promotion d'options",
  optionTeamIncome: 'Option revenu équipe',
  optionParityAward: "Récompense de niveau d'option",
  investmentTeamReturn: "Revenu de l'équipe d'investissement",
  investmentParityReturn: "Revenus d'investissement au même niveau",
  investmentPromotionIncome: 'Revenu de promotion des investissements',
  forexEntrustTrading: 'Opération de change confiée',
  forexCancelEntrustTrading: "Opération de commission d'annulation de change",
  liquidationCompensation: 'Compensation position fermée',
  lockedMiningIncome: "Revenu de l'équipe",
  speedContractProfit: 'Bénéfice du contrat au comptant',
  speedContractLoss: 'Pertes de contrats ponctuelles',
  NEWACTIVITY_IN: 'Revenu de mise',
  NEWACTIVITY_DEDUCT: 'Rachat de promesses de don',
  NEWACTIVITY_FREEZE: 'Coût de jalonnement',
  NEWACTIVITY_UNFREEZE: 'Option Dégeler',
  NEWACTIVITY_REDEEM_UNFREEZE: 'Dégel du rachat d’options',
  speedContractCommission: 'Frais de traitement du deuxième contrat',
  CONTRACT_FAST_FREEZE: 'Contrat spot gelé',
  CONTRACT_FAST_UNFREEZE: 'Dégeler le contrat spot',
  confirmUploadAllImages: 'Veuillez confirmer si toutes les images ont été téléchargées',
  inputRealName: 'Veuillez saisir votre prénom/nom de famille',
  idCardPhoto: "Photo d'identité",
  congratulations: 'Félicitations, vous avez réussi et vous pouvez trader en toute confiance',
  quantity2: 'Volume 24h(USDT)',
  wechatAccount2: 'Veuillez saisir votre compte WeChat',
  realNameIdentityVerified: "L'identité du vrai nom a été vérifiée",
  withdrawalTime: 'Date',
  withdrawalAmount: 'Montant',
  creditting: 'En cours de révision',
  waitingmoney: 'en cours',
  dztime: 'Date',
  rushaddress: 'Adresse de dépôt',
  rushnumber: 'Montant',
  regisertitle: 'inscription par e-mail',
  inputemailcode: "Veuillez saisir le code de vérification de l'e-mail",
  inputloginpassword: 'Veuillez entrer un mot de passe (plus de 6 chiffres, une combinaison de lettres majuscules et minuscules)',
  inputreloginpassword: 'Veuillez saisir à nouveau le mot de passe',
  inputinvite: "Veuillez entrer le code d'invitation",
  iagree: "J'ai lu et accepté",
  useragreement: "Accord de l'utilisateur",
  nowlogin: 'connectez-vous immédiatement',
  sendcode: 'Envoyer le code de vérification',
  rightemailformat: "Veuillez saisir le format d'e-mail correct",
  confirmPassword: 'Confirmez le nouveau mot de passe',
  passwordtip: 'Le mot de passe doit contenir au moins 6 chiffres',
  nosamepassword: 'Le nouveau mot de passe de connexion est incohérent, veuillez reconfirmer',
  noagree: "Décoché pour accepter les conditions d'utilisation",
  emailverification: "Vérification de l'E-mail",
  amount: 'quantité',
  overturn: 'transfert',
  overturntitle: "transfert d'actifs",
  from: 'depuis',
  to: 'arriver',
  swapcurrency: 'Devise du contrat',
  overturnplaceholder: 'Veuillez entrer le montant du transfert',
  selectaccount: 'sélectionner un compte',
  balance: 'Solde disponible actuel',
  contractaccount: 'compte de contrat',
  invitecode: "Code d'invitation",
  notset: 'Aller aux paramètres',
  day: 'jours',
  contactservice: 'Contactez le service à la clientèle',
  appname: 'Btrok APP',
  download: "Télécharger l'APP",
  orderByHand: 'commande manuelle',
  orderByAuto: 'Commande intelligente',
  trevenue: 'Revenu total',
  pincome: 'Revenus promotionnels',
  tincome: "avantages de l'équipe",
  dropList: 'la liste déroulante',
  uploaded: 'téléchargé',
  youare: 'tu es',
  disabling: 'fermeture',
  enabling: 'allumer',
  theAutomaticReinvestmentFeatur: 'Fonction de réinvestissement automatique',
  nth: 'Non.',
  phase: 'Attendre',
  currentForecastPeriod: 'période de prévision actuelle',
  maximum: 'Le montant maximal',
  openingPrice: "prix d'ouverture",
  currentPrice: 'prix actuel',
  profitSharing: 'partage des profits',
  CurrentTradingIsRestricted: 'Transactions actuellement restreintes',
  ToEnsureTheSsecurityOfYourAssetsPleaseCarefullyVerifyTheReceivingWalletAddressBeforeMakingAnyDeposits:
    "Afin d'assurer la sécurité de vos actifs, veuillez vérifier attentivement l'adresse du portefeuille de réception pour la recharge :",
  PleaseProceedWithTheDepositOnlyAfterVerifyingThatEverythingIsCorrect: ". L'appoint ne peut se faire qu'après vérification.",
  PleaseDoNotDepositAnyAssetsOtherThanTheSpecifiedCryptocurrencyToTheAddressMentionedAboveOtherwiseYourAssetsWillNotBeRecoverable:
    "Ne rechargez aucun actif non monétaire à l'adresse ci-dessus, sinon les actifs ne seront pas récupérés.",
  AfterYouMakeADepositToTheMentionedAddressItRequiresConfirmationFromTheEntireBlockchainNetworkTypicallyItTakesAround30SecondsForTheFundsToBeCreditedToYourAccountAfterThreeConfirmationOnTheBlockchainMainnet:
    "Après avoir rechargé à l'adresse ci-dessus, vous devez confirmer l'ensemble du nœud du réseau blockchain.Généralement, le réseau principal blockchain prendra environ 30 secondes pour arriver après 3 confirmations du réseau.",
  PleaseEnsureTheSecurityOfYourElectronicDevicesToPreventAnyTamperingOrLeakageOfInformation:
    'Assurez-vous de confirmer la sécurité des appareils électroniques pour éviter que des informations ne soient falsifiées ou divulguées.',
  WithdrawalsAreCurrentlyDisabled: 'Les retraits sont actuellement interdits',
  CurrentWithdrawalAddress: "Veuillez confirmer l'adresse de retrait actuelle",
  isYourWalletAddress: 'Est-ce votre adresse de portefeuille',
  LinkCopiedSuccessfully: 'copier avec succès',
  LinkCopiedFailed: 'la réplication a échoué',
  goLogin: 'Se connecter',
  other: 'autre',
  SavePicture: "enregistrer l'image",
  SaveLink: 'sauvegarder le lien',
  languageSettings: 'paramètres de langue',
  simplifiedChinese: '简体中文',
  traditionalChinese: '繁体中文',
  arabic: 'عربي',
  english: 'English',
  japanese: '日本語',
  French: 'Français',
  Spanish: 'Español',
  German: 'Deutsch',
  Italiano: 'Italiano',
  TotalDailyLoss: 'bénéfice total',
  TotalExpenditureToday: "Montant total de la commande d'aujourd'hui",
  TotalIncomeToday: "Bénéfice total aujourd'hui",
  CurrentDelivery: 'Ordres en cours',
  HistoricalDelivery: 'Historique des ordres',
  InitialAmount: 'montant de la commande',
  ForecastDirection: 'Direction des prévisions',
  BonusAmount: 'montant de la prime',
  TransactionFee: 'Les frais de transaction',
  OrderDuration: 'durée de la commande',
  Status: 'État',
  SelectCurrency: 'Sélectionnez la devise',
  EnterAmount: 'Entrez le montant',
  BUY: 'ACHETER(LONG)',
  SELL: 'VENDRE(SHORT)',
  WIN: 'succès',
  LOSE: 'échouer',
  Amount: 'Montant',
  expectedReturn: 'Retour attendu',
  service: 'Centre de service clientèle',
  untrade: 'actuellement indisponible',
  netError: 'Le réseau est en panne !',
  questError: 'Il y a eu une erreur dans la demande, veuillez réessayer plus tard ! ',
  failVerified: "L'authentification a échoué",
  tiprealverified: 'Vérification du nom réel, veuillez contacter le service client',
  uploadImg: 'télécharger les identifiants',
  collected: 'collecté',
  canceld: 'Annulé',
  placeholderAddress: "Saisir/coller l'adresse ou le nom ENS",
  bindAccountTitle: 'Adresse de la blockchain',
  marketTitle: 'Aperçu du marché',
  regiserSuccess: "l'enregistrement a réussi",
  setSuccess: 'réglé avec succès',
  updateAddress: "Je souhaite changer l'adresse de retrait",
  doingRealName: "L'authentification par nom réel est en cours, veuillez patienter",
  updateSuccess: 'Modification réussie',
  uploadSuccess: 'Téléchargé avec succès',
  forgetLpass:
    'Si vous oubliez votre mot de passe, veuillez contacter le service client pour vérifier les informations et les traiter pour réinitialiser votre mot de passe de connexion.',
  forgetApass:
    'Si vous oubliez votre mot de passe, veuillez contacter le service client pour vérifier les informations et réinitialiser votre mot de passe de transaction.',
  updateAddressTip:
    "Pour modifier l'adresse de paiement, veuillez contacter le service client pour vérifier les informations personnelles et réinitialiser l'adresse de paiement.",
  a48h: 'Le retrait a été accepté, la demande de retrait sera traitée dans les 48 heures, merci de patienter',
  r48h: 'La recharge a été acceptée, la commande de recharge sera traitée sous 48h, merci de patienter',
  accepted: 'En attente du déblocage des fonds',
  notremind: 'Ne rappelle plus',
  serviceCode: {
    0: 'succès',
    500: 'échec',
    501: "échec de l'inscription",
    502: "échec de l'envoi",
    503: "échec de l'ajout",
    504: 'requête échouée',
    600: "cette devise n'est pas supportée par le timing",
    1001: 'captcha inexiste',
    1003: 'le nombre de pièces retirées est supérieur au maximum',
    1004: 'le nombre de pièces retirées est inférieur au minimum',
    1005: 'solde disponible insuffisant',
    1006: 'le portefeuille est verrouillé',
    1007: "L'authentification actuelle par nom réel est en cours de révision, veuillez attendre patiemment, puis opérer une fois la révision réussie.",
    1008: "Veuillez d'abord définir un mot de passe de transaction",
    1009: 'mot de passe incorrect pour les fonds',
    1010: 'impossible de verrouiller les actifs',
    1011: 'cette boîte aux lettres a déjà été liée',
    1012: "ce nom d'utilisateur existe déjà",
    1013: "pas d'utilisateur pour cet utilisateur",
    1014: "l'e- mail d'activation de l'inscription a été envoyé à votre adresse e- mail.Ne répétez pas la demande",
    1015: "veuillez d'abord lier la boîte aux lettres",
    1016: 'le montant de la commande ne peut pas être 0',
    1017: ' votre compte actuel est désactivé, veuillez contacter le service client! ',
    1018: 'activité inexistante',
    1019: 'achat inférieur au minimum',
    1020: "le montant de l'achat est supérieur au maximum",
    1021: ' devise non prise en charge! ',
    1022: 'paire de trading déjà collectée',
    1023: 'ne pas répéter le réglage',
    1024: "la longueur du mot de passe des fonds n'est pas légale",
    1025: "le contrat n'existe pas",
    1026: 'mauvaise direction prévue',
    1027: "le portefeuille utilisateur n'existe pas",
    1028: 'compte ou mot de passe incorrect',
    1029: 'le compte est inactif / désactivé, veuillez contacter le service clientèle',
    1030: "Cher utilisateur, le staking n'est disponible que pour les comptes avec une vérification d'identité. Veuillez la compléter d'abord.",
    1031: 'Veuillez définir un mot de passe de transaction',
    1032: "L'authentification par nom réel a été effectuée, pas de ré-authentification",
    1033: "Un même numéro d'identification ne peut être authentifié qu'une seule fois",
    1035: "L'ancien mot de passe est erroné",
    1036: "Veuillez télécharger les informations d'identification de la recharge",
    1037: 'Veuillez saisir le montant correct de la recharge',
    1038: "Le compte n'existe pas",
    1039: 'Le mot de passe est incorrect',
    1040: "L'ancien mot de passe de transaction est incorrect",
    1041: 'Le mot de passe de transaction est erroné, veuillez le ressaisir',
    1042: "erreur de code d'invitation",
    1043: 'Adresse de retrait anormale',
    1065: 'La commande en cours est en cours de révision, veuillez ne pas la soumettre à nouveau.',
    3000: 'délai de débarquement',
    888: 'Tous les nouveaux utilisateurs et les utilisateurs existants doivent effectuer la vérification d’identité pour accéder à la gamme complète de produits et services',
  },

  //new
  rate: 'RSI',
  mode: 'Mode transactionnel',
  num: "Quantité d'ouverture",
  openTime: "Temps d'ouverture",
  phoneNi: 'Veuillez saisir le code de vérification du téléphone portable',
  phonePlaceholder: 'Veuillez entrer votre numéro de téléphone',
  inputphonecode: 'Veuillez saisir le code de vérification du téléphone portable',
  phone: 'Numéro de téléphone',
  phoneVcode: 'Code de vérification du téléphone portable',
  sendSucc: 'Bien envoyé',
  cScore: 'Pointage de crédit',
  wallet: 'Portefeuille',
  searchMp: "Rechercher le nom d'une crypto",
  clickUploadId: "Cliquez pour télécharger votre photo d'identité",
  modifyFp: 'Paramètres',
  confirmFPassword: 'Confirmez le mot de passe de Trading',
  pleaseSetYourNewFundPassword: 'Veuillez saisir votre mot de passe de trading',
  nosamefpassword: "Les deux mots de passe de Trading sont différents. Vérifiez s'il vous plaît",
  forgotFPassword: 'Vous avez oublié votre mot de passe de trading ?',
  korean: '한국어',
  turkish: 'Türkçe',
};
