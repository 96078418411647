import request from '/@/utils/useAxiosApi';
//获取充值配置信息 判断是否能充值 1 开启  0 关闭
export function payable() {
  return request.get('uc/ico/paybtnconfig');
}
// 充值接口;
// export function pay() {
//   return request.get('uc/ico/config');
// }
// export function pay(paramas) {
//   return request.post('uc/transfer/rechange', paramas);
// }
// 充值接口;
export function pay(params: any) {
  return request.get('foreign/memberadd/rechange', { params });
}

// 上传接口;
export function payUpload(params: any) {
  return request.post('uc/upload/oss/base64', params);
}

//充值地址
export function payConfig() {
  return request.get('uc/ico/config');
}

//充值地址
export function rechargeSubmit(params: any) {
  return request.post('uc/recharge/submit', params);
}
