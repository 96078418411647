import { langType } from './lang-base';

export const lang: langType = {
  receivingAddress: 'Auszahlungsadresse',
  second: 'Sek',
  minimum: 'Minimum',
  most: 'am meisten',
  minimumSell: 'Fangen Sie an zu verkaufen',
  ceiling: 'Decke',
  availableBalance: 'Derzeit verfügbarer Kontostand',
  Nomoreatmoment: 'Momentan nicht mehr',
  updateSet: 'Aktualisieren',
  pleaseEnterTheAmount: 'Bitte geben Sie den Betrag ein',
  emailNi: 'Geben Sie E -Mail -Code ein',
  existingSES: 'Sie haben bereits ein Konto',
  email: 'Email',
  myinvitation: 'Freunde einladen',
  teamValue: 'Teambeitrag',
  shareValue: 'Gemeinsamer Beitrag',
  allValue: 'Totaler Beitrag',
  myBenefits: 'Meine Vorteile',
  contribution: 'Beitrag',
  contributionDetail: 'Beitragsdetail',
  Dailyinterestrate: 'Täglicher Zinssatz',
  LendingDays: 'Darlehensdauer',
  Financialproductdetails: 'Verpfänden Sie die Details des Finanzprodukts',
  Subscriptionwallet: 'Abonnement Brieftasche',
  Subscriptionamount: 'Abonnementbetrag',
  SubscriptionDetails: 'Abonnement Methode',
  RevenueDetails: 'Einzelheiten zu den Einnahmen',
  Expirationtime: 'Ablaufzeit',
  Investmenttype: 'Pfandtyp',
  Contractnumber: 'Vertrag Nr',
  InvestmentDetails: 'Unterzeichnen Sie die Pfand Vereinbarung',
  Thesystemwillautomatically:
    'Das System gleicht neue Pfandbestellungen automatisch mit abgelaufenen Pfandbestellungen ab. Nachträgliche Bestellungen profitieren weiterhin von den Vorteilen der Plattform. Sobald Bestellungen, die nicht erneut eingereicht wurden, das Ablaufdatum erreichen, werden die präsentierten Vorteile vernichtet.',
  Reswitchingfunction: 'Reinvestitionsfunktion',
  description: 'Beschreibung',
  Investmentquantity: 'Maximale Staking Einsatzmenge',
  Investmentdetermination: 'Investitionsbestätigung',
  InvestmentOrders: 'Absteckdatensätze',
  Estimatedtotalrevenue: 'Geschätzter Gesamtnutzen',
  Redemptionmethod: 'Einlösungsmethode',
  Dailyrevenue: 'tägliches Einkommen',
  LockedDays: 'Verpfändungszyklus',
  Currenttotalinvestmentequivalent: 'Aktueller Gesamteinsatz Betrag (Äquivalent)',
  complete: 'Bestätigen',
  Fundinglimit: 'Verpfändung Kapitallimit',
  Loanfinancing: 'Reinvestitionsfunktion',
  Subscriptiontime: 'Startzeit',
  Myinvestmenthistory: 'Mein staking Historie',
  Investmentdays: 'Investitionstage',
  Selfselectionarea: 'Selbstauswahlbereich',
  Quickconstructionarea: 'Schneller Baubereich',
  Smartcontractmatching: 'Smart Contract Matching',
  sourceTime: 'Zeit',
  viewCode: 'Code anzeigen',
  goWealh: 'Der Weg zum Wohlstand',
  fileGo: 'Gehen Sie zur Datei',
  videoRequest: 'Videoanfrage',
  gorequest: 'Anfrage ziehen',
  proceeding: 'Vorgehen',
  pwds: 'Passwort',
  experienceAll: 'Volles Star -Erlebnis',
  experienceFive: 'Fünf -Sterne -Erfahrung',
  sharing: 'Reichtumsteilung',
  customer: 'Kunde',
  todoList: 'Aufgabenliste',
  systemMessages: 'Systemmeldungen',
  youHave: 'Du hast',
  totleWait: 'Artikel zu tun, um bitte rechtzeitig umzugehen',
  totleBook: 'ungelesene Systemnachrichten',
  message: 'Nachricht',
  officialNowGo: 'Jetzt beginnend',
  officialFinace: 'Die Blockchain -Finanzierung zu genießen war noch nie so einfach',
  officialTechnologyLeft: 'Innovativ, auf dem neuesten Stand',
  officialTechnologyRight: 'Technologie',
  superParagraphOne:
    'Die BTROK International Digital Crypto Asset Trading Platform ist eine umfassende Handelsplattform für digitale Asset-Handel. Wir verfügen über eine führende technische Stärke, einen qualitativ hochwertigen Kundenservice und eine gute Benutzererfahrung und bieten eine kostenlose Online-Austauschplattform für die Mehrheit der Enthusiasten für digitale Währungen.',
  superParagraphTwo:
    'BTROK International Digital Crypto Asset Trading Platform bietet auch Blockchain -Dienste für die Planung digitaler Währung, die Anpassung der Verschlüsselungsalgorithmus, die Forschung und Entwicklung digitaler Währung sowie die Auflistung der Handelsplattform.',
  superParagraphThree:
    'BTROK International Digital Crypto Asset Trading Platform Operation Centers sind in Singapur, Kambodscha und Marshall eingerichtet.',
  superSESMessage: 'BTROK -Nachricht',
  superSES: 'Btrok',
  superFomamce: 'Vertragsfinanzierung, Finanzierung genießen',
  superSecure: 'Hochwertige Blockchain-Investitionen mit hoher Kennzeichnung',
  TodayEarnings: 'Der heutige Verdienst',
  ImmediateInvestment: 'Jetzt einzahlen',
  financialManagement: 'Staking',
  Traverser: 'Traverser',
  announceList: 'Ankündigung',
  announceListDetail: 'Ankündigungsdetail',
  official: 'Offiziell',
  supervise: 'Aufsichtsknoten',
  Source: 'Open Source',
  report: 'Bericht',
  more: 'Mehr',
  deposit: 'Kryptos einzahlen',
  invite: 'Freunde einladen',
  lockMining: 'Sperrenabbau',
  riseRanking: 'aufsteigender Rangliste',
  dropList: 'Dropliste',
  home: 'Titelseite',
  welcomes: 'Willkommen',
  gains: '24h-Veränderung',
  recentPrice: 'Letzter Preis',
  messageList: 'Nachrichtenliste',
  noData: 'Keine Aufzeichnungen',
  market: 'Märkte',
  optional: 'Favorites',
  buy: 'kaufen',
  sell: 'verkaufen',
  completed: 'fertig',
  cancel: 'Abbrechen',
  limitOrder: 'Bestellung begrenzen',
  marketOrder: 'Marktbestellung',
  call: 'Optimistisch',
  put: 'Bärisch',
  success: 'Erfolg',
  failure: 'Ausfall',
  transaction: 'Transaktion',
  contract: 'Option',
  forex: 'Forex',
  mine: 'Konto',
  notNull: 'kann nicht leer sein',
  optionContract: 'Optionsvertrag',
  currentDelivery: 'Offene Orders',
  historyDelivery: 'Historique des ordres',
  fee: 'Transaktionsgebühr',
  openAmount: 'Eröffnungshalterung',
  bonusQuantity: 'Bonusmenge',
  predictionDirection: 'Prognose Richtung',
  requestFailure: 'Anfrage fehlgeschlagen, klicken Sie auf das Nachladen',
  predictionResult: 'Prognoseergebnis',
  submitSuccess: 'Erfolgreich einreichen',
  sharePoster: 'Plakat teilen',
  copyLink: 'Link kopieren',
  saveImage: 'Bild speichern',
  pleaseLoginFirst: 'Bitte loggen Sie sich zuerst ein',
  pleaseUseTronWallet: 'Bitte öffnen Sie die Tron -Brieftasche, um den Aufladungslink zu öffnen',
  currentInterest: 'Aktuelle Interessen',
  fundsSecurity: 'Finanziert Sicherheit',
  guarantee: 'Garantie',
  holidayEarnings: 'Urlaubserträge sind ununterbrochen',
  warning:
    'Zahlen Sie keine Vermögenswerte ohne Währung in die oben genannte Adresse ein, andernfalls werden die Vermögenswerte nicht abgerufen.',
  confirmation:
    'Nachdem Sie die obige Adresse aufgeladen haben, müssen Sie den gesamten Blockchain -Netzwerkknoten bestätigen. Im Allgemeinen wird das Blockchain -Hauptnetzwerk nach 3 Netzwerkbestätigungen gutgeschrieben.',
  addressChange:
    'Ihre Aufladungsadresse ändert sich nicht häufig und Sie können sich wiederholt aufladen. Wenn es eine Änderung gibt, werden wir unser Bestes geben, um Sie über Website -Ankündigungen oder E -Mails zu benachrichtigen.',
  safetyReminder:
    'Bitte bestätigen Sie, dass die Betriebsumgebung sicher ist, um zu verhindern, dass Informationen manipuliert oder durchgesickert werden.',
  rechargeUSDT: 'Wiederaufladen usdt',
  details: 'Einzelheiten',
  dayLimit: 'Tage',
  mining: 'Bergbau verdient nonstop',
  upcoming: 'demnächst',
  inProgress: 'im Gange',
  distribution: 'Verbreitung',
  progress: 'Fortschritt',
  totalAmount: 'Gesamtaktivität',
  startTime: 'Startzeit',
  endTime: 'Endzeit',
  lockingMining:
    'Lock Mining ist es, das Bergbaueinkommen im Plattform -Mining -Pool zu verdienen, indem Sie den Super Computing Power Mining Machine der Plattform anvertrauen',
  productHighlights: 'Produkt Höhepunkte',
  withdrawal: 'Withdraw Crypto',
  dividendCycle: 'Dividendenzyklus',
  successfulDepositOnDay: 'Am Tag nach erfolgreicher Einzahlung',
  interestAccrual: 'Start',
  example: 'Gib mir ein Beispiel',
  profitCalculation: 'Gewinnberechnung',
  simplestProfitFormula: 'Die einfachste Formel für Gewinnberechnung ist',
  profitRateCalculationExample: 'Zum Beispiel ist das Einkommen 2W, der Kapital 10 W, dann der Renditesatz',
  calculationOfPrincipal:
    'Wenn Sie sich für ein Wertpapierkonto nicht an den investierten Kapital erinnern, können Sie es auf der Grundlage des aktuellen Gesamtmarktwerts, des aktuellen Gewinns und des aktuellen Verlusts berechnen',
  currentMarketValueExample:
    'Zum Beispiel beträgt der aktuelle Gesamtmarktwert 11 W und der aktuelle schwimmende Gewinn 2W, der Auftraggeber 11W-2W = 9W und die Ertragsrate = (2W/9W).',
  penalty: 'Schadensersatz',
  participate: 'Ich will mitmachen',
  entrustedOrder: 'Anvertraut',
  pledgingInProgress: 'Versprechen',
  pledgingComplete: 'Versprechen abgeschlossen',
  earlyRedemption: 'Frühe Erlösung',
  loading: 'Wird geladen',
  pledgeAmount: 'Versprechen',
  dailyEarnings: 'Tägliche Einnahmen',
  earlyRedemptionDeductionHandlingFee: 'Frühere Rückzahlungsabzugsgebühr',
  earlyRedemptionAgain: 'Frühe Erlösung',
  endOfContent: 'Es ist aus',
  financialProduct: 'Finanzprodukt',
  fundsUnderManagement: 'Fonds, die verwaltet werden',
  expectedTodayEarnings: 'Geschätzte Einnahmen heute',
  accumulatedEarnings: 'Angesammelter Profit',
  trustOrder: 'in Vertrauen in Ordnung',
  buy2: 'Gehen Sie zum Kaufen',
  dailyReturn: 'tägliches Einkommen',
  period: 'Zeitraum',
  switchLanguage: 'Sprachumschaltung',
  username: 'Nutzername',
  emailPlaceholder: 'Bitte geben Sie ihre E-Mail-Adresse ein',
  forgotPassword: 'Passwort vergessen?',
  passwordPlaceholder: 'Bitte geben Sie Ihr Passwort ein',
  submit: 'Einreichen',
  registerNow: 'Jetzt registrieren',
  search: 'Suchen',
  high: '24h-Hoch',
  low: '24h-Tief',
  depth: 'Tiefe',
  deal: 'Handeln',
  buyQuantity2: 'Volumen kaufen',
  price: 'Preis',
  quantity: 'Menge',
  sellQuantity: 'Volumen verkaufen',
  time: 'Zeit',
  direction: 'Richtung',
  inputPrice: 'Qing -Eingangspreis',
  tradingVolume: 'Handelsvolumen',
  entrust: 'Anvertrauen',
  entrustPrice: 'Anvertrauungspreis',
  entrustQuantity: 'Anvertraute Menge',
  totalEntrustment: 'Gesamtverantwortung',
  category: 'Kategorie',
  confirm: 'Bestätigen',
  inputCorrectQuantity: 'Bitte geben Sie die richtige Menge ein',
  operationSuccessful: 'Operation erfolgreich',
  revoke: 'Widerrufen',
  entrustmentCancellationConfirmation: 'Verantwortung Stornierungsbestätigung',
  areYouSureToCancel: 'Sind Sie sicher, die Bestellung zu stornieren?',
  all: 'Alle',
  currentEntrustment: 'Aktuelle Verantwortung',
  completed2: 'Vollendet',
  aboutSES: 'Über Btrok',
  freeExchange: 'Bieten Sie eine kostenlose Online -Austauschplattform für digitale Währungsbegeisterte an',
  modify: 'Revidieren',
  bind: 'Binden',
  alipayAccount: 'Alipay -Konto',
  wechatAccount: 'Wechat -Konto',
  unbound: 'Ungebunden',
  sesPlatformOverview3:
    'Btrok Digital Asset Exchange ist eine globale Handelsplattform für digitale Vermögenswerte, die von der MAI International Foundation in Singapur investiert und entwickelt wird. Der Hauptsitz befindet sich in Singapur und wird von der Singapore MAS reguliert. In der globalen Blockchain-Industrie wurden eine Reihe von Infrastrukturbauarbeiten durchgeführt. Als internationale Börse für digitale Vermögenswerte für globale Investoren kombiniert Btrok Digital Asset Exchange aktuelle Branchenvorteile mit einem hochrangigen Bank-Finanzmanagementteam, einem professionellen Big-Data-Entwicklungsteam, dem weltweit besten Blockchain-Entwicklungsteam und einem führenden Blockchain-Technologie-Serviceteam. Ziel ist es, Benutzern effiziente und sichere Handelsdienste für digitale Vermögenswerte bereitzustellen.',
  sesPlatformOverview4:
    'Als weltweit erste umfassende Handelsplattform für digitale Vermögenswerte ist sie eine der Top-Handelsplattformen im globalen Blockchain-Bereich. Es deckt das globale ökologische Layout digitaler Vermögenswerte ab, verfügt über internationale Lizenzen und ist von Regierungsbehörden anerkannt. Btrok wird sich in Bezug auf technologische Innovation, Produktiteration, Sicherheitsrisikokontrollsystem, Betrieb und Kundendienstsystem weiter verbessern. Während Btrok globalen Nutzern Mainstream-Spot- und Derivate-Handelsdienste für digitale Währungen wie Bitcoin und Ethereum zur Verfügung stellt, wird es mit der Zeit gehen und zum Aufbau eines vollständigen Blockchain-Ökosystems beitragen.',
  sesPlatformOverview5:
    'Richten Sie eine sichere, erfahrene und kostenlose Handelsplattform für digitale Vermögenswerte und eine Finanzinfrastruktur ein, um den Bedürfnissen der weltweiten Mehrheit der Enthusiasten digitaler Währungen gerecht zu werden, und bauen Sie im Zeitalter der digitalen Vernetzung eine wirklich ideale Community für den Austausch virtueller digitaler Vermögenswerte auf. Die Mehrheit der Anleger ist bestrebt, eine sichere, zuverlässige und stabile integrierte Service-Handelsplattform bereitzustellen.',
  bankCardNumber: 'Bankkartenkontontonummer',
  accountSettings: 'Account Einstellungen',
  modifyAlipayAccount: 'Ändern Sie das Alipay -Konto',
  receiveQRCode: 'Empfangen Sie zweidimensionales',
  fundPassword: 'Trading-Passwort',
  pleaseEnterCorrectFundPassword: 'Bitte geben Sie das Transaktionspasswort ein',
  pleaseEnterAlipayAccount: 'Bitte geben Sie die Alipay -Kontonummer ein',
  myAssets: 'Wallet-Übersicht',
  details2: 'Einzelheiten',
  withdrawalRecords: 'Abhebung Historie',
  totalAssetsApprox: 'Geschätztes Guthaben（USDT）',
  spotAccount: 'Krypto -Konto',
  searchAssets: 'Suchanlagen suchen',
  hideZeroBalances: 'Währung als 0 versteckt',
  frozen: 'Vorübergehend nicht erreichbar',
  toRelease: 'Warten auf Veröffentlichung',
  modifyBankCardNumber: 'Bankkartenkontonummer ändern',
  save: 'Speichern',
  bankName: 'Kontobank',
  bankCardNumberConfirm: 'Kartennummer bestätigen',
  pleaseEnterBankCardNumberAgain: 'Bitte geben Sie die Bankkartennummer erneut ein',
  fundPasswordPrompt: 'Fondspasswort',
  pleaseEnterFundPassword: 'Bitte geben Sie das Fonds Passwort ein',
  modifyFundPassword: 'Transaktion Passwort ändern',
  modifyingFundPassword: 'Sie ändern jetzt Ihr Transaktionspasswort',
  oldFundPassword: 'Altes Transaktionspasswort',
  newFundPassword: 'Neues Handeln Passwort',
  confirmFundPassword: 'Bestätigen Sie das Kennwort des Fonds',
  pleaseEnterSetPassword: 'Bitte geben Sie das Setup -Passwort ein',
  pleaseEnterConfirmFundPassword: 'Bitte geben Sie das Passwort für Bestätigungsfonds ein',
  passwordsDoNotMatch: 'Passwörter stimmen nicht überein',
  pleaseEnterYourOldFundPassword: 'Bitte geben Sie das alte Transaktionspasswort ein',
  pleaseEnterYourNewFundPassword: 'Bitte geben Sie ein neues Handels Passwort ein',
  pleaseEnterA6DigitPassword: 'Bitte geben Sie ein 6-stelliges Passwort ein',
  pleaseConfirmNewFundPassword: 'Bitte bestätigen Sie Ihr Handel Passwort erneut',
  theNewFundPasswordCannotBeTheSameAsTheOldFundPassword: 'Das neue Fonds Passwort kann nicht mit dem alten Fonds Passwort übereinstimmen',
  pleaseEnterTheVerificationCode: 'Bitte den Bestätigungscode eingeben',
  feedback: 'Rückmeldung',
  yourOpinionIsVeryImportantToUs: 'Ihre Meinung ist uns sehr wichtig!',
  leaveYourValuableOpinionToHelpUsImprove: 'Hinterlassen Sie Ihre wertvolle Meinung, damit wir es besser machen können',
  pleaseLeaveYourValuableOpinion: 'Bitte hinterlassen Sie Ihre wertvolle Meinung',
  submissionSuccessfulThankYouForYourFeedback: 'Erfolgreiche Einreichung, vielen Dank für Ihr Feedback',
  submissionFailed: 'Einreichung fehlgeschlagen',
  setFundPassword: 'Handelspasswort festlegen',
  setYourPaymentMethod: 'Bitte legen Sie Ihre Zahlungsmethode fest. Verwenden Sie Ihr reales Konto unbedingt',
  newPassword: 'Neues Kennwort',
  repeatPassword: 'Passwort wiederholen',
  notLoggedIn: 'nicht eingeloggt',
  welcome: 'Willkommen bei Btrok',
  assetManagement: 'Vermögensverwaltung',
  assetFlow: 'Hauptkonto-Transaktionsverlauf',
  myInvitation: 'Meine Einladung',
  invitationLink: 'Mein Einladungslink',
  firstLevelFriends: 'Freunde der ersten Ebene',
  secondLevelFriends: 'Freunde der zweiten Ebene',
  partnerLevel: 'Das persönliche Niveau',
  myEntrustment: 'Meine Beförderung',
  coinTrading: 'Münzhandel',
  securitySettings: 'Sicherheitseinstellungen',
  pleaseLogInFirst: 'Bitte loggen Sie sich zuerst ein',
  announcement: 'Ankündigung',
  changeLoginPassword: 'Login-Passwort zurücksetzen',
  oldLoginPassword: 'Altes Login-Passwort',
  newLoginPassword: 'Neues Login-Passwort',
  confirmPassword: 'Bestätige das neue Passwort',
  emailVerificationCode: 'E -Mail -Überprüfungscode',
  emailBound: 'Sie haben eine gebundene E -Mail',
  pleaseEnterOldLoginPassword: 'Bitte geben Sie Ihr altes Login-Passwort ein',
  pleaseEnterNewLoginPassword: 'Geben Sie das neue Login-Passwort ein',
  makeSureTheOldAndNewPasswordsAreDifferent: 'Stellen Sie sicher, dass die alten und die neuen Passwörter unterschiedlich sind',
  pleaseConfirmNewLoginPassword: 'Bitte geben Sie Ihr neues Login-Passwort erneut ein',
  verificationCode: 'Bestätigungscode',
  sendVerificationCode: 'Bestätigungscode senden',
  pleaseConfirmInformation: 'Bitte bestätigen Sie, ob alle Informationen gefüllt sind',
  pleaseConfirmPassword: 'Bitte bestätigen Sie, ob die zweimal eingegebenen Passwörter gleich sind',
  teamPerformance: 'Teamleistung',
  currentLevel: 'Aktuelles Level',
  nextLevel: 'nächste Ebene',
  teamNumber: 'Teamnummer',
  totalPerformance: 'Gesamtleistung',
  onlyForUSDT: 'Nur für USDT',
  walletAddress: 'Brieftaschenadresse',
  securityReminder: 'Um die Sicherheit Ihres Kontos zu schützen, führen Sie bitte vor dem Handel eine Echtnamen-Authentifizierung durch!',
  personalAvatar: 'Persönlicher Avatar',
  isVerified: 'Prüfen',
  realNameAuthentication: 'Identitätsprüfung',
  notVerified: 'Jetzt Prüfen',
  receivingAccount: 'Zahlungsempfänger Konto',
  loginPassword: 'Login-Passwort',
  fundsPassword: 'Trading-Passwort',
  settingsCenter: 'Einstellungszentrum',
  aboutUs: 'Unternehmensprofil',
  helptext: 'Helfen',
  help: 'Helfen',
  logout: 'Ausloggen',
  confirmLogout: 'Möchten Sie sich wirklich abmelden?',
  bindWeChatAccount: 'Binden Sie das WeChat -Konto',
  withdrawalAddress: 'Auszahlungsadresse',
  available: 'Derzeit verfügbarer Kontostand',
  minimumWithdrawalAmount: 'Mindestauszahlungsbetrag',
  amountReceived: 'Erhaltener Betrag',
  transactionTime: 'Zeit',
  payableHandlingFee: 'Transaktionsgebühr',
  deductibleHandlingFee: 'Gebührenabzug',
  actualHandlingFee: 'Tatsächliche Gebühr',
  feeGenerationTime: 'Die Start- und Endzeit ist die Gebührenerzeugungszeit',
  takeIDPhoto: 'Nehmen Sie Ihr ID -Foto auf und laden Sie sie hoch',
  identityAuthentication: 'Verifica personale',
  uploadIDCardFront: 'Hochladen ID -Karte Frontbild',
  uploadIDCardBack: 'ID -Karten -Rückbild hochladen',
  photoAlbum: 'Fotoalbum',
  IDCardNumber: 'ID-Nummer',
  enterIDCardNumber: 'Bitte geben Sie Ihre Ausweisnummer ein',
  name: 'Vorname/Nachname',
  idea: 'Btrok Vision',
  goal: 'Btrok-Ziel',
  depositRecord: 'Einzahlungsverlauf',
  withdrawal2: 'Auszahlung',
  accountOpeningBranch: 'Kontoeröffnungszweig',
  quantityInput: 'Bitte geben Sie die Menge ein',
  singleLimit: 'Einzelgrenze',
  investmentAmount: 'Staking Betrag',
  incomePrincipal: 'Einkommen/Kapital',
  password: 'Passwort',
  historicalCommission: 'Historische Kommission',
  type: 'Typ',
  commissionAmount: 'in Auftrag gegebener Betrag',
  filter: 'Filter',
  pleaseSelect: 'Bitte auswählen',
  tradingPair: 'Handelspaar',
  reset: 'Zurücksetzen',
  goLogin: 'Mach dich an',
  currency: 'Währung',
  startEndTime: 'Start- und Endzeit',
  recharge2: 'Einzahlung',
  withdraw: 'Zurückziehen',
  transfer: 'Überweisen',
  fiatCurrencyPurchase: 'Kauf von Fiat -Währung',
  fiatCurrencySale: 'Fiat -Währungsverkauf',
  activityReward: 'Aktivitätsbelohnung',
  promotionReward: 'Promotion -Belohnung',
  dividend: 'Dividende',
  vote: 'Abstimmung',
  manualRecharge: 'Return-Gewinn aus Aktivitäten',
  pairing: 'Paarung',
  activityExchange: 'Aktivitätsaustausch',
  ctcPurchase: 'CTC kaufen',
  ctcSale: 'CTC verkaufen',
  redEnvelopeSending: 'Red Hüllkurve senden',
  redEnvelopeReceiving: 'Roter Umschlag empfangen',
  withdrawalCodeWithdrawal: 'Auszahlung des Auszahlungskodex',
  withdrawalCodeRecharge: 'Bargeldabhebungskodex aufladen',
  perpetualContractFee: 'Perpetuelle Vertragsgebühr',
  perpetualContractProfit: 'Perpetual Vertragsgewinn',
  perpetualContractLoss: 'Ewiger Vertragsverlust',
  optionContractFailure: 'Optionsvertrag fehlgeschlagen',
  optionContractFee: 'Optionsvertragsgebühr',
  optionContractBonus: 'Optionsvertragsbonus',
  contractRebate: 'Vertragsrabatt',
  levelReward: 'Level Belohnung',
  platformFeeIncome: 'Plattformgebühreneinkommen',
  income: 'Einkommen',
  promotionIncome: 'Einkommensförderung',
  optionsPromotionIncome: 'Option Promotion Einkommen',
  optionTeamIncome: 'Option Teameinkommen',
  optionParityAward: 'Option Parity Award',
  investmentTeamReturn: 'Einkommen des Anlageteams',
  investmentParityReturn: 'Einkommen der Anlageparität',
  investmentPromotionIncome: 'Einkommen der Investitionsförderung',
  forexEntrustTrading: 'Devisenbörse betraut den Handel',
  forexCancelEntrustTrading: 'Devisen -Stornierungen betraut Transaktion',
  liquidationCompensation: 'Liquidationskompensation',
  lockedMiningIncome: 'Verschlossenes Bergbaueinkommen',
  speedContractProfit: 'Gewinn aus Spotverträgen',
  speedContractLoss: 'Spot-Vertrag Verluste',
  speedContractCommission: 'Speed Contract Commission',
  CONTRACT_FAST_FREEZE: 'Spot-Vertragssperre',
  CONTRACT_FAST_UNFREEZE: 'Spot-Vertrag freigeben',
  NEWACTIVITY_IN: 'Wetteinnahmen',
  NEWACTIVITY_DEDUCT: 'Pfandeinlösung',
  NEWACTIVITY_FREEZE: 'Absteckkosten',
  NEWACTIVITY_UNFREEZE: 'Option aufheben',
  NEWACTIVITY_REDEEM_UNFREEZE: 'Einlösung der Option wird aufgehoben',
  confirmUploadAllImages: 'Bitte bestätigen Sie, ob alle Bilder hochgeladen wurden',
  inputRealName: 'Bitte geben Sie Vorname/Nachname ein',
  idCardPhoto: 'Ausweisfoto',
  congratulations: 'Herzlichen Glückwunsch, Sie haben bestanden und können mit Zuversicht handeln.',
  quantity2: 'Volume 24h(USDT)',
  wechatAccount2: 'Bitte geben Sie das WeChat -Konto ein',
  realNameIdentityVerified: 'Richtige Name Identität verifiziert',
  withdrawalTime: 'Zeit',
  withdrawalAmount: 'Menge',
  creditting: 'Wird derzeit überprüft',
  waitingmoney: 'wird verarbeitet',
  dztime: 'Zeit',
  rushaddress: 'Einzahlungsadresse',
  rushnumber: 'Menge',
  regisertitle: 'E-Mail Registrierung',
  inputemailcode: 'Geben Sie den E -Mail -Überprüfungscode ein',
  inputloginpassword: 'Passwort (6+ Zeichen, Zahlen, obere/untere Buchstaben)',
  inputreloginpassword: 'Bitte geben Sie das Passwort erneut ein',
  inputinvite: 'Bitte geben Sie den Einladungscode ein',
  iagree: 'Ich habe gelesen und stimme zu',
  useragreement: 'Nutzungsbedingungen',
  nowlogin: 'Anmeldung',
  sendcode: 'Bestätigungscode senden',
  rightemailformat: 'Bitte geben Sie das richtige E -Mail -Format ein',
  passwordtip: 'Das Passwort muss mindestens 6 Ziffern enthalten',
  nosamepassword: 'Das neue Login-Passwort ist inkonsistent. Bitte bestätigen Sie es erneut',
  noagree: 'Benutzervereinbarung nicht akzeptiert',
  emailverification: 'E-Mail-Verifizierung',
  amount: 'Menge',
  overturn: 'Überweisen',
  overturntitle: 'Vermögensübertragung',
  from: 'Aus',
  to: 'Zu',
  swapcurrency: 'Perpetuelle Vertragswährung',
  overturnplaceholder: 'Bitte geben Sie den Überweisungsbetrag ein',
  selectaccount: 'Konto auswählen',
  balance: 'Aktuell verfügbarer Kontostand',
  contractaccount: 'Vertragskonto',
  invitecode: 'Einladungscode',
  notset: 'Gehe zu den Einstellungen',
  day: 'Tage',
  contactservice: 'Wenden Sie sich an den Kundendienst',
  appname: 'BTROK -App',
  download: 'Lade App herunter',
  orderByHand: 'Mannual Order',
  orderByAuto: 'Smart Order',
  trevenue: 'Gesamtumsatz',
  pincome: 'Werbevorteil',
  tincome: 'Teameinkommen',
  SearchCurrency: 'Suchvertragsnummer',
  uploaded: 'hochgeladen',
  confirmLoginPassword: 'bestätigen',
  PleaseEnter: 'Bitte geben Sie die Vertragsnummer ein',
  Ordinarysingle: 'Dauerauftragc',
  Unabletochangeorder: 'Den Bestellstatus kann nicht geändert werden',
  Yourbalanceisinsufficient: 'Ihr Gleichgewicht ist unzureichend',
  Resumptionhasbeenactivated: 'Die Reinvestition ist jetzt aktiv',
  orderCount: 'Bestellmenge verpfänden',
  Resumptionhasbeenclosed: 'Die Reinvestition ist jetzt deaktiviert',
  DailyDistributions: 'Tägliche Verteilung des Gewinns, Rückgabe des Auftraggebers nach der Sperrdauer',
  youare: 'Du bist',
  disabling: 'Deaktivierung',
  enabling: 'Aktivieren',
  theAutomaticReinvestmentFeatur: 'Die automatische Reinvestitionsfunktion',
  nth: 'NEIN.',
  phase: '',
  currentForecastPeriod: 'Aktueller Prognosezeitraum',
  maximum: 'Max',
  openingPrice: 'Eröffnungskurs',
  currentPrice: 'Derzeitiger Preis',
  profitSharing: 'Gewinnbeteiligung',
  CurrentTradingIsRestricted: 'Der aktuelle Handel ist eingeschränkt',
  ToEnsureTheSsecurityOfYourAssetsPleaseCarefullyVerifyTheReceivingWalletAddressBeforeMakingAnyDeposits:
    'Um die Sicherheit Ihres Vermögens zu gewährleisten, überprüfen Sie bitte die Empfangs -Brieftaschenadresse sorgfältig, bevor Sie Einlagen vornehmen:',
  PleaseProceedWithTheDepositOnlyAfterVerifyingThatEverythingIsCorrect:
    '. Bitte fahren Sie mit der Einzahlung erst fort, nachdem Sie überprüft haben, ob alles korrekt ist.',
  PleaseDoNotDepositAnyAssetsOtherThanTheSpecifiedCryptocurrencyToTheAddressMentionedAboveOtherwiseYourAssetsWillNotBeRecoverable:
    'Bitte geben Sie andere Vermögenswerte als die angegebene Kryptowährung nicht in die oben genannte Adresse ein. Andernfalls können Ihr Vermögen nicht wiederhergestellt werden.',
  AfterYouMakeADepositToTheMentionedAddressItRequiresConfirmationFromTheEntireBlockchainNetworkTypicallyItTakesAround30SecondsForTheFundsToBeCreditedToYourAccountAfterThreeConfirmationOnTheBlockchainMainnet:
    'Nachdem Sie eine Einzahlung an die genannte Adresse vorgenommen haben, müssen Sie aus dem gesamten Blockchain -Netzwerk bestätigt werden. In der Regel dauert es ungefähr 30 Sekunden, bis die Mittel Ihrem Konto nach drei Bestätigungen auf dem Blockchain -Hauptnetz gutgeschrieben werden.',
  PleaseEnsureTheSecurityOfYourElectronicDevicesToPreventAnyTamperingOrLeakageOfInformation:
    'Bitte stellen Sie die Sicherheit Ihrer elektronischen Geräte sicher, um Manipulationen oder Leckagen von Informationen zu verhindern.',
  WithdrawalsAreCurrentlyDisabled: 'Abhebungen sind derzeit deaktiviert',
  CurrentWithdrawalAddress: 'Bitte bestätigen Sie, ob die aktuelle Abhebungsadresse',
  isYourWalletAddress: 'Ist Ihre Brieftascheadresse',
  LinkCopiedSuccessfully: 'Link erfolgreich kopiert',
  LinkCopiedFailed: 'Linkkopie fehlgeschlagen',
  other: 'Andere',
  SavePicture: 'Bild speichern',
  SaveLink: 'Link speichern',
  languageSettings: 'Sprache',
  simplifiedChinese: '简体 中文',
  traditionalChinese: '繁体 中文',
  arabic: 'عربي',
  english: 'English',
  japanese: '日本語',
  French: 'Français',
  Spanish: 'Español',
  German: 'Deutsch',
  Italiano: 'Italiano',
  TotalDailyLoss: 'Gesamtgewinn',
  TotalExpenditureToday: 'Gesamtauftragsbetrag heute',
  TotalIncomeToday: 'Gesamtgewinn heute',
  CurrentDelivery: 'Aktueller Auftrag',
  HistoricalDelivery: 'Bestellverlauf',
  InitialAmount: 'Bestellbetrag',
  ForecastDirection: 'Prognose Richtung',
  BonusAmount: 'Bonusmenge',
  TransactionFee: 'Transaktionsgebühr',
  OrderDuration: 'Bestelldauer',
  Status: 'Status',
  SelectCurrency: 'Währung wählen',
  EnterAmount: 'Menge eingeben',
  BUY: 'Kaufen (LANG)',
  SELL: 'Verkaufen (Short)',
  WIN: 'Erfolg',
  LOSE: 'Versagen',
  Amount: 'Menge',
  expectedReturn: 'erwartete Rückkehr',
  service: 'Kundendienstzentrum',
  untrade: 'Derzeit unerklärlich',
  netError: 'Das Netzwerk ist aus dem Geschäft!',
  questError: 'Die Anfrage gab einen Fehler, bitte versuchen Sie es später erneut!',
  failVerified: 'Authentifizierung fehlgeschlagen',
  tiprealverified: 'Richtige Namensprüfung, bitte wenden Sie sich an den Kundendienst',
  uploadImg: 'Anmeldeinformationen hochladen',
  collected: 'gesammelt',
  canceld: 'Abgesagt',
  placeholderAddress: 'Geben Sie die Adresse oder den ENS-Namen ein bzw. fügen Sie sie ein',
  bindAccountTitle: 'Blockchain-Adresse',
  marketTitle: 'Marktübersicht',
  regiserSuccess: 'Register war erfolgreich',
  setSuccess: 'erfolgreich eingestellt',
  updateAddress: 'Ich möchte die Auszahlungsadresse ändern',
  doingRealName: 'Die Echtnamen-Authentifizierung läuft, bitte warten Sie geduldig',
  updateSuccess: 'Erfolgreich geändert',
  uploadSuccess: 'Erfolgreich hochgeladen',
  forgetLpass:
    'Wenn Sie Ihr Passwort vergessen, wenden Sie sich bitte an den Kundendienst, um die Informationen zu überprüfen und sie zu verarbeiten, um Ihr Anmeldekennwort zurückzusetzen',
  forgetApass:
    'Wenn Sie Ihr Passwort vergessen, wenden Sie sich bitte an den Kundendienst, um die Informationen zu überprüfen und Ihr Transaktionskennwort zurückzusetzen.',
  updateAddressTip:
    'Um die Zahlungsadresse zu ändern, wenden Sie sich bitte an den Kundendienst, um die persönlichen Informationen zu überprüfen und die Zahlungsadresse zurückzusetzen.',
  a48h: 'Die Auszahlung wurde angenommen, der Auszahlungsantrag wird innerhalb von 48 Stunden bearbeitet. Bitte warten Sie geduldig,',
  r48h: 'Die Aufladung wurde akzeptiert, die Aufladungsbestellung wird innerhalb von 48 Stunden bearbeitet. Bitte warten Sie geduldig.',
  accepted: 'Akzeptieren',
  notremind: 'Nicht noch einmal daran erinnern',
  serviceCode: {
    '0': 'Erfolg',
    '500': 'Versagen',
    '501': 'Registrierungsfehler',
    '502': 'Senden fehlgeschlagen',
    '503': 'Fügen Sie fehlgeschlagen hinzu',
    '504': 'Anfrage fehlgeschlagen',
    '600': 'Diese Währung unterstützt keine Auszahlung',
    '1001': 'Der Verifizierungscode existiert nicht',
    '1003': 'Die Anzahl der Abhebungen ist größer als das Maximum',
    '1004': 'Der abgenommene Geldbetrag ist geringer als das Minimum',
    '1005': 'Unzureichender verfügbarer Gleichgewicht',
    '1006': 'Brieftasche ist verschlossen',
    '1007':
      'Die reale Authentifizierung wird derzeit überprüft. Bitte warten Sie geduldig, bis die Überprüfung vor dem Fortfahren abgeschlossen ist.',
    '1008': 'Bitte legen Sie zunächst ein Transaktionspasswort fest',
    '1009': 'Falsches Fonds Passwort',
    '1010': 'Vermögenswerte können nicht gesperrt werden',
    '1011': 'Diese Mailbox wurde gebunden',
    '1012': 'Dieser Benutzername existiert schon',
    '1013': 'Kein Benutzer',
    '1014': 'Die Registrierungsaktivierungs -E -Mail wurde an Ihre E -Mail gesendet. Bitte wiederholen Sie die Anfrage nicht',
    '1015': 'Bitte binden Sie zuerst E -Mail',
    '1016': 'Bestellmenge kann nicht 0 sein',
    '1017': 'Ihr geltendes Konto ist deaktiviert. Bitte wenden Sie sich an den Kundendienst!',
    '1018': 'Aktivität existiert nicht',
    '1019': 'Der Kaufbetrag ist geringer als der Mindestbetrag',
    '1020': 'Kaufbetrag größer als maximaler Betrag',
    '1021': 'Nicht unterstützte Währung!',
    '1022': 'Handelspaare wurden gesammelt',
    '1023': 'Wiederholen Sie keine Einstellungen',
    '1024': 'Fonds Passwortlänge ist nicht legal',
    '1025': 'Der Vertrag existiert nicht',
    '1026': 'Falsche Vorhersagerichtung',
    '1027': 'Benutzerbrieftasche existiert nicht',
    '1028': 'Falsche Kontonummer oder Passwort',
    '1029': 'Dieses Konto ist inaktiv/deaktiviert. Bitte wenden Sie sich an den Kundendienst',
    '1030': 'Sehr geehrter Benutzer, das Abstecken ist nur für Konten mit Identitätsprüfung möglich. Bitte füllen Sie es zuerst aus.',
    '1031': 'Bitte setzen Sie ein Transaktions Passwort ein',
    '1032': 'Eine echte Authentifizierung wurde durchgeführt, keine Neuauthentifizierung',
    '1033': 'Die gleiche ID -Nummer kann nur einmal authentifiziert werden',
    '1035': 'Das alte Passwort ist falsch',
    '1036': 'Bitte laden Sie die Anmeldeinformationen für die Aufladung hoch.',
    '1037': 'Bitte geben Sie den richtigen Aufladungsbetrag ein',
    '1038': 'Konto existiert nicht',
    '1039': 'Das Passwort ist inkorrekt',
    '1040': 'Das alte Transaktion Passwort ist falsch',
    '1041': 'Das Transaktionspasswort ist falsch. Bitte gehen Sie wieder ein',
    '1042': 'Einladungscode -Fehler',
    '1043': 'Abnormale Abzugsadresse',
    1065: 'Die aktuelle Bestellung wird derzeit überprüft. Bitte senden Sie sie nicht erneut.',
    '3000': 'Zeitüberschreitung beim Anmelden',
    888: 'Alle neuen und bestehenden Nutzer müssen eine Identitätsverifizierung durchführen, um Zugang zu allen -Produkten und -Dienstleistungen zu erhalten.',
  },
  //Der profit
  rate: 'Gewinn und zinsen',
  mode: 'Händler.',
  num: 'Handarbeit anzubieten.',
  openTime: 'Macht frei.',
  phoneNi: 'Geben sie bitte die handybestätigung ein',
  phonePlaceholder: 'Geben sie eine handynummer ein',
  inputphonecode: 'Geben sie bitte die handybestätigung ein',
  phone: 'Das handy.',
  phoneVcode: 'Authentifizierungscode für handy',
  sendSucc: 'Abwurf erfolgreich.',
  cScore: 'kredit',
  wallet: 'Brieftasche',
  searchMp: 'Nach dem namen der verschlüsselten währung suchen',
  clickUploadId: 'Klicken sie, um ihr identitätsfoto zu hochladen',
  modifyFp: 'Anpassen.',
  confirmFPassword: 'Der code für die anlage wird bestätigt.',
  pleaseSetYourNewFundPassword: 'Legen sie einen geldkode fest',
  nosamefpassword: 'Falsche geldsummen. Bestätigen sie das erneut.',
  forgotFPassword: 'Vergessen sie ihr passwort?',
  korean: '한국어',
  turkish: 'Türkçe',
};
