// import { RequestPage } from '/@/types/api';
import { useToken } from '/@/hooks/useToken';
export type Params = {
  pageNo: number;
  pageSize: number;
  [key: string]: string | number;
};
export const useList = (requestHandle?) => {
  const { token } = useToken();
  const listError = ref(false);
  const listLoading = ref(false);
  const listFinished = ref(false);
  const listQuery = reactive<Params>({
    pageNo: 1,
    pageSize: 10
  });
  const listData = ref<any>([]);
  // 获取列表数据
  function getList(reload = false) {
    if (!token) return;
    if (reload) {
      listQuery.pageNo = 1;
      listData.value = [];
    }
    console.log(requestHandle)
    requestHandle &&
      requestHandle(listQuery)
        .then((res: any) => {
          console.log(res)
          const data = res.data ? res.data : res;
          const items: any = data;
          if (items.numberOfElements) {
            listData.value.push(...items.content);

            listQuery.pageNo++;
          } else {
            listFinished.value = true;
          }
        })
        .catch(() => {
          listError.value = true;
        })
        .finally(() => {
          listLoading.value = false;
        });
  }

  return {
    listError,
    listLoading,
    listFinished,
    listQuery,
    listData,
    getList,
  };
};
