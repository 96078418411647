<script lang="ts">
import { defineComponent } from 'vue'
export default defineComponent({
  name: 'QuotationPage',
})
</script>
<template>
  <!-- 资产流水 -->
  <div style="height: 100vh; background-color: #fff">
    <!-- 资产流水tabbar -->
    <van-sticky>
      <div class="recordHeader">
        <div class="header">
          <var-icon @click="$router.back()" name="chevron-left" :size="30" style="padding-left: 5px" />
          <span style="font-weight: 600; font-size: 16px">{{ $t('historyDelivery') }}</span>
        </div>
      </div>
    </van-sticky>
    <!-- 交易内容 -->
    <div class="recordBody">
      <!-- 列表 -->
      <van-list
        v-if="historyList.length > 0"
        ref="list"
        :loading-text="$t('loading')"
        v-model:loading="historyLoading"
        v-model:error="historyError"
        @load="histroyListHandle"
        :finished="historyFinished"
      >
        <div class="orderItem" v-for="(item, index) in historyList" :key="index">
          <div class="orderHeader">
            <div class="symbol">{{ item.symbol }}</div>
            <div class="">{{ handleTime(item.createTime) }}</div>
          </div>
          <div class="content" style="justify-content: space-between">
            <div class="item" style="flex: 1">
              <!-- <p class="label">{{ $t('Status') }}</p> -->
              <div>
                <p class="value" style="font-size: 14px">{{ item.openPrice }} </p>
              </div>
              <div>|</div>
              <div>
                <span :style="'color:' + (item.rewardAmount > 0 ? 'green' : 'red')">{{ item.closePrice }}</span>
              </div>
            </div>
            <div class="item" style="flex: 1; display: flex; justify-content: center; align-items: center">
              <!-- <p class="label">{{ $t('ForecastDirection') }}</p> -->

              <p class="value">
                <span> {{ $t(item.direction) }} </span> {{ '(' + item.betAmount + ')' }}
              </p>
            </div>
            <div class="item" style="flex: 1; display: flex; justify-content: center; align-items: center">
              <p class="value" :style="'color:' + (item.rewardAmount > 0 ? 'green' : 'red')">{{ item.rewardAmount }} </p>
            </div>
          </div>
        </div>
      </van-list>
      <van-empty v-else :description="$t('noData')" />
    </div>
  </div>
</template>

<script setup lang="ts" name="QuotationPage">
  import { useList } from '/@/hooks/useList';
  import { historyContract } from '/@/api/contract';
  import dayjs from 'dayjs';

  function handleTime(timer: any) {
    let hour = Number(dayjs().format('HH'));
    return dayjs(timer).format('DD/MM/YYYY HH:mm:ss ') + (hour < 12 || hour == 24 ? 'AM' : 'PM');
  }

  const {
    listError: historyError,
    listLoading: historyLoading,
    listFinished: historyFinished,
    listData: historyList,
    getList: histroyListHandle,
  } = useList(historyContract);

  histroyListHandle(true);
</script>
<style lang="scss" scoped>
  @import '../../transaction/css/detail.scss';

  .orderItem {
    width: 96%;
    margin: 0 auto;
    margin-top: 30px;
    background-color: #fff;
    border-radius: 8px;
    padding: 0 24px;
    box-sizing: border-box;

    .orderHeader {
      display: flex;
      justify-content: space-between;
      padding: 30px 10px;
      border-bottom: 1px solid rgba(0, 0, 0, 0.1);
      box-sizing: border-box;
      color: #000;

      .symbol {
        font-weight: 600;
      }
    }

    .content {
      display: flex;
      flex-wrap: wrap;
      padding-bottom: 28px;

      .item {
        width: 33.333%;
        text-align: center;

        p {
          margin: 0;
        }

        .label {
          font-family: Roboto-Regular, Roboto;
          color: #666666;
          font-size: 26px;
          margin-top: 40px;
        }

        .value {
          font-size: 32px;
          font-family: Roboto-Regular, Roboto;
          color: #000000;
          margin-top: 24px;
        }

        .green {
          color: #19be51;
        }
      }
    }
  }

  // 导航头部
  .recordHeader {
    // width: 750px;
    height: 90px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    background: #f5f5f5;

    .header {
      // width: 100%;
      display: flex;
      align-items: center;
      justify-content: space-between;

      span {
        position: absolute;
        left: 50%;
        transform: translateX(-50%);
      }
    }
  }

  // 交易内容
  .recordBody {
    width: 100%;

    .history {
      width: 95%;
      height: 300px;
      margin: 0 auto;
      margin-top: 30px;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      background: #f5f5f5;
      border-radius: 10px;
      padding-right: 10px;

      .leftHistory {
        width: 100%;
        height: 20%;
        padding-left: 20px;
        padding-top: 10px;
        display: flex;
        align-items: center;
        justify-content: space-between;
        font-size: 26px;
      }

      //中部
      .centerHistory {
        width: 100%;
        height: 40%;
        padding-left: 20px;
        display: flex;
        // align-items: center;
        justify-content: space-between;
      }

      //右侧
      .rightHistory {
        width: 100%;
        height: 40%;
        padding-left: 20px;
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding-bottom: 10px;
      }
    }
  }

  //弹出筛选
  ::v-deep .var-popup {
    .var-field-decorator__middle {
      height: 100px;
      font-size: 23px;
      padding-top: 40px;

      .var-select__select {
        height: 80px;
        display: flex;
        align-items: center;
      }

      .var-icon {
        font-size: 35px;
      }
    }

    .var-field-decorator__placeholder {
      font-size: 25px;
    }
  }

  ::v-deep .var-button--normal {
    height: 70px;
    font-weight: 550;
  }

  .nut-picker-roller-mask {
    background-image: null;
    /* background-color: var(--popup-content-background-color) */
  }
</style>
