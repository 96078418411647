<script lang="ts">
import { defineComponent } from 'vue'
export default defineComponent({
  name: 'LoginPage',
})
</script>
<template>
  <div class="register">
    <!-- 注册tabbar -->
    <div class="registerHeader">
      <div class="header">
        <var-icon @click="$router.back()" name="chevron-left" :size="30" style="padding-left: 5px" />
        <span style="color: #000; font-weight: 600; font-size: 15px">{{ $t('regisertitle') }}</span>
      </div>
    </div>
    <!-- SES图片 -->
    <div class="SESiMG">
      <img src="/public/img/logo2x.png" style="margin-top: 0px; width: 90px; height: 145px" />
      <img class="w-100% h-5" src="/public/img/brand@2x.png" />
    </div>
    <!-- 注册账号密码验证 -->
    <div class="registerFrom">
      <!-- 邮箱账号密码 -->
      <van-form ref="ruleForm" @submit="submit">
        <!-- 邮箱 -->
        <van-field
          v-model.trim="formData.email"
          autocomplete="off"
          clearable
          :name="$t('email')"
          :label="$t('email')"
          label-align="top"
          :placeholder="$t('emailPlaceholder')"
          @end-validate="endValidateEmail"
          :rules="rules.username"
        />
        <!-- 邮箱验证码 -->
        <van-field
          v-model="formData.code"
          autocomplete="off"
          clearable
          :name="$t('emailVerificationCode')"
          :label="$t('emailVerificationCode')"
          label-align="top"
          :placeholder="$t('emailNi')"
          center
          :rules="rules.code"
        >
          <template #button>
            <van-button @click="sendCode()" :disabled="disabledSms" size="mini" plain type="primary">{{ smsText }}</van-button>
          </template>
        </van-field>

        <!-- 密码 -->
        <van-field
          v-model="formData.password"
          autocomplete="off"
          clearable
          :type="!isShow.pass ? 'password' : 'text'"
          :label="$t('password')"
          :name="$t('password')"
          :placeholder="$t('inputloginpassword')"
          label-align="top"
          :rules="rules.password"
          :right-icon="isShow.pass ? 'eye-o' : 'closed-eye'"
          @click-right-icon="toggleShowpass"
        />
        <!-- 确认密码 -->
        <van-field
          v-model="repassword"
          :label="$t('confirmPassword')"
          autocomplete="new-password"
          clearable
          :type="!isShow.repass ? 'password' : 'text'"
          :name="$t('password')"
          :placeholder="$t('inputreloginpassword')"
          label-align="top"
          :rules="rules.repassword"
          :right-icon="isShow.repass ? 'eye-o' : 'closed-eye'"
          @click-right-icon="toggleShowrepass"
        />
        <!-- 邀请码 -->
        <van-field
          v-if="showInvitecode"
          :label="$t('invitecode')"
          autocomplete="off"
          clearable
          v-model="formData.promotion"
          :placeholder="$t('inputinvite')"
          label-align="top"
        />
        <!-- 已阅读申请 -->
        <van-row justify="center" style="padding: 20px 0">
          <van-checkbox style="padding-right: 10px" v-model="checked" shape="square" icon-size="15px" />
          {{ $t('iagree') }} <router-link style="padding-left: 5px" to="/help/detail/7">{{ $t('useragreement') }}</router-link>
        </van-row>
        <!-- 立即注册按钮 -->
        <div class="registerBtn">
          <div style="">
            <van-button block type="primary" native-type="submit"> {{ $t('registerNow') }} </van-button>
          </div>
        </div>
      </van-form>

      <!-- 有密码去立即登录 -->
      <div class="loginWait">
        <div class="loginLeft">
          <van-row justify="space-between" style="padding: 10px 0">
            <span>{{ $t('existingSES') }}</span>
            <router-link to="/login">{{ $t('nowlogin') }}</router-link>
          </van-row>
        </div>
      </div>
    </div>
    <!-- 下载app -->
    <!-- <div class="app_bottom_reg" style="display:none" > 
      <div class="left_logo">
        <img style="float: left" src="/public/img/logo2x.png" />
        <div class="sps">{{ $t('appname') }}</div>
      </div>
      <div class="right_logo">
        <router-link to="/app" class="right_btn">{{ $t('download') }}</router-link>
      </div>
    </div> -->
  </div>
</template>

<script lang="ts" setup name="LoginPage">
  import { reactive, ref } from 'vue';
  import { registerByEmail, registerCodeEmail, vEmail } from '/@/api/user';
  import { showToast } from 'vant';
  import { useI18n } from 'vue-i18n';
  import { useRouter } from 'vue-router';
  const router = useRouter();
  const route = useRoute();
  const { t } = useI18n();
  const checked = ref(false);
  const disabledSms = ref(true);
  const smsText = ref(t('sendcode'));
  const repassword = ref('');

  const isShow = ref({
    pass: false,
    repass: false,
    confirmNew: false,
  });

  const showInvitecode = ref(true);
  // showInviteCode();
  // function showInviteCode() {
  //   registerInvite({}).then((res: any) => {
  //     if (res.code == 0) {
  //       console.log(res);
  //       showInvitecode.value = res.data.value == 1;
  //     }
  //   });
  // }
  const toggleShowpass = () => {
    isShow.value.pass = !isShow.value.pass;
  };
  const toggleShowrepass = () => {
    isShow.value.repass = !isShow.value.repass;
  };
  const formData = reactive<any>({
    email: '',
    promotion: '',
    username: '',
    password: '',
    country: '',
    superPartner: '',
    code: '',
  });
  console.log(route.query);
  if (route.query) {
    formData.promotion = route.query.code;
  }
  const ruleForm = ref<any>(null);
  const endValidateEmail = (data: any) => {
    if (data.status == 'failed') {
      disabledSms.value = true;
    } else {
      disabledSms.value = false;
    }
  };
  // 验证邮箱
  async function blurVEmail(val) {
    try {
      await vEmail({ email: val });
      return Promise.resolve(true);
    } catch (e) {
      if (e) {
        return Promise.resolve(false);
      }
    }
  }
  const rules = ref<any>({
    username: [
      { required: true, message: t('emailPlaceholder') },
      {
        pattern: /^\w+(\.)?(\w+)?@[0-9a-zA-Z]+(\.[a-z]+){1,3}$/,
        message: t('rightemailformat'),
        trigger: ['onBlur'],
      },
      {
        validator: blurVEmail,
        message: t('serviceCode.1011'),
        trigger: ['onBlur'],
      },
    ],
    password: [
      { required: true, message: t('passwordPlaceholder') },
      {
        pattern: /^.{6,}$/,
        message: t('passwordtip'),
        trigger: ['onBlur'],
      },
    ],
    repassword: [
      { required: true, message: t('inputreloginpassword') },
      {
        validator: (value) => {
          return value == formData.password;
        },
        message: t('nosamepassword'),
        trigger: ['onChange', 'onBlur'],
      },
    ],
    code: [
      { required: true, message: t('emailNi') },
      {
        pattern: /^.{6,}$/,
        message: t('emailNi'),
        trigger: ['onBlur'],
      },
    ],
  });
  // const formLoading = ref(false);
  const submit = () => {
    if (!checked.value) {
      showToast(t('noagree'));
      return;
    }
    let params = {
      email: formData.email,
      promotion: formData.promotion || '',
      username: formData.email,
      password: formData.password,
      country: '中国',
      superPartner: '',
      code: formData.code,
    };
    registerByEmail(params).then((res: any) => {
      if (res.code == 0) {
        showToast(t('regiserSuccess'));

        setTimeout(() => {
          router.push({ path: '/login' });
        }, 2000);
      }
    });
  };
  let intervalId;
  let count = 600;
  const sendCode = () => {
    disabledSms.value = true;
    registerCodeEmail({ email: formData.email })
      .then((res: any) => {
        if (res.code == 0) {
          showToast(res.message);
          intervalId = setInterval(function () {
            count--;
            if (count === 0) {
              count = 600;
              smsText.value = t('sendcode');
              disabledSms.value = false;
              clearInterval(intervalId);
            } else {
              smsText.value = count + '';
            }
          }, 1000);
        } else {
          disabledSms.value = false;
        }
      })
      .catch(() => {
        disabledSms.value = false;
      });
  };
  onUnmounted(() => {
    if (intervalId) {
      clearInterval(intervalId);
    }
  });
</script>

<style scoped lang="scss">
  @import './css/register.scss';
</style>
