
export enum OrderType {
  BUY = 'buy',
  SELL = 'sell',
}

export enum OrderStatus {
  TRADING = 'inTrade',
  COMPLETED = 'completed',
  CANCELED = 'cancelled',
  OVERTIMED = 'timeout',
}

export enum OrderType2 {
  LIMIT_PRICE = 'limitOrder',
  MARKET_PRICE = 'marketOrder',
}

export enum ContractOrderType {
  BUY = 'call',
  SELL = 'put',
}

export enum ContractOrderStatus {
  WIN = 'success',
  LOSE = 'failure',
  WAIT = 'inProgress',
}

export enum Period {
  'Time' = '0',
  '1M' = '1min',
  '5M' = '5min',
  '15M' = '15min',
  '30M' = '30min',
  '60M' = '60min',
  '1D' = '1day',
  '7D' = '1week',
}


// '1 分时' = '1week',
