<script lang="ts">
import { defineComponent } from 'vue'
export default defineComponent({
  name: 'QuotationPage',
})
</script>
<template>
  <!-- 安全设置页面 -->
  <div class="safe">
    <!-- 安全设置tabbar -->
    <div class="safeHeader">
      <div class="header">
        <var-icon @click="$router.push('/user')" name="chevron-left" :size="30" style="padding-left: 5px" />
        <span style="font-weight: 600; font-size: 16px">{{ $t('securitySettings') }}</span>
        <!-- <van-icon style="margin-right: 10px" name="send-gift-o" :size="20" /> -->
      </div>
    </div>
    <!-- content -->
    <div class="list">
      <div class="text-xs" style="padding: 15px 20px; color: #656668; font-size: 14px"> {{ user.realVerified === 2?$t('congratulations'):$t('securityReminder') }} </div>
      <!-- 个人头像 -->
      <div
        class="item flex items-center justify-between"
        style="padding: 5px 20px; background-color: #fafafa; margin-bottom: 3px; font-size: 14px"
      >
        <!-- 头像左边 -->
        <div style="width: 35%; white-space: nowrap">{{ $t('personalAvatar') }}</div>
        <!-- 头像右边 -->
        <div @click="showBottom = true" class="flex items-center justify-end" style="width: 65%; text-align: right">
          <van-image round width="50" height="50" :src="user.avatar" />
          <span style="display: inline-block">
            <van-icon name="arrow" />
          </span>
        </div>
      </div>
      <!-- 实名认证 -->
      <div
        class="item flex items-center justify-between"
        style="padding: 15px 20px; background-color: #fafafa; margin-bottom: 3px; font-size: 14px"
      >
        <div style="width: 35%">{{ $t('realNameAuthentication') }}</div>
        <!-- 已认证 -->
        <div
          v-if="user.realVerified === 2"
          @click="$router.push('/verified/' + user.idCard + '/' + user.realName + '/' + encodeURIComponent(user.identityCardImgFront)  + '/' + encodeURIComponent(user.identityCardImgReverse))"
          style="width: 65%; text-align: right; font-size: 14px"
        >
          <span style="padding-right: 3px;color: blue">{{ $t('isVerified') }}</span>
          <van-icon name="arrow" />
        </div>
        <!-- 认证中 -->
        <div
          v-else-if="user.realVerified === 1"
          @click="
            showDialog({
              message: t('doingRealName'),
              confirmButtonText: t('confirm'),
            })
          "
          style="width: 65%; text-align: right; font-size: 14px"
        >
          <span style="padding-right: 3px;color: #fc0202">{{ $t('creditting') }}</span>
          <van-icon name="arrow" />
        </div>
        <!-- 认证失败 -->
        <div
          v-else-if="user.realVerified === 3"
          @click="$router.push('/unVerified/')"
          style="width: 65%; text-align: right; font-size: 14px"
        >
          <span style="padding-right: 3px; color: #fc0202">{{ $t('notVerified') }}</span>
          <van-icon name="arrow" />
        </div>
        <!-- 未认证 -->
        <div
          v-else-if="user.realVerified === 0"
          @click="$router.push('/unVerified/')"
          style="width: 65%; text-align: right; font-size: 14px"
        >
          <span style="padding-right: 3px; color: #fc0202">{{ $t('notVerified') }}</span>
          <van-icon name="arrow" />
        </div>
      </div>
      <!-- 收款账户 -->
      <div
        class="item flex items-center justify-between"
        style="padding: 15px 20px; background-color: #fafafa; margin-bottom: 3px; font-size: 14px"
      >
        <div style="width: 35%">{{ $t('receivingAccount') }}</div>
        <div @click="$router.push('/bindAccount/')" style="width: 65%; text-align: right">
          <span :style="'padding-right: 3px; ' + (user.accountVerified ? 'color: blue' : 'color: red')">{{
            user.accountVerified ? $t('isVerified') : $t('notset')
          }}</span>
          <van-icon name="arrow" />
        </div>
      </div>
      <!-- 登录密码 -->
      <div
        class="item flex items-center justify-between"
        style="padding: 15px 20px; background-color: #fafafa; margin-bottom: 3px; font-size: 14px"
      >
        <div style="width: 35%; white-space: nowrap">{{ $t('loginPassword') }}</div>
        <div @click="$router.push('/loginPassword')" style="width: 65%; text-align: right">
          <span style="padding-right: 3px; color: blue">{{ $t('modify') }}</span>
          <van-icon name="arrow" />
        </div>
      </div>
      <!-- 资金密码 -->
      <div
        class="item flex items-center justify-between"
        style="padding: 15px 20px; background-color: #fafafa; margin-bottom: 3px; font-size: 14px"
      >
        <div style="width: 35%; white-space: nowrap">{{ $t('fundPassword') }}</div>
        <div v-if="user.fundsVerified" @click="$router.push('/changePassword')" style="width: 65%; text-align: right">
          <span style="padding-right: 3px; color: blue">{{ $t('modify') }}</span>
          <van-icon name="arrow" />
        </div>
        <div v-else @click="$router.push('/setPassword')" style="width: 65%; text-align: right">
          <span style="padding-right: 3px; color: red">{{ $t('modifyFp') }}</span>
          <van-icon name="arrow" />
        </div>
      </div>
    </div>

    <!-- 修改头像/相册 -->
    <div>
      <van-popup v-model:show="showBottom" position="bottom">
        <!-- <div style="border-bottom: 1px solid #13161d; font-size: 16px; background-color: #1a212b; padding: 20px; text-align: center"
          >拍照</div
        > -->
        <div
          style="border-bottom: 1px solid rgb(217 208 208); font-size: 16px; background-color: #f5f5f5; padding: 20px; text-align: center"
        >
          <van-uploader :after-read="afterRead">{{ $t('photoAlbum') }}</van-uploader>
        </div>
        <div
          @click="showBottom = false"
          style="border-bottom: 1px solid rgb(217 208 208); font-size: 16px; background-color: #f5f5f5; padding: 20px; text-align: center"
        >
          {{ $t('cancel') }}
        </div>
      </van-popup>
    </div>
  </div>
</template>

<script lang="ts" setup name="QuotationPage">
import { showDialog } from 'vant';
import { setting, upload, changeAvatar } from '/@/api/user';
import { useUserStore } from '/@/store/modules/user';
import { ref, reactive, toRefs, onActivated, onDeactivated } from 'vue';
import { useI18n } from 'vue-i18n';
// import { useRouter } from 'vue-router';
// const router = useRouter();
const { t } = useI18n();
const userStore = useUserStore();

const showBottom = ref(false);

const getInfo = async () => {
  let res = await setting();

  data.user = res.data;
};
onActivated(() => {
  getInfo();
});
onDeactivated(() => {
  getInfo();
});
const data = reactive({
  user: getInfo(),
}) as any;

const { user } = toRefs(data);
//修改 上传头像
const afterRead = async (file) => {
  // 此时可以自行将文件上传至服务器
  // 此时可以自行将文件上传至服务器s
  let res = await upload({ base64Data: file.content }, { 'Content-Type': 'application/json' });

  await changeAvatar({ url: res.data });
  user.value.avatar = res.data;
  if (userStore.userInfo) {
    userStore.userInfo.avatar = res.data;
  }
  showBottom.value = false;
};
</script>
<style scoped lang="scss">
@import './css/safe.scss';
</style>
