<script lang="ts">
import { defineComponent } from 'vue'
export default defineComponent({
  name: 'QuotationPage',
})
</script>
<template>
  <!-- 头部 -->
  <!-- 行情title -->
  <div class="quotation">
    <!-- 头部 -->
    <van-sticky>
      <div class="quotationTabbar">
        <div class="leftTitle">
          <h2>{{ $t('marketTitle') }}</h2>
        </div>
        <!-- 右侧搜索 -->
        <div class="rightSearch">
          <router-link to="/quotation/search" style="display: flex">
            <van-icon :size="16" name="search" /> <span>{{ $t('search') }}</span>
          </router-link>
        </div>
      </div>
    </van-sticky>
    <!-- 中部内容+tab -->
    <div class="quotationCenter">
      <!-- tab切换 USDT/自选 -->
      <div class="quotationHeaderTab">
        <!-- 自选/USDT -->
        <van-tabs ref="tabs" elevation background="#F3F5F7FF" active-color="#f0a70a" inactive-color="#fff"
          v-model:active="active" animated>
          <!-- 自选 -->
          <van-tab :title="$t('optional')">
            <template v-if="token">
              <div class="coinDescription">
                <span>{{ $t('currency') }}</span>
                <span>{{ $t('recentPrice') }}
                  <!-- <img src="/img/symbol/sort.png" alt="" /> -->
                </span>
                <span>{{ $t('gains') }}
                  <!-- <img src="/img/symbol/sort.png" alt="" /> -->
                </span>
              </div>
              <div v-if="favorList.length" style="padding-bottom:100px">
                <!-- :to="`/contract?symbol=${item.symbol}`" -->
                <div @click="toContract(item.symbol)" v-for="item in favorList" :key="item.symbol" class="p-4 block">
                  <van-row>
                    <!-- symbol -->
                    <van-col :span="12" style="display: flex">
                      <!-- 图片 -->
                      <div id="imgBox" class="mr-2 flex items-center justify-center mt-1">
                        <img id="imgs" :src="item.logo" alt="" />
                      </div>
                      <div class="text-xs align-text-top text-gray-400">
                        <div class="text-base font-600 text-black">
                          {{ item.symbol }}
                        </div>
                        <div class="text-xs" v-if="item.turnover >= 1000000000">{{ $t('quantity2') }}{{ (item.turnover
                          / 1000000000).toFixed(2) }}b</div>
                        <div class="text-xs" v-else-if="item.turnover >= 1000000">{{ $t('quantity2') }}{{
                          (item.turnover / 1000000).toFixed(2) }}m</div>
                        <div class="text-xs" v-else>{{ $t('quantity2') }}{{ item.turnover.toFixed(4) }}</div>
                        <!-- 24h{{ $t('quantity2') }}{{ formatNumber(parseInt(item.volume.toFixed(4))) }} -->
                      </div>
                    </van-col>
                    <!-- close -->
                    <van-col :span="5">
                      <div class="flex items-center h-full">
                        <div class="font-600" :class="item.chg > 0 ? 'greenColors' : 'redColors'">{{ item.close.toFixed(Number(item.coinScale??0)) }}</div>
                      </div>
                    </van-col>
                    <!-- chg -->
                    <van-col :span="7">
                      <div class="flex justify-end items-center h-full">
                        <van-tag v-if="item.chg > 0" class="w-20 justify-center" size="large"
                          style="background-color: #4cca47; height: 30px">
                          +{{ (item.chg * 100).toFixed(2) }}%
                        </van-tag>
                        <van-tag v-else class="w-20 justify-center" size="large"
                          style="background-color: #f03627; height: 30px">
                          {{ (item.chg * 100).toFixed(2) }}%
                        </van-tag>
                      </div>
                    </van-col>
                  </van-row>
                </div>
              </div>
              <div v-else class="emptyImg">
                <img src="/img/symbol/empry.png" alt="" />
              </div>
            </template>
          </van-tab>

          <!-- USDT -->
          <van-tab title="USDT">
            <template v-if="!token || token">
              <div class="coinDescription">
                <span>{{ $t('currency') }}</span>
                <span>{{ $t('recentPrice') }}
                  <!-- <img src="/img/symbol/sort.png" alt="" /> -->
                </span>
                <span>{{ $t('gains') }}
                  <!-- <img src="/img/symbol/sort.png" alt="" /> -->
                </span>
              </div>
              <div style="padding-bottom:100px">
                <div @click="toContract(item.symbol)" v-for="item in coinList" :key="item.symbol" class="p-4 block">
                  <van-row>
                    <!-- symbol -->
                    <van-col :span="12" style="display: flex">
                      <!-- 图片 -->
                      <div id="imgBox" class="mr-2 flex items-center justify-center mt-1">
                        <img id="imgs" :src="item.logo" alt="" />
                      </div>
                      <div class="text-xs align-text-top text-gray-400">
                        <div class="text-base font-600 text-black">
                          {{ item.symbol }}
                        </div>
                        <div class="text-xs" v-if="item.turnover >= 1000000000">{{ $t('quantity2') }}{{ (item.turnover
                          / 1000000000).toFixed(2) }}b</div>
                        <div class="text-xs" v-else-if="item.turnover >= 1000000">{{ $t('quantity2') }}{{
                          (item.turnover / 1000000).toFixed(2) }}m</div>
                        <div class="text-xs" v-else>{{ $t('quantity2') }}{{ item.turnover.toFixed(4) }}</div>
                        <!-- 24h{{ $t('quantity2') }}{{ formatNumber(parseInt(item.volume.toFixed(4))) }} -->
                      </div>
                    </van-col>
                    <!-- close -->
                    <van-col :span="5">
                      <div class="flex items-center h-full">
                        <div class="font-600" :class="item.ul > 0 ? 'greenColorQuo' : 'redColorQuo'">{{ item.close.toFixed(Number(item.coinScale??0)) }}
                        </div>
                      </div>
                    </van-col>
                    <!-- chg -->
                    <van-col :span="7">
                      <div class="flex justify-end items-center h-full">
                        <van-tag v-if="item.chg > 0" class="w-20 justify-center" size="large"
                          style="background-color: #4cca47; height: 30px">
                          +{{ (item.chg * 100).toFixed(2) }}%
                        </van-tag>
                        <van-tag v-else class="w-20 justify-center" size="large"
                          style="background-color: #f03627; height: 30px">
                          {{ (item.chg * 100).toFixed(2) }}%
                        </van-tag>
                      </div>
                    </van-col>
                  </van-row>
                </div>
              </div>
            </template>
            <div v-else class="emptyImg">
              <img src="/img/symbol/empry.png" alt="" />
            </div>
            <!-- <van-empty v-else image-size="80" :description="$t('noData')" /> -->
          </van-tab>
        </van-tabs>
      </div>
    </div>
  </div>
</template>

<script lang="ts" setup name="QuotationPage">
import { onMounted, watch } from 'vue';
import { useColinList } from '/@/hooks/useColin';
// import { formatNumber } from '/@/utils';
import { getCoinList } from '/@/api/transaction';
import { useToken } from '/@/hooks/useToken';
import { useRoute, useRouter } from 'vue-router';
import axios from 'axios';
import { useAppStore } from '/@/store/modules/app';
const appStore = useAppStore();
const router = useRouter();
const route = useRoute();
const { token } = useToken();
const { coinList, favorList, getFavorListHanler } = useColinList('', true);
const active = ref(1);
const tabs = ref();
const currentCoin = ref();
const symbol = ref<string>('');

symbol.value = 'BTC/USDT';


function toContract(symbol) {
  console.log("sss",symbol);
  appStore.setCSymbol(symbol);
  console.log(appStore.ucSymbol);
  router.push('/contract');
}

watch(
  () => route.fullPath,
  () => {
    if (route.fullPath.includes('/quotation')) {
      token && getFavorListHanler();
    }
  },
);

const timerIdTrade = ref<any>();
onMounted(() => {
  token && getFavorListHanler();
  tabs.value.resize();
  active.value = 1;

  if (active.value == 1) {
    getCoinList().then((res) => {
      //@ts-ignore
      coinList.value = res;
      coinList.value.forEach((coin: any) => {
        coin.ul = 0;
        listDetailUSDT(coin);
      });
      currentCoin.value = coinList.value.find((v) => v.symbol === symbol.value) || {};
    });
  }
});

onActivated(() => {
  if (active.value == 1) {
    timerIdTrade.value = setInterval(tradeInfo, 1000);
  }
});

onDeactivated(() => {
  if (timerIdTrade.value) {
    clearTimeout(timerIdTrade.value);
  }
});
function tradeInfo() {
  coinList.value.forEach((coin: any) => {
    coin.ul = 0;
    listDetailUSDT(coin);
  });
}

function listDetailUSDT(coin) {
  axios.get('https://api.huobi.pro/market/detail/merged?symbol=' + coin.symbol.replace('/', '').toLowerCase()).then((res: any) => {
    // @ts-ignore
    res = res.data;
    // @ts-ignore
    if (res.status == 'ok') {
      // @ts-ignore
      let _close = coin.close;
      let t_close = res.tick.close;
      coin.close = res.tick.close;
      coin.ul = t_close - _close;
      let t_open = res.tick.open;
      let chg = (t_close - t_open) / t_open;
      coin.chg = chg;
    }
  });
}
</script>
<style lang="scss">
@import './css/index.scss';
</style>
