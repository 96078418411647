<script lang="ts">
import { defineComponent } from 'vue'
export default defineComponent({
  name: 'LoginPage',
})
</script>
<template>
  <div class="login">
    <!-- 登录tabbar -->
    <div class="loginHeader">
      <div class="header">
        <div @click="$router.push('/home')">{{ $t('cancel') }}</div>
        <!-- <router-link to="/langs" style="color: #000">{{ $t('switchLanguage') }}</router-link> -->
      </div>
    </div>
    <!-- SES图片 -->
    <div class="SESiMG">
      <img src="/public/img/logo2x.png" style="width: 90px; height: 145px" />
      <img class="w-25 h-5" src="/public/img/brand@2x.png" />
    </div>
    <!-- 账号密码输入框 -->
    <div class="loginFrom">
      <van-form ref="ruleForm" @submit="submit" class="pl-2">
        <!-- 账号 
          autocomplete="off" 
          autocomplete="new-password"
        -->
        <van-field
          v-model.trim="formData.username"
          :name="$t('username')"
          :label="$t('username')"
          label-align="top"
          :placeholder="$t('emailPlaceholder')"
          :rules="usernameRules"
        />
        <!-- 密码 -->
        <van-field
          v-model="formData.password"
          type="password"
          :name="$t('password')"
          :label="$t('password')"
          :placeholder="$t('passwordPlaceholder')"
          label-align="top"
          :rules="passwordRules"
        />
        <!-- 没有账号+去注册页面 -->
        <div id="flResgin" class="flex justify-between">
          <div class="pl-4" style="color: #989286" @click="showToast($t('forgetLpass'))">{{ $t('forgotPassword') }} ? </div>
          <router-link to="/register" class="pr-4">{{ $t('registerNow') }}</router-link>
        </div>
        <!-- 登录按钮 -->
        <!-- :loading="formLoading" -->
        <div
          @click="submit"
          style="
            margin-top: 30px;
            font-size: 16px;
            color: #fff;
            border-radius: 5px;
            background-color: #f99f05;
            height: 12vw;
            display: flex;
            align-items: center;
            justify-content: center;
            margin: 20px auto;
            width: calc(100% - 20px);
            height: 45px;
          "
        >
          {{ $t('nowlogin') }}
        </div>
        <!-- margin-top: 8px;
            text-align: center;
            line-height: 50px; -->
        <!-- <van-button class="h-90px" :loading="formLoading" round block type="primary" native-type="submit">
          {{ $t('nowlogin') }}
        </van-button> -->
      </van-form>
    </div>
  </div>
</template>

<script lang="ts" setup name="LoginPage">
  import { reactive, ref } from 'vue';
  import { useUserStore } from '/@/store/modules/user';
  import { Login } from '/@/types/api';
  import { useRouter } from 'vue-router';
  import { showToast } from 'vant';
  import { useI18n } from 'vue-i18n';
  const { t } = useI18n();
  const router = useRouter();

  const userStore = useUserStore();
  const formData = reactive<Login>({
    // username: '495089876@qq.com',
    // password: 'Aa123456',
    username: '',
    password: '',
  });
  const ruleForm = ref<any>(null);
  const formLoading = ref(false);
  const usernameRules = [{ validateEmpty: true }];
  const passwordRules = [{ validateEmpty: true }];
  // const back = () => {
  //   router.back();
  // };
  const submit = () => {
    ruleForm.value.validate().then(async (res: any) => {
      if (!res) {
        formLoading.value = true;
        const userInfo = await userStore.login(formData).finally(() => {
          formLoading.value = false;
        });
        if (userInfo) {
          showToast({
            message: t('success'),
            duration: 2000,
            onClose: () => {
              router.push({ path: '/home' });
            },
          });
        }
      }
    });
  };
</script>

<style scoped lang="scss">
  @import './css/index.scss';
</style>
