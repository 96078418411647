export interface popupSelectType {
  SizeColor: String;
  activeColor: String;
  activeName: any;
}

export interface getmyordersPamsType {
  pageNo: number;
  pageSize: number;
  status: string | '';
}

export interface dataType {
  popupselectList: any,
  filterHY: string,
  code: string,
  iconColor: string,
  buttonColor: string,
}

export enum Static {
  进行中 = 1,
  已完成 = 2,
  提前赎回 = 3,
}
export interface refreshType {
  loading: boolean;
}

export interface ListconfigType {
  checked: boolean;
  finished: boolean;
  loading: boolean;
}

export interface OrderDataType {
  entrustMoney: string;
  todayIncome: string;
  totalIncome: string;
  orderCount: string;
}

export interface InvestmentOrderListItem {
  status: number;
  baseSymbol: string;
  days: string;
  releasePercent: number;
  amount: string;
  createTime: string;
  symbol: String;
  url: string;
  isRedo: number;
}
export interface ListdataType {
  lockedUnit: string;
  pageNo: number;
  pageSize: number;
  unit: string;
}

export interface ListconfigType {
  checked: boolean;
  finished: boolean;
  loading: boolean;
}

export interface TZlistItemType {
  symbol: string;
  acceptUnit: string;
  miningInvite: string;
  lockedUnit: string;
  releasePercent: number;
  bannerImageUrl: string;
}

export interface dataParams {
  itemDetail: {
    id: number;
    symbol: string;
    releasePercent: number;
    price: number;
    lockedDays: number;
    max:number;
    min:number;
  };
  checked: boolean;
  code: string;
  balance: number;
  Investment: number;
}

export interface DetailType {
  dayIncome: number;
  releasePercent: number;
  days: string;
  miningInvite: string;
  symbol: string;
  amount: string;
  allIncome: string;
  endTime: string;
  createTime: string;
  status: number;
  url: string;
  pactNum: number;
}

export const mytofixed = (num: number, age: number): number => {
  let nums = 0;
  if (age == 2) {
    nums = 100;
  }
  if (age == 3) {
    nums = 1000;
  }
  if (age == 4) {
    nums = 10000;
  }
  return Math.floor(num * nums) / nums;
};

export const getdate = (time: string): string => {
  let date = new Date(parseInt(time));
  let Year = date.getFullYear();
  let Moth = date.getMonth() + 1 < 10 ? '0' + (date.getMonth() + 1) : date.getMonth() + 1;
  let Day = date.getDate() < 10 ? '0' + date.getDate() : date.getDate();
  let Hour = date.getHours() < 10 ? '0' + date.getHours() : date.getHours();
  let Minute = date.getMinutes() < 10 ? '0' + date.getMinutes() : date.getMinutes();
  let Sechond = date.getSeconds() < 10 ? '0' + date.getSeconds() : date.getSeconds();
  let GMT = Year + '-' + Moth + '-' + Day + '   ' + Hour + ':' + Minute + ':' + Sechond;
  return GMT;
};
