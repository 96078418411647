<script lang="ts">
import { defineComponent } from 'vue'
export default defineComponent({
  name: 'QuotationPage',
})
</script>
<template>
  <div style="height: 100vh; background-color: #fafafa">
    <!-- top bar -->
    <div class="setsBar">
      <span @click="$router.back()"> <var-icon name="chevron-left" :size="30" style="padding-left: 5px" /></span>
      <span style="font-weight: 600; font-size: 16px">{{ $t('settingsCenter') }}</span>
    </div>

    <!-- 语言切换 -->
    <div
      @click="$router.push({ path: 'langs' })"
      class="flex justify-between items-center"
      style="margin-top: 8px; padding: 0 20px; background-color: #f5f5f5; line-height: 50px"
    >
      <div style="font-size: 14px">{{ $t('switchLanguage') }}</div>
      <div>
        <van-icon :size="16" name="arrow" />
      </div>
    </div>
    <!-- 意见反馈 -->
    <div
      @click="$router.push({ path: 'feedback' })"
      class="flex justify-between items-center"
      style="margin-top: 8px; padding: 0 20px; background-color: #f5f5f5; line-height: 50px"
    >
      <div style="font-size: 14px">{{ $t('feedback') }}</div>
      <div>
        <van-icon :size="16" name="arrow" />
      </div>
    </div>
    <!-- 关于我们 -->
    <div
      @click="$router.push({ path: 'about' })"
      class="flex justify-between items-center"
      style="margin-top: 8px; padding: 0 20px; background-color: #f5f5f5; line-height: 50px"
    >
      <div style="font-size: 14px">{{ $t('aboutUs') }}</div>
      <div>
        <van-icon :size="16" name="arrow" />
      </div>
    </div>
    <!-- 公告 -->
    <div
      @click="$router.push('/announcement')"
      class="flex justify-between items-center"
      style="margin-top: 8px; padding: 0 20px; background-color: #f5f5f5; line-height: 50px"
    >
      <div style="font-size: 14px">{{ $t('announcement') }}</div>
      <div>
        <van-icon :size="16" name="arrow" />
      </div>
    </div>
    <!-- 帮助 -->
    <!-- <div
      @click="$router.push('/help')"
      class="flex justify-between items-center"
      style="margin-top: 8px; padding: 0 20px; background-color: #f5f5f5; line-height: 50px"
    >
      <div style="font-size: 14px">{{ $t('help') }}</div>
      <div>
        <van-icon :size="16" name="arrow" />
      </div>
    </div> -->
    <!-- 版本更新 -->
    <!-- <div class="flex justify-between items-center" style="margin-top: 8px; padding: 0 20px; background-color: #f5f5f5; line-height: 50px">
      <div style="font-size: 14px">{{ $t('updateSet') }}</div>
      <div style="font-size: 14px; padding-right: 5px"> 2.0.6 </div>
    </div> -->
    <!-- 退出当前账号 -->
    <div
      @click="show = true"
      style="
        margin-top: 80px;
        font-size: 16px;
        color: #fff;
        border-radius: 5px;
        background-color: #f99f05;
        height: 12vw;
        display: flex;
        align-items: center;
        justify-content: center;
        margin: 20px auto;
        width: calc(100% - 20px);
        height: 45px;
      "
      >{{ $t('logout') }}
    </div>
    <van-dialog
      v-model:show="show"
      :title="$t('confirmLogout')"
      :confirmButtonText="$t('confirm')"
      :cancelButtonText="$t('cancel')"
      show-cancel-button
      @confirm="logout"
    >
    </van-dialog>
  </div>
</template>

<script lang="ts" setup name="QuotationPage">
  import { useUserStore } from '/@/store/modules/user';

  const show = ref(false);
  const user = useUserStore();
  //退出登录
  const logout = () => {
    user.logout();
  };
</script>
<style lang="scss" scoped>
  // 头部tabbar
  .setsBar {
    height: 90px;
    background: #f5f5f5;
    display: flex;
    align-items: center;
    span:nth-child(2) {
      position: absolute;
      left: 50%;
      transform: translateX(-50%);
    }
  }
  //退出登录弹框
  ::v-deep .van-dialog {
    width: 467px;
    // height: 160px;
    background: #fff !important;
    .van-dialog__header {
      background: #fff;
      font-size: 28px;
    }
    .van-button--default {
      background: #fff;
      font-size: 28px;
    }
  }

  :deep(.van-dialog__header) {
    background-color: #1a212a;
  }

  :deep(.van-dialog__confirm),
  :deep(.van-dialog__cancel) {
    background-color: #1a212a;
  }
</style>
