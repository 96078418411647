<template>
  <!-- 涨幅页面 -->
  <div class="details" ref="scrollContainer" style="overflow-y: hidden">
    <!-- 头部导航 -->
    <van-sticky>
      <div class="navBarHeader">
        <div @click="openLeftPop" style="padding-left: 10px; display: flex; align-items: center">
          <img style="color: black" src="/img/symbol/menu.png" width="20" />
          <span>{{ currentCoin.symbol }}</span>
        </div>
        <router-link :to="`/transaction/detail?symbol=${currentCoin.symbol}`">
          <img style="margin-right: 15px; margin-left: 15px" src="/img/symbol/datas.png" width="16" />
        </router-link>
      </div>
    </van-sticky>

    <!-- 高低能量值 -->
    <div class="EnergyValue" style="margin-top: 0; height: auto">
      <div class="energy">
        <div id="energyLeft">
          <div :class="(coninInfo?.close || 0 - beforeValue) > 0 ? 'text-green-500' : 'text-red-500'"> {{ coninInfo?.close || '-' }} </div>
          <div style="font-size: 20px" :class="(coninInfo?.close || 0) - (coninInfo?.open || 0) > 0 ? 'text-green-500' : 'text-red-500'">
            {{ coninInfo?.close ? (((coninInfo?.close - coninInfo?.open) / coninInfo?.open) * 100).toFixed(2) : '-' }}
            %
          </div>
        </div>
        <!-- 右侧能量值 -->
        <div class="eneryRight">
          <div id="highs" class="flex justify-between">
            <div>{{ $t('high') }}</div> {{ coninInfo?.high || '-' }}
          </div>
          <div id="highs" class="flex justify-between">
            <div>{{ $t('low') }}</div> {{ coninInfo?.low || '-' }}
          </div>
          <div id="highs" class="flex justify-between">
            <div class="mr-3">{{ $t('quantity2') }}</div>
            {{ coninInfo?.vol ? (coninInfo?.vol - 0).toFixed(4) : '-' }}
          </div>
        </div>
      </div>
    </div>
    <!-- tab切换栏 -->
    <van-tabs class="mt-1 pl-0" v-model:active="klinePeriod" @change="periodChange">
      <van-tab v-for="(item, key) in Period" :title="lang === 'en_US' ? key : key" :name="item" :key="key" />
    </van-tabs>
    <!-- 切换图表 -->
    <div class="charts-border mb-3" style="height: 200px">
      <div id="kLineChart" class="charts"></div>
      <div class="charts-label" style="padding-left: 20px">
        <div style="color: rgb(219, 219, 89)">MA5:{{ MA5 || 0 }}</div>
        <div style="color: #7bf0f7">MA10:{{ MA10 || 0 }}</div>
        <div style="color: #bb97f4">MA30:{{ MA30 || 0 }}</div>
      </div>
    </div>
    <var-tabs style="height: 35px" v-model:active="activeNum" @change="clickTabs(activeNum)">
      <var-tab>{{ $t('currentDelivery') }}</var-tab>
      <var-tab>{{ $t('historyDelivery') }}</var-tab>
    </var-tabs>
    <var-tabs-items v-model:active="activeNum">
      <var-tab-item>
        <van-empty v-if="!currents.length" :description="$t('noData')" />
        <van-list v-else style="overflow-y: auto; height: 500px; margin-bottom: 80px">
          <div class="currentOrder" v-for="(item, index) in currents" :key="index" style="padding-bottom: 5px">
            <div class="currentHeader">
              <div class="symbol">{{ handleCoin(item.symbol) }}</div>
              <div>{{ $t(item.direction) }}({{ item.betAmount }})</div>
              <div>{{ handleTime2(item) }}</div>
            </div>
            <div class="price">
              {{ item.openPrice.toFixed(4) }} - <span> {{ coninInfo?.close.toFixed(4) || item.openPrice.toFixed(4) }}</span>
            </div>
            <div class="">{{ handleTime3(item.createTime) }}</div>
            <div class="cus-slider" style="height: 2px">
              <div class="bar" :style="{ width: handleWidth(item) + '%' }"></div>
            </div>
          </div>
        </van-list>
      </var-tab-item>
      <var-tab-item style="padding-bottom: 30px">
        <van-empty v-if="!datas.length" :description="$t('noData')" />
        <van-list
          v-model:loading="currentLoading"
          :finished="currentFinished"
          @load="loadMore"
          :loading-text="t('loading')"
          style="overflow-y: auto; height: 300px; margin-bottom: 200px"
        >
          <div class="orderItem" v-for="(item, index) in datas" :key="index">
            <div class="orderHeader">
              <div class="symbol">{{ item.symbol }}</div>
              <div class="">{{ handleTime(item.createTime) }}</div>
            </div>
            <div class="content" style="justify-content: space-between">
              <div class="item" style="flex: 1">
                <!-- <p class="label">{{ $t('Status') }}</p> -->
                <div>
                  <p class="value" style="font-size: 14px">{{ item.openPrice }} </p>
                </div>
                <div>|</div>
                <div>
                  <span :style="'color:' + (item.rewardAmount > 0 ? 'green' : 'red')">{{ item.closePrice }}</span>
                </div>
              </div>
              <div class="item" style="flex: 1; display: flex; justify-content: center; align-items: center">
                <!-- <p class="label">{{ $t('ForecastDirection') }}</p> -->

                <p class="value">
                  <span> {{ $t(item.direction) }} </span> {{ '(' + item.betAmount + ')' }}
                </p>
              </div>
              <div class="item" style="flex: 1; display: flex; justify-content: center; align-items: center">
                <p class="value" :style="'color:' + (item.rewardAmount > 0 ? 'green' : 'red')">{{ item.rewardAmount }} </p>
              </div>
            </div>
          </div>
        </van-list>
      </var-tab-item>
    </var-tabs-items>
    <div class="footer-sticky">
      <div class="flex p-2 items-center" style="background: #f3f3f3">
        <!-- :to="{ path: '/transaction', query: { symbol: symbol, direction: 'BUY' } }" -->
        <div class="flex-1 mr-1">
          <var-button type="success" block @click="showPopup(0)">{{ $t('BUY') }}</var-button>
        </div>
        <!-- :to="{ path: '/transaction', query: { symbol: symbol, direction: 'SELL' } }" -->
        <div class="flex-1 ml-1">
          <var-button type="danger" block @click="showPopup(1)">{{ $t('SELL') }}</var-button>
        </div>
        <!-- <Favorite class="ml-1" :symbol="symbol" /> -->
      </div>
    </div>
    <!-- 购买窗口-->
    <var-popup class="van-popup-cus" position="bottom" v-model:show="confirmPop">
      <div class="bg-white-gray-850 mt-0 p-3 text-base" style="overflow: hidden">
        <var-row :gutter="3" justify="space-between" v-if="tradeList?.length" style="padding-bottom: 5px">
          <var-col :span="6" v-for="item in tradeList" :key="item.id" @click="changeId(item)">
            <div class="rounded py-2 text-center mb-2" style="margin: 0 auto; background-color: #d1d1d1; position: relative; width: 100%">
              <div class="mb-0" style="text-overflow: ellipsis; white-space: nowrap; overflow: hidden">
                {{ item.closeTimeGap }} {{ $t('second') }}
              </div>
              <div class="p-0" style="font-size: 8px; color: red; text-overflow: ellipsis; white-space: nowrap; overflow: hidden">
                {{ $t('rate') + ' ' + item.oods * 100 + '%' }}
              </div>
              <img
                v-if="formData.fastId == item.id"
                src="/img/icon/fastSel.png"
                style="position: absolute; right: 0; bottom: 0"
                width="30"
              />
            </div>
          </var-col>
        </var-row>
        <van-cell-group inset>
          <van-field
            type="number"
            :label="toCoin + '|'"
            v-model="formData.amount"
            :placeholder="`${$t('minimum')}${currentContract?.minLimitAmout || '0'}${$t('minimumSell')}，${
              $t('most') + currentContract?.maxLimitAmout
            }${$t('ceiling')}`"
            :autosize="{ maxHeight: 120 }"
          >
            <template #label>
              <div class="flex items-center" style=""> {{ toCoin }} <var-divider vertical /> </div>
            </template>
          </van-field>
        </van-cell-group>
        <div class="flex justify-between text-sm py-3" style="font-size: 13px">
          {{ $t('availableBalance') }}：{{ false ? '-' : balanceUsdt ? balanceUsdt.balance.toFixed(4) : '-' || '-' }}
          {{ toCoin }}
          <div style="display: none" v-if="currentContract">
            {{ $t('fee') }}：{{ false ? '-' : (currentContract?.fee * 100).toFixed(4) }} %
          </div>
        </div>
        <div class="price-4">
          <div class="item">
            <p>{{ $t('currency') }}</p>
            <p>{{ fromCoin }}</p>
          </div>
          <div class="item">
            <p>{{ $t('direction') }}</p>
            <p>{{ !isLong ? $t('BUY') : $t('SELL') }}</p>
          </div>
          <div class="item">
            <p>{{ $t('price') }}</p>
            <p :class="(coninInfo?.close || 0 - beforeValue) > 0 ? 'text-green-500' : 'text-red-500'">{{
              (coninInfo?.close || 0).toFixed(Number(currentCoin.coinScale ?? 0))
            }}</p>
          </div>
          <div class="item">
            <p>{{ $t('Amount') }}</p>
            <p>{{ formData.amount ? formData.amount : 0 }}</p>
          </div>
        </div>

        <div class="flex mb-3">
          <var-button class="flex-1 bg-yellow-400 text-white" @click="submitHandle(isLong)">{{ $t('confirm') }}</var-button>
        </div>
        <div class="expected-return" v-if="currentContract?.oods">
          {{ $t('expectedReturn') }}: {{ Number(formData.amount) + Number(formData.amount) * currentContract?.oods }}
        </div>
      </div>
    </var-popup>
    <!-- 左侧弹出交易按钮 -->
    <var-popup position="left" class="h-full" v-model:show="leftMenu" @close="closeLeftPop">
      <div class="w-80 h-full">
        <!-- 左侧关闭弹框按钮 -->
        <div class="flex p-3 justify-between">
          <span class="text-base"></span>

          <div>
            <img src="/img/icon/icon_side_close.jpg" width="20" class="mr-2" @click="leftMenu = !leftMenu" />
          </div>
        </div>
        <!-- tab切换栏 -->
        <van-tabs animated shrink>
          <!-- USDT按钮 -->
          <van-tab title="USDT">
            <VanRow v-for="item in coinList" :key="item.symbol" @click="selectItem(item)">
              <VanCol :span="8" class="p-3">
                <div class="font-bold">{{ item.symbol.split('/')[0] }}</div>
              </VanCol>
              <VanCol :span="8" class="p-3">
                <div class="text-sm" :class="item.chg > 0 ? 'text-green-600' : 'text-red-600'"
                  >{{ item.close.toFixed(Number(item.coinScale ?? 0)) }}
                </div>
              </VanCol>
              <VanCol :span="8" class="p-3 text-right">
                <van-tag class="w-16 justify-center" size="medium" :type="item.chg > 0 ? 'success' : 'danger'"
                  >{{ (item.chg * 100).toFixed(2) }}%
                </van-tag>
              </VanCol>
            </VanRow>
          </van-tab>
        </van-tabs>
      </div>
    </var-popup>
  </div>
</template>

<script lang="ts" setup>
  // 引入文件
  import useBalance from '/@/hooks/useBalance';
  import { getContractList, addContractOrder, currentContract as _currentContract, historyContract } from '/@/api/contract';
  // import { getBgWidth } from '/@/utils';
  import { useColinList } from '/@/hooks/useColin';
  import { useKline } from '/@/hooks/useKline';
  // import { TradeTick } from '/@/types/api';
  import { ref } from 'vue';
  // import { useRoute } from 'vue-router';
  // import { transactionList, transactDepth, transactDeal } from '/@/api/transacDetail';
  import { onMounted, watchEffect } from 'vue';
  import {
    // CoinList,
    Contract,
    DeliveryRefresh,
  } from '/@/types/api';
  import { useToken } from '/@/hooks/useToken';
  import { closeToast, showLoadingToast, showToast } from 'vant';
  import dayjs from 'dayjs';
  import { CoinList } from '/@/types/api';
  import { useAppStore } from '/@/store/modules/app';
  const appStore = useAppStore();
  const cSymbol = appStore.ucSymbol;
  const { token } = useToken();
  // const route = useRoute();
  const router = useRouter();
  const _symbol = cSymbol;
  const { currentCoin, toCoin, fromCoin, coinList, startRefresh, stopRefresh, symbol } = useColinList(_symbol + '');
  let { balanceUsdt, getWalletListHanler } = useBalance(fromCoin.value);
  import { useI18n } from 'vue-i18n';
  const leftMenu = ref(false);
  // 打开侧边
  function openLeftPop() {
    leftMenu.value = !leftMenu.value;
    startRefresh();
  }

  function closeLeftPop() {
    stopRefresh();
  }

  // 交易
  const formData = reactive<any>({
    fastId: 0,
    amount: '',
    direction: 1,
    symbol: '',
    startPrice: 0.0,
  });
  const tradeList = ref<Contract[]>([]);
  const isNoTrade = ref(false);

  function changeId(item: Contract) {
    formData.fastId = item.id;
    currentContract.value = item;
    console.log(currentContract);
  }
  getTradeList();
  function getTradeList() {
    getContractList({})
      .then((res) => {
        if (res.data.length > 0) {
          tradeList.value = res.data;
          currentContract.value = tradeList.value[0];
          formData.fastId = tradeList.value[0].id;
          isNoTrade.value = false;
        } else {
          isNoTrade.value = true;
        }
      })
      .catch(() => {
        isNoTrade.value = true;
      });
  }

  const confirmPop = ref(false);
  const isLong = ref(0); // 0 long 1 short
  function showPopup(type) {
    confirmPop.value = true;
    isLong.value = type;
  }

  function submitHandle(direction: number): void {
    let index = tradeList.value!.findIndex((item) => {
      return item.id == formData.fastId;
    });
    let tempobj: DeliveryRefresh = {
      timeOrder: new Date().valueOf(),
      colseTime: tradeList.value![index].closeTimeGap,
    };
    localStorage.setItem('Delivery', JSON.stringify(tempobj));

    if (!token) {
      router.push('/login');
      return;
    }
    if (formData.amount == '' || parseFloat(formData.amount) <= 0) {
      showToast(t('quantityInput'));
      return;
    }
    if (
      parseFloat(formData.amount) < (currentContract.value?.minLimitAmout || 10) ||
      parseFloat(formData.amount) > (currentContract.value?.maxLimitAmout || 1000000000)
    ) {
      showToast(t('outOfRange'));
      return;
    }
    showLoadingToast({
      message: t('loading'),
      forbidClick: true,
    });
    formData.symbol = currentCoin.value.symbol;
    formData.direction = direction;
    formData.startPrice = coninInfo.value?.close || 0;
    if (formData.startPrice == 0) {
      showToast(t('questError'));
      return;
    }
    addContractOrder(formData)
      .then(() => {
        showToast(t('submitSuccess'));
        formData.amount = '';
        getCurrentContract();
        activeNum.value = 0;
        confirmPop.value = false;
        // 0311 add 刷新余额和收益
        getWalletListHanler(currentCoin.value.coin);
      })
      .finally(() => {
        closeToast();
      });
  }
  // 选择币种
  function selectItem(item: CoinList): void {
    symbol.value = item.symbol;
    currentCoin.value = item;
    appStore.setCSymbol(item.symbol);
    leftMenu.value = false;
    formData.symbol = item.symbol;
    coinName.value = fromCoin.value.toLocaleLowerCase();
    token && getWalletListHanler(fromCoin.value);
    if (activeNum.value == 0) {
      getCurrentContract();
    } else if (activeNum.value == 1) {
      page.value = 1;
      datas.value = [];
      currentFinished.value = false;
      loadMore();
    }
  }
  // 当前订单 历史订单

  function handleCoin(params: any) {
    return params.replace('/USDT', '').trim();
  }
  interface Current {
    symbol: string;
    betAmount: number;
    direction: string;
    fee: number;
    createTime: number;
    closeTimeGap: number;
    openPrice: number;
  }
  const currents = ref<Current[]>([]);
  const backups = ref<Current[]>([]);
  let interval;
  function getCurrentContract() {
    _currentContract({
      symbol: currentCoin.value.symbol ? currentCoin.value.symbol : _symbol,
    })
      .then((res: any) => {
        if (res.code == 0) {
          backups.value = [...res.data];
          currents.value = res.data;

          if (interval) {
            clearInterval(interval);
          }
          if (res.data?.length) {
            interval = setInterval(() => {
              currents.value = [];
              nextTick(() => {
                currents.value = backups.value;
              });
            }, 1000);
          }
        }
      })
      .catch(() => {});
  }

  function handleTime(timer: any) {
    let hour = Number(dayjs().format('HH'));
    return dayjs(timer).format('DD/MM/YYYY HH:mm:ss ') + (hour < 12 || hour == 24 ? 'AM' : 'PM');
  }

  function handleTime2(item: any) {
    let timer = item.closeTime - Date.now();
    let hours = Math.floor(timer / (1000 * 3600)); // 3600 秒 = 1 小时
    let minutes = Math.floor((timer % (1000 * 3600)) / (1000 * 60)); // 剩余分钟数
    let seconds = Math.floor((timer % (1000 * 60)) / 1000); // 剩余秒数
    if (hours < 0) {
      hours = 0;
    }
    if (minutes < 0) {
      minutes = 0;
    }
    if (seconds < 0) {
      seconds = 0;
    }
    return `${hours < 10 ? '0' + hours : hours}:${minutes < 10 ? '0' + minutes : minutes}:${seconds < 10 ? '0' + seconds : seconds}`;
  }

  function handleTime3(timer: any) {
    return dayjs(timer).format('YYYY-MM-DD HH:mm:ss ');
  }

  function handleWidth(item: any) {
    const currentTime = Math.floor(Date.now() / 1000);
    const timeDifference = Math.floor(item.closeTime / 1000) - currentTime;
    const val = Math.floor(((timeDifference - 1) / item.closeTimeGap) * 100);

    if (timeDifference <= 1) {
      let newData = backups.value.filter((value: any) => {
        return value.id != item.id;
      });

      currents.value = [];
      nextTick(() => {
        currents.value = backups.value;
      });

      backups.value = [...newData];

      if (!backups.value.length) {
        clearInterval(interval);
      }
    }

    return val;
  }
  interface Data {
    symbol: string;
    betAmount: number;
    result: string;
    direction: string;
    rewardAmount: number;
    fee: number;
    createTime: number;
    closeTimeGap: number;
    openPrice: number;
    closePrice: number;
  }
  const activeNum = ref(0);
  const page = ref<number>(1);
  const datas = ref<Data[]>([]);
  const totalPages = ref<number>(1);

  const currentLoading = ref(true);
  const currentFinished = ref(false);

  function loadMore() {
    if (activeNum.value != 1) return;
    if (currentFinished.value) return;
    currentLoading.value = true;
    historyContract({
      symbol: currentCoin.value.symbol,
      pageNo: page.value,
      pageSize: 10,
    }).then((res: any) => {
      console.log('historyContract loadMore', res);
      if (res.code == 0) {
        currentLoading.value = false;
        if (res.data.last) {
          // 到底了
          currentFinished.value = true;
        } else {
          page.value += 1;
        }
        datas.value.push(...res.data.content);
        totalPages.value = res.data.totalPages;
      }
    });
  }
  watch(activeNum, (val) => {
    if (val == 0) {
      getCurrentContract();
    } else if (val == 1) {
      page.value = 1;
      datas.value = [];
      currentFinished.value = false;
      loadMore();
    }
  });

  const { t } = useI18n();
  //
  function clickTabs(active) {
    activeNum.value = active;
    document.documentElement.scrollTop = 0;
  }
  const lang = localStorage.getItem('lang');
  const { MA10, MA30, MA5, Period, changePeriod, coninInfo, coinName } = useKline({
    isSubDeep: true,
    isSubTrad: true,
    isSubDetail: true,
    fromName: fromCoin.value,
    wsCallback,
  });
  const beforeValue = ref(0);
  nextTick(() => {
    beforeValue.value = currentCoin.value.close;
    getCurrentContract();
  });
  function wsCallback() {
    if (coninInfo.value) {
      beforeValue.value = coninInfo.value.close;
      currentCoin.value.close = coninInfo.value.close;
    }
  }

  const klinePeriod = ref('1min');
  // const detailDetachS = ref<DeepTick>();
  // const tradeItems = ref<TradeTick[]>([]);
  const currentContract = ref<Contract>();
  // const { currentCoin, symbol, toCoin, fromCoin } = useColinList();
  // 买入卖出弹框 changeTab
  onActivated(() => {
    total.value = '— ';
    income.value = '— ';
  });

  // 当前
  const total = ref('— ');
  const income = ref('— ');

  function periodChange(val: string): void {
    // period.value = val;
    changePeriod(val);
  }

  // const newDetach = ref<any>([]);
  watchEffect(() => {
    // 在这里可以更新页面的显示
    // console.log('Data updated:', detailDeal.value); // 示例：打印更新的数据
  });

  onMounted(() => {});
  onDeactivated(() => {});
  onDeactivated(() => {
    stopRefresh();
  });
</script>
<style lang="scss" scoped>
  @import '../transaction/css/detail.scss';

  ::v-deep {
    .van-cell-group {
      margin: 0;
      background-color: inherit;
      border: 1px solid #363e55;
      // height: 80px;
      // display: flex;
      // align-items: center;
      // justify-content:;
    }

    .van-tabs__nav {
      padding-left: 0;
    }

    .van-cell {
      display: flex;
      align-items: center;
      padding: 10px 5px;
      background-color: inherit;

      .van-field__label {
        color: orange;
        font-size: 28px;
        font-weight: 600;
        width: initial;
        margin-right: 0;
      }

      .var-divider--vertical {
        margin: 0 10px;
      }

      input::placeholder {
        font-size: 20px;
        color: #999;
      }
    }

    .van-field__value {
      // display: flex;
      align-items: center;
    }

    .van-field__control {
      color: #999;
      // padding: 10px 5px;
      white-space: normal !important;
    }

    input {
      font: initial;
      font-family: initial;
    }
  }

  .details {
    --van-tabs-line-height: 50px;
  }

  ::v-deep .van-tabs__nav {
    padding-left: 0 !important;
    padding-right: 0 !important;
  }

  .currentOrder {
    width: 96%;
    margin: 0 auto;
    margin-top: 30px;
    background-color: #fff;
    border-radius: 8px;
    padding: 24px;
    box-sizing: border-box;
    font-size: 32px;

    .currentHeader {
      display: flex;
      justify-content: space-between;
      box-sizing: border-box;
      align-items: center;
    }

    .price {
      margin-top: 16px;
      margin-bottom: 20px;

      span {
        color: #07c160;
        margin-left: 10px;
      }
    }

    .cus-slider {
      background: #ebedf0;
      overflow: hidden;
      box-sizing: border-box;
      margin-top: 20px;

      .bar {
        background: #07c160;
        height: 100%;
        transition: all 0.3s;
      }
    }
  }

  .orderItem {
    width: 96%;
    margin: 0 auto;
    margin-top: 30px;
    background-color: #fff;
    border-radius: 8px;
    padding: 0 24px;
    box-sizing: border-box;

    .orderHeader {
      display: flex;
      justify-content: space-between;
      padding: 30px 10px;
      border-bottom: 1px solid rgba(0, 0, 0, 0.1);
      box-sizing: border-box;
      color: #000;

      .symbol {
        font-weight: 600;
      }
    }

    .content {
      display: flex;
      flex-wrap: wrap;
      padding-bottom: 28px;

      .item {
        width: 33.333%;
        text-align: center;

        p {
          margin: 0;
        }

        .label {
          font-family: Roboto-Regular, Roboto;
          color: #666666;
          font-size: 26px;
          margin-top: 40px;
        }

        .value {
          font-size: 32px;
          font-family: Roboto-Regular, Roboto;
          color: #000000;
          margin-top: 24px;
        }

        .green {
          color: #19be51;
        }
      }
    }
  }
</style>
