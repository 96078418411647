import { langType } from './lang-base';

export const lang: langType = {
  receivingAddress: 'お支払い住所',
  second: '秒',
  minimum: '少しでも',
  most: '多くの',
  minimumSell: '買い始めます',
  ceiling: 'キャップ付き',
  availableBalance: '経常収支',
  Nomoreatmoment: 'これ以上はありません',
  updateSet: 'バージョンの更新',
  pleaseEnterTheAmount: '金額を入力してください',
  SearchCurrency: '契約番号の検索',
  Unabletochangeorder: 'オーダーステータスを変更できません',
  Yourbalanceisinsufficient: '残高が不足しています',
  Ordinarysingle: '一般オーダー',
  DailyDistributions: '日次収益の発行,ロック期間終了後の元金の戻し',
  Resumptionhasbeenactivated: '複投が開始されました',
  Resumptionhasbeenclosed: '複投オフ',
  orderCount: 'オーダー数量',
  PleaseEnter: '契約番号を入力してください',
  emailNi: 'メールボックス認証コードを入力してください',
  existingSES: '既存のBtrokアカウント',
  email: 'メールボックス',
  myinvitation: '私の招待',
  teamValue: 'チーム貢献値',
  shareValue: '寄与値の共有',
  allValue: '总贡献值',
  myBenefits: '私の貢献値',
  contribution: 'コントリビューション値',
  contributionDetail: '寄与値の詳細',
  Dailyinterestrate: '日利率',
  LendingDays: '貸借日数',
  Financialproductdetails: '投資信託商品の詳細',
  Subscriptionwallet: '財布を買う',
  Subscriptionamount: '購入金額',
  SubscriptionDetails: '購入の詳細',
  Expirationtime: '有効期限',
  Investmenttype: '投資タイプ',
  InvestmentDetails: '投資の詳細',
  Thesystemwillautomatically:
    '投資が満期になった注文は,システムが自動的に新しい財テク注文にマッチングします。複投の注文は,プラットフォームから贈られたFTH権益を獲得し続け,未複投の注文が期限切れになると,贈られたFTH権益は破棄される。',
  Reswitchingfunction: 'ふくとうきのう',
  description: '説明',
  Investmentquantity: '投資',
  Investmentdetermination: '投資確定',
  RevenueDetails: '収益の詳細',
  InvestmentOrders: '投資オーダ',
  Estimatedtotalrevenue: '予想総収益',
  Redemptionmethod: '償還方法',
  Dailyrevenue: '毎日の収益',
  LockedDays: 'ロック日数',
  Currenttotalinvestmentequivalent: '現在の投資総額（換算）',
  complete: '完了',
  Fundinglimit: '資金調達額',
  Contractnumber: '契約番号',
  Loanfinancing: '貸借理財',
  Subscriptiontime: '予約期間',
  Myinvestmenthistory: '私の投資記録',
  dailyReturn: '日次収益率',
  Investmentdays: '投資日数',
  Selfselectionarea: '高速選択範囲',
  Quickconstructionarea: '高速建設区',
  Smartcontractmatching: 'インテリジェント契約照合',
  ImmediateInvestment: '即時投資',
  sourceTime: '時間',
  viewCode: 'コードの表示',
  goWealh: '富の道',
  fileGo: 'ファイルに移動',
  videoRequest: 'ビデオリクエスト',
  gorequest: 'プル要求',
  proceeding: '訴訟',
  pwds: 'パスワード',
  experienceAll: '満星の体験感',
  experienceFive: '五つ星の体験感',
  sharing: '富の共有',
  customer: 'カスタマーサービス',
  todoList: '代行事項',
  systemMessages: 'システムメッセージ',
  youHave: 'あなたは',
  totleWait: '条は代行して,直ちに処理してください',
  totleBook: 'バー未読システムメッセージ',
  message: 'メッセージ',
  officialNowGo: '今から始めよう',
  officialFinace: 'ブロックチェーン金融を楽しむのは,こんなに簡単なことはありません！',
  officialTechnologyLeft: '最前線',
  officialTechnologyRight: 'ぎじゅつ',
  superSESMessage: 'Btrok情報',
  superSES: 'セス',
  superFomamce: '契約金融,金融の享受',
  superSecure: '高収益安全ブロックチェーン投資',
  superParagraphOne:
    'Btrok国際デジタル暗号化資産取引プラットフォームは総合デジタル資産取引プラットフォームであり,我々は先進的な技術力,良質な顧客サービスと良好なユーザー体験を持ち,多くのデジタル通貨愛好家に自由なオンライン交換のプラットフォームを提供している。',
  superParagraphTwo:
    'Btrok国際デジタル暗号化資産取引プラットフォームは同時にデジタル通貨白書計画,暗号アルゴリズム調整,デジタル通貨研究開発,取引プラットフォーム上に一連のブロックチェーンサービスを提供する。',
  superParagraphThree:
    'Btrok国際デジタル暗号化資産取引プラットフォーム海外運営センターはシンガポール,カンボジア,マーシャルにそれぞれ設立されている。',
  Traverser: 'トラバースそくていき',
  announceList: '公告リスト',
  announceListDetail: '公告リスト',
  official: '公式',
  supervise: 'モニタノード',
  Source: 'オープンソースコード',
  report: '監査レポート',
  more: '詳細',
  deposit: '貨幣を充填する',
  invite: 'に勧める',
  lockMining: 'ロックタンク掘削',
  riseRanking: '上昇幅ランキング',
  currency: '通貨＃ツウカ＃',
  recentPrice: '最近の価格',
  gains: '24の上昇幅',
  welcomes: 'ようこそいらっしゃいました!',
  home: 'トプペジ',
  messageList: 'メッセージ・リスト',
  noData: 'データが一時的にありません!',
  market: '相場',
  financialManagement: 'ファイナンス',
  optional: '自選',
  buy: '購入する',
  sell: '売り出す',
  completed: '完了',
  limitOrder: '価格制限依頼',
  marketOrder: '市価委託',
  call: '強気',
  put: '弱気',
  success: '成功',
  failure: 'に失敗',
  transaction: '取り引き',
  contract: 'オプション＃オ',
  forex: '外国為替',
  mine: 'マイ',
  notNull: '空にすることはできません',
  optionContract: 'オプション契約',
  currentDelivery: '現在の受け渡し',
  historyDelivery: '歴史の受け渡し',
  fee: '手数料',
  openAmount: 'オープン金額',
  bonusQuantity: '賞与額',
  predictionDirection: '予測方向',
  requestFailure: 'リクエストに失敗しました。再ロードをクリックしてください',
  predictionResult: '予測結果',
  submitSuccess: 'コミット成功',
  sharePoster: 'ポスターの共有',
  cancel: 'キャンセル',
  copyLink: 'リンクのコピー',
  saveImage: '画像を保存',
  pleaseLoginFirst: '先にログインしてください',
  pleaseUseTronWallet: '波場ウォレットを使用してチャージリンクを開いてください',
  currentInterest: '当座利息',
  fundsSecurity: '資金セキュリティ',
  guarantee: 'に保証',
  holidayEarnings: '休日の収益は途切れない',
  warning: '上記の住所に非貨幣資産をチャージしないでください。そうしないと,資産は回収できません。',
  confirmation:
    '上記アドレスにチャージすると,ブロックチェーンネットワークノード全体の確認が必要になり,一般的にブロックチェーンメインネットワークは3回ネットワーク確認後に入金されます。',
  addressChange:
    'チャージアドレスは常に変更されず,繰り返しチャージできます。変更があれば,できるだけウェブサイトの公告やメールでお知らせします',
  safetyReminder: '情報の改ざんや流出を防ぐために,操作環境の安全性を必ず確認してください。',
  rechargeUSDT: 'USDTをチャージ',
  details: '詳細',
  dayLimit: '日',
  mining: '鉱山を掘ってもうけが止まらない',
  upcoming: '開始',
  inProgress: '進行中',
  distribution: '配布中',
  progress: '進行状況',
  totalAmount: 'アクティビティの合計',
  startTime: '開始時間',
  endTime: '終了時間',
  lockingMining:
    'ロック倉庫掘削は,USDTをプラットフォームスーパーコンピュータに信託することによってプラットフォーム鉱山プールで掘削収益を行う',
  productHighlights: '製品の特長',
  withdrawal: '保存したまま受け取る',
  dividendCycle: '配当サイクル',
  successfulDepositOnDay: '正常に入金された当日',
  interestAccrual: '利子がつく',
  example: '例を挙げる',
  profitCalculation: '収益計算',
  simplestProfitFormula: '最も単純な収益計算式は,',
  profitRateCalculationExample: '例えば,収益が2 W,元金が10 Wであれば,収益率',
  calculationOfPrincipal: '証券口座の場合,投入した元金を覚えていない場合は,現在の時価総額と現在の損益で計算することができます',
  currentMarketValueExample: '例えば,現在の時価総額は11 Wで,現在の配当金は2 Wであれば,元金は11 W-2 W=9 Wであり,収益率=（2 W/9 W）',
  penalty: '違約金',
  participate: '私は参加します',
  entrustedOrder: '発注',
  pledgingInProgress: '質入れ中',
  pledgingComplete: '質押完了',
  earlyRedemption: '繰り上げ償還',
  loading: 'ロード中',
  pledgeAmount: '質権抵当金額',
  dailyEarnings: '日次収益',
  earlyRedemptionDeductionHandlingFee: '前払控除手数料',
  earlyRedemptionAgain: '繰り上げ償還',
  endOfContent: 'もうおしまいだ',
  financialProduct: '資産運用製品',
  fundsUnderManagement: '管理中の資金',
  expectedTodayEarnings: '今日の収益予想',
  TodayEarnings: '今日の収益',
  accumulatedEarnings: '累積収益',
  trustOrder: '管理対象のオーダー',
  buy2: '買いに行く',
  period: 'サイクル',
  switchLanguage: '言語切り替え',
  username: 'ユーザー名',
  emailPlaceholder: 'メールアドレスを入力してください',
  forgotPassword: 'パスワードを忘れる',
  passwordPlaceholder: 'パスワードを入力してください',
  submit: '送信',
  registerNow: '今すぐ登録',
  search: '検索けんさく',
  high: '高い',
  low: '低い',
  depth: '深さ',
  deal: '成約する',
  buyQuantity2: '購入数',
  price: '価格',
  quantity: '数量',
  sellQuantity: '売出数量',
  time: '時間',
  direction: '方向',
  inputPrice: '価格を入力してください',
  tradingVolume: '取引高',
  entrust: '委任',
  entrustPrice: '委託価格',
  entrustQuantity: '委任量',
  totalEntrustment: '委託総額',
  category: 'カテゴリ',
  confirm: 'を選択して,',
  inputCorrectQuantity: '正しい数量を入力してください',
  operationSuccessful: '操作が成功しました',
  revoke: '元に戻す',
  entrustmentCancellationConfirmation: '取り消し確認の委任',
  areYouSureToCancel: 'キャンセルを確認しますか。',
  all: 'すべて',
  currentEntrustment: '現在の委任',
  completed2: '成約済み',
  aboutSES: 'Btrokについて',
  freeExchange: '多くのデジタル通貨愛好家に自由なオンライン交換プラットフォームを提供',
  modify: '変更',
  bind: 'バインド',
  alipayAccount: '支付宝アカウント',
  wechatAccount: 'ウィチャットアカウント',
  unbound: 'バインドされていません',
  sesPlatformOverview3:
    'Btrokデジタル資産取引所は,英国のMAI国際基金によって投資開発されたグローバルなデジタル資産取引プラットフォームであり,英国を拠点とし,英国NFCの規制を受けています。欧州のブロックチェーン産業において基盤整備を行い,英国を中心にヨーロッパ,アジア,東南アジア,アメリカ,オーストラリアに展開し,デジタル資産とブロックチェーンのエコシステムを構築しています。Btrokデジタル資産取引所は,グローバルな投資家を対象とした国際的なデジタル資産取引所であり,業界の優位性を結集し,銀行金融管理の経験豊富なチーム,専門のビッグデータ開発チーム,世界トップクラスのブロックチェーン開発チーム,先進的なブロックチェーン技術サービスチームを有しています。ユーザーに効率的で安全なデジタル資産取引サービスを提供することを目指しています。',
  sesPlatformOverview4:
    '世界初の総合的なデジタル資産取引プラットフォームとして,Btrokは世界的にトップクラスのブロックチェーン取引プラットフォームの一つであり,世界中のデジタル資産エコシステムに展開しています。国際的なライセンスと政府機関の承認を持ち,技術革新,製品アップデート,安全なリスク管理システム,運営および顧客サービス体制などの面で持続的な改善を行っています。世界のユーザーにビットコイン,イーサリアムなどの主要なデジタル通貨の現物およびデリバティブ取引サービスを提供するだけでなく,Btrokは時代の変化に合わせて,全方位のブロックチェーンエコシステム構築に貢献しています。',
  sesPlatformOverview5:
    '安全で使いやすく自由なデジタル資産取引プラットフォームを構築し,世界中の仮想通貨愛好者の要求に応える金融インフラを提供し,真にデジタルインターコネクト時代の仮想デジタル資産の取引と交流を促進する理想的なコミュニティを築きます。広大な投資家に安全で信頼性の高い総合サービス取引プラットフォームを提供します。',
  bankCardNumber: '銀行カード口座番号',
  accountSettings: 'アカウントの設定',
  modifyAlipayAccount: '支付宝のアカウントを修正する',
  receiveQRCode: '入金QRコード',
  fundPassword: '資金パスワード',
  pleaseEnterCorrectFundPassword: '正しい資金パスワードを入力してください',
  pleaseEnterAlipayAccount: 'アリペイのアカウントを入力してください',
  myAssets: 'マイ資産',
  details2: '詳細',
  withdrawalRecords: '貨幣引出記録',
  totalAssetsApprox: '総資産契約（USDT）',
  spotAccount: '貨幣口座',
  searchAssets: '資産の検索',
  hideZeroBalances: '非表示の通貨',
  frozen: 'とうけつ',
  toRelease: '保留中',
  modifyBankCardNumber: '銀行カード口座番号の変更',
  save: '保存＃',
  bankName: '口座開設銀行',
  bankCardNumberConfirm: 'カード番号の確認',
  pleaseEnterBankCardNumberAgain: '銀行カード番号を再入力してください',
  fundPasswordPrompt: '資金パスワード',
  pleaseEnterFundPassword: '資金パスワードを入力してください',
  modifyFundPassword: '資金パスワードの変更',
  modifyingFundPassword: '資金パスワードを変更しています',
  oldFundPassword: '旧資金パスワード',
  newFundPassword: '新規資金パスワード',
  confirmFundPassword: '資金パスワードの確認',
  pleaseEnterSetPassword: '設定パスワードを入力してください',
  pleaseEnterConfirmFundPassword: '資金確認パスワードを入力してください',
  passwordsDoNotMatch: 'パスワードが一致しません',
  pleaseEnterYourOldFundPassword: '旧資金パスワードを入力してください',
  pleaseEnterYourNewFundPassword: '新しい資金パスワードを入力してください',
  pleaseEnterA6DigitPassword: '6桁の数字のパスワードを入力してください',
  pleaseConfirmNewFundPassword: '新しい資金のパスワードを確認してください',
  theNewFundPasswordCannotBeTheSameAsTheOldFundPassword: '新しい資金パスワードを旧資金パスワードと同じにすることはできません',
  pleaseEnterTheVerificationCode: '認証コードを入力してください',
  feedback: '意見のフィードバック',
  yourOpinionIsVeryImportantToUs: 'あなたの意見は私たちにとって特に重要です！',
  leaveYourValuableOpinionToHelpUsImprove: '私たちがより良いことをするのを助けるために,あなたの貴重な意見を残してください！',
  pleaseLeaveYourValuableOpinion: '貴重なご意見をお残しください',
  submissionSuccessfulThankYouForYourFeedback: 'コミットに成功しました。フィードバックありがとうございます',
  submissionFailed: 'コミットに失敗しました',
  setFundPassword: '資金パスワードの設定',
  setYourPaymentMethod: '入金方法を設定してください。必ず本人の実名アカウントを使用してください',
  newPassword: '新しいパスワード',
  repeatPassword: 'パスワードの繰り返し',
  notLoggedIn: 'ログインしていません',
  welcome: 'ようこそBtrokへ',
  assetManagement: '資産管理',
  assetFlow: '資産フロー',
  myInvitation: '私の招待',
  invitationLink: '招待リンク',
  firstLevelFriends: 'クラスメイト',
  secondLevelFriends: '二級の親友',
  partnerLevel: '個人レベル',
  myEntrustment: '私の依頼',
  coinTrading: '貨幣取引',
  securitySettings: 'セキュリティ設定',
  pleaseLogInFirst: '先にログインしてください',
  announcement: '公告げる',
  changeLoginPassword: 'ログインパスワードの変更',
  oldLoginPassword: '古いログインパスワード',
  newLoginPassword: '新規ログインパスワード',
  pleaseEnterOldLoginPassword: '古いログインパスワードを入力してください',
  pleaseEnterNewLoginPassword: '新しいログインパスワードを入力してください',
  confirmLoginPassword: 'ログインの確認',
  emailVerificationCode: 'メールボックス認証コード',
  emailBound: 'メールボックスをバインドしました',
  verificationCode: '認証コード',
  sendVerificationCode: '認証コードの送信',
  pleaseConfirmInformation: 'すべての情報が入力されているか確認してください',
  pleaseConfirmPassword: '2回入力したパスワードが同じか確認してください',
  makeSureTheOldAndNewPasswordsAreDifferent: '新しいパスワードと新しいパスワードが異なることを確認してください',
  pleaseConfirmNewLoginPassword: '新しいログインパスワードを確認してください',
  teamPerformance: 'チームの業績',
  currentLevel: '現在のレベル',
  nextLevel: '次のレベル',
  teamNumber: 'チーム人数',
  totalPerformance: '総実績',
  onlyForUSDT: 'このアドレスへのUSDTチャージのみサポート',
  walletAddress: 'ウォレットアドレス',
  securityReminder: 'あなたの口座の安全を保障するために,実名認証を完了してから取引操作ができます！',
  personalAvatar: '個人アイコン',
  isVerified: '資格認定済み',
  realNameAuthentication: '実名認証',
  notVerified: '認証されていません',
  receivingAccount: '入金口座',
  loginPassword: 'ログインパスワード',
  fundsPassword: '資金パスワード',
  settingsCenter: 'セットセンタ',
  aboutUs: '私たちについて',
  helptext: 'ヘルプの説明',
  help: 'ヘルプ',
  logout: '現在のアカウントを終了',
  confirmLogout: '本当にログインを終了しますか？',
  bindWeChatAccount: 'ウィチャットアカウントをバインドする',
  withdrawalAddress: '外貨引出先住所',
  available: '使用可能',
  minimumWithdrawalAmount: '最小外貨引出数量',
  amountReceived: '入金数量',
  transactionTime: '取引時間',
  payableHandlingFee: '未払手数料',
  deductibleHandlingFee: '手数料の控除',
  actualHandlingFee: '払込手数料',
  feeGenerationTime: '開始時間は手数料生成時間',
  takeIDPhoto: '身分証明書の写真を撮影してアップロードします',
  identityAuthentication: '資格認定',
  uploadIDCardFront: '身分証明書の正面写真をアップロードする',
  uploadIDCardBack: '身分証明書の裏の画像をアップロードする',
  photoAlbum: 'アルバム冊',
  IDCardNumber: '身分証明書番号',
  enterIDCardNumber: '身分証明書番号を入力してください',
  name: '姓/名',
  idea: 'Btrokイデオロギー',
  goal: 'Btrokターゲット',
  depositRecord: '涵養記録',
  withdrawal2: '貨幣をつりあげる',
  accountOpeningBranch: '口座開設支店',
  quantityInput: '数量を入力してください',
  singleLimit: '一筆書き限度額',
  investmentAmount: '投資額',
  incomePrincipal: 'に利益を与える/元金',
  password: 'パスワード',
  historicalCommission: '履歴の委任',
  type: 'を選択してオプションを設定します。',
  commissionAmount: '委任金額',
  filter: 'フィルタリング',
  pleaseSelect: '選択してください',
  tradingPair: '取引ペア',
  reset: 'リセット',
  startEndTime: '開始時間',
  recharge2: 'チャージ',
  withdraw: '引き出し',
  transfer: '振り替え',
  fiatCurrencyPurchase: '外貨購入',
  fiatCurrencySale: '法貨が売れる',
  activityReward: '活動奨励',
  promotionReward: '普及奨励金',
  dividend: '配当金',
  vote: '投票する',
  manualRecharge: '手動チャージ',
  pairing: 'ペア',
  activityExchange: 'アクティブな換算',
  ctcPurchase: 'CTC購入する',
  ctcSale: 'CTC売り出す',
  redEnvelopeSending: 'お年玉を出す',
  redEnvelopeReceiving: 'お年玉の受け取り',
  withdrawalCodeWithdrawal: '現金化コード現金化',
  withdrawalCodeRecharge: 'キャッシュコードチャージ',
  perpetualContractFee: '契約手数料',
  perpetualContractProfit: '契約利益',
  perpetualContractLoss: '契約損失',
  optionContractFailure: 'オプション契約に失敗しました',
  optionContractFee: 'オプション契約手数料',
  optionContractBonus: 'オプション契約ボーナス',
  contractRebate: '契約書の奉還',
  levelReward: 'レベル別奨励金',
  platformFeeIncome: 'プラットフォーム手数料収入',
  income: 'に利益を与える',
  promotionIncome: 'プロモーション収益',
  optionsPromotionIncome: 'オプションプロモーション収益',
  optionTeamIncome: 'オプションチーム収益',
  optionParityAward: 'オプションレベルの奨励',
  investmentTeamReturn: '投資チームの収益',
  investmentParityReturn: '投資レベル収益',
  investmentPromotionIncome: '投資プロモーション収益',
  forexEntrustTrading: '外国為替委託取引',
  forexCancelEntrustTrading: '外国為替委託取引の取消',
  liquidationCompensation: '爆倉賠償',
  lockedMiningIncome: 'チーム収益',
  speedContractProfit: '急速な契約利益',
  speedContractLoss: '極速契約損失',
  NEWACTIVITY_IN: '投資収益',
  NEWACTIVITY_DEDUCT: '投資の償還',
  NEWACTIVITY_FREEZE: 'オプションのフリーズ',
  NEWACTIVITY_UNFREEZE: 'オプションの凍結解除',
  NEWACTIVITY_REDEEM_UNFREEZE: 'オプション償還の凍結解除',
  speedContractCommission: '極速契約手数料',
  CONTRACT_FAST_FREEZE: '秒合约冻结',
  CONTRACT_FAST_UNFREEZE: '秒合约解冻',
  confirmUploadAllImages: 'すべての画像がアップロードされているか確認してください',
  inputRealName: '実名を入力してください',
  idCardPhoto: '身分証明書の写真',
  congratulations: 'おめでとうございます。審査に合格して,安心して取引することができます',
  quantity2: 'はかる',
  wechatAccount2: 'ウィチャットアカウントを入力してください',
  realNameIdentityVerified: '実名認証済み',
  withdrawalTime: 'キャッシュタイム',
  withdrawalAmount: '引き出し数量',
  creditting: 'レビュー中',
  waitingmoney: '貨幣投入待ち',
  dztime: 'チャージ時間',
  rushaddress: 'チャージアドレス',
  rushnumber: 'チャージ数量',
  //邮箱注册
  regisertitle: 'メールボックス登録',
  inputemailcode: 'メールボックス認証コードを入力してください',
  inputloginpassword: 'パスワードを入力してください（6桁以上の数字,大文字と小文字の組み合わせ）',
  inputreloginpassword: 'パスワードを再入力してください',
  inputinvite: '招待コードを入力してください',
  iagree: '読んで同意しました',
  useragreement: 'ユーザプロトコル',
  nowlogin: '今すぐログイン',
  sendcode: '認証コードの送信',
  rightemailformat: '正しいメールボックス形式を入力してください',
  confirmPassword: 'パスワードの確認',
  passwordtip: 'パスワードには少なくとも6桁の数字が含まれている必要があります',
  nosamepassword: '2回のパスワードが一致しません',
  noagree: '同意ユーザプロトコルがチェックされていません',
  emailverification: 'メールボックスの検証',
  // 划转
  amount: '数量',
  overturn: 'の方向をそらす',
  overturntitle: '資産振替',
  from: '開始',
  to: 'まで',
  swapcurrency: '契約通貨',
  overturnplaceholder: '振り替え数量を入力してください',
  selectaccount: 'アカウントの選択',
  balance: '資産',
  // 钱包页面
  contractaccount: '契約アカウント',
  invitecode: '招待コード',
  notset: '未設定',
  day: '日',
  other: 'その他',
  contactservice: 'カスタマーサービスへの連絡',
  appname: 'μアプリケーション',
  download: 'APPのダウンロード',
  orderByHand: '手動発注',
  orderByAuto: 'ジェントな注文',
  trevenue: '総収益',
  pincome: 'プロモーション収益',
  tincome: 'チーム収益',
  dropList: 'ドロップダウンリスト',
  uploaded: 'アップロード済み',
  youare: 'あなたは',
  disabling: '閉じる',
  enabling: 'オープン',
  theAutomaticReinvestmentFeatur: '自動複投機能',
  nth: '第',
  phase: '期',
  currentForecastPeriod: '当期予測期間',
  maximum: '最大量',
  openingPrice: '寄り付き相場',
  currentPrice: '現在の価格',
  profitSharing: '利益配当',
  CurrentTradingIsRestricted: '現在の取引制限',
  ToEnsureTheSsecurityOfYourAssetsPleaseCarefullyVerifyTheReceivingWalletAddressBeforeMakingAnyDeposits:
    '皆さんの資産の安全を保証するために,チャージは入金財布の住所を真剣にチェックしてください：',
  PleaseProceedWithTheDepositOnlyAfterVerifyingThatEverythingIsCorrect: '。間違いなくチャージできることを確認する。',
  PleaseDoNotDepositAnyAssetsOtherThanTheSpecifiedCryptocurrencyToTheAddressMentionedAboveOtherwiseYourAssetsWillNotBeRecoverable:
    '上記の住所に非貨幣資産をチャージしないでください。そうしないと,資産は回収できません。',
  AfterYouMakeADepositToTheMentionedAddressItRequiresConfirmationFromTheEntireBlockchainNetworkTypicallyItTakesAround30SecondsForTheFundsToBeCreditedToYourAccountAfterThreeConfirmationOnTheBlockchainMainnet:
    '上記アドレスにチャージすると,ブロックチェーンネットワークノード全体の確認が必要になります。一般的にブロックチェーンメインネットワークは3回ネットワーク確認後に入金され,30秒ほどかかります。',
  PleaseEnsureTheSecurityOfYourElectronicDevicesToPreventAnyTamperingOrLeakageOfInformation:
    '情報の改ざんや漏洩を防ぐために,電子機器の安全性を必ず確認してください。',
  WithdrawalsAreCurrentlyDisabled: '現在の貨幣引き出し禁止',
  CurrentWithdrawalAddress: '現在の貨幣引出先住所を確認してください',
  isYourWalletAddress: 'ウォレットアドレスかどうか',
  LinkCopiedSuccessfully: 'コピー成功',
  LinkCopiedFailed: 'コピーに失敗しました',
  goLogin: 'ログインに行く',
  SavePicture: 'リンクを保存',
  SaveLink: '画像を保存',
  languageSettings: '言語設定',
  simplifiedChinese: '简体中文',
  traditionalChinese: '繁体中文',
  arabic: 'عربي',
  english: 'English',
  japanese: '日本語',
  French: 'Français',
  Spanish: 'Español',
  German: 'Deutsch',
  Italiano: 'Italiano',
  TotalDailyLoss: '総利益',
  TotalExpenditureToday: '本日の総注文金額',
  TotalIncomeToday: '本日の総利益',
  CurrentDelivery: '現在の納品',
  HistoricalDelivery: '歴史的な納品',
  InitialAmount: '注文金額',
  ForecastDirection: '予測の方向',
  BonusAmount: 'ボーナスの金額',
  TransactionFee: '手数料',
  OrderDuration: '注文の継続時間',
  Status: '状態',
  SelectCurrency: '通貨を選択',
  EnterAmount: '金額を入力',
  BUY: '上昇で購入',
  SELL: '下落で購入',
  WIN: '成功',
  LOSE: '失敗',
  Amount: '金額',
  expectedReturn: '予想収益',
  service: 'カスタマーサポート',
  untrade: '現在取引できません',
  netError: 'ネットワークが閉じられています!',
  questError: 'リクエストにエラーがありました。後でもう一度試してください。',
  failVerified: '認証に失敗しました',
  tiprealverified: '実名認証、カスタマーサービスまでご連絡ください',
  uploadImg: 'アップロード認証情報',
  collected: '集めました',
  canceld: 'キャンセル',
  placeholderAddress: '支払い先住所を入力してください',
  bindAccountTitle: 'お支払い住所',
  marketTitle: '相場',
  regiserSuccess: '登録は成功しました',
  setSuccess: '正常に設定されました',
  updateAddress: 'ウォレットアドレスを変更したいのですが。',
  doingRealName: '実名認証中です。しばらくお待ちください。',
  updateSuccess: '正常に変更されました',
  uploadSuccess: 'アップロード成功',
  forgetLpass: 'パスワードを忘れた場合は、カスタマーサービスに連絡して情報を確認し、ログインパスワードをリセットしてください。',
  forgetApass: 'パスワードを忘れた場合は、カスタマーサービスに連絡して情報を確認し、取引パスワードをリセットしてください。',
  updateAddressTip:
    '受信アドレスを変更するには、カスタマーサービスに連絡して個人情報を確認し、受信アドレスをリセットする手続きを行ってください。',
  a48h: '出金が受理されました。出金申請は 48 時間以内に処理されます。しばらくお待ちください。',
  r48h: 'リチャージは受け入れられました。リチャージ注文は 48 時間以内に処理されます。しばらくお待ちください。',
  accepted: '受け入れる',
  notremind: '二度と思い出さないでください',
  serviceCode: {
    0: '成功',
    500: '失敗しました',
    501: '登録に失敗しました',
    502: '送信に失敗しました',
    503: '追加に失敗しました',
    504: 'クエリは失敗しました',
    600: 'この通貨は引き出しをサポートしていません',
    1001: '検証コードが存在しません',
    1003: '引き出し金額が最大値を超えています',
    1004: '引き出し金額が最低値を下回っています',
    1005: '利用可能な残高が不足しています',
    1006: 'ウォレットはロックされています',
    1007: '実名認証は現在審査中ですので、審査が通るまで今しばらくお待ちください。',
    1008: '最初にファンドのパスワードを設定してください',
    1009: 'ファンドパスワードエラー',
    1010: 'アセットをロックできません',
    1011: 'メールボックスはバインドされています',
    1012: 'ユーザー名はすでに存在します',
    1013: 'そのようなユーザーはいません',
    1014: '登録アクティベーション電子メールがあなたのメールボックスに送信されました。リクエストを繰り返さないでください',
    1015: '最初にメールボックスをバインドしてください',
    1016: '注文金額を0にすることはできません',
    1017: '現在のアカウントは無効になっています。カスタマー サービスにお問い合わせください。 ',
    1018: 'アクティビティが存在しません',
    1019: '購入金額が最低金額未満です',
    1020: '購入金額が上限金額を超えています',
    1021: 'サポートされていない通貨です! ',
    1022: '取引ペアが収集されました',
    1023: '設定を繰り返さないでください',
    1024: 'ファンドのパスワードの長さが無効です',
    1025: '契約は存在しません',
    1026: '予測の方向が間違っています',
    1027: 'ユーザーウォレットが存在しません',
    1028: 'アカウントまたはパスワードのエラー',
    1029: 'アカウントが有効化/無効化されていません。カスタマー サービスにお問い合わせください。',
    1030: '最初に実名認証を完了してください',
    1031: '取引パスワードを設定してください',
    1032: '実名認証は完了しました。再認証はありません',
    1033: '同じID番号は一度しか認証できません',
    1035: '古いパスワードが間違っています',
    1036: 'リチャージ認証情報をアップロードしてください',
    1037: '正しいトップアップ金額を入力してください',
    1038: 'アカウントが存在しません',
    1039: 'パスワードが間違っています',
    1040: '古いパスワードが間違っています',
    1041: '取引パスワードが間違っています。再入力してください',
    1042: '招待コードエラー',
    1043: '異常な出金アドレス',
    1065: '現在の注文は審査中です。再度送信しないでください。',
    3000: 'ログインがタイムアウトしました',
    888: '実名認証をお願いします',
  },
  //new
  rate: '収益性',
  mode: '取引モデル',
  num: 'オープンポジション数量',
  openTime: '開始時間',
  phoneNi: '携帯電話の認証コードを入力してください',
  phonePlaceholder: '電話番号を入力してください',
  inputphonecode: '携帯電話の認証コードを入力してください',
  phone: '電話番号',
  phoneVcode: '携帯電話認証コード',
  sendSucc: '正常に送信されました',
  cScore: 'クレジットスコア',
  wallet: '財布',
  searchMp: '暗号通貨の名前を検索する',
  clickUploadId: 'クリックして証明写真をアップロードしてください',
  modifyFp: '調整',
  confirmFPassword: 'アセットパスワードの確認',
  pleaseSetYourNewFundPassword: 'ファンドのパスワードを設定してください',
  nosamefpassword: 'ファンドのパスワードが間違っています。もう一度確認してください.',
  forgotFPassword: '取引パスワードをお忘れですか?',
  korean: '한국어',
  turkish: 'Türkçe',
};
