import { createApp } from 'vue';
import App from './App.vue';
import { i18n } from '/@/i18n';
import router from '/@/router';
import store from '/@/store';
import VueClipBoard from 'vue-clipboard2'
// import { Toast } from "vant";
// import { StyleProvider, Themes } from '@varlet/ui';
// import { PullRefresh } from 'vant';
import 'normalize.css';
import 'virtual:windi.css';
import 'virtual:windi-devtools';
import './assets/font/iconfont.css';
import './assets/app.css';
import './styles/index.scss';

const app = createApp(App);
window.global ||= window;

app.config.warnHandler = () => null
// 注册上来加载
// app.use(PullRefresh);
// 路由
app.use(router);

// 国际化
app.use(i18n);

// app.use(Toast)

// 状态管理
app.use(store);
app.use(VueClipBoard);
app.mount('#app');

// 设置varle组件位暗黑模式
// StyleProvider(Themes.dark);
