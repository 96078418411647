<script lang="ts">
import { defineComponent } from 'vue'
export default defineComponent({
  name: 'QuotationPage',
})
</script>
<template>
  <div class="lang">
    <!-- 头部tabbar -->
    <div class="langTabbar">
      <div class="header">
        <var-icon @click="$router.back()" name="chevron-left" :size="30" style="padding-left: 5px" />
        <span style="font-weight: 600; font-size: 16px"> {{ $t('languageSettings') }}</span>
      </div>
    </div>
    <!-- under -->
    <van-radio-group v-model="checked" class="mt-2" @change="switchLang">
      <!-- 简体中文-->
      <!-- <van-cell :title="$t('simplifiedChinese')" clickable class="mb-2" @click="checked = 'zh_CN'">
        <template #right-icon>
          <van-radio name="zh_CN" /> -->
      <!-- <van-icon size="20" color="#148DE6" name="success" /> -->
      <!-- </template>
      </van-cell> -->

      <!-- 繁体中文 -->
      <!-- <van-cell :title="$t('traditionalChinese')" clickable @click="checked = 'zh_TC'">
        <template #right-icon>
          <van-radio name="zh_TC" />
        </template>
      </van-cell> -->

      <!-- 英文 -->
      <van-cell :title="$t('english')" clickable @click="checked = 'en_US'">
        <template #right-icon>
          <van-radio name="en_US" />
          <!-- <van-icon size="20" color="#148DE6" name="success" /> -->
        </template>
      </van-cell>

      <!-- 阿拉伯语 -->
      <!-- <van-cell :title="$t('arabic')" clickable @click="checked = 'ar_SA'">
        <template #right-icon>
          <van-radio name="ar_SA" />
        </template>
      </van-cell> -->

      <!-- 韩语 -->
      <van-cell :title="$t('korean')" clickable @click="checked = 'ko_KR'">
        <template #right-icon>
          <van-radio name="ko_KR" />
        </template>
      </van-cell>

      <!-- 日语 -->
      <!-- <van-cell :title="$t('japanese')" clickable @click="checked = 'ja_JP'">
        <template #right-icon>
          <van-radio name="ja_JP" />
        </template>
      </van-cell> -->

      <!-- 法语 -->
      <van-cell :title="$t('French')" clickable @click="checked = 'Fr_US'">
        <template #right-icon>
          <van-radio name="Fr_US" />
        </template>
      </van-cell>

      <!-- 西班牙语 -->
      <van-cell :title="$t('Spanish')" clickable @click="checked = 'es_ES'">
        <template #right-icon>
          <van-radio name="es_ES" />
        </template>
      </van-cell>

      <!-- 德语 -->
      <van-cell :title="$t('German')" clickable @click="checked = 'de_DE'">
        <template #right-icon>
          <van-radio name="de_DE" />
        </template>
      </van-cell>

      <!-- 意大利语 -->
      <van-cell :title="$t('Italiano')" clickable @click="checked = 'it_IT'">
        <template #right-icon>
          <van-radio name="it_IT" />
        </template>
      </van-cell>

      <!-- 土耳其语 -->
      <van-cell :title="$t('turkish')" clickable @click="checked = 'tr_TR'">
        <template #right-icon>
          <van-radio name="tr_TR" />
        </template>
      </van-cell>
    </van-radio-group>
  </div>
</template>

<script lang="ts" setup name="QuotationPage">
  import { useAppStore } from '/@/store//modules/app';
  import { ref } from 'vue';
  import { useRouter } from 'vue-router';
  const router = useRouter();
  const appStore = useAppStore();
  const checked = ref(appStore.ulang);
  function switchLang(val: string) {
    appStore.setULang(val);
    router.back();
  }
</script>
<style lang="scss" scoped>
  @import './css/lang.scss';
</style>
