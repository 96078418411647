import request from '/@/utils/useAxiosApi';

// 查询盘口
export function getDiskport(params: { symbol: string }) {
  return request.post('swap/exchange-plate-mini', params);
}

// 提交委托
export interface AddEntrustParams {
  symbol: string;
  amount: string;
  price: string;
  type: string;
  direction: string;
}
export function addEntrust(params: AddEntrustParams) {
  return request.post('exchange/order/add', params);
}

// 获取交易币列表
export function getCoinList() {
  return request.post('swap/symbol-thumb');
}

// 获取所有钱包
export function getWalletList(wallet?: string) {
  return request.post('uc/asset/wallet' + (wallet ? `/${wallet}` : ''));
}

interface FavorParams {
  symbol: string;
}

// 添加自选
export function addFavor(params: FavorParams) {
  return request.post('uc/favor/add', params);
}

// 自选列表
export function getFavorList() {
  return request.post('uc/favor/find');
}

// 删除自选
export function deleteFavor(params: FavorParams) {
  return request.post('uc/favor/delete', params);
}
// 获取当前委托
export function getOrderList(params) {
  return request.post('exchange/order/personal/current', params);
}

// 获取当前委托
export function getOrderHistory(params) {
  return request.post('exchange/order/personal/history', params);
}

// 获取当前委托
export function cancelOrder(id?: string) {
  return request.post('exchange/order/cancel' + (id ? `/${id}` : ''));
}

// 获取深度数据
export function getPlateFull(params: FavorParams) {
  return request.post('option/exchange-plate-full', params);
}


// 获取深度数据
export function getOptionCoinList() {
  return request.post('option/coin/coin-list');
}
