export function hideEmail(email) {
  if (String(email).indexOf('@') > 0) {
    let newEmail,
      str = email.split('@'),
      _s = '';
    if (str[0].length > 4) {
      _s = str[0].substr(0, 2);
      for (let i = 0; i < str[0].length - 4; i++) {
        _s += '*';
      }
      _s += str[0].substr(str[0].length - 2, str[0].length - 2);
    } else {
      _s = str[0].substr(0, 1);
      for (let i = 0; i < str[0].length - 1; i++) {
        _s += '*';
      }
    }
    newEmail = _s + '@' + str[1];
    return newEmail;
  } else {
    return email;
  }
}

export function formatDate(value: string | number | Date) {
  const date = new Date(value);
  const y = date.getFullYear();
  let MM = (date.getMonth() + 1) as number;
  MM = MM < 10 ? (('0' + MM) as unknown as number) : MM;
  let d = date.getDate();
  d = d < 10 ? (('0' + d) as unknown as number) : d;
  let h = date.getHours();
  h = h < 10 ? (('0' + h) as unknown as number) : h;
  let m = date.getMinutes();
  m = m < 10 ? (('0' + m) as unknown as number) : m;
  let s = date.getSeconds();
  s = s < 10 ? (('0' + s) as unknown as number) : s;
  return y + '-' + MM + '-' + d + ' ' + h + ':' + m + ':' + s;
}

export function mySetInterval(fn, interval) {
  let timerId; // 定时器 ID
  let isRunning = false; // 定时器是否在运行中的标志

  function repeat() {
    fn();
    timerId = setTimeout(repeat, interval);
  }

  function start() {
    if (!isRunning) {
      isRunning = true;
      repeat();
    }
  }

  function stop() {
    if (isRunning) {
      clearTimeout(timerId);
      isRunning = false;
    }
  }
  return {
    start: start,
    stop: stop,
  };
}
