import { CoinList } from '/@/types/api';
import { getFavorList, getCoinList } from '/@/api/transaction';
import { useToken } from '/@/hooks/useToken';

export function useColinList(toSymbol?: string, isFavor?: boolean) {
  const { token } = useToken();
  const symbol = ref<string>('');
  symbol.value = toSymbol || 'BTC/USDT';
  const coinList = ref<any[]>([]);
  const coinOList = ref<CoinList[]>([]);
  const currentCoin = ref();
  // const currentOCoin = ref();
  currentCoin.value = {};
  const [_fromCoin, _toCoin] = symbol.value.split('/');
  const favorItems = ref<any>();

  const fromCoin = computed(() => {
    return currentCoin.value.symbol?.split('/')[0] || _fromCoin;
  });

  const toCoin = computed(() => {
    return currentCoin.value.symbol?.split('/')[1] || _toCoin;
  });

  const coinOItems = computed(() => {
    return coinOList.value.map((v) => {
      return {
        text: v.symbol,
        value: v.symbol,
      };
    });
  });

  onActivated(() => {
    console.log("useColin onactivity");
  })

  onDeactivated(() => {
    console.log("useColin onDeactivated onDeactivated");
  })

  let timer: any = null;
  function startRefresh() {
    if (timer != null) return;
    timer = setInterval(getAllCoins, 1000);
  }

  function stopRefresh() {
    if (timer) {
      clearInterval(timer);
      timer = null;
    }
  }

  function getAllCoins() {
    getCoinList().then((res) => {
      //@ts-ignore
      coinList.value = res;
      currentCoin.value = coinList.value.find((v) => v.symbol === symbol.value) || {};
    });
  }

  getCoinList().then((res) => {
    //@ts-ignore
    coinList.value = res;
    currentCoin.value = coinList.value.find((v) => v.symbol === symbol.value) || {};
  });
  const coinItems = computed(() => {
    return coinList.value.map((v) => {
      return {
        text: v.symbol,
        value: v.symbol,
      };
    });
  });

  function getFavorListHanler() {
    // 获取我的自选
    getFavorList().then((res): void => {
      favorItems.value = res;
    });
  }
  const favorList = computed((): CoinList[] => {
    return coinList.value.filter((v) => favorItems.value?.some((v2) => v2.symbol === v.symbol));
  });

  if (isFavor && token) {
    isFavor && getFavorListHanler();
  }

  return {
    coinOItems,
    coinOList,
    coinList,
    coinItems,
    currentCoin,
    symbol,
    fromCoin,
    toCoin,
    favorList,
    getFavorListHanler,
    startRefresh,
    stopRefresh, Symbol
  };
}
