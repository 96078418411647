import { langType } from './lang-base';

export const lang: langType = {
  receivingAddress: 'Withdrawal Address',
  second: 'Sec',
  minimum: 'minimum',
  most: 'most',
  minimumSell: 'start selling',
  ceiling: 'ceiling',
  availableBalance: 'Available',
  Nomoreatmoment: 'No more at the moment',
  updateSet: 'Update',
  pleaseEnterTheAmount: 'Please enter the amount',
  emailNi: 'Enter email code',
  existingSES: 'Already have an account',
  email: 'Email',
  myinvitation: 'Invite friends',
  teamValue: 'Team Contribution',
  shareValue: 'Shared Contribution',
  allValue: 'Total Contribution',
  myBenefits: 'My Benefits',
  contribution: 'Contribution',
  contributionDetail: 'Contribution Detail',
  Dailyinterestrate: 'Daily Interest Rate',
  LendingDays: 'Staking Duration',
  Financialproductdetails: 'Staking Financial Product Details',
  Subscriptionwallet: 'Subscription Wallet',
  Subscriptionamount: 'Subscription Amount',
  SubscriptionDetails: 'Subscription method',
  RevenueDetails: 'Revenue Details',
  Expirationtime: 'Expiration time',
  Investmenttype: 'Staking Type',
  Contractnumber: 'Contract Number',
  InvestmentDetails: 'Pledge contract signing',
  Thesystemwillautomatically:
    'The system will automatically match new pledge orders with expired pledge orders. Retroactive orders will continue to receive the benefits presented by the platform. After orders that have not been resubmitted reach the expiration date, the benefits that have been presented will be destroyed.',
  Reswitchingfunction: 'Reinvestment Feature',
  description: 'Description',
  Investmentquantity: "Staking d'investissement",
  Investmentdetermination: 'Staking details',
  InvestmentOrders: 'Staking records',
  Estimatedtotalrevenue: 'Estimated total revenue',
  Redemptionmethod: 'Staking mode',
  Dailyrevenue: 'Daily Earnings',
  LockedDays: 'Staking cycle',
  Currenttotalinvestmentequivalent: 'Current Total Staking Amt (equivalent)',
  complete: 'Confirm',
  Fundinglimit: 'Pledge capital limit',
  Loanfinancing: 'Reinvestment Feature',
  Subscriptiontime: 'start time',
  Myinvestmenthistory: 'My staking record',
  Investmentdays: 'Investment days',
  Selfselectionarea: 'Self selection area',
  Quickconstructionarea: 'Quick construction area',
  Smartcontractmatching: 'Smart contract matching',
  sourceTime: 'Time',
  viewCode: 'View code',
  goWealh: 'The Way to Wealth',
  fileGo: 'Go to File',
  videoRequest: 'Video Request',
  gorequest: 'Pull request',
  proceeding: 'Proceeding',
  pwds: 'Password',
  experienceAll: 'Full Star Experience',
  experienceFive: 'Five Star Experience',
  sharing: 'Wealth sharing',
  customer: 'Customer',
  todoList: 'ToDo list',
  systemMessages: 'System messages',
  youHave: 'You have',
  totleWait: 'items to do, please handle it in a timely manner',
  totleBook: 'unread system messages',
  message: 'Message',
  officialNowGo: 'Starting Now',
  officialFinace: 'Enjoying blockchain finance has never been so simple',
  officialTechnologyLeft: 'Cutting-edge',
  officialTechnologyRight: 'technology',
  superParagraphOne:
    'Btrok International Digital Crypto Asset Trading Platform is a comprehensive digital asset trading platform, we have leading technical strength, high-quality customer service and good user experience, providing a free online exchange platform for the majority of digital currency enthusiasts.',
  superParagraphTwo:
    'Btrok International Digital Crypto Asset Trading Platform also provides blockchain services for digital currency white paper planning, encryption algorithm adjustment, digital currency research and development, and trading platform listing.',
  superParagraphThree:
    'Btrok International Digital Crypto Asset Trading Platform Overseas Operation Centers are set up in Singapore, Cambodia and Marshall.',
  superSESMessage: 'Btrok Message',
  superSES: 'Btrok',
  superFomamce: 'Contract finance, enjoy finance',
  superSecure: 'High-yield secure blockchain investment',
  TodayEarnings: "Today's earnings",
  ImmediateInvestment: 'Stake Now',
  financialManagement: 'Staking',
  Traverser: 'Traverser',
  announceList: 'Announcement',
  announceListDetail: 'Announcement Detail',
  official: 'Official',
  supervise: 'Supervisory Node',
  Source: 'Open Source',
  report: 'Report',
  more: 'More',
  deposit: 'Deposit',
  invite: 'Invite',
  lockMining: 'Lock Mining',
  riseRanking: 'Ranking List',
  dropList: 'Drop list',
  home: 'Home',
  welcomes: 'Welcome',
  gains: '24h Change',
  recentPrice: 'Latest Price',
  messageList: 'message list',
  noData: 'No Records',
  market: 'Markets',
  optional: 'Favorites',
  buy: 'buy',
  sell: 'sell',
  completed: 'Completed',
  cancel: 'Cancell',
  limitOrder: 'Limit order',
  marketOrder: 'market order',
  call: 'Bullish',
  put: 'Bearish',
  success: 'Success',
  failure: 'Failure',
  // wait: 'in Progress',
  transaction: 'Transaction',
  contract: 'Option',
  forex: 'forex',
  mine: 'Account',
  notNull: 'cannot be empty',
  optionContract: 'Option Contract',
  currentDelivery: 'Open Orders',
  historyDelivery: 'Order History',
  fee: 'Transaction Fee',
  openAmount: 'Opening Mount',
  bonusQuantity: 'Bonus Amount',
  predictionDirection: 'Forecast Direction',
  requestFailure: 'Request failed, click to reload',
  predictionResult: 'Forecast Outcome',
  submitSuccess: 'Submit successfully',
  sharePoster: 'Share poster',
  copyLink: 'Copy Link',
  saveImage: 'Save Image',
  pleaseLoginFirst: 'Please log in first',
  pleaseUseTronWallet: 'Please use the TRON wallet to open the recharge link',
  currentInterest: 'Current Interest',
  fundsSecurity: 'Funds Security',
  guarantee: 'Guarantee',
  holidayEarnings: 'Holiday earnings are uninterrupted',
  warning: 'Do not deposit any non-currency assets to the above address, otherwise the assets will not be retrieved. ',
  confirmation:
    'After you recharge to the above address, you need to confirm the entire blockchain network node. Generally, the blockchain main network will be credited after 3 network confirmations. ',
  addressChange:
    'Your recharge address will not change frequently, and you can recharge repeatedly; if there is any change, we will try our best to notify you through website announcements or emails. ',
  safetyReminder: 'Please confirm that the operating environment is safe to prevent information from being tampered with or leaked. ',
  rechargeUSDT: 'Recharge USDT',
  details: 'Details',
  dayLimit: 'days',
  mining: 'Mining earns non-stop',
  upcoming: 'coming soon',
  inProgress: 'in Progress',
  distribution: 'distributing',
  progress: 'progress',
  totalAmount: 'total activity',
  startTime: 'Start time',
  endTime: 'Ending time',
  lockingMining:
    "Lock mining is to earn mining income in the platform mining pool by entrusting USDT to the platform's super computing power mining machine",
  productHighlights: 'Product Highlights',
  withdrawal: 'Withdraw Crypto',
  dividendCycle: 'Dividend cycle',
  successfulDepositOnDay: 'The day after successful deposit',
  interestAccrual: 'start',
  example: 'Give me an example',
  profitCalculation: 'Profit Calculation',
  simplestProfitFormula: 'The simplest profit calculation formula is',
  profitRateCalculationExample: 'For example, the income is 2W, the principal is 10W, then the rate of return',
  calculationOfPrincipal:
    "For a securities account, if you don't remember the invested principal, you can calculate it based on the current total market value and current profit and loss",
  currentMarketValueExample:
    'For example, the current total market value is 11W, and the current floating win is 2W, then the principal is 11W-2W=9W, and the yield rate=(2W/9W)',
  penalty: 'Liquidated damages',
  participate: 'I want to participate',
  entrustedOrder: 'Entrusted Order',
  pledgingInProgress: 'Pledging',
  pledgingComplete: 'Pledging completed',
  earlyRedemption: 'Early Redemption',
  loading: 'Loading',
  pledgeAmount: 'pledge amount',
  dailyEarnings: 'Daily Earnings',
  earlyRedemptionDeductionHandlingFee: 'Early Redemption Deduction Handling Fee',
  earlyRedemptionAgain: 'early redemption',
  endOfContent: "It's over",
  financialProduct: 'financial product',
  fundsUnderManagement: 'funds under management',
  expectedTodayEarnings: 'Estimated earnings today',
  accumulatedEarnings: 'Accumulated profit',
  trustOrder: 'order in trust',
  buy2: 'Go to buy',
  dailyReturn: 'daily rate of return',
  period: 'period',
  switchLanguage: 'Language Switch',
  username: 'Username',
  emailPlaceholder: 'Please enter your email',
  forgotPassword: 'Forgot Password',
  passwordPlaceholder: 'Please enter your password',
  submit: 'Submit',
  registerNow: 'Register Now',
  search: 'Search',
  high: '24h High',
  low: '24h Low',
  depth: 'Depth',
  deal: 'Deal',
  buyQuantity2: 'Buy Volume',
  price: 'Price',
  quantity: 'Amount',
  sellQuantity: 'Sell Volume',
  time: 'time',
  direction: 'direction',
  inputPrice: 'Qing input price',
  tradingVolume: 'trading volume',
  entrust: 'Entrust',
  entrustPrice: 'Entrustment Price',
  entrustQuantity: 'Entrusted Quantity',
  totalEntrustment: 'Total Entrustment',
  category: 'category',
  confirm: 'Confirm',
  inputCorrectQuantity: 'Please enter the correct quantity',
  operationSuccessful: 'Operation successful',
  revoke: 'Revoke',
  entrustmentCancellationConfirmation: 'Entrustment Cancellation Confirmation',
  areYouSureToCancel: 'Are you sure to cancel the order? ',
  all: 'All',
  currentEntrustment: 'Current Entrustment',
  completed2: 'Completed',
  aboutSES: 'About Btrok',
  freeExchange: 'Provide a free online exchange platform for digital currency enthusiasts',
  modify: 'Change',
  bind: 'Bind',
  alipayAccount: 'Alipay Account',
  wechatAccount: 'WeChat Account',
  unbound: 'Unbound',
  sesPlatformOverview3:
    "Btrok Digital Asset Exchange is a global digital asset trading platform invested and developed by Singapore's MAI International Foundation. Its head office is located in Singapore and is regulated by Singapore MAS. A series of infrastructure construction work has been carried out in the global blockchain industry. As an international digital asset exchange for global investors, Btrok Digital Asset Exchange combines current industry advantages with a senior bank financial management team, a professional big data development team, the world's best blockchain development team and leading Blockchain technology service team. It aims to provide users with efficient and safe digital asset trading services.",
  sesPlatformOverview4:
    "As the world's first comprehensive digital asset trading platform, it is one of the top trading platforms in the global blockchain field. Covering the global digital asset ecological layout, it holds international licenses and is recognized by government agencies. Btrok will continue to improve in terms of technological innovation, product iteration, security risk control system, operation and customer service system. While providing global users with mainstream digital currency spot and derivatives trading services such as Bitcoin and Ethereum, Btrok will keep pace with the times and help build a complete blockchain ecosystem.",
  sesPlatformOverview5:
    'Establish a safe, experienced and free digital asset trading platform and a financial infrastructure to meet the needs of the global majority of digital currency enthusiasts, and truly build an ideal community for the exchange of virtual digital assets in the era of digital interconnection. majority of investors to provide a safe, reliable and stable integrated service trading platform.',
  bankCardNumber: 'Bank card account number',
  accountSettings: 'Account Settings',
  modifyAlipayAccount: 'Modify Alipay account',
  receiveQRCode: 'Receive two-dimensionalcode',
  fundPassword: 'Trading Password',
  pleaseEnterCorrectFundPassword: 'Please fill in the transaction password',
  pleaseEnterAlipayAccount: 'Please enter Alipay account number',
  myAssets: 'Wallet Overview',
  details2: 'Details',
  withdrawalRecords: 'Withdrawal History',
  totalAssetsApprox: 'Estimated Balance（USDT）',
  spotAccount: 'Crypto Account',
  searchAssets: 'Search Assets',
  hideZeroBalances: 'Currency Hidden as 0',
  frozen: 'Temporarily unavailable',
  toRelease: 'Waiting to be released',
  modifyBankCardNumber: 'Modify bank card account number',
  save: 'Save',
  bankName: 'account bank',
  bankCardNumberConfirm: 'Confirm card number',
  pleaseEnterBankCardNumberAgain: 'Please enter the bank card number again',
  fundPasswordPrompt: 'Fund Password',
  pleaseEnterFundPassword: 'Please enter the fund password',
  modifyFundPassword: 'Change transaction password',
  modifyingFundPassword: 'You are now changing your transaction password',
  oldFundPassword: 'Old transaction password',
  newFundPassword: 'New Trading Password',
  confirmFundPassword: 'Confirm Fund Password',
  pleaseEnterSetPassword: 'Please enter the setup password',
  pleaseEnterConfirmFundPassword: 'Please enter the confirmation fund password',
  passwordsDoNotMatch: 'Passwords do not match',
  pleaseEnterYourOldFundPassword: 'Please enter the old transaction password',
  pleaseEnterYourNewFundPassword: 'Please enter a new trading password',
  pleaseEnterA6DigitPassword: 'Please enter a 6-digit password',
  pleaseConfirmNewFundPassword: 'Please confirm your Trading Password again',
  theNewFundPasswordCannotBeTheSameAsTheOldFundPassword: 'The new fund password cannot be the same as the old fund password',
  pleaseEnterTheVerificationCode: 'Please enter the verification code',
  feedback: 'Feedback',
  yourOpinionIsVeryImportantToUs: 'Your opinion is very important to us!',
  leaveYourValuableOpinionToHelpUsImprove: 'Leave your valuable opinion to help us do better!',
  pleaseLeaveYourValuableOpinion: 'Please leave your valuable opinion',
  submissionSuccessfulThankYouForYourFeedback: 'Successful submission, thank you for your feedback',
  submissionFailed: 'Submission failed',
  setFundPassword: 'Set Trading Password',
  setYourPaymentMethod: 'Please set your payment method, please be sure to use your real-name account',
  newPassword: 'New Password',
  repeatPassword: 'repeat password',
  notLoggedIn: 'not logged in',
  welcome: 'Welcome to Btrok',
  assetManagement: 'Asset Management',
  assetFlow: 'Main Account Transaction History',
  myInvitation: 'My invitation',
  invitationLink: 'My Invitation Link',
  firstLevelFriends: 'First Level Friends',
  secondLevelFriends: 'Second Level Friends',
  partnerLevel: 'Personal Level',
  myEntrustment: 'My Entrustment',
  coinTrading: 'coin trading',
  securitySettings: 'Security Settings',
  pleaseLogInFirst: 'Please log in first',
  announcement: 'Announcement',
  changeLoginPassword: 'Reset Login Password',
  oldLoginPassword: 'Old Login Password',
  newLoginPassword: 'New Login Password',
  confirmPassword: 'Confirm the new password',
  emailVerificationCode: 'Email Verification Code',
  emailBound: 'You have bound email ',
  pleaseEnterOldLoginPassword: 'Please enter the old login password',
  pleaseEnterNewLoginPassword: 'Enter the new login password',
  makeSureTheOldAndNewPasswordsAreDifferent: 'Make sure the old and new passwords are different',
  pleaseConfirmNewLoginPassword: 'Please enter your new login password again',
  verificationCode: 'verification code',
  sendVerificationCode: 'Send Verification Code',
  pleaseConfirmInformation: 'Please confirm whether all the information is filled',
  pleaseConfirmPassword: 'Please confirm whether the passwords entered twice are the same',
  teamPerformance: 'team performance',
  currentLevel: 'current level',
  nextLevel: 'next level',
  teamNumber: 'Team number',
  totalPerformance: 'Total Performance',
  onlyForUSDT: 'Only for USDT',
  walletAddress: 'Wallet Address',
  securityReminder: 'To protect your account security, please complete real-name authentication before trading!',
  personalAvatar: 'Personal Avatar',
  isVerified: 'Check',
  realNameAuthentication: 'ID Verification',
  notVerified: 'Verify Now',
  receivingAccount: 'Payee Account',
  loginPassword: 'Login Password',
  fundsPassword: 'Trading Password',
  settingsCenter: 'Setting Center',
  aboutUs: 'About Us',
  helptext: 'Help',
  help: 'Help',
  logout: 'logout',
  confirmLogout: 'Are you sure you want to log out?',
  bindWeChatAccount: 'Bind WeChat account',
  withdrawalAddress: 'Withdrawal Address',
  available: 'Available',
  minimumWithdrawalAmount: 'Minimum Withdrawal Amount',
  amountReceived: 'Amount Received',
  transactionTime: 'Time',
  payableHandlingFee: 'Transaction Fee',
  deductibleHandlingFee: 'Fee Deduction',
  actualHandlingFee: 'Actual Fee',
  feeGenerationTime: 'The start and end time is the fee generation time',
  takeIDPhoto: 'Take and upload your ID photo',
  identityAuthentication: 'Personal Verification',
  uploadIDCardFront: 'Upload ID card front image',
  uploadIDCardBack: 'Upload ID card back image',
  photoAlbum: 'Photo Album',
  IDCardNumber: 'ID Number',
  enterIDCardNumber: 'Please enter your ID card number',
  name: 'First name/Last name',
  idea: 'Btrok Vision',
  goal: 'Btrok Target',
  depositRecord: 'Deposit History',
  withdrawal2: 'Withdraw',
  accountOpeningBranch: 'account opening branch',
  quantityInput: 'Please enter the quantity',
  singleLimit: 'Single limit',
  investmentAmount: 'Staking Amount',
  incomePrincipal: 'income/principal',
  password: 'Password',
  historicalCommission: 'Historical Commission',
  type: 'Type',
  commissionAmount: 'commissioned amount',
  filter: 'Filter',
  pleaseSelect: 'Please select',
  tradingPair: 'trading pair',
  reset: 'Reset',
  goLogin: 'Go Login',
  currency: 'Currency',
  startEndTime: 'Start and end time',
  recharge2: 'Deposit',
  withdraw: 'Withdraw',
  transfer: 'Transfer',
  fiatCurrencyPurchase: 'Fiat Currency Purchase',
  fiatCurrencySale: 'Fiat Currency Sale',
  activityReward: 'Activity Reward',
  promotionReward: 'Promotion Reward',
  dividend: 'dividend',
  vote: 'vote',
  manualRecharge: 'Return profit from activities',
  pairing: 'pairing',
  activityExchange: 'Activity exchange',
  ctcPurchase: 'CTC Buy',
  ctcSale: 'CTC sell',
  redEnvelopeSending: 'Red Envelope Sending',
  redEnvelopeReceiving: 'red envelope receiving',
  withdrawalCodeWithdrawal: 'Withdrawal code withdrawal',
  withdrawalCodeRecharge: 'Cash withdrawal code recharge',
  perpetualContractFee: 'perpetual contract fee',
  perpetualContractProfit: 'Perpetual Contract Profit',
  perpetualContractLoss: 'perpetual contract loss',
  optionContractFailure: 'Option contract failed',
  optionContractFee: 'Option contract fee',
  optionContractBonus: 'Option contract bonus',
  contractRebate: 'Contract rebate',
  levelReward: 'level reward',
  platformFeeIncome: 'Platform Fee Income',
  income: 'income',
  promotionIncome: 'Promotion Income',
  optionsPromotionIncome: 'Option Promotion Income',
  optionTeamIncome: 'Option Team Income',
  optionParityAward: 'Option Parity Award',
  investmentTeamReturn: 'Investment Team Income',
  investmentParityReturn: 'Investment Parity Income',
  investmentPromotionIncome: 'Investment Promotion Income',
  forexEntrustTrading: 'foreign exchange entrusted trading',
  forexCancelEntrustTrading: 'Foreign exchange cancellation entrusted Transaction',
  liquidationCompensation: 'Liquidation Compensation',
  lockedMiningIncome: 'Locked Mining Income',
  speedContractProfit: 'Profit from spot seconds contract',
  speedContractLoss: 'Spot contract losses',
  speedContractCommission: 'Second contract handling fee',
  CONTRACT_FAST_FREEZE: 'Spot contract lock-up',
  CONTRACT_FAST_UNFREEZE: 'Spot contract unfrozen',
  NEWACTIVITY_IN: 'Staking income',
  NEWACTIVITY_DEDUCT: 'Pledge redemption',
  NEWACTIVITY_FREEZE: 'Staking cost',
  NEWACTIVITY_UNFREEZE: 'Option Unfreeze',
  NEWACTIVITY_REDEEM_UNFREEZE: 'Option redemption unfreeze',
  confirmUploadAllImages: 'Please confirm whether all images have been uploaded',
  inputRealName: 'Please enter First name/Last name',
  idCardPhoto: 'ID Photo',
  congratulations: 'Congratulations, you have passed the review, you can trade with confidence',
  quantity2: 'Volume 24h(USDT)',
  wechatAccount2: 'Please enter WeChat account',
  realNameIdentityVerified: 'Real Name Identity Verified',
  withdrawalTime: 'Time',
  withdrawalAmount: 'Amount',
  creditting: 'Under Review',
  waitingmoney: 'being processed',
  dztime: 'Time',
  rushaddress: 'Deposit Address',
  rushnumber: 'Amount',
  //邮箱注册
  regisertitle: 'Email Registration',
  inputemailcode: 'Enter email verification code',
  inputloginpassword: 'Password(6+ chars,numbers,upper/lower letters)',
  inputreloginpassword: 'Please enter the password again',
  inputinvite: 'Please enter invitation code',
  iagree: 'I have read and agree',
  useragreement: 'User Agreement',
  nowlogin: 'Log In',
  sendcode: 'Send Verification Code',
  rightemailformat: 'Please enter the correct email format',
  passwordtip: 'Password must contain at least 6 digits',
  nosamepassword: 'The two passwords are different. Please check',
  noagree: 'User agreement not accepted',
  emailverification: 'Email Verification',
  // 划转
  amount: 'Amount',
  overturn: 'Transfer',
  overturntitle: 'Asset Transfer',
  from: 'From',
  to: 'To',
  swapcurrency: 'Perpetual Contract Currency',
  overturnplaceholder: 'Please enter the transfer amount',
  selectaccount: 'Select Account',
  balance: 'Current Available Balance',
  // 钱包页面
  contractaccount: 'Contract Account',
  invitecode: 'Invitation Code',
  notset: 'Go to settings',
  day: 'Days',
  contactservice: 'Contact customer service',
  appname: 'Btrok APP',
  download: 'DownLoad APP',
  orderByHand: 'Mannual Order',
  orderByAuto: 'Smart Order',
  trevenue: 'Total Revenue',
  pincome: 'Promotion Benefit',
  tincome: 'Team Income',
  // Dailydistribution: 'Daily allocation',
  SearchCurrency: 'Search Contract Number',
  uploaded: 'uploaded',
  confirmLoginPassword: 'confirm',
  PleaseEnter: 'Please enter the contract number',
  Ordinarysingle: 'Regular Order',
  Unabletochangeorder: 'Unable to change the order status',
  Yourbalanceisinsufficient: 'Your balance is insufficient',
  Resumptionhasbeenactivated: 'Reinvestment is now active',
  orderCount: 'Staking Order Quantity',
  Resumptionhasbeenclosed: 'Reinvestment is now disabled',
  DailyDistributions: 'Daily distribution of earnings, return of principal after the lock-up period',
  youare: 'You are',
  disabling: ' disabling',
  enabling: ' enabling',
  theAutomaticReinvestmentFeatur: ' the automatic reinvestment feature',
  nth: 'No.',
  phase: '',
  currentForecastPeriod: 'Current Forecast Period',
  maximum: 'max',
  openingPrice: 'Opening Price',
  currentPrice: 'Current Price',
  profitSharing: 'Profit Sharing',
  CurrentTradingIsRestricted: 'Current trading is restricted',
  ToEnsureTheSsecurityOfYourAssetsPleaseCarefullyVerifyTheReceivingWalletAddressBeforeMakingAnyDeposits:
    'To ensure the security of your assets, please carefully verify the receiving wallet address before making any deposits:',
  PleaseProceedWithTheDepositOnlyAfterVerifyingThatEverythingIsCorrect:
    '. Please proceed with the deposit only after verifying that everything is correct.',
  PleaseDoNotDepositAnyAssetsOtherThanTheSpecifiedCryptocurrencyToTheAddressMentionedAboveOtherwiseYourAssetsWillNotBeRecoverable:
    'Please do not deposit any assets other than the specified cryptocurrency to the address mentioned above. Otherwise, your assets will not be recoverable.',
  AfterYouMakeADepositToTheMentionedAddressItRequiresConfirmationFromTheEntireBlockchainNetworkTypicallyItTakesAround30SecondsForTheFundsToBeCreditedToYourAccountAfterThreeConfirmationOnTheBlockchainMainnet:
    'After you make a deposit to the mentioned address, it requires confirmation from the entire blockchain network. Typically, it takes around 30 seconds for the funds to be credited to your account after three confirmations on the blockchain mainnet.',
  PleaseEnsureTheSecurityOfYourElectronicDevicesToPreventAnyTamperingOrLeakageOfInformation:
    'Please ensure the security of your electronic devices to prevent any tampering or leakage of information.',
  WithdrawalsAreCurrentlyDisabled: 'Withdrawals are currently disabled',
  CurrentWithdrawalAddress: 'Please confirm if the current withdrawal address ',
  isYourWalletAddress: ' is your wallet address',

  // 复制链接
  LinkCopiedSuccessfully: 'Link copied successfully',
  LinkCopiedFailed: 'Link copy failed',

  other: 'Other',
  SavePicture: ' Save Picture',
  SaveLink: ' Save Link',
  languageSettings: 'Language',
  simplifiedChinese: '简体中文',
  traditionalChinese: '繁体中文',
  arabic: 'عربي',
  english: 'English',
  japanese: '日本語',
  French: 'Français',
  Spanish: 'Español',
  German: 'Deutsch',
  Italiano: 'Italiano',
  TotalDailyLoss: 'Total Profit',
  TotalExpenditureToday: 'Total Order Amount Today',
  TotalIncomeToday: 'Total Profit Today',
  CurrentDelivery: 'Current Order',
  HistoricalDelivery: 'Order History',
  InitialAmount: 'Order Amount',
  ForecastDirection: 'Forecast Direction',
  BonusAmount: 'Bonus Amount',
  TransactionFee: 'Transaction Fee',
  OrderDuration: 'Order Duration',
  Status: 'Status',
  SelectCurrency: 'Select Currency',
  EnterAmount: 'Enter Amount',
  BUY: 'Buy Long',
  SELL: 'Sell Short',
  WIN: 'Success',
  LOSE: 'Failure',
  Amount: 'Amount',
  expectedReturn: 'expected return',
  service: 'Support',
  untrade: 'Currently untradeable',
  netError: 'The network is out of business!',
  questError: 'There was an error in the request, please try again later!',
  failVerified: 'Authentication failed',
  tiprealverified: 'Real name verification, please contact customer service',
  uploadImg: 'upload credentials',
  collected: 'collected',
  canceld: 'Cancelled',
  placeholderAddress: 'Enter/paste address or ENS name',
  bindAccountTitle: 'Blockchain Address',
  marketTitle: 'Markets Overview',
  regiserSuccess: 'Register was successful',
  setSuccess: 'successfully set',
  updateAddress: 'I want to change the withdrawal address',
  doingRealName: 'The real-name authentication is in progress, please wait patiently',
  updateSuccess: 'Successfully modified',
  uploadSuccess: 'Uploaded successfully',
  forgetLpass:
    'If you forget your password, please contact customer service to check the information and process it to reset your login password',
  forgetApass:
    'If you forget your password, please contact customer service to verify the information and reset your transaction password.',
  updateAddressTip:
    'To modify the payment address, please contact customer service to verify the personal information and reset the payment address.',
  a48h: 'Withdrawal has been accepted, the withdrawal application will be processed within 48 hours, please wait patiently',
  r48h: 'The recharge has been accepted, the recharge order will be processed within 48 hours, please wait patiently',
  accepted: 'Accepting',
  notremind: "Don't remind again",
  serviceCode: {
    0: ' Success ',
    500: ' Failure ',
    501: ' Registration failure ',
    502: ' Send failed ',
    503: ' Add failed ',
    504: ' Query failed ',
    600: ' This currency does not support withdrawal ',
    1001: ' Verification code does not exist ',
    1003: ' The number of withdrawals is greater than the maximum ',
    1004: ' The amount of money withdrawn is less than the minimum ',
    1005: ' Insufficient available balance ',
    1006: ' Wallet is locked ',
    1007: ' The real-name authentication is currently under review. Please wait patiently until the review is passed before proceeding.',
    1008: 'Please set a transaction password first',
    1009: ' Incorrect fund password ',
    1010: ' Unable to lock assets ',
    1011: ' This mailbox has been bound ',
    1012: ' This user name already exists ',
    1013: ' No user ',
    1014: 'Registration activation email has been sent to your email, please do not repeat the request',
    1015: ' Please bind email first ',
    1016: ' Order amount cannot be 0',
    1017: ' Your current account is disabled, please contact customer service! ',
    1018: ' Activity does not exist ',
    1019: ' Purchase amount is less than the minimum amount ',
    1020: ' Purchase amount greater than maximum amount ',
    1021: ' Unsupported currency! ',
    1022: ' Trading pairs have been collected ',
    1023: ' Do not repeat Settings ',
    1024: ' Fund password length is not legal ',
    1025: ' The contract does not exist ',
    1026: ' Wrong direction of prediction ',
    1027: ' User wallet does not exist ',
    1028: ' Incorrect account number or password ',
    1029: ' This account is inactive/disabled, please contact customer service ',
    1030: 'Dear user, Staking is only available to accounts with ID Verification. Please complete it first.',
    1031: 'Please set a transaction password',
    1032: 'Real-name authentication has been done, no re-authentication',
    1033: 'The same ID number can only be authenticated once',
    1035: 'The old password is wrong',
    1036: 'Please upload recharge credentials',
    1037: 'Please enter the correct recharge amount',
    1038: 'Account does not exist',
    1039: 'The password is incorrect',
    1040: 'The old transaction password is incorrect',
    1041: 'The transaction password is wrong, please re-enter',
    1042: 'invitation code error',
    1043: 'Abnormal withdrawal address',
    1065: 'The current order is under review, please do not submit it again.',
    3000: ' Login timeout ',
    888: 'All new and existing users are required to complete Identity Verification to access the full range of products and services.',
  },
  //new
  rate: 'ROI',
  mode: 'Transaction mode',
  num: 'Opening quantity',
  openTime: 'Open time',
  phoneNi: 'Please enter the mobile phone verification code',
  phonePlaceholder: 'Please enter your phone number',
  inputphonecode: 'Please enter the mobile phone verification code',
  phone: 'Phone number',
  phoneVcode: 'Code de vérification du téléphone portable',
  sendSucc: 'Bien envoyé',
  cScore: 'Credit score',
  wallet: 'Wallet',
  searchMp: 'Search Coin Name',
  clickUploadId: 'Haz clic para subir tu foto de identificación',
  modifyFp: 'Settings',
  confirmFPassword: 'Confirm Trading Password',
  pleaseSetYourNewFundPassword: 'Please set your Trading Password',
  nosamefpassword: 'The two trading passwords are different. Please check',
  forgotFPassword: 'Forgot your trading password?',
  korean: '한국어',
  turkish: 'Türkçe',
};
