import { Balance } from '/@/types/balance';
import { getWalletList } from '/@/api/transaction';
import { useToken } from '/@/hooks/useToken';
// import { getowalletall } from '/@/api/user';
export default function useBalance(coin: string) {
  const { token } = useToken();
  const balanceInfo = ref<Balance>();
  const balanceUsdt = ref<Balance>();
  const balanceOusdt = ref();
  // const allCount = ref(0);
  function getWalletListHanler(coinType) {
    // 获取钱包
    getWalletList(coinType || coin).then((res): void => {
      balanceInfo.value = res.data;
    });
    // 获取钱包
    getWalletList('USDT').then((res): void => {
      balanceUsdt.value = res.data;
    });

    // getowalletall().then((res): void => {
    //   console.log(res);
    //   allCount.value = getAll(res.data);
    //   balanceOusdt.value = allCount.value.toFixed(4);

    //   console.log(balanceOusdt.value, 'sssssssss');
    // });
    // function getAll(data) {
    //   let sumusd = 0;
    //   data.forEach((_coin) => {
    //     // sumusd += (_coin.balance + _coin.frozenBalance) * _coin.coin.usdRate;
    //     sumusd += _coin.balance * _coin.coin.usdRate;
    //   });
    //   return sumusd;
    // }
  }

  token && getWalletListHanler(coin);

  return {
    balanceInfo,
    balanceUsdt,
    balanceOusdt,
    getWalletListHanler,
  };
}
