// import { useAxios } from '@vueuse/integrations/useAxios';
import axios from 'axios';
import md5 from 'md5';
import { showToast } from 'vant';
import 'vant/es/toast/style';
import { useUserStore } from '/@/store/modules/user';
import { useAppStore } from '/@/store/modules/app';
import router from '/@/router';
import { i18n } from '/@/i18n';

const config = {
  withCredentials: false,
  timeout: 20000,
  baseURL: import.meta.env.VITE_BASE_URL,
};
// create an axios instance
// axios.defaults.headers.post['Content-Type'] = 'application/x-www-form-urlencoded';

const instance = axios.create(config);

// request interceptor
instance.interceptors.request.use(
  (config) => {
    const appStore = useAppStore();
    const userStore = useUserStore();
    if (!config?.url?.includes('announcement/onenotice')) {
      appStore.loading = true;
    }
    // do something before request is sent
    config.headers.lang = appStore.ulang == 'Spanish' ? 'es_ES' : appStore.ulang;
    if (config.url?.indexOf('uc/ancillary/more/help') != -1) {
      config.headers.lang = appStore.ulang !== 'zh_CN' ? 'en_US' : 'zh_CN';
    }
    // console.log(userStore.token);

    if (config?.url?.includes('uc/upload/oss/base64')) {
      config.headers['Content-Type'] = 'application/json';
    } else {
      config.headers['Content-Type'] = 'application/x-www-form-urlencoded';
    }

    if (userStore.token) {
      config.headers['x-auth-token'] = userStore.token;
      config.headers['access-auth-token'] = md5(userStore.token);
    }
    return config;
  },
  (error) => {
    // do something with request error
    console.log(error); // for debug
    return Promise.reject(error);
  },
);

// response interceptor
instance.interceptors.response.use(
  /**
   * If you want to get http information such as headers or status
   * Please return  response => response
   */

  /**
   * Determine the request status by custom code
   * Here is just an example
   * You can also judge the status by HTTP Status Code
   */
  (response) => {
    const res = response.data;
    const appStore = useAppStore();
    appStore.loading = false;
    // if the custom code is not 200, it is judged as an error.
    if (!res.code) {
      return res;
    } else if (res.code !== 0 && !res.sort) {
      if (res.code === 4000 || res.code === 3000) {
        const userStore = useUserStore();
        userStore.userInfo = null;
        router.replace('/login');
      } else {
        const code = res.code;
        const message = 'serviceCode.' + code;
        showToast(i18n.global.t(message) || i18n.global.t('questError'));
      }
      return Promise.reject(res.code);
    } else {
      return res;
    }
  },
  (error) => {
    const appStore = useAppStore();
    appStore.loading = false;
    let message;
    if (error.name === 'AxiosError') {
      message = error.message;
    } else {
      message = error.response.data.message;
      router.push('/login');
    }
    showToast(message || i18n.global.t('netError'));
    return Promise.reject(error.message);
  },
);

/**
 * reactive useFetchApi
 */

export default instance;
