<script lang="ts">
import { defineComponent } from 'vue'
export default defineComponent({
  name: 'records',
})
</script>
<template>
  <div class="pb-20px">
    <!-- 行情title -->
    <var-app-bar
      :title="$t('Investmentdetermination')"
      color="#F7F7F7"
      title-position="center"
      style="z-index: 10; color: #333333; font-weight: 600"
    >
      <template #left>
        <div @click="$router.back">
          <van-icon class="text-gray-500" name="arrow-left" size="20" />
        </div>
      </template>
    </var-app-bar>
    <!-- content -->
    <div class="content p-40px pt-140px">
      <div class="conTop">
        <!-- 投资金额 -->
        <div class="h-auto rounded-xl w100 p-20px bg-gray-100 mb-25px">
          <!-- investment title -->
          <div class="flex items-center justify-center pb-0px">
            <span class="font-600">{{ $t('investmentAmount') }}</span>
          </div>
          <!-- + - 框 -->
          <div class="mt-20px h-100px">
            <div class="relative h-80px re addDel bg-white flex justify-between rounded-sm items-center px-30px">
              <div @click="del" class="w-60px h-60px rounded-full bg-gray-200 flex items-center justify-center">
                <van-icon class="text-gray-500 font-600" name="minus" size="20" />
              </div>
              <div class="text-center" style="flex-grow: 1">
                <span class="text-center font-600 text-lg">
                  <!-- @input="balanceValue" -->
                  <van-field :center="true" @input="balanceValue" @change="changeBalanceValue" type="number" v-model="_investment" />
                </span>
              </div>
              <div style="position: absolute; right: 70px">
                <span class="font-600">{{ data.itemDetail.symbol }}</span>
              </div>
              <div @click="add" class="w-60px h-60px rounded-full bg-gray-200 flex items-center justify-center">
                <van-icon class="text-gray-500 font-600" name="plus" size="20" />
              </div>
            </div>
          </div>
          <!-- balance & code  -->
          <div class="balance flex justify-between items-center h-5" style="font-size: 12px">
            <span>
              <span>{{ $t('balance') }}: </span>
              <span class="mr-10px">{{ data.balance.toFixed(4) }}</span
              >USDT
            </span>
            <!-- <span>{{ codefixed }}</span> -->
          </div>
          <!-- 投资数量 -->
          <div class="balance flex justify-between items-center h-5 mt-1" style="font-size: 12px">
            {{ $t('Investmentquantity') }}: {{ minInves }} ~ {{ Investquantity }} {{ data.itemDetail.symbol }}
          </div>
        </div>
        <!-- 锁定天数 -->
        <div class="h-auto rounded-xl w100 p-20px bg-gray-100 mb-25px">
          <div class="flex items-center justify-between pb-20px">
            <span class="font-600 mr-5" style="width: 30%">{{ $t('LockedDays') }}</span>
            <span class="text-gray-500">{{ data.itemDetail.lockedDays }} {{ $t('day') }}</span>
          </div>
          <div class="flex items-center justify-between pb-20px">
            <span class="font-600 mr-5" style="width: 30%">{{ $t('dailyReturn') }}</span>
            <span class="text-gray-500">{{ (data.itemDetail.releasePercent * 10000) / 100 }}%</span>
          </div>
          <div class="flex items-center justify-between pb-20px">
            <span class="font-600 mr-5" style="width: 30%">{{ $t('investmentAmount') }}</span>
            <span class="text-gray-500">
              <span class="mr-5px">{{ mytofixed(data.Investment * data.itemDetail.price, 4) }}</span
              >USDT</span
            >
          </div>
          <div class="flex items-center justify-between pb-20px">
            <span class="font-600 mr-5" style="width: 30%">{{ $t('Dailyrevenue') }}</span>
            <span class="text-gray-500">{{ Dailyrevenue }}<span class="ml-5px">USDT</span></span>
          </div>
          <div class="flex items-center justify-between pb-20px">
            <span class="font-600 mr-5" style="width: 30%">{{ $t('Redemptionmethod') }}</span>
            <span class="text-gray-500">{{ $t('DailyDistributions') }}</span>
          </div>
          <div class="flex items-center justify-between pb-0px">
            <span class="font-600 mr-5" style="width: 30%">{{ $t('Estimatedtotalrevenue') }}</span>
            <span class="text-gray-500">{{ Estimatedtotalrevenue }}<span class="ml-5px">USDT</span></span>
          </div>
        </div>
        <!-- 复投功能 -->
        <div class="Bottom">
          <div class="BottomCon mt-20px">
            <div class="h-auto rounded-xl w100 p-20px bg-gray-100 mb-25px">
              <div class="flex items-center justify-between pb-20px">
                <!-- 复投title -->
                <span class="font-600 text-me">{{ $t('Reswitchingfunction') }}</span>
                <!-- 开关 -->
                <van-switch @click="onUpdateValue()" :model-value="data.checked" active-color="#ec8920" size="medium" />
              </div>
              <div class="mt-5px">
                <div class="title">
                  <span class="text-gray-500">{{ $t('description') }}:</span>
                </div>
                <div class="contant text-gray-500 mt-10px" style="line-height: 25px">{{ $t('Thesystemwillautomatically') }}</div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- 完成 button -->
    <!-- <div class="flex justify-center" style=""> -->
    <div
      class="mx-40px"
      @click="tonewattend"
      style="
        display: flex;
        align-items: center;
        justify-content: center;
        height: 12vw;
        font-size: 16px;
        color: #fff;
        border-radius: 5px;
        background-color: #f99f05;
      "
    >
      {{ $t('complete') }}
    </div>
    <!-- </div> -->
  </div>
</template>

<script lang="ts" setup name="records">
  import { getWalletList } from '/@/api/transaction';
  import { mytofixed, dataParams } from '/@/types/Intelligent';
  import { getnewattend } from '/@/api/Intelligent';
  import { showConfirmDialog, showToast } from 'vant';
  import { reactive, computed, onMounted } from 'vue';
  import { useRouter, useRoute } from 'vue-router';
  import { useI18n } from 'vue-i18n';
  const { t } = useI18n();
  const route = useRoute();
  const router = useRouter();

  const data = reactive<dataParams>({
    itemDetail: {
      id: 0,
      symbol: '',
      releasePercent: 0,
      price: 0,
      lockedDays: 0,
      max: 0,
      min: 0,
    },
    checked: false,
    code: '',
    balance: 0,
    Investment: 0,
  });

  onMounted(() => {
    data.itemDetail = route.params as any;
    // data.itemDetail = JSON.parse(route.query.ItemData as any);
    // rechange();
  });
  const _investment = ref(0.0);
  const balanceValue = () => {
    let _s = _investment.value + '';
    if (String(_s).includes('.')) {
      let numDian = _s.toString().split('.')[1].length;
      if (numDian > 4) {
        let dIndex = _s.indexOf('.');
        _investment.value = Number(_s.substring(0, dIndex + 5));
      }
    }
  };

  const changeBalanceValue = () => {
    if (data.balance < data.itemDetail.min) {
      return showToast({
        message: t('serviceCode.1005'),
        duration: 1000,
      });
    }
    if (Number(_investment.value) > Number(Investquantity.value)) {
      data.Investment = _investment.value = Number(Investquantity.value);
    } else if (Number(_investment.value) < Number(minInves.value)) {
      data.Investment = _investment.value = Number(minInves.value);
    } else {
      data.Investment = _investment.value;
    }
  };

  // (data.balance / data.itemDetail.price).toFixed(2)
  const Investquantity = computed((): number => {
    return mytofixed(data.itemDetail.max / data.itemDetail.price, 4);
  });

  const minInves = computed((): number => {
    return mytofixed(data.itemDetail.min / data.itemDetail.price, 4);
  });

  const add = () => {
    if (data.balance < data.itemDetail.min) {
      return showToast({
        message: t('serviceCode.1005'),
        duration: 1000,
      });
    }
    if (Number(data.Investment) + 0.01 > Number(Investquantity.value)) {
      return showToast({
        message: t('Yourbalanceisinsufficient'),
        duration: 1000,
      });
    }
    data.Investment = _investment.value = mytofixed(Number(data.Investment * 100 + 1) / 100, 4);
  };

  const del = () => {
    if (data.balance < data.itemDetail.min) {
      return showToast({
        message: t('serviceCode.1005'),
        duration: 1000,
      });
    }
    if (Number(data.Investment) <= minInves.value) {
      return (data.Investment = _investment.value = minInves.value);
    }
    data.Investment = _investment.value = mytofixed(Number(data.Investment * 100 - 1) / 100, 4);
  };

  // const codefixed = computed((): String => {
  //   return data.code.substring(0, 8) + '...' + data.code.substring(data.code.length - 8);
  // });
  const Dailyrevenue = computed((): number => {
    return mytofixed(Number(data.Investment) * mytofixed(data.itemDetail.releasePercent, 4) * Number(data.itemDetail.price), 4);
  });

  const Estimatedtotalrevenue = computed(() => {
    if (Number(_investment.value) > Number(Investquantity.value)) {
      data.Investment = Number(Investquantity.value);
    } else if (Number(_investment.value) < Number(minInves.value)) {
      data.Investment = Number(minInves.value);
    }
    console.log(Number(data.Investment) * Number(data.itemDetail.releasePercent));
    return (
      Number(data.Investment) *
      Number(data.itemDetail.releasePercent) *
      data.itemDetail.lockedDays *
      Number(data.itemDetail.price)
    ).toFixed(4);
  });

  // 复投功能开关
  const onUpdateValue = () => {
    let str = t('youare') + (data.checked ? t('disabling') : t('enabling')) + t('theAutomaticReinvestmentFeatur');
    console.log(str);
    showConfirmDialog({
      // title: str,
      message: str,
      confirmButtonText: t('confirm'),
      cancelButtonText: t('cancel'),
    })
      .then(() => {
        data.checked = !data.checked;
      })
      .catch(() => {});
  };
  const tonewattend = () => {
    if (data.balance < data.itemDetail.min) {
      return showToast({
        message: t('serviceCode.1005'),
        duration: 1000,
      });
    }
    if (data.Investment <= 0) {
      return showToast({
        message: t('pleaseEnterTheAmount'),
        duration: 1000,
      });
    }
    getnewattend({
      amount: mytofixed(Number(data.Investment) * Number(data.itemDetail.price), 4),
      activityId: Number(data.itemDetail.id),
      isRedo: data.checked == true ? 1 : 0,
    })
      .then((res: any) => {
        if (res.code == 0) {
          showToast({
            message: t('serviceCode.0'),
            duration: 1000,
          });
          setTimeout(() => {
            router.push({ path: '/Intelligent/records' });
          }, 1000);
        } else {
          showToast({
            message: res.message,
            duration: 1000,
          });
        }
      })
      .catch((err) => {
        showToast({ message: t('serviceCode.' + err) });
        // showDialog({
        //   message: t('serviceCode.'+err),
        //   confirmButtonText: t('confirm'),
        // }).then(() => {
        //   router.push('/unVerified');
        // });
      });
  };

  // const rechange = () => {
  //   getrechange({
  //     unit: 'USDT',
  //   }).then((res: any) => {
  //     if (res.code == 0) {
  //       data.code = res.data;
  //     }
  //   });
  // };

  getWalletList().then((res: any) => {
    if (res.code == 0) {
      data.balance = res.data.filter((item) => {
        return item.coin.name == 'USDT';
      })[0].balance;
      if (data.balance >= data.itemDetail.min) {
        data.Investment = _investment.value = minInves.value;
      }
    }
  });
</script>

<style lang="scss" scoped>
  ::v-deep .var-app-bar__title {
    white-space: break-spaces;
    text-align: center;
  }

  ::v-deep .var-elevation--3 {
    box-shadow: none;
  }

  ::v-deep .van-field__control {
    text-align: center;
  }

  ::v-deep .van-cell {
    background-color: inherit;
  }

  .From {
    height: auto;
    padding-left: 40px;
    padding-right: 40px;
    color: #333333;

    .TZmoneyTitle {
      color: #000000;
      font-size: 30px;
      font-family: PingFangSCBold;
      font-weight: 600;
    }

    .TZmoneyC {
      font-size: 25px;
      font-family: PingFangSCBold;
      color: #777777;
    }

    .USDT {
      justify-content: space-between;
      align-items: center;
      background: rgba($color: #00133c, $alpha: 0.05);
      // opacity: 0.05;
      // box-shadow: 4px 0px 4px 0px #00133c;
      border-radius: 7px 7px 7px 7px;
      height: 64px;

      .USDTIcon {
        font-size: 31px;
        font-weight: 600;
        font-family: PingFangSCBold;
        color: #000000;
      }
    }

    .balance {
      border-bottom: 1px solid rgba($color: #333333, $alpha: 0.3);
    }

    .TZlistItemCon {
      border-bottom: 1px solid rgba($color: #999999, $alpha: 0.3);
    }
  }
</style>
