<script lang="ts">
import { defineComponent } from 'vue'
export default defineComponent({
  name: 'QuotationPage',
})
</script>
<template>
  <div style="height: 100vh; background-color: #fff">
    <!-- 头部tabbar -->
    <div class="withdrawalTabbar">
      <div class="header">
        <var-icon @click="$router.back()" name="chevron-left" :size="30" style="padding-left: 5px" />
        <span style="font-weight: 600; font-size: 16px">{{ $t('withdrawalRecords') }}</span>
      </div>
    </div>

    <div style="margin-top: 30px">
      <van-list
        ref="list"
        :loading-text="$t('loading')"
        v-model:loading="historyLoading"
        v-model:error="historyError"
        :error-text="$t('requestFailure')"
        @load="histroyListHandle"
        :finished="historyFinished"
      >
        <div v-if="historyList.length">
          <div class="listVant" v-for="item in historyList" :key="item.id">
            <!-- 币种 -->
            <div class="flex justify-between items-center mb-3">
              <div style="font-size: 18px; width: 35%"> {{ item.coin.name }} </div>
              <span :class="colors[item.status]" style="text-align: right; word-wrap: break-word; width: 65%">{{
                status[item.status]
              }}</span>
            </div>
            <!-- 提现时间 -->
            <div class="flex justify-between mb-2">
              <div class="text-gray-500 text-xs" style="width: 35%"> {{ $t('withdrawalTime') }} </div>
              <span style="text-align: right; word-wrap: break-word; width: 65%">{{ handleTime3(item.createTime) }}</span>
            </div>
            <!-- 提币地址 -->
            <div class="flex justify-between mb-2">
              <div class="text-gray-500 text-xs" style="width: 35%"> {{ $t('withdrawalAddress') }} </div>
              <span style="text-align: right; word-wrap: break-word; width: 65%">{{ item.address }}</span>
            </div>
            <!-- 提币数量 -->
            <div class="flex justify-between mb-2">
              <div class="text-gray-500 text-xs" style="width: 35%"> {{ $t('withdrawalAmount') }} </div>
              <span style="text-align: right; word-wrap: break-word; width: 65%">{{ item.totalAmount }}</span>
            </div>
            <!-- 手续费 -->
            <div class="flex justify-between mb-2">
              <div class="text-gray-500 text-xs" style="width: 35%"> {{ $t('fee') }} </div>
              <span style="text-align: right; word-wrap: break-word; width: 65%">{{ item.fee }}</span>
            </div>
          </div>
        </div>
        <div v-else class="emptyImg">
          <img src="/img/symbol/empry.png" alt="" />
        </div>
      </van-list>
    </div>
  </div>
</template>

<script setup lang="ts" name="QuotationPage">
import { useList } from '/@/hooks/useList';
import { withdrawlRecord } from '/@/api/user';
import { useI18n } from 'vue-i18n';
import dayjs from 'dayjs';
function handleTime3(timer: any) {
  return dayjs(timer).format('YYYY-MM-DD HH:mm:ss ');
}
const { t } = useI18n();
const status = ref([t('waitingmoney'), t('waitingmoney'), t('failure'), t('success')]);
const colors = ref(['', 'text-yellow-500', 'text-red-500', 'text-green-500']);
const {
  listError: historyError,
  listLoading: historyLoading,
  listFinished: historyFinished,
  listQuery: historyQuery,
  listData: historyList,
  getList: histroyListHandle,
} = useList(withdrawlRecord);
historyQuery.page = 0;
</script>
<style lang="scss" scoped>
.emptyImg {
  width: 100%;
  background: rgb(255, 255, 255);
  height: 100vh;
  position: relative;

  img {
    position: absolute;
    left: 50%;
    top: 20%;
    margin-left: -30%;
    width: 402px;
    height: 302px;
  }
}
//导航头部
.withdrawalTabbar {
  height: 90px;
  display: flex;
  align-items: center;
  background: #f5f5f5;
  .header {
    display: flex;
    align-items: center;
    span {
      position: absolute;
      left: 50%;
      transform: translateX(-50%);
    }
  }
}
//列表
.listVant {
  width: 93%;
  margin: 0 auto;
  border-bottom: 1px solid #f1f1f1;
  margin-top: 20px;
}
.nut-picker-roller-mask {
  background-image: null;
  /* background-color: var(--popup-content-background-color) */
}
</style>
