import { langType } from './lang-base';

export const lang: langType = {
  receivingAddress: 'عنوان الدفع',
  second: 'ثانية',
  minimum: 'الحد الأدنى',
  most: 'الأكثر',
  minimumSell: 'البيع الأدنى',
  ceiling: 'الحد الأقصى',
  availableBalance: 'التوازن المتاح',
  Nomoreatmoment: 'لا أكثر',
  updateSet: 'تحديث الإصدار الجديد',
  pleaseEnterTheAmount: 'الرجاء إدخال المبلغ',
  SearchCurrency: 'البحث عن رقم العقد',
  Unabletochangeorder: 'غير قادر على تغيير حالة الطلب',
  Yourbalanceisinsufficient: 'رصيدك غير كاف',
  Ordinarysingle: 'ترتيب عادي',
  DailyDistributions: 'يتم توزيع الأرباح يوميًا ، وسيعاد رأس المال بعد انتهاء فترة الإغلاق',
  Resumptionhasbeenactivated: 'إعادة الاستثمار قيد التشغيل',
  Resumptionhasbeenclosed: 'إعادة الاستثمار مغلقة',
  orderCount: 'كمية الطلب',
  PleaseEnter: 'الرجاء إدخال رقم العقد',
  emailNi: 'الرجاء إدخال رمز التحقق من البريد الإلكتروني',
  existingSES: 'حساب Btrok الحالي',
  email: 'بريد',
  myinvitation: 'دعوتي',
  teamValue: 'مساهمة الفريق',
  shareValue: 'مشاركة المساهمة',
  allValue: 'إجمالي قيمة المساهمة',
  myBenefits: 'مساهمتي',
  contribution: 'مساهمة',
  contributionDetail: 'تفاصيل قيمة المساهمة',
  Dailyinterestrate: 'تقييم يومي',
  LendingDays: 'أيام القرض',
  Financialproductdetails: 'تفاصيل المنتج المالي',
  Subscriptionwallet: 'محفظة الاشتراك',
  Subscriptionamount: 'مبلغ الاشتراك',
  SubscriptionDetails: 'تفاصيل الاشتراك',
  Expirationtime: 'تاريخ انتهاء الصلاحية',
  Investmenttype: 'نوع الاستثمار',
  InvestmentDetails: 'تفاصيل الاستثمار',
  Thesystemwillautomatically:
    'سيقوم النظام تلقائيًا بمطابقة أوامر الإدارة المالية الجديدة لأوامر الاستثمار المستحقة. ستستمر الطلبات المعاد استثمارها في تلقي حقوق FTH التي تقدمها المنصة. بعد انتهاء صلاحية الطلبات غير المستثمرة ، سيتم إتلاف حقوق FTH الممنوحة.',
  Reswitchingfunction: 'وظيفة إعادة الاستثمار',
  description: 'يوضح',
  Investmentquantity: 'مبلغ الاستثمار',
  Investmentdetermination: 'تأكيد الاستثمار',
  RevenueDetails: 'تفاصيل الإيرادات',
  InvestmentOrders: 'أمر الاستثمار',
  Estimatedtotalrevenue: 'إجمالي الفائدة المقدرة',
  Redemptionmethod: 'طريقة الفداء',
  Dailyrevenue: 'الدخل اليومي',
  LockedDays: 'أيام القفل',
  Currenttotalinvestmentequivalent: 'إجمالي الاستثمار الحالي (ما يعادله)',
  complete: 'ينهي',
  Fundinglimit: 'مبلغ التمويل',
  Contractnumber: 'رقم العقد',
  Loanfinancing: 'الاقتراض والإدارة المالية',
  Subscriptiontime: 'وقت الاشتراك',
  Myinvestmenthistory: 'تاريخ الاستثمار الخاص بي',
  dailyReturn: 'معدل العائد اليومي',
  Investmentdays: 'أيام الاستثمار',
  Selfselectionarea: 'منطقة الاختيار السريع',
  Quickconstructionarea: 'منطقة بناء سريعة',
  Smartcontractmatching: 'مطابقة العقد الذكية',
  ImmediateInvestment: 'استثمر الآن',
  sourceTime: 'وقت',
  viewCode: 'عرض الكود',
  goWealh: 'طريق الثروة',
  fileGo: 'انتقل إلى ملف',
  videoRequest: 'طلب فيديو',
  gorequest: 'طلب سحب',
  proceeding: 'دعوى',
  pwds: 'كلمة المرور',
  experienceAll: 'تجربة نجمة كاملة',
  experienceFive: 'تجربة خمس نجوم',
  sharing: 'تقاسم الثروة',
  customer: 'خدمة الزبائن',
  todoList: 'لكى يفعل',
  systemMessages: 'معلومات النظام',
  youHave: 'لديك',
  totleWait: 'وكالة المادة ، يرجى التعامل معها في الوقت المناسب',
  totleBook: 'رسائل النظام غير المقروءة',
  message: 'معلومة',
  officialNowGo: 'ابدأ الآن',
  officialFinace: 'تمتع بالتمويل بتقنية البلوكشين بسهولة لم تكن متاحة من قبل!',
  officialTechnologyLeft: 'المتطور والحديث',
  officialTechnologyRight: 'تكنولوجيا',
  superSESMessage: 'معلومات Btrok',
  superSES: 'Btrok',
  superFomamce: 'تمويل العقود ، والتمتع بالتمويل',
  superSecure: 'استثمار بلوكشين أمان عالي العائد',
  superParagraphOne:
    'منصة Btrok الدولية لتداول الأصول الرقمية المشفرة هي منصة تداول أصول رقمية شاملة.لدينا قوة تقنية رائدة وخدمة عملاء عالية الجودة وتجربة مستخدم جيدة ، ونوفر منصة تبادل مجانية عبر الإنترنت لعشاق العملات الرقمية.',
  superParagraphTwo:
    'تقدم منصة تداول الأصول الرقمية العالمية Btrok خدمات شاملة للبلوكشين، بما في ذلك تخطيط الكتاب الأبيض للعملة المشفرة، وضبط الخوارزميات الرمزية، وتطوير العملات المشفرة، وإطلاق المنصة التداولية.',
  superParagraphThree: 'تأسست مراكز العمليات الدولية لمنصة Btrok لتداول الأصول الرقمية في سنغافورة وكمبوديا وجزر مارشال.',
  Traverser: 'Traverser',
  announceList: 'قائمة الإعلانات',
  announceListDetail: 'تفاصيل الإعلان',
  official: 'رسمي',
  supervise: 'عقدة الإشراف',
  Source: 'كود المصدر المفتوح',
  report: 'تقرير المراجعة',
  more: 'أكثر',
  deposit: 'إيداع',
  invite: 'يدعو',
  lockMining: 'التعدين القفل',
  riseRanking: 'قائمة المكاسب',
  currency: 'عملة',
  recentPrice: 'أحدث السعر',
  gains: '24 ساعة زيادة',
  welcomes: 'مرحباً!',
  home: 'الصفحة الأمامية',
  messageList: 'قائمة الرسائل',
  noData: 'لايوجد بيانات',
  market: 'يقتبس',
  financialManagement: 'المالية',
  optional: 'خياري',
  buy: 'يشتري',
  sell: 'يبيع',
  completed: 'مكتمل',
  limitOrder: 'طلب محدود',
  marketOrder: 'طلب السوق',
  call: 'صاعد',
  put: 'سبحة',
  success: 'نجاح',
  failure: 'يفشل',
  transaction: 'تجارة',
  contract: 'ثانية ثانية',
  forex: 'سعر صرف العملات',
  mine: 'مِلكِي',
  notNull: 'لايمكن ان يكون فارغا',
  optionContract: 'عقد الخيارات',
  currentDelivery: 'التسليم الحالي',
  historyDelivery: 'التسليم التاريخي',
  fee: 'رسوم المعالجة',
  openAmount: 'مبلغ الافتتاح',
  bonusQuantity: 'مبلغ المكافأة',
  predictionDirection: 'اتجاه التنبؤ',
  requestFailure: 'فشل الطلب ، انقر لإعادة التحميل',
  predictionResult: 'نتيجة التنبؤ',
  submitSuccess: 'تم الإرسال بنجاح',
  sharePoster: 'مشاركة الملصق',
  cancel: 'يلغي',
  copyLink: 'نسخ الوصلة',
  saveImage: 'احفظ الصورة',
  pleaseLoginFirst: 'الرجاء تسجيل الدخول أولا',
  pleaseUseTronWallet: 'يرجى استخدام محفظة الموجة لفتح رابط الشحن',
  currentInterest: 'الفائدة الحالية',
  fundsSecurity: 'الامن المالي',
  guarantee: 'أؤكد',
  holidayEarnings: 'دخل الإجازة المستمر',
  warning: 'لا تودع أي أصول بعملات أخرى في العنوان أعلاه ، وإلا فلن يتم استرداد الأصول.',
  confirmation:
    'عندما تشحن إلى العنوان المذكور أعلاه، تحتاج إلى تأكيد من نقطة شبكة سلسلة الكتل بأكملها، بعد 3 عمليات تأكيد الشبكة الشبكة الرئيسية لسلسلة الكتل بشكل عام.',
  addressChange:
    'لن يتغير عنوان إعادة الشحن الخاص بك بشكل متكرر ، ويمكنك إعادة الشحن بشكل متكرر ؛ إذا كان هناك أي تغيير ، سنبذل قصارى جهدنا لإعلامك من خلال إعلانات الموقع أو رسائل البريد الإلكتروني.',
  safetyReminder: 'تأكد من التأكد من أن بيئة التشغيل آمنة لمنع العبث بالمعلومات أو تسريبها.',
  rechargeUSDT: 'إيداع USDT',
  details: 'تفاصيل',
  dayLimit: 'سماء',
  mining: 'التعدين يستمر في الكسب',
  upcoming: 'رقن',
  inProgress: 'في تَقَدم',
  distribution: 'توزيع',
  progress: 'جدول',
  totalAmount: 'إجمالي النشاط',
  startTime: 'وقت البدء',
  endTime: 'وقت النهاية',
  lockingMining: 'التعدين القفل هو كسب دخل من التعدين في مجمع تعدين المنصة من خلال تكليف USDT بآلة التعدين فائقة الحوسبة الخاصة بالمنصة',
  productHighlights: 'يسلط الضوء على المنتج',
  withdrawal: 'تخزين وجلب',
  dividendCycle: 'دورة توزيع الأرباح',
  successfulDepositOnDay: 'اليوم التالي للإيداع الناجح',
  interestAccrual: 'يعلو',
  example: 'على سبيل المثال',
  profitCalculation: 'حساب الدخل',
  simplestProfitFormula: 'أبسط معادلة حساب الدخل',
  profitRateCalculationExample: 'على سبيل المثال ، إذا كان الدخل 2 وات وكان المبلغ الأساسي 10 وات ، فإن معدل العائد',
  calculationOfPrincipal:
    'بالنسبة لحساب الأوراق المالية ، إذا كنت لا تتذكر رأس المال المستثمر ، فيمكنك حسابه بناءً على إجمالي القيمة السوقية الحالية والأرباح والخسائر الحالية',
  currentMarketValueExample:
    'على سبيل المثال ، القيمة السوقية الإجمالية الحالية هي 11 واط ، والربح العائم الحالي هو 2 واط ، ثم الأساسي هو 11 واط - 2 واط = 9 واط ، ومعدل العائد = (2 واط / 9 واط)',
  penalty: 'الأضرار الناجمة',
  participate: 'أريد أن أشارك',
  entrustedOrder: 'أمر بتكليف',
  pledgingInProgress: 'التعهد',
  pledgingComplete: 'تم التعهد',
  earlyRedemption: 'الفداء المبكر',
  loading: 'تحميل',
  pledgeAmount: 'مبلغ التعهد',
  dailyEarnings: 'الدخل اليومي',
  earlyRedemptionDeductionHandlingFee: 'الاسترداد المبكر خصم رسوم المناولة',
  earlyRedemptionAgain: 'الفداء المبكر',
  endOfContent: 'انتهى',
  financialProduct: 'منتج مالي',
  fundsUnderManagement: 'الأموال المحتجزة',
  expectedTodayEarnings: 'الأرباح المقدرة اليوم',
  TodayEarnings: 'أرباح اليوم',
  accumulatedEarnings: 'الدخل التراكمي',
  trustOrder: 'أوامر في الضمان',
  buy2: 'للشراء',
  period: 'دورة',
  switchLanguage: 'تبديل اللغة',
  username: 'اسم المستخدم',
  emailPlaceholder: 'الرجاء إدخال البريد الإلكتروني الخاص بك',
  forgotPassword: 'نسيت كلمة المرور',
  passwordPlaceholder: 'الرجاء إدخال كلمة المرور',
  submit: 'يُقدِّم',
  registerNow: 'أفتح حساب الأن',
  search: 'يبحث',
  high: 'عالي',
  low: 'قليل',
  depth: 'عمق',
  deal: 'إبرام اتفاق',
  buyQuantity2: 'كمية الشراء',
  price: 'سعر',
  quantity: 'كمية',
  sellQuantity: 'عدد أوامر البيع',
  time: 'وقت',
  direction: 'اتجاه',
  inputPrice: 'الرجاء إدخال السعر',
  tradingVolume: 'قيمة التحويل',
  entrust: 'ودع',
  entrustPrice: 'سعر العمولة',
  entrustQuantity: 'كمية الطلب',
  totalEntrustment: 'إجمالي المبلغ الموكلة',
  category: 'فئة',
  confirm: 'بالتأكيد',
  inputCorrectQuantity: 'الرجاء إدخال الكمية الصحيحة',
  operationSuccessful: 'عملية ناجحة',
  revoke: 'سحب او إبطال',
  entrustmentCancellationConfirmation: 'تأكيد تفويض الإلغاء',
  areYouSureToCancel: 'هل أنت متأكد من إلغاء الطلب؟',
  all: 'الجميع',
  currentEntrustment: 'العمولة الحالية',
  completed2: 'الاتفاق تم',
  aboutSES: 'عن',
  freeExchange: 'توفير منصة تبادل مجانية عبر الإنترنت لغالبية عشاق العملات الرقمية',
  modify: 'يراجع',
  bind: 'لربط',
  alipayAccount: 'حساب Alipay',
  wechatAccount: 'حساب WeChat',
  unbound: 'غير مجلد',
  sesPlatformOverview3:
    'Btrok أسواق الأصول الرقمية، هم من المؤسسة الدولية أبقي البريطانيين من الاستثمار في الأصول الرقمية العالمية، مقرها بريطانيا العظمى البريطاني منصات تداول NFC مراقبته، في سلسلة كتل الأوروبية للصناعات صنعت سلسلة من البنية التحتية، العمل على أساس البريطانية، في أوروبا وآسيا، في جنوب شرق آسيا والأمريكتين وأستراليا، وفي حرفة سلسلة كتل بناء الأصول الرقمية، بيوسفار. وبوصفها بورصة للأصول الرقمية الدولية للمستثمرين على الصعيد العالمي، فإنها تجمع بين المزايا الحالية للصناعة الحالية، ووجود فريق رفيع المستوى للإدارة المالية المصرفية، وفريق مهني لتطوير البيانات، وفريق عالمي رفيع المستوى لتطوير السلاسل الكتل، وفريق خدمات تقنية رائدة لسلسلة الكتل. تهدف إلى توفير خدمات فعالة ومأمونة للمعاملات في الأصول الرقمية للمستعملين.',
  sesPlatformOverview4:
    'وبوصفها أول منصة عالمية شاملة لتبادل الأصول الرقمية، فإنها من أفضل المنابر التجارية في مجال سلسلة القطع على الصعيد العالمي، وهي تغطي التوزيع الإيكولوجي للأصول الرقمية في جميع أنحاء العالم، وتحتفظ برخص دولية وبموافقة الوكالات الحكومية. وستتواصل التحسينات في مجال الابتكار التكنولوجي، وتكرار المنتجات، ونظم مراقبة المخاطر الأمنية، ونظم التشغيل، ونظم خدمة العملاء، مع تطوير نظم إيكولوجية شاملة لسلسلة الكتل، إلى جانب توفير الخدمات للمستخدمين العالميين في مجال المعاملات الفورية والمشتقات بالعملات الرقمية الرئيسية مثل البيتكوين، ومحل الأثير.',
  sesPlatformOverview5:
    'إن إنشاء منبر آمن وخبير وحر للمعاملات في الأصول الرقمية وبنية أساسية مالية تلبي احتياجات عشاق العملة الرقمية العالمية الواسعة النطاق من أجل بناء مجتمع مثالي لتبادل المعاملات في الأصول الرقمية الافتراضية في عصر الترابط الرقمي. توفير منصة مأمونة وموثوق بها ومستقرة وشاملة لتجارة الخدمات لطائفة واسعة من المستثمرين.',
  bankCardNumber: 'رقم الحساب المصرفي',
  accountSettings: 'إعدادات الحساب',
  modifyAlipayAccount: 'تعديل حساب Alipay',
  receiveQRCode: 'رمز الاستجابة السريعة للدفع',
  fundPassword: 'كلمة مرور الصندوق',
  pleaseEnterCorrectFundPassword: 'الرجاء إدخال كلمة المرور الصحيحة للصندوق',
  pleaseEnterAlipayAccount: 'الرجاء إدخال رقم حساب Alipay',
  myAssets: 'أصولي',
  details2: 'تفاصيل',
  withdrawalRecords: 'سجل العملات',
  totalAssetsApprox: 'مجموع الأصول',
  spotAccount: 'حسابات العملات',
  searchAssets: 'البحث عن الأصول',
  hideZeroBalances: 'العملة مخفية كـ 0',
  frozen: 'تجميد',
  toRelease: 'للاصدار',
  modifyBankCardNumber: 'تعديل رقم حساب البطاقة البنكية',
  save: 'يحفظ',
  bankName: 'بنك الإيداع',
  bankCardNumberConfirm: 'تأكيد رقم البطاقة',
  pleaseEnterBankCardNumberAgain: 'تأكيد رقم البطاقة',
  fundPasswordPrompt: 'كلمة مرور الصندوق',
  pleaseEnterFundPassword: 'الرجاء إدخال كلمة مرور الصندوق',
  modifyFundPassword: 'تعديل كلمة مرور الصندوق',
  modifyingFundPassword: 'أنت تقوم بتغيير كلمة مرور الصندوق',
  oldFundPassword: 'كلمة المرور القديمة للصندوق',
  newFundPassword: 'كلمة السر الجديدة للصندوق',
  confirmFundPassword: 'تأكيد كلمة مرور الصندوق',
  pleaseEnterSetPassword: 'الرجاء إدخال كلمة مرور الإعداد',
  pleaseEnterConfirmFundPassword: 'الرجاء إدخال كلمة مرور صندوق التأكيد',
  passwordsDoNotMatch: 'كلمة المرور غير مطابقة',
  pleaseEnterYourOldFundPassword: 'الرجاء إدخال كلمة المرور القديمة للصندوق',
  pleaseEnterYourNewFundPassword: 'الرجاء إدخال كلمة المرور الجديدة للصندوق',
  pleaseEnterA6DigitPassword: 'الرجاء إدخال كلمة مرور مكونة من 6 أرقام',
  pleaseConfirmNewFundPassword: 'يرجى تأكيد كلمة المرور الجديدة للصندوق',
  theNewFundPasswordCannotBeTheSameAsTheOldFundPassword: 'لا يمكن أن تكون كلمة المرور الجديدة للصندوق هي نفسها كلمة المرور القديمة للصندوق',
  pleaseEnterTheVerificationCode: 'الرجاء إدخال رمز التحقق',
  feedback: 'تعليق',
  yourOpinionIsVeryImportantToUs: 'رأيك مهم جدا بالنسبة لنا!',
  leaveYourValuableOpinionToHelpUsImprove: 'اترك تعليقاتك القيمة لمساعدتنا على القيام بعمل أفضل!',
  pleaseLeaveYourValuableOpinion: 'يرجى ترك تعليقاتك القيمة',
  submissionSuccessfulThankYouForYourFeedback: 'تم الإرسال بنجاح ، شكرًا لك على ملاحظاتك',
  submissionFailed: 'فشل التقديم',
  setFundPassword: 'قم بتعيين كلمة مرور الصندوق',
  setYourPaymentMethod: 'يرجى إعداد طريقة الدفع الخاصة بك ، يرجى التأكد من استخدام حساب الاسم الحقيقي الخاص بك',
  newPassword: 'كلمة المرور الجديدة',
  repeatPassword: 'اعد كلمة السر',
  notLoggedIn: 'لم تقم بتسجيل الدخول',
  welcome: 'مرحبًا بكم في Btrok',
  assetManagement: 'إدارة الأصول',
  assetFlow: 'تدفق الأصول',
  myInvitation: 'دعوتي',
  invitationLink: 'رابط الدعوة',
  firstLevelFriends: 'صديق من الدرجة الأولى',
  secondLevelFriends: 'الصديق الثاني',
  partnerLevel: 'المستوى الشخصي',
  myEntrustment: 'عمولتي',
  coinTrading: 'تداول العملات',
  securitySettings: 'اعدادات الامان',
  pleaseLogInFirst: 'الرجاء تسجيل الدخول أولا',
  announcement: 'إعلان',
  changeLoginPassword: 'تغيير كلمة مرور تسجيل الدخول',
  oldLoginPassword: 'كلمة مرور تسجيل الدخول القديمة',
  newLoginPassword: 'كلمة مرور جديدة لتسجيل الدخول',
  pleaseEnterOldLoginPassword: 'الرجاء إدخال كلمة المرور القديمة لتسجيل الدخول',
  pleaseEnterNewLoginPassword: 'الرجاء إدخال كلمة مرور جديدة لتسجيل الدخول',
  confirmLoginPassword: 'تأكيد تسجيل الدخول',
  emailVerificationCode: 'رمز التحقق من البريد الإلكتروني',
  emailBound: 'لديك بريد إلكتروني ملزم',
  verificationCode: 'رمز التحقق',
  sendVerificationCode: 'أرسل رمز التحقق',
  pleaseConfirmInformation: 'يرجى التأكد من ملء جميع المعلومات',
  pleaseConfirmPassword: 'يرجى تأكيد ما إذا كانت كلمات المرور التي تم إدخالها مرتين متطابقة',
  makeSureTheOldAndNewPasswordsAreDifferent: 'يرجى التأكد من اختلاف كلمات المرور القديمة والجديدة',
  pleaseConfirmNewLoginPassword: 'يرجى تأكيد كلمة المرور الجديدة لتسجيل الدخول',
  teamPerformance: 'أداء الفريق',
  currentLevel: 'المستوى الحالي',
  nextLevel: 'المرحلة التالية',
  teamNumber: 'حجم الفريق',
  totalPerformance: 'الأداء الكلي',
  onlyForUSDT: 'دعم فقط إعادة شحن USDT على هذا العنوان',
  walletAddress: 'عنوان المحفظة',
  securityReminder: 'من أجل حماية أمان حسابك ، يرجى إكمال مصادقة الاسم الحقيقي قبل التداول!',
  personalAvatar: 'الصوره الشخصيه',
  isVerified: 'تم التحقق',
  realNameAuthentication: 'تم التحقق',
  notVerified: 'غير معتمد',
  receivingAccount: 'الحسابات المستحقة',
  loginPassword: 'كلمة سر الدخول',
  fundsPassword: 'كلمة مرور الصندوق',
  settingsCenter: 'تعيين المركز',
  aboutUs: 'معلومات عنا',
  helptext: 'يساعد',
  help: 'يساعد',
  logout: 'تسجيل الخروج من الحساب الجاري',
  confirmLogout: 'هل أنت متأكد أنك تريد تسجيل الخروج؟',
  bindWeChatAccount: 'ربط حساب WeChat',
  withdrawalAddress: 'عنوان الانسحاب',
  available: 'متاح',
  minimumWithdrawalAmount: 'الحد الأدنى لمبلغ السحب',
  amountReceived: 'مبلغ الوصول',
  transactionTime: 'ساعة الصفقة',
  payableHandlingFee: 'رسوم المناولة المستحقة الدفع',
  deductibleHandlingFee: 'رسوم الاستقطاع',
  actualHandlingFee: 'الرسوم الفعلية',
  feeGenerationTime: 'وقت البدء والانتهاء هو وقت إنشاء الرسوم',
  takeIDPhoto: 'التقط وتحميل صورة لبطاقة الهوية الخاصة بك',
  identityAuthentication: 'المصادقة',
  uploadIDCardFront: 'قم بتحميل صورة للوجه الأمامي لبطاقة الهوية',
  uploadIDCardBack: 'قم بتحميل صورة من ظهر بطاقة الهوية',
  photoAlbum: 'إلبوم الصور',
  IDCardNumber: 'رقم الهوية',
  enterIDCardNumber: 'الرجاء إدخال رقم الهوية',
  name: 'اسم',
  idea: 'فلسفة Btrok',
  goal: 'أهداف Btrok',
  depositRecord: 'سجل إعادة الشحن',
  withdrawal2: 'سحب العملات المعدنية',
  accountOpeningBranch: 'فرع فتح الحساب',
  quantityInput: 'الرجاء إدخال الكمية',
  singleLimit: 'حد واحد',
  investmentAmount: 'مبلغ الاستثمار',
  incomePrincipal: 'الأرباح / رأس المال',
  password: 'كلمة المرور',
  historicalCommission: 'العمولة التاريخية',
  type: 'يكتب',
  commissionAmount: 'المبلغ المؤتمن',
  filter: 'منقي',
  pleaseSelect: 'اختر من فضلك',
  tradingPair: 'زوج تداول',
  reset: 'إعادة ضبط',
  startEndTime: 'وقت البدء والانتهاء',
  recharge2: 'تعبئة رصيد',
  withdraw: 'ينسحب',
  transfer: 'تحويل',
  fiatCurrencyPurchase: 'شراء العملات الورقية',
  fiatCurrencySale: 'بيع فيات',
  activityReward: 'جائزة النشاط',
  promotionReward: 'مكافأة الترقية',
  dividend: 'أرباح',
  vote: 'تصويت',
  manualRecharge: 'إعادة الشحن اليدوي',
  pairing: 'زوج',
  activityExchange: 'استرداد النشاط',
  ctcPurchase: 'شراء CTC',
  ctcSale: 'بيع CTC',
  redEnvelopeSending: 'صدر المغلف الأحمر',
  redEnvelopeReceiving: 'جمع المغلف الأحمر',
  withdrawalCodeWithdrawal: 'كود السحب النقدي',
  withdrawalCodeRecharge: 'إعادة تعبئة رمز السحب النقدي',
  perpetualContractFee: 'رسوم العقد',
  perpetualContractProfit: 'ربح العقد',
  perpetualContractLoss: 'خسارة العقد',
  optionContractFailure: 'فشل عقد الخيار',
  optionContractFee: 'رسوم التعامل مع عقد الخيار',
  optionContractBonus: 'مكافأة عقد الخيار',
  contractRebate: 'خصم العقد',
  levelReward: 'جائزة المستوى',
  platformFeeIncome: 'دخل رسوم المنصة',
  income: 'دخل',
  promotionIncome: 'الدخل الترويجي',
  optionsPromotionIncome: 'دخل ترويج الخيار',
  optionTeamIncome: 'أرباح فريق الخيار',
  optionParityAward: 'جائزة مستوى الخيار',
  investmentTeamReturn: 'دخل فريق الاستثمار',
  investmentParityReturn: 'دخل مستوى الاستثمار',
  investmentPromotionIncome: 'دخل تشجيع الاستثمار',
  forexEntrustTrading: 'صفقة الفوركس المفوضة',
  forexCancelEntrustTrading: 'معاملة تعهد بإلغاء فوركس',
  liquidationCompensation: 'تعويض التصفية',
  lockedMiningIncome: 'فوائد الفريق',
  speedContractProfit: 'ربح عقد سريع',
  speedContractLoss: 'خسارة كبيرة في العقد',
  speedContractCommission: 'رسوم العقد القصوى',
  CONTRACT_FAST_FREEZE: '秒合约冻结',
  CONTRACT_FAST_UNFREEZE: '秒合约解冻',
  NEWACTIVITY_IN: '投资收益',
  NEWACTIVITY_DEDUCT: '投资赎回',
  NEWACTIVITY_FREEZE: '期权冻结',
  NEWACTIVITY_UNFREEZE: '期权解冻',
  NEWACTIVITY_REDEEM_UNFREEZE: '期权赎回解冻',
  confirmUploadAllImages: 'يرجى تأكيد ما إذا تم تحميل جميع الصور',
  inputRealName: 'الرجاء إدخال اسمك الحقيقي',
  idCardPhoto: 'صور الهوية',
  congratulations: 'تهانينا ، لقد اجتزت المراجعة ، يمكنك التداول براحة البال',
  quantity2: 'كمية',
  wechatAccount2: 'الرجاء إدخال حساب WeChat',
  realNameIdentityVerified: 'تم التحقق من هوية الاسم الحقيقي',
  withdrawalTime: 'وقت الانسحاب',
  withdrawalAmount: 'كمية السحب',
  creditting: 'قيد المراجعة',
  waitingmoney: 'في انتظار العملات',
  dztime: 'وقت الإعادة',
  rushaddress: 'عنوان الإيداع',
  rushnumber: 'مبلغ الشحن',
  //邮箱注册
  regisertitle: 'تسجيل البريد الإلكتروني',
  inputemailcode: 'الرجاء إدخال رمز التحقق من البريد الإلكتروني',
  inputloginpassword: 'الرجاء إدخال كلمة مرور (أكثر من 6 أرقام ، مزيج من الأحرف الكبيرة والصغيرة)',
  inputreloginpassword: 'الرجاء إدخال كلمة المرور مرة أخرى',
  inputinvite: 'الرجاء إدخال رمز الدعوة',
  iagree: 'لقد قرأت وأوافق',
  useragreement: 'اتفاقية المستخدم',
  nowlogin: 'تسجيل الدخول على الفور',
  sendcode: 'أرسل رمز التحقق',
  rightemailformat: 'الرجاء إدخال تنسيق البريد الإلكتروني الصحيح',
  confirmPassword: 'تأكيد كلمة المرور',
  passwordtip: 'يجب أن تحتوي كلمة المرور على 6 أرقام على الأقل',
  nosamepassword: 'كلمتا المرور غير متطابقتان',
  noagree: 'لم يتم تحديده للموافقة على اتفاقية المستخدم',
  emailverification: 'تأكيد بواسطة البريد الالكتروني',
  // 划转
  amount: 'كمية',
  overturn: 'تحويل',
  overturntitle: 'نقل الأصول',
  from: 'من',
  to: 'يصل',
  swapcurrency: 'عملة العقد',
  overturnplaceholder: 'الرجاء إدخال مبلغ التحويل',
  selectaccount: 'حدد حساب',
  balance: 'الأصول ',
  contractaccount: 'حساب العقد',
  //
  invitecode: 'شفرة الدعوة',
  notset: 'غير مضبوط',
  day: 'سماء',
  contactservice: 'اتصل بخدمة العملاء',
  appname: 'Btrok APP',
  download: 'تحميل APP',
  orderByHand: 'الطلبات اليدوية',
  orderByAuto: 'أوامر ذكية',
  trevenue: 'إجمالي الإيرادات',
  pincome: 'الدخل الترويجي',
  tincome: 'فوائد الفريق',
  dropList: 'قائمة منسدلة',
  uploaded: 'تم الرفع',
  youare: 'أنت',
  disabling: 'إنهاء',
  enabling: 'شغله',
  theAutomaticReinvestmentFeatur: 'وظيفة إعادة الاستثمار التلقائي',
  nth: 'لا.',
  phase: 'يتوقع',
  currentForecastPeriod: 'فترة التوقعات الحالية',
  maximum: 'المبلغ الأقصى',
  openingPrice: 'سعر الافتتاح',
  currentPrice: 'السعر الحالي',
  profitSharing: 'مشاركة الأرباح',
  CurrentTradingIsRestricted: 'المعاملات المقيدة حاليا',
  ToEnsureTheSsecurityOfYourAssetsPleaseCarefullyVerifyTheReceivingWalletAddressBeforeMakingAnyDeposits:
    'لضمان سلامة الأصول الخاصة بك ، يرجى التحقق بعناية من عنوان المحفظة المستلم لإعادة الشحن:',
  PleaseProceedWithTheDepositOnlyAfterVerifyingThatEverythingIsCorrect: '. لا يمكن إجراء إعادة التعبئة إلا بعد التحقق.',
  PleaseDoNotDepositAnyAssetsOtherThanTheSpecifiedCryptocurrencyToTheAddressMentionedAboveOtherwiseYourAssetsWillNotBeRecoverable:
    'لا تعيد شحن أي أصول بغير عملات إلى العنوان أعلاه ، وإلا فلن يتم استرداد الأصول.',
  AfterYouMakeADepositToTheMentionedAddressItRequiresConfirmationFromTheEntireBlockchainNetworkTypicallyItTakesAround30SecondsForTheFundsToBeCreditedToYourAccountAfterThreeConfirmationOnTheBlockchainMainnet:
    'بعد إعادة الشحن إلى العنوان أعلاه ، تحتاج إلى تأكيد عقدة شبكة blockchain بالكامل. بشكل عام ، ستستغرق شبكة blockchain الرئيسية حوالي 30 ثانية للوصول بعد 3 تأكيدات من الشبكة.',
  PleaseEnsureTheSecurityOfYourElectronicDevicesToPreventAnyTamperingOrLeakageOfInformation:
    'يرجى التأكد من التأكد من سلامة الأجهزة الإلكترونية لمنع العبث بالمعلومات أو تسريبها.',
  WithdrawalsAreCurrentlyDisabled: 'عمليات السحب محظورة حاليًا',
  CurrentWithdrawalAddress: 'يرجى تأكيد عنوان السحب الحالي',
  isYourWalletAddress: ' هل هو عنوان محفظتك',
  LinkCopiedSuccessfully: 'نسخ بنجاح',
  LinkCopiedFailed: 'فشل النسخ المتماثل',
  goLogin: 'لتسجيل الدخول',
  other: 'آخر',
  SavePicture: 'حفظ الصور',
  SaveLink: 'حفظ الرابط',
  languageSettings: 'اعدادات اللغة',
  simplifiedChinese: '简体中文',
  traditionalChinese: '繁体中文',
  arabic: 'عربي',
  english: 'English',
  japanese: '日本語',
  French: 'Français',
  Spanish: 'Español',
  German: 'Deutsch',
  Italiano: 'Italiano',
  TotalDailyLoss: 'إجمالي الأرباح',
  TotalExpenditureToday: '(إجمالي مبلغ الطلبات',
  TotalIncomeToday: 'إجمالي الأرباح اليومية',
  CurrentDelivery: 'التسليم الحالي',
  HistoricalDelivery: 'تسليم التاريخي',
  InitialAmount: 'مبلغ الطلب',
  ForecastDirection: 'اتجاه التوقع',
  BonusAmount: 'مبلغ العلاوة',
  TransactionFee: 'رسوم الخدمة',
  OrderDuration: 'مدة الطلب',
  Status: 'الحالة',
  SelectCurrency: 'اختيار العملة',
  EnterAmount: 'إدخال المبلغ',
  BUY: 'شراء على الصعود',
  SELL: 'شراء على الهبوط',
  WIN: 'نجاح',
  LOSE: 'فشل',
  Amount: 'مبلغ',
  expectedReturn: 'العائد المتوقع',
  service: 'خدمة العملاء',
  untrade: 'غير قابلة للتداول حاليًا',
  netError: 'الشبكة معطلة عن العمل!',
  questError: 'حدث خطأ في الطلب، يرجى المحاولة مرة أخرى في وقت لاحق!',
  failVerified: 'المصادقة فشلت',
  tiprealverified: 'التحقق من الاسم الحقيقي، يرجى الاتصال بخدمة العملاء',
  uploadImg: 'تحميل الشهادة',
  collected: 'تم جمعها',
  canceld: 'ألغيت',
  placeholderAddress: 'الرجاء إدخال عنوان الاستلام الخاص بك',
  bindAccountTitle: 'عنوان الدفع',
  marketTitle: 'يقتبس',
  regiserSuccess: 'تم التسجيل بنجاح',
  setSuccess: 'تم تعيينه بنجاح',
  updateAddress: 'أريد تغيير عنوان محفظتي.',
  doingRealName: 'الاسم الحقيقي قيد المراجعة، يرجى الانتظار بصبر',
  updateSuccess: 'تم التعديل بنجاح',
  uploadSuccess: 'تم الرفع بنجاح',
  forgetLpass:
    'إذا نسيت كلمة المرور الخاصة بك، فيرجى الاتصال بخدمة العملاء للتحقق من المعلومات ومعالجتها لإعادة تعيين كلمة مرور تسجيل الدخول الخاصة بك',
  forgetApass: 'إذا نسيت كلمة المرور الخاصة بك، فيرجى الاتصال بخدمة العملاء للتحقق من المعلومات وإعادة تعيين كلمة مرور المعاملة الخاصة بك.',
  updateAddressTip: 'لتعديل عنوان الاستلام، يرجى الاتصال بخدمة العملاء للتحقق من المعلومات الشخصية وعملية إعادة تعيين عنوان الاستلام',
  a48h: 'تم قبول السحب. سيتم معالجة طلب السحب خلال 48 ساعة. يرجى الانتظار بصبر.',
  r48h: 'تم قبول إعادة الشحن. ستتم معالجة طلب إعادة الشحن خلال 48 ساعة. يرجى الانتظار بصبر.',
  accepted: 'قبول',
  notremind: 'لا أذكر مرة أخرى',
  serviceCode: {
    0: 'النجاح',
    500: 'فشل',
    501: 'فشل في التسجيل',
    502: 'فشل فى الارسال',
    503: 'فشلت الإضافة',
    504: 'فشل الاستعلام',
    600: 'هذه العملة لا تدعم السحب',
    1001: 'رمز التحقق غير موجود',
    1003: 'مبلغ السحب أكبر من الحد الأقصى للقيمة',
    1004: 'مبلغ السحب أقل من الحد الأدنى للقيمة',
    1005: 'الرصيد غير كاف المتاحة',
    1006: 'المحفظة مقفلة',
    1007: 'مصادقة الاسم الحقيقي قيد المراجعة حاليًا. يرجى الانتظار بصبر حتى تتم المراجعة قبل المتابعة.',
    1008: 'يرجى ضبط كلمة مرور الصندوق أولاً',
    1009: 'خطأ في كلمة مرور الصندوق',
    1010: 'غير قادر على تأمين الأصول',
    1011: 'تم ربط صندوق البريد',
    1012: 'اسم المستخدم هذا موجود بالفعل',
    1013: 'لا يوجد مثل هذا المستخدم',
    1014: 'لقد تم إرسال رسالة تفعيل التسجيل إلى صندوق البريد الخاص بك، برجاء عدم تكرار الطلب',
    1015: 'يرجى ربط البريد الإلكتروني أولا',
    1016: 'لا يمكن أن يكون مبلغ الطلب 0',
    1017: 'لقد تم تعطيل حسابك الحالي، يرجى الاتصال بخدمة العملاء!',
    1018: 'النشاط غير موجود',
    1019: 'مبلغ الشراء أقل من الحد الأدنى للمبلغ',
    1020: 'مبلغ الشراء أكبر من الحد الأقصى',
    1021: 'عملة غير مدعومة!',
    1022: 'لقد تم تفضيل زوج التداول',
    1023: 'لا تكرر الإعدادات',
    1024: 'طول كلمة مرور الصندوق غير قانوني',
    1025: 'العقد غير موجود',
    1026: 'اتجاه التوقعات خاطئ',
    1027: 'محفظة المستخدم غير موجودة',
    1028: 'اسم المستخدم أو كلمة المرور غير صحيحة',
    1029: 'الحساب غير مفعل/معطل، يرجى الاتصال بخدمة العملاء',
    1030: 'الرجاء إكمال مصادقة الاسم الحقيقي أولاً',
    1031: 'يرجى تعيين كلمة مرور المعاملة',
    1032: 'تمت مصادقته بالاسم الحقيقي ولا يمكن إعادة مصادقته',
    1033: 'لا يمكن المصادقة على نفس رقم الهوية إلا مرة واحدة',
    1035: 'خطأ في كلمة المرور القديمة',
    1036: 'يرجى تحميل شهادة إعادة الشحن',
    1037: 'الرجاء إدخال مبلغ إعادة الشحن الصحيح',
    1038: 'الحساب غير موجود',
    1039: 'كلمة سر خاطئة',
    1040: 'كلمة المرور القديمة غير صحيحة',
    1041: 'كلمة المرور الخاصة بالمعاملة خاطئة، يرجى إعادة إدخالها',
    1042: 'خطأ في رمز الدعوة',
    1043: 'عنوان سحب غير طبيعي',
    1065: 'الطلب الحالي قيد المراجعة، يرجى عدم تقديمه مرة أخرى.',
    3000: 'نفذ الوقت المسموح للدخول',
    888: 'يرجى إكمال شهادة الاسم الحقيقي أولاً',
  },
  //new
  rate: 'ربح',
  mode: 'وضع المعاملة',
  num: 'كمية الافتتاح',
  openTime: 'وقت متاح',
  phoneNi: 'الرجاء إدخال رمز التحقق للهاتف المحمول',
  phonePlaceholder: 'الرجاء إدخال رقم الهاتف',
  inputphonecode: 'الرجاء إدخال رمز التحقق للهاتف المحمول',
  phone: 'رقم التليفون',
  phoneVcode: 'رمز التحقق من الهاتف',
  sendSucc: 'أرسل بنجاح',
  cScore: 'مستوى الرصيد',
  wallet: 'wallet',
  searchMp: 'ابحث عن اسم العملة المشفرة',
  clickUploadId: 'انقر لرفع صورة هويتك',
  modifyFp: 'تعديل',
  confirmFPassword: 'تأكيد كلمة مرور الأصول',
  pleaseSetYourNewFundPassword: 'الرجاء ضبط كلمة مرور المال',
  nosamefpassword: 'كلمة مرور المال غير صحيحة، يرجى التأكيد مرة أخرى.',
  forgotFPassword: 'ننسى كلمة المرور التجارية الخاصة بك؟',
  korean: '한국어',
  turkish: 'Türkçe',
};
