<script lang="ts">
import { defineComponent } from 'vue'
export default defineComponent({
  name: 'QuotationPage',
})
</script>
<template>
  <div style="background-color: #fff">
    <!-- 我的资产tabbar -->
    <div class="balanceHeader">
      <div class="header">
        <!-- <var-icon @click="$router.push('/user')" name="chevron-left" :size="30" style="padding-left: 5px" /> -->
        <span style="font-weight: 600; font-size: 16px; text-align: center"> {{ $t('myAssets') }}</span>
        <!-- <div style="padding-right: 15px; position: absolute; right: 0">
          <var-button style="font-size: 14px" @click="show = !show" color="transparent" text-color="#000" round text>
            {{ $t('details2') }}
          </var-button>
          <div
            v-if="show"
            style="z-index: 99999; text-align: center; width: 100px; position: absolute; right: 0; top: 50px; background-color: #fff"
          >
            <div style="padding: 15px 0; border-bottom: 1px solid #f5f5f5" @click="toGive()">{{ $t('depositRecord') }}</div>
            <div style="padding: 15px" @click="toDown()">{{ $t('withdrawalRecords') }}</div>
          </div>
        </div> -->
      </div>
    </div>

    <!-- under 资产 -->
    <div class="p-4">
      <!-- 图片 -->
      <div class="total" style="padding: 50px 30px">
        <div class="flex" style="font-size: 16px; align-items: center">
          <span style="padding-right: 8px">{{ $t('totalAssetsApprox') }}</span>
          <van-icon size="18" :name="eyeName" @click="showBalance" />
        </div>
        <div style="font-size: 20px; font-weight: bold; padding-top: 10px">
          {{ active == 0 ? Number(balance).toFixed(4) : Number(balanceCount).toFixed(4) }}</div>
      </div>

      <!-- 充币/提币/划转 -->
      <div class="flex justify-between" style="padding: 20px 0">
        <div @click="$router.push({ path: 'pay' })"
          style="margin-right: 10px; padding: 10px; width: 50%; text-align: center; background-color: #f5f5f5">{{
            $t('recharge2') }}</div>
        <div @click="gotoWithdraw"
          style="margin-left: 10px; padding: 10px; width: 50%; text-align: center; background-color: #f5f5f5">{{
            $t('withdrawal2')
          }}</div>
        <!-- <div
          @click="$router.push('/transfer')"
          style="margin-left: 10px; padding: 10px; width: 50%; text-align: center; background-color: #f5f5f5"
        >
          {{ $t('overturn') }}</div
        > -->
      </div>
      <!-- 账户 -->
      <div v-for="item in issearch ? currentSearch.data : checked ? currentZero.data : coins.data" :key="item.id"
        style="padding: 5px 0 20px 0; border-bottom: 1px solid #d1d1d1">
        <van-row gutter="20" style="padding-bottom: 20px">
          <van-col span="8">
            <div style="font-size: 16px; font-weight: bold">{{ item.coin.unit }}</div>
          </van-col>
        </van-row>
        <div style="display: flex;width: 100%;text-align: center;">
          <div style="flex: 1;">
            <div style="height: 45px;position: relative;">
              <div style="color: #999a9e;position: absolute;" :style="$t('available').length>15?$t('available').length>20?'margin: auto 0%':'margin: auto 10%':'margin: 10% 25%'">{{ $t('available') }}</div>
            </div>
            <div style="padding-top: 15px">{{ item.balance.toFixed(4) }}</div>
          </div>
          <div style="flex: 1;">
            <div style="color: #999a9e;height: 45px;">{{ $t('frozen') }}</div>
            <div style="padding-top: 15px">{{ item.frozenBalance.toFixed(4) }}</div>
          </div>
          <div style="flex: 1;">
            <div style="color: #999a9e;height: 45px;">{{ $t('toRelease') }}</div>
            <div style="padding-top: 15px">{{ item.toReleased.toFixed(4) }}</div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script lang="ts" setup name="QuotationPage">
import { showConfirmDialog } from 'vant';
import { getWalletList } from '/@/api/transaction';
// import { useRouter } from 'vue-router';
// import { getowalletall } from '/@/api/user';
import { setting } from '/@/api/user';
import { useUserStore } from '/@/store/modules/user';
import { ref, reactive, onActivated, onDeactivated } from 'vue';
import { useI18n } from 'vue-i18n';
import { useRouter } from 'vue-router';
const router = useRouter();
const { t } = useI18n();

const active = ref(0);
// 合约账户相关

// const constractSearch = reactive({ data: [] }) as any;
// const constractZero = reactive({ data: [] }) as any;
// const constract = reactive({ data: [] as any });
// let toSearch = ref(false);
// const Contentvalue = ref('');
// const checkeds = ref(false);
const allCount = ref(0.0);
const balanceCount = ref('');
const user = useUserStore();
// const router = useRouter();

//点击充币关闭弹框+跳转
// function toGive() {
//   if (user.token) {
//     router.push({ path: '/depositRecord' });
//   } else {
//     router.push('/login');
//   }
//   show.value = false;
// }
//点击提币关闭弹框+跳转
// function toDown() {
//   if (user.token) {
//     router.push({ path: '/withdrawalRecords' });
//   } else {
//     router.push('/login');
//   }
//   show.value = false;
// }
allWalletList();
// init();
// function init() {
//   isShowBalance.value = localStorage.getItem('showbalance') ? localStorage.getItem('showbalance') == '1' : true;
// }
function showBalance() {
  isShowBalance.value = !isShowBalance.value;
  eyeName.value = isShowBalance.value ? 'eye' : 'closed-eye';
  balance.value = isShowBalance.value ? allUsdt.value + '' : '*******';
  balanceCount.value = isShowBalance.value ? allCount.value + '' : '*******';
  localStorage.setItem('showbalance', isShowBalance.value ? '1' : '0');
}

// function getAllConstracts() {
//   getowalletall().then((res: any) => {
//     console.log(res);
//     if (res.code == 0) {
//       constract.data = res.data;
//       allCount.value = getAll(constract.data);
//       balanceCount.value = allCount.value + '';
//     }
//   });
// }

const getInfo = async () => {
  let res = await setting();
  data._user = res.data;
};

const data = reactive({
  _user: getInfo(),
}) as any;
const { _user } = toRefs(data);
async function gotoWithdraw() {
  await getInfo();
  if (_user.value.realVerified == 1) {
    // 实名认证 认证中
    // showToast(t('tiprealverified'));
    // return;
  } else if (_user.value.realVerified == 0 || _user.value.realVerified == 3) {
    // 未认证
    showConfirmDialog({
      message: t('serviceCode.888'),
      confirmButtonText: t('confirm'),
      cancelButtonText: t('cancel'),
    }).then(() => {
      router.push('unVerified/');
    }).catch();
    return;
  }
  // if (!_user.value.fundsVerified) {
  //   // 资金密码 未设置
  //   showDialog({
  //     message: t('serviceCode.1008'),
  //     confirmButtonText: t('confirm'),
  //   }).then(() => {
  //     router.push('/setPassword');
  //   });
  //   return;
  // }
  // if (!_user.value.accountVerified) {
  //   // 收款账户未设置
  //   showDialog({
  //     message: t('setYourPaymentMethod'),
  //     confirmButtonText: t('confirm'),
  //   }).then(() => {
  //     router.push('bindAccount');
  //   });
  //   return;
  // }
  router.push({ path: 'withdraw' });
}

onActivated(() => {
  if (user.token) {
    allWalletList();
    // getAllConstracts();
  }
});
onDeactivated(() => {
  allWalletList();
  // getAllConstracts();
});
// function computeRight(item: any) {
//   let result =
//     item.usdtBalance + item.usdtFrozenBalance + item.usdtBuyPrincipalAmount + item.usdtSellPrincipalAmount + item.usdtTotalProfitAndLoss;
//   return result.toFixed(4);
// }

// 币币账户相关
// const show = ref(false);
const checked = ref(false);

const coins = reactive({ data: [] }) as any;
const currentSearch = reactive({ data: [] }) as any;
const currentZero = reactive({ data: [] }) as any;
let issearch = ref(false);
const allUsdt = ref(0.0);
const balance = ref('');
const isShowBalance = ref(true);
const eyeName = ref('eye');

//合约账户搜索
// function noCount(_checkeds: true) {
//   checkeds.value = _checkeds;
//   if (_checkeds) {
//     // 排除余额是0的
//     constractZero.data.length = 0;
//     let zcoins = toSearch ? constract.data : constractSearch.data;
//     zcoins.forEach((it) => {
//       console.log(it.balance);
//       if (it.balance > 0) {
//         constractZero.data.push(it);
//       }
//     });
//     onChangeContent(Contentvalue.value);
//   }
// }

function allWalletList() {
  getWalletList().then((res: any) => {
    if (res.code == 0) {
      coins.data = res.data.filter((item: any) => {
        return item.coin?.unit === 'USDT';
      });
      allUsdt.value = getAll(coins.data);

      balance.value = allUsdt.value + '';
    }
  });
}
function getAll(data) {
  let sumusd: number = 0;
  data.forEach((_coin) => {
    sumusd += (_coin.balance + _coin.frozenBalance) * _coin.coin.usdRate;
  });
  return sumusd;
}

// function onChangeContent(e) {
//   let _input = e.toUpperCase();
//   constractSearch.data.length = 0;
//   if (_input !== '') {
//     toSearch.value = true;
//     let _coins = checkeds.value ? constractZero.data : constract.data;
//     _coins.forEach((coin) => {
//       let _unit: string = coin.coin.unit;
//       if (_unit.indexOf(_input) >= 0) {
//         constractSearch.data.push(coin);
//       }
//     });
//     console.log(constractSearch.data.length);
//   } else {
//     toSearch.value = false;
//   }
// }
</script>
<style scoped lang="scss">
.van-col--8 {
  flex: none;
  max-width: none;
}

//个人中心头部
.balanceHeader {
  // width: 750px;
  height: 90px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  background-color: #f5f5f5;

  .header {
    // position: relative;
    display: flex;
    align-items: center;
    justify-content: space-between;

    span {
      position: absolute;
      left: 50%;
      transform: translateX(-50%);
    }
  }
}

//搜索资产
.banks {
  display: flex;
  align-items: center;
  overflow: visible;
  justify-content: space-between;
}

::v-deep .van-search {
  padding-left: 10px;
  padding-right: 0;

  .van-search__field {
    padding-right: 0;
  }
}

//搜索icon
::v-deep .van-icon {
  font-size: 30px;
}

// input内容
::v-deep .van-field__control {
  color: #5c5959;
  padding-left: 0px;
  font-size: 28px;
}

// check框
.van-checkbox {
  overflow: visible;
  // width: 230px;
  // background: salmon;
  height: 100%;
  padding-right: 20px;
  display: flex;
  align-items: center;
  font-size: 26px;
}

// :checked ICON
::v-deep .van-checkbox__icon .van-icon {
  font-size: 28px;
  // top: -5px;
  // height: unset !important;
  // line-height: unset !important;
}

::v-deep .van-tabs__wrap .van-tabs__nav {
  background-color: unset;
  background: none;
}

::v-deep .van-search__field {
  width: 400px;
}

// 内容
::v-deep .van-tabs__content {
  margin-top: 10px;
}

::v-deep .van-tabs__line {
  // bottom: 6vw;
  background: none;
}

::v-deep .van-tab {
  font-size: 26px;
}

::v-deep .var-app-bar {
  z-index: 9 !important;
}

::v-deep .van-search {
  background-color: unset;
}

::v-deep .van-search__content {
  background: unset !important;
  padding-left: unset !important;
}

.total {
  background: url('/public/img/icon_bg_my_center.jpg');
  background-size: cover;
  background-repeat: no-repeat;
}
</style>
