<script lang="ts">
import { defineComponent } from 'vue'
export default defineComponent({
  name: 'QuotationPage',
})
</script>
<template>
  <!-- 资产流水 -->
  <div style="height: 100vh; background-color: #fff">
    <!-- 资产流水tabbar -->
    <van-sticky>
      <div class="recordHeader">
        <div class="header">
          <var-icon @click="$router.back()" name="chevron-left" :size="30" style="padding-left: 5px" />
          <span style="font-weight: 600; font-size: 16px">{{ $t('assetFlow') }}</span>
          <van-icon style="position: absolute;right:20px" @click="fitterDialog = !fitterDialog" name="filter-o"
            :size="20" />
        </div>
      </div>
    </van-sticky>
    <!-- 交易内容 -->
    <div class="recordBody">
      <!-- 列表 -->
      <van-list v-if="historyList.length > 0" ref="list" :loading-text="$t('loading')" v-model:loading="historyLoading"
        v-model:error="historyError" @load="histroyListHandle" :finished="historyFinished">
        <div class="history" v-for="item in (historyList)" :key="item.id">
          <div style="padding: 10px;">
            <div class="flex mb-2">
              <div class="text-gray-500 text-xs" style="width: 35%"> {{ $t('dztime') }} </div>
              <span style="text-align: right; word-wrap: break-word; width: 65%">{{ formatDate(item.createTime) }}</span>
            </div>
            <!-- 充币地址 -->
            <div class="flex mb-2">
              <div class="text-gray-500 text-xs" style="width: 35%">{{ $t('type') }} </div>
              <span style="text-align: right; word-wrap: break-word; width: 65%">{{ getType(item.type) }}</span>
            </div>
            <!-- 充值数量 -->
            <div class="flex mb-2">
              <div class="text-gray-500 text-xs" style="width: 35%"> {{ $t('quantity') }} </div>
              <span style="text-align: right; word-wrap: break-word; width: 65%">
                {{ item.amount.toFixed(4) }}</span>
            </div>

            <div class="flex mb-2" v-if="(item.fee ?? 0) > 0">
              <div class="text-gray-500 text-xs" style="width: 35%"> {{ $t('payableHandlingFee') }} </div>
              <span style="text-align: right; word-wrap: break-word; width: 65%">
                {{ item.fee?.toFixed(4) || '-' }}</span>
            </div>
          </div>
        </div>
      </van-list>
      <van-empty v-else :description="$t('noData')" />
      <!-- 点击顶部右侧弹出框 -->
      <var-popup position="bottom" v-model:show="fitterDialog">
        <var-form class="p-4">
          <var-space direction="column" justify="center">
            <!-- <var-select :placeholder="$t('currency')" v-model="formData.data.symbol">
              <var-option v-for="item in symbols.data" :label="item" :value="item" />
            </var-select> -->
            <var-select :placeholder="$t('type')" v-model="formData.data.type">
              <var-option v-for="(item) in filterTypes" :label="item.text" :value="item.value" />
            </var-select>
            <div>
              {{ $t('startEndTime') }}
              <div class="text-xs text-gray-500">{{ $t('feeGenerationTime') }}</div>
            </div>
            <var-input readonly :placeholder="$t('startTime')" v-model="formData.data.startTime"
              @click="datePopupS = true" />
            <var-input readonly :placeholder="$t('endTime')" v-model="formData.data.endTime" @click="datePopupE = true" />
            <div class="flex">
              <var-button class="flex-1 mr-1" type="default" native-type="reset" @click="resetSearch">{{ $t('reset')
              }}</var-button>
              <var-button class="flex-1 ml-1" type="primary" native-type="submit" @click="submitSearch">{{ $t('confirm')
              }}</var-button>
            </div>
          </var-space>
        </var-form>
      </var-popup>
      <var-popup position="bottom" v-model:show="datePopupS">
        <nut-date-picker :ok-text="$t('confirm')" :cancel-text="$t('cancel')" :min-date="minDate" @confirm="popupConfirmS"
          @cancel="datePopupS = false">
        </nut-date-picker>
      </var-popup>
      <var-popup position="bottom" v-model:show="datePopupE">
        <nut-date-picker :ok-text="$t('confirm')" :cancel-text="$t('cancel')" :min-date="minDate" @confirm="popupConfirmE"
          @cancel="datePopupE = false">
        </nut-date-picker>
      </var-popup>
    </div>
  </div>
</template>

<script setup lang="ts" name="QuotationPage">
import { useList } from '/@/hooks/useList';
import { transactionAll } from '/@/api/user';
import { formatDate } from '/@/utils/tools';
// import { getWalletList } from '/@/api/transaction';
import { useI18n } from 'vue-i18n';
import { useToken } from '/@/hooks/useToken';
const { userInfo } = useToken();
const { t } = useI18n();
const fitterDialog = ref(false);
const datePopupS = ref(false);
const datePopupE = ref(false);
// const list = reactive({ data: [] });
// const symbols = reactive<any>({ data: [] });
const filterTypes = [
  { value: 36, text: t('NEWACTIVITY_IN') },
  { value: 41, text: t('NEWACTIVITY_FREEZE') },
  { value: 37, text: t('NEWACTIVITY_DEDUCT') },
  { value: 44, text: t('speedContractProfit') },
  { value: 45, text: t('speedContractLoss') },
  { value: 0, text: t('deposit') },
  { value: 1, text: t('withdrawal') },
  { value: 10, text: t('manualRecharge') }
];
const types = ref([
  t('deposit'),
  t('withdraw'),
  t('transfer'),
  t('coinTrading'),
  t('fiatCurrencyPurchase'),
  t('fiatCurrencySale'),
  t('activityReward'),
  t('promotionReward'),
  t('dividend'),
  t('vote'),
  t('manualRecharge'),
  t('pairing'),
  t('activityExchange'),
  t('ctcPurchase'),
  t('ctcSale'),
  t('redEnvelopeSending'),
  t('redEnvelopeReceiving'),
  t('withdrawalCodeWithdrawal'),
  t('withdrawalCodeRecharge'),
  t('perpetualContractFee'),
  t('perpetualContractProfit'),
  t('perpetualContractLoss'),
  t('optionContractFailure'),
  t('optionContractFee'),
  t('optionContractBonus'),
  t('contractRebate'),
  t('levelReward'),
  t('platformFeeIncome'),
  t('forexEntrustTrading'),
  t('forexCancelEntrustTrading'),
  t('income'),
  t('promotionIncome'),
  t('optionsPromotionIncome'),
  t('liquidationCompensation'),
  t('optionTeamIncome'),
  t('optionParityAward'),
  t('NEWACTIVITY_IN'),
  t('NEWACTIVITY_DEDUCT'),
  t('investmentTeamReturn'),
  t('investmentParityReturn'),
  t('investmentPromotionIncome'),
  t('NEWACTIVITY_FREEZE'),
  t('NEWACTIVITY_UNFREEZE'),
  t('NEWACTIVITY_REDEEM_UNFREEZE'),
  t('speedContractProfit'),
  t('speedContractLoss'),
  t('speedContractCommission'),
  t('CONTRACT_FAST_FREEZE'),
  t('CONTRACT_FAST_UNFREEZE'),
]);
console.log(userInfo, "userInfo");
let regtime = userInfo?.registrationTime;
const minDate = new Date(regtime);
const popupConfirmS = ({ selectedValue }) => {
  let date = selectedValue.slice(0, 3).join('-');
  let time = selectedValue.slice(3).join(':');
  let realtime = date + ' ' + time;
  formData.data.startTime = realtime;
  datePopupS.value = false;
};
const popupConfirmE = ({ selectedValue }) => {
  let date = selectedValue.slice(0, 3).join('-');
  let time = selectedValue.slice(3).join(':');
  let realtime = date + ' ' + time;
  formData.data.endTime = realtime;
  datePopupE.value = false;
};
const {
  listError: historyError,
  listLoading: historyLoading,
  listFinished: historyFinished,
  listQuery: historyQuery,
  listData: historyList,
  getList: histroyListHandle,
} = useList(transactionAll);

const formData = reactive({
  data: {
    pageNo: 1,
    pageSize: 10,
    memberId: userInfo?.id || '',
    startTime: '',
    endTime: '',
    symbol: '',
    type: '',
  },
});
submitSearch();
function submitSearch() {
  let params = formData.data;
  Object.keys(params).forEach((v) => {
    console.log(v + '>>' + params[v]);
    historyQuery[v] = params[v];
  });
  histroyListHandle(true);
  fitterDialog.value = false;
  resetSearch();
}

function resetSearch() {
  formData.data = {
    pageNo: 1,
    pageSize: 10,
    memberId: '',
    startTime: '',
    endTime: '',
    symbol: '',
    type: '',
  };
}


function getType(type) {
  return types.value[type];
}
</script>
<style lang="scss" scoped>
// 导航头部
.recordHeader {
  // width: 750px;
  height: 90px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  background: #f5f5f5;

  .header {
    // width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;

    span {
      position: absolute;
      left: 50%;
      transform: translateX(-50%);
    }
  }
}

// 交易内容
.recordBody {
  width: 100%;

  .history {
    width: 95%;
    height: auto;
    margin: 0 auto;
    margin-top: 30px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    background: #f5f5f5;
    border-radius: 10px;
    padding-right: 10px;

    .leftHistory {
      width: 100%;
      height: 20%;
      padding-left: 20px;
      padding-top: 10px;
      display: flex;
      align-items: center;
      justify-content: space-between;
      font-size: 26px;
    }

    //中部
    .centerHistory {
      width: 100%;
      height: 40%;
      padding-left: 20px;
      display: flex;
      // align-items: center;
      justify-content: space-between;
    }

    //右侧
    .rightHistory {
      width: 100%;
      height: 40%;
      padding-left: 20px;
      display: flex;
      align-items: center;
      justify-content: space-between;
      padding-bottom: 10px;
    }
  }
}

//弹出筛选
::v-deep .var-popup {
  .var-field-decorator__middle {
    height: 100px;
    font-size: 23px;
    padding-top: 40px;

    .var-select__select {
      height: 80px;
      display: flex;
      align-items: center;
    }

    .var-icon {
      font-size: 35px;
    }
  }

  .var-field-decorator__placeholder {
    font-size: 25px;
  }
}

::v-deep .var-button--normal {
  height: 70px;
  font-weight: 550;
}

.nut-picker-roller-mask {
  background-image: null;
  /* background-color: var(--popup-content-background-color) */
}
</style>
