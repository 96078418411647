<template>
  <div class="payPage">
    <!-- 头部导航 -->
    <van-sticky>
      <div class="navBarHeader">
        <div @click="$router.back">
          <var-icon name="chevron-left" :size="30" />
        </div>
        <div class="center">
          {{ $t('deposit') }}
        </div>
      </div>
    </van-sticky>

    <div class="select">
      <div class="title">
        {{ $t('SelectCurrency') }}
      </div>
      <div class="wrap">
        <div class="item" @click="currency = 'TRX'" :class="{ active: currency === 'TRX' }">TRC-20</div>
        <div class="item" @click="currency = 'ERC'" :class="{ active: currency === 'ERC' }">ERC-20</div>
        <div class="item" @click="currency = 'BTC'" :class="{ active: currency === 'BTC' }">BTC</div>
      </div>
    </div>
    <div class="qrcode">
      <div class="img">
        <img v-if="qr" :src="qr" alt="qr" />
      </div>
      <div style="padding: 0 15px">
        <van-field v-model="address" rows="1" autosize type="textarea" readonly placeholder="">
          <template #right-icon>
            <var-icon name="content-copy" class="text-yellow-500" @click="copy" :size="24" />
          </template>
        </van-field>
      </div>
    </div>
    <van-uploader :disabled="isUploaded" :after-read="afterRead" :before-read="beforeRead">
      <div class="upload">{{ isUploaded ? $t('uploaded') : $t('uploadImg') }}</div>
    </van-uploader>

    <div class="enter">
      <div class="title">
        {{ $t('EnterAmount') }}
      </div>
      <van-field type="number" v-model="amount" :placeholder="$t('pleaseEnterTheAmount')" />
    </div>
    <div @click="submit" class="submit"> {{ $t('confirm') }} </div>
  </div>
  <van-overlay :show="showOverlay" style="z-index: 2000" />
</template>

<script lang="ts" setup>
import { compressAccurately } from 'image-conversion';
import { payUpload, payConfig, rechargeSubmit } from '/@/api/pay';
import { useRouter } from 'vue-router';
import QRCode from 'qrcode';
import { closeToast, showLoadingToast, showToast } from 'vant';
import { getCurrentInstance, watch } from 'vue';
import { useI18n } from 'vue-i18n';
const { t } = useI18n();
const instance = getCurrentInstance();
const proxy = instance?.proxy as any;
const currency = ref('TRX');
const amount = ref('');
const qr = ref('');
const address = ref<string>('');
const router = useRouter();
let adrrRes;
let image = '';
let key = {
  ERC: 'collection_erc_address',
  BTC: 'collection_btc_address',
  TRX: 'collection_usdt_address',
};
payConfig().then((res: any) => {
  if (res.code === 0) {
    adrrRes = res.data;
    address.value = res.data[key[currency.value]];
    QRCode.toDataURL(address.value).then((img) => {
      qr.value = img;
    });
  }
});

const isUploaded = ref(false);
const showOverlay = ref(false);
const beforeRead: any = async function () {
  showLoadingToast({
    duration: 0,
    message: t('loading'),
    forbidClick: true,
  });
  showOverlay.value = true;
};

async function afterRead(file) {
  let res = await compressAccurately(file.file, {
    size: 100,
    accuracy: 0.8,
    orientation: 6,
    quality: 0.7,
  });
  const fileReader = new FileReader();
  fileReader.onload = (e) => {
    closeToast();
    showOverlay.value = false;
    let content = e.target?.result || '';
    if (content) {
      payUpload({
        base64Data: content,
      }).then((res: any) => {
        if (res.code == 0) {
          image = res.data;
          isUploaded.value = true;
          showToast(t('uploadSuccess'));
        }
      });
    }
  };
  // readAsDataURL
  fileReader.readAsDataURL(res);
}

watch(currency, (val: any) => {
  address.value = adrrRes[key[val]];
  QRCode.toDataURL(address.value).then((img) => {
    qr.value = img;
  });
});

function submit() {
  if (!image) {
    showToast(t('serviceCode.1036'));
    return;
  }
  if (Number(amount.value) <= 0) {
    showToast(t('serviceCode.1037'));
    return;
  }
  const params = {
    amount: amount.value,
    baseSymbol: 'USDT',
    image: image,
    symbol: `${currency.value}/USDT`,
  };

  rechargeSubmit(params).then((res: any) => {
    if (res.code === 0) {
      showToast(t('r48h'));
      setTimeout(() => {
        image = '';
        amount.value = '';
        isUploaded.value = false;
        router.push({ path: '/depositRecord' });
      }, 2000);
    }
  });
}

function copy() {
  proxy?.$copyText(address.value).then(() => {
    showToast(t('LinkCopiedSuccessfully'));
  });
}
</script>

<style lang="scss" scoped>
@import './css/pay.scss';
</style>
