import * as echarts from 'echarts';
import axios from 'axios';
import HuobiWebSocket from '/@/plugins/huobi';
import { DetailTick, Kline } from '/@/types/api';
import { Period } from '/@/enum';
// import { useRoute } from 'vue-router';

import '/@/plugins/kline.css';

interface Config {
  isKline?: boolean;
  fromName?: string;
  isSubDeep?: boolean;
  isSubTrad?: boolean;
  isSubDetail?: boolean;
  wsCallback?: Function;
}
export function useKline(_config: Config = {}) {
  const config = { isKline: true, fromName: 'BTC', isSubDeep: false, isSubTrad: false, isSubDetail: false, ..._config };
  // const route = useRoute();

  // 获取传入的coinName.value和
  const coinName = ref(config.fromName.toLocaleLowerCase());
  const period = ref('1min');
  const isTime = ref(1);
  //   const coin_id = ref(4);

  const MA5 = ref();
  const MA10 = ref();
  const MA30 = ref();
  const volMA5 = ref();
  const volMA10 = ref();
  const coninInfo = ref<DetailTick>();

  //   const interval = ref();
  //   const volBaseArr = ref([]);
  let myChart: echarts.ECharts;
  const kline = ref<Kline[]>([]);
  let deepData;
  let tradeData;
  let detailData;
  let ws: HuobiWebSocket;
  const klineSubParams = computed(() => {
    return `market.${coinName.value}usdt.kline.${period.value}`;
  });
  const deepSubParams = computed(() => {
    return `market.${coinName.value}usdt.depth.step0`;
  });
  const tradeSubParams = computed(() => {
    return `market.${coinName.value}usdt.trade.detail`;
  });
  const detailSubParams = computed(() => {
    return `market.${coinName.value}usdt.detail`;
  });

  watch(
    () => klineSubParams.value,
    async (val, old) => {
      if (!config.isKline) {
        return;
      }
      ws.unsub(old);
      myChart.clear();
      draw();
      await getLastKline();
      ws.sub(val);
    },
  );

  watch(
    () => deepSubParams.value,
    (val, old) => {
      if (!config.isSubDeep) {
        return;
      }
      ws.unsub(old);
      ws.sub(val);
    },
  );
  watch(
    () => tradeSubParams.value,
    (val, old) => {
      if (!config.isSubTrad) {
        return;
      }
      ws.unsub(old);
      ws.sub(val);
    },
  );
  watch(
    () => detailSubParams.value,
    (val, old) => {
      if (!config.isSubDetail) {
        return;
      }
      ws.unsub(old);
      ws.sub(val);
    },
  );

  onMounted(async function () {
    if (config.isKline) {
      myChart = echarts.init(<HTMLElement>document.getElementById('kLineChart'));
      draw();
      await getLastKline();
    }

    // 接下來的更新採用websocket
    ws = new HuobiWebSocket('wss://api.huobi.pro/ws');
    ws.onMessage((d) => {
      // console.log('d', d);
      switch (d.ch) {
        case klineSubParams.value:
          // console.log('d', d);
          if (kline.value[kline.value.length - 1] && kline.value[kline.value.length - 1].id == d.tick.id) {
            kline.value.pop();
          }
          kline.value.push(d.tick);

          if (kline.value.length > 150) {
            kline.value.shift();
          }
          // TODO: 現在的這個資料轉換方式顯然效能不好 之後應該修正
          // 實際上兩種圖就差在資料格式
          if (isTime.value === 0) {
            sendTimeHttp();
          } else {
            sendKHttp();
          }
          break;
        case deepSubParams.value:
          deepData = d.tick;
          break;
        case tradeSubParams.value:
          tradeData = d.tick.data;
          break;
        case detailSubParams.value:
          coninInfo.value = d.tick;
          break;
      }
      config.wsCallback &&
        config.wsCallback({
          deepData,
          tradeData,
          detailData,
        });
    });

    ws.onOpen(() => {
      config.isKline && ws.sub(klineSubParams.value);
      config.isSubDeep && ws.sub(deepSubParams.value);
      config.isSubTrad && ws.sub(tradeSubParams.value);
      config.isSubDetail && ws.sub(detailSubParams.value);
    });
  });

  onUnmounted(() => {
    console.log('onUnmounted');
    if (ws)
      ws.close();
  });

  onActivated(() => {
    console.log('onActivated');
  });

  // onDeactivated(() => {
  //   console.log('onDeactivated');
  //   ws.close();
  // });

  // function changeWsSubscription() {}

  async function getLastKline() {
    // 分時圖資料?
    // 實際上好像是k線的變形
    // 使用http api取得稍早的資料
    try {
      // 基本行數是150行
      const base = await axios.get('https://api.huobi.pro/market/history/kline', {
        params: { symbol: coinName.value + 'usdt', period: period.value },
      });
      // const base = await axios.post('http://123.58.212.6:6012/swap/history',
      //   { symbol: 'BTC/USDT', from: 1687168272000, to: 1687258061000, resolution: 1 },
      // );
      if (base.data.status == 'ok') {
        kline.value = base.data.data.reverse();
      }
    } catch (error) {
      console.log(`can't get base data`);
    }
  }

  async function changePeriod(_period: string) {
    ws.unsub(klineSubParams.value);
    period.value = _period;
    isTime.value = parseInt(_period);
    isTime.value === 0 && (period.value = '1min');
    myChart.clear();
    draw();
    await getLastKline();
    ws.sub(klineSubParams.value);
  }

  // 发送分时图http请求
  // 只是格式不同而已
  function sendTimeHttp() {
    const tmp = kline.value;
    const arr = <number[][]>[];
    const dates = <number[]>[];
    const volumes = <number[][]>[];
    const priceArr = <number[]>[];
    tmp.forEach((item, index) => {
      // 分时图的要push
      // *1转化为数字格式
      arr.push([item.open, item.close, item.low, item.high, item.vol]);
      dates.push(item.id);
      volumes.push([index, item.vol, item.open > item.close ? 1 : -1]);
      priceArr.push(item.close);
    });
    getTimeLine(arr, dates, volumes, priceArr);
  }

  // 发送k线http请求
  // 實際上是轉換資料格式
  function sendKHttp() {
    const tmp = kline.value;
    // return;

    const arr = <number[][]>[];
    const dates = <number[]>[];
    const volumes = <number[][]>[];
    tmp.forEach((item, index) => {
      arr.push([item.open, item.close, item.low, item.high, item.vol]);
      dates.push(item.id);
      volumes.push([index, item.vol, item.open > item.close ? 1 : -1]);
    });

    getKline(arr, dates, volumes);
  }

  // randomVol() {
  // 	let index = Math.round(Math.random()*10);
  // 	return volBaseArr[index];
  // },

  // 生成分时图
  function getTimeLine(data: number[][], dates: number[], volumes: number[][], priceArr: number[]) {
    const dataMA5 = calculateMA(5, data);
    const dataMA10 = calculateMA(10, data);
    const dataMA30 = calculateMA(30, data);
    // const volumeMA5 = calculateMA(5, volumes);
    // const volumeMA10 = calculateMA(10, volumes);

    myChart.setOption({
      xAxis: [
        {
          data: dates,
          type: 'category',
        },
        {
          data: dates,
        },
      ],
      tooltip: {
        formatter(params) {
          // console.log(params);
          let tooltip = '';
          let time = '',
            open = 0,
            high = 0,
            low = 0,
            close = 0,
            amount = 0;
          for (let i = 0; i < params.length; i++) {
            if (params[i].seriesName === '日K') {
              time = params[i].name;
              time = echarts.format.formatTime('MM-dd hh:mm:ss ', new Date(params[i].name * 1000))
              const index = params[i].dataIndex;

              open = data[index].length > 1 ? Number(formatterNum(data[index][0], 2)) : 0;
              close = data[index].length > 1 ? Number(formatterNum(data[index][1], 2)) : 0;
              low = data[index].length > 1 ? Number(formatterNum(data[index][2], 2)) : 0;
              high = data[index].length > 1 ? Number(formatterNum(data[index][3], 2)) : 0;
              amount = data[index].length > 1 ? Number(formatterNum(data[index][4], 2)) : 0;
              tooltip =
                '<div class="charts-tooltip">' +
                '<div class="charts-tooltip-row"><div class="ctr-label">' +
                'time' +
                '</div><div class="ctr-value">' +
                time +
                '</div></div>' +
                '<div class="charts-tooltip-row"><div class="ctr-label">' +
                'open' +
                '</div><div class="ctr-value">' +
                open +
                '</div></div>' +
                '<div class="charts-tooltip-row"><div class="ctr-label">' +
                'high' +
                '</div><div class="ctr-value">' +
                high +
                '</div></div>' +
                '<div class="charts-tooltip-row"><div class="ctr-label">' +
                'low' +
                '</div><div class="ctr-value">' +
                low +
                '</div></div>' +
                '<div class="charts-tooltip-row"><div class="ctr-label">' +
                'close' +
                '</div><div class="ctr-value">' +
                close +
                '</div></div>' +
                '<div class="charts-tooltip-row"><div class="ctr-label">' +
                'amount' +
                '</div><div class="ctr-value">' +
                amount +
                '</div></div></div>';
            }
            if (params[i].seriesName === 'MA5') {
              MA5.value = params[i].data !== 'NAN' ? Number(formatterNum(params[i].data, 2)) : 0;
            }
            if (params[i].seriesName === 'MA10') {
              MA10.value = params[i].data !== 'NAN' ? Number(formatterNum(params[i].data, 2)) : 0;
            }
            if (params[i].seriesName === 'MA30') {
              MA30.value = params[i].data !== 'NAN' ? Number(formatterNum(params[i].data, 2)) : 0;
            }
            if (params[i].seriesName === 'VolumeMA5') {
              volMA5.value = params[i].data !== 'NAN' ? Number(formatterNum(params[i].data, 2)) : 0;
            }
            if (params[i].seriesName === 'VolumeMA10') {
              volMA10.value = params[i].data !== 'NAN' ? Number(formatterNum(params[i].data, 2)) : 0;
            }
          }
          return tooltip;
        },
      },
      series: [
        {
          name: '日K',
          type: 'line',
          data: priceArr,
          symbol: 'none',
          itemStyle: {
            color: '#4491d6',
          },
          areaStyle: {
            color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [
              {
                offset: 0,
                color: '#428ed1',
              },
              {
                offset: 1,
                color: '#1b1d22',
              },
            ]),
          },
        },

        {
          name: 'MA5',
          data: dataMA5,
        },
        {
          name: 'MA10',
          data: dataMA10,
        },
        {
          name: 'MA30',
          data: dataMA30,
        },
        {
          name: 'Volume',
          data: volumes,
        },
        /*
            {
              name: 'VolumeMA5',
              data: volumeMA5
            },
            {
              name: 'VolumeMA10',
              data: volumeMA10
            },
            */
      ],
    });
  }

  // 获取k线数据,生成k线
  function getKline(data, dates, volumes) {
    // 把后台取到的数据格式化
    // 格式：[开盘价，收盘价，最低价，最高价，时间戳]
    const dataMA5 = calculateMA(5, data);
    const dataMA10 = calculateMA(10, data);
    const dataMA30 = calculateMA(30, data);
    const volumeMA5 = calculateMA(5, volumes);
    const volumeMA10 = calculateMA(10, volumes);

    myChart.setOption({
      xAxis: [
        {
          data: dates,
        },
        {
          data: dates,
        },
      ],
      series: [
        {
          name: '日K',
          data: data,
        },
        {
          name: 'MA5',
          data: dataMA5,
        },
        {
          name: 'MA10',
          data: dataMA10,
        },
        {
          name: 'MA30',
          data: dataMA30,
        },
        {
          name: 'Volume',
          data: volumes,
        },
        {
          name: 'VolumeMA5',
          data: volumeMA5,
        },
        {
          name: 'VolumeMA10',
          data: volumeMA10,
        },
      ],
    });
  }

  // 截取数字字符串 保留precision小数
  function formatterNum(value, precision) {
    const reg = new RegExp('^\\d+(?:\\.\\d{0,' + precision + '})?');
    return value.toString().match(reg);
  }

  // 计算MA
  function calculateMA(dayCount, data) {
    const result = <string[]>[];
    for (let i = 0, len = data.length; i < len; i++) {
      if (i < dayCount) {
        result.push('-');
        continue;
      }
      let sum = 0;
      for (let j = 0; j < dayCount; j++) {
        sum += data[i - j][1];
      }
      // console.log(sum, dayCount)
      result.push((sum / dayCount).toFixed(2));
    }
    return result;
  }

  // 绘制(配置项)
  function draw() {
    const upColor = '#03ad91';
    const downColor = '#dd345b';
    const colorList = [
      '#c23531',
      '#2f4554',
      '#61a0a8',
      '#d48265',
      '#91c7ae',
      '#749f83',
      '#ca8622',
      '#bda29a',
      '#6e7074',
      '#546570',
      '#c4ccd3',
    ];
    // const labelFont = 'bold 12px Sans-serif';
    const option = {
      backgroundColor: '#fff',
      title: {
        show: false,
      },
      legend: {
        show: false,
      },
      visualMap: {
        show: false,
        seriesIndex: 4,
        dimension: 2,
        pieces: [
          {
            value: 1,
            color: downColor,
          },
          {
            value: -1,
            color: upColor,
          },
        ],
      },
      grid: [
        {
          top: '5%',
          left: 10,
          right: 10,
          bottom:'5%',
        },
        {
          left: 20,
          right: 20,
          height: '0%',
          bottom:0,
        },
      ],
      axisPointer: {
        //坐标轴指示器配置项
        link: {
          xAxisIndex: 'all',
        },
        label: {
          backgroundColor: '#0d1723',
          color: '#000',
          borderColor: 'rgb(99, 117, 139)',
          borderWidth: 1,
          borderRadius: 2,
          fontSize: 10,
        },
      },
      xAxis: [
        {
          type: 'category', //坐标轴类型。(value:数值轴，适用于连续数据。,category:类目轴，适用于离散的类目数据,time: 时间轴，适用于连续的时序数据,log:对数轴。适用于对数数据)
          data: [], //类目数据，在类目轴（type: 'category'）中有效。
          scale: true,
          boundaryGap: false, //坐标轴两边留白策略，类目轴和非类目轴的设置和表现不一样。
          axisLine: {
            show: false,
          }, //坐标轴轴线相关设置
          axisTick: {
            show: false,
          }, //坐标轴刻度相关设置。
          axisLabel: {
            show: false,
          }, //坐标轴刻度标签的相关设置。
          splitLine: {
            show: false,
            lineStyle: {
              color: 'rgba(255,255,255, 0.1)',
            },
          }, //坐标轴在 grid 区域中的分隔线。
          min: 'dataMin', //坐标轴刻度最小值。可以设置成特殊值 'dataMin'，此时取数据在该轴上的最小值作为最小刻度。
          max: 'dataMax', //坐标轴刻度最大值。可以设置成特殊值 'dataMax'，此时取数据在该轴上的最大值作为最大刻度。
          axisPointer: {
            label: {
              margin: 200,
            },
          },
        },
        {
          type: 'category',
          gridIndex: 1, //x 轴所在的 grid 的索引，默认位于第一个 grid。
          data: [], //类目数据，在类目轴（type: 'category'）中有效。
          scale: true,
          boundaryGap: false, //坐标轴两边留白策略，类目轴和非类目轴的设置和表现不一样。
          axisLine: {
            show: false,
            lineStyle: {
              color: 'rgba(255,255,255,1)',
              width: 1,
            },
          }, //坐标轴轴线相关设置
          axisTick: {
            show: false,
          }, //坐标轴刻度相关设置。
          axisLabel: {
            //坐标轴刻度标签的相关设置。
            show: true,
            margin: 6,
            fontSize: 10,
            color: 'rgba(99, 117, 139, 1.0)',
            formatter: function (value) {
              return echarts.format.formatTime('MM-dd', new Date(value * 1000));
            },
          },
          splitNumber: 20,
          splitLine: {
            show: false,
            lineStyle: {
              color: 'rgba(255,255,255, 0.1)',
            },
          }, //坐标轴在 grid 区域中的分隔线。
          min: 'dataMin', //坐标轴刻度最小值。可以设置成特殊值 'dataMin'，此时取数据在该轴上的最小值作为最小刻度。
          max: 'dataMax', //坐标轴刻度最大值。可以设置成特殊值 'dataMax'，此时取数据在该轴上的最大值作为最大刻度。
          // axisPointer: { show: true, type: 'none', label: { show: false }},
        },
      ],
      yAxis: [
        {
          type: 'value', //坐标轴类型。(value:数值轴，适用于连续数据。,category:类目轴，适用于离散的类目数据,time: 时间轴，适用于连续的时序数据,log:对数轴。适用于对数数据)
          position: 'right', //y 轴的位置。'left','right'
          scale: true, //是否是脱离 0 值比例。设置成 true 后坐标刻度不会强制包含零刻度。在双数值轴的散点图中比较有用。(在设置 min 和 max 之后该配置项无效。)
          axisLine: {
            show: true,
          }, //坐标轴轴线相关设置。

          axisTick: {
            show: true,
            inside: true,
          }, //坐标轴刻度相关设置。
          axisLabel: {
            //坐标轴刻度标签的相关设置。
            show: true,
            color: 'rgba(99, 117, 139, 1.0)',
            inside: true,
            fontSize: 10,
            formatter: function (value) {
              return Number(value).toFixed(2);
            },
          },
          splitLine: {
            show: false,
            lineStyle: {
              color: 'rgba(255,255,255, 0.1)',
            },
          }, //坐标轴在 grid 区域中的分隔线。
        },
        {
          type: 'value',
          position: 'right',
          scale: true,
          gridIndex: 1,
          axisLine: {
            show: false,
          },
          axisTick: {
            show: false,
          },
          axisLabel: {
            show: false,
          },
          splitLine: {
            show: false,
          },
        },
      ],

      animation: false, //是否开启动画。
      color: colorList,
      tooltip: {
        show: true, //是否显示提示框组件，包括提示框浮层和 axisPointer。
        trigger: 'axis', //触发类型。item,axis,none
        formatter(params) {
          let tooltip = '';
          let time = '',
            open = 0,
            high = 0,
            low = 0,
            close = 0,
            amount = 0;
          for (let i = 0; i < params.length; i++) {
            if (params[i].seriesName === '日K') {
              time = params[i].name;
              time = echarts.format.formatTime('MM-dd hh:mm:ss ', new Date(params[i].name * 1000))
              open = params[i].data.length > 1 ? Number(formatterNum(params[i].data[1], 2)) : 0;
              close = params[i].data.length > 1 ? Number(formatterNum(params[i].data[2], 2)) : 0;
              low = params[i].data.length > 1 ? Number(formatterNum(params[i].data[3], 2)) : 0;
              high = params[i].data.length > 1 ? Number(formatterNum(params[i].data[4], 2)) : 0;
              amount = params[i].data.length > 1 ? Number(formatterNum(params[i].data[5], 2)) : 0;
              // console.log(time,open,close,low,high,amount)
              tooltip =
                '<div class="charts-tooltip">' +
                '<div class="charts-tooltip-row"><div class="ctr-label">' +
                'time' +
                '</div><div class="ctr-value">' +
                time +
                '</div></div>' +
                '<div class="charts-tooltip-row"><div class="ctr-label">' +
                'open' +
                '</div><div class="ctr-value">' +
                open +
                '</div></div>' +
                '<div class="charts-tooltip-row"><div class="ctr-label">' +
                'high' +
                '</div><div class="ctr-value">' +
                high +
                '</div></div>' +
                '<div class="charts-tooltip-row"><div class="ctr-label">' +
                'low' +
                '</div><div class="ctr-value">' +
                low +
                '</div></div>' +
                '<div class="charts-tooltip-row"><div class="ctr-label">' +
                'close' +
                '</div><div class="ctr-value">' +
                close +
                '</div></div>' +
                '<div class="charts-tooltip-row"><div class="ctr-label">' +
                'amount' +
                '</div><div class="ctr-value">' +
                amount +
                '</div></div></div>';
            }
            if (params[i].seriesName === 'MA5') {
              MA5.value = params[i].data !== 'NAN' ? Number(formatterNum(params[i].data, 2)) : 0;
            }
            if (params[i].seriesName === 'MA10') {
              MA10.value = params[i].data !== 'NAN' ? Number(formatterNum(params[i].data, 2)) : 0;
            }
            if (params[i].seriesName === 'MA30') {
              MA30.value = params[i].data !== 'NAN' ? Number(formatterNum(params[i].data, 2)) : 0;
            }
            if (params[i].seriesName === 'VolumeMA5') {
              volMA5.value = params[i].data !== 'NAN' ? Number(formatterNum(params[i].data, 2)) : 0;
            }
            if (params[i].seriesName === 'VolumeMA10') {
              volMA10.value = params[i].data !== 'NAN' ? Number(formatterNum(params[i].data, 2)) : 0;
            }
          }
          return tooltip;
        },
        triggerOn: 'click', //提示框触发的条件 'mousemove','click','mousemove|click','none'
        textStyle: {
          fontSize: 10,
        }, //提示框浮层的文本样式
        backgroundColor: 'rgba(30,42,66,0.8);', //提示框浮层的背景颜色。
        borderColor: '#2f3a56', //提示框浮层的边框颜色。
        borderWidth: 2,
        position: function (pos, _params, _el, _elRect, size) {
          //提示框浮层的位置，默认不设置时位置会跟随鼠标的位置。
          const obj = {
            top: 20,
          };
          obj[['left', 'right'][+(pos[0] < size.viewSize[0] / 2)]] = 10;
          return obj;
        },
        axisPointer: {
          //坐标轴指示器配置项。
          label: {
            color: 'rgba(255,255,255,.87)',
            fontSize: 9,
            backgroundColor: '#020204',
            borderColor: '#000',
            shadowBlur: 0,
            borderWidth: 0.5,
            padding: [4, 2, 3, 2],
            formatter: function (params) {
              if(params.axisDimension == 'x'&& params.axisIndex == 0){
                return echarts.format.formatTime('MM-dd hh:mm:ss ', new Date(params.value * 1000));
              }
            },
          },
          animation: false,
          type: 'cross',
          lineStyle: {
            color: {
              type: 'linear',
              x: 0,
              y: 0,
              x2: 0,
              y2: 1,
              colorStops: [
                {
                  offset: 0,
                  color: 'rgba(30, 42, 66, 0.1)', // 0% 处的颜色
                },
                {
                  offset: 0.7,
                  color: 'rgba(30, 42, 66,0.9)', // 100% 处的颜色
                },
                {
                  offset: 1,
                  color: 'rgba(30, 42, 66,0.2)', // 100% 处的颜色
                },
              ],
            },
            width: 1,
            shadowColor: 'rgba(30, 42, 66,0.7)',
            shadowBlur: 0,
            shadowOffsetY: 68,
          },
        },
      },

      dataZoom: [
        {
          //用于区域缩放
          type: 'inside',
          xAxisIndex: [0, 1],
          realtime: false,
          start: 50,
          end: 100,
          preventDefaultMouseMove: false,
        },
      ],
      series: [
        {
          type: 'candlestick',
          name: '日K',
          data: [],
          itemStyle: {
            color: upColor,
            color0: downColor,
            borderColor: upColor,
            borderColor0: downColor,
          },
          markPoint: {
            symbol: 'rect',
            symbolSize: [-10, 0.5],
            symbolOffset: [5, 0],
            itemStyle: {
              color: 'rgba(255,255,255,.87)',
            },
            label: {
              color: 'rgba(0,0,0,1)',
              offset: [10, 0],
              fontSize: 10,
              align: 'left',
              formatter: function (params) {
                return Number(params.value).toFixed(2);
              },
            },
            data: [
              {
                name: 'max',
                type: 'max',
                valueDim: 'highest',
              },
              {
                name: 'min',
                type: 'min',
                valueDim: 'lowest',
              },
            ],
          },
        },
        {
          name: 'MA5',
          type: 'line',
          data: [],
          symbol: 'none', //去除圆点
          smooth: true,
          lineStyle: { normal: { opacity: 1, width: 1, color: '#eef4ba' } },
          z: 5,
        },
        {
          name: 'MA10',
          type: 'line',
          data: [],
          symbol: 'none', //去除圆点
          smooth: true,
          lineStyle: { normal: { opacity: 1, width: 1, color: '#83c1c5' } },
          z: 4,
        },
        {
          name: 'MA30',
          type: 'line',
          data: [],
          symbol: 'none', //去除圆点
          smooth: true,
          lineStyle: { normal: { opacity: 1, width: 1, color: '#b39cd8' } },
          z: 3,
        },
        {
          name: 'Volume',
          type: 'bar',
          xAxisIndex: 1,
          yAxisIndex: 1,
          data: [],
        },
        {
          name: 'VolumeMA5',
          type: 'line',
          xAxisIndex: 1,
          yAxisIndex: 1,
          data: [],
          symbol: 'none', //去除圆点
          smooth: true,
          lineStyle: { normal: { opacity: 1, width: 1, color: '#eef4ba' } },
          z: 5,
        },
        {
          name: 'VolumeMA10',
          type: 'line',
          xAxisIndex: 1,
          yAxisIndex: 1,
          data: [],
          symbol: 'none', //去除圆点
          smooth: true,
          lineStyle: { normal: { opacity: 1, width: 1, color: '#83c1c5' } },
          z: 4,
        },
      ],
    };
    myChart.setOption(option);
    // 加载上一页数据
    myChart.on('datazoom', function (params: any) {
      const num = params.batch[0].start;
      if (num == 0) {
        console.log('到最左边了');
      }
    });
    window.addEventListener('resize', () => {
      myChart.resize();
    });
  }

  return {
    MA10,
    MA30,
    MA5,
    volMA5,
    volMA10,
    coinName,
    period,
    Period,
    coninInfo,
    changePeriod,
  };
}
