<script lang="ts">
import { defineComponent } from 'vue'
export default defineComponent({
  name: 'Intelligent',
})
</script>
<template>
  <!-- 头部导航 -->
  <!-- <div class="flex justify-center Header">
      <div class="HeaderTab flex">
        <div @click="tocheckTab(0)" class="flex justify-center" :class="data.active == 0 ? 'active' : ''">
          <span class="block flex">{{ $t('Quickconstructionarea') }}</span>
        </div>
        <div @click="tocheckTab(1)" class="flex justify-center" :class="data.active == 1 ? 'active' : ''">
          <span class="block flex">{{ $t('Selfselectionarea') }} </span>
        </div>
      </div>
    </div> -->
  <!-- <van-sticky>
  <div class="navBarHeader">
    <div class="header">
      <span>{{ $t('Intelligent') }}</span>
    </div>
  </div>
  </van-sticky> -->
  <var-app-bar
    :title="$t('financialManagement')"
    color="#F7F7F7"
    title-position="center"
    style="z-index: 100000; color: #333333; font-weight: 600; box-shadow: none"
  />
  <!-- 我是快选区 -->
  <div class="KXQ pt-18.5">
    <!-- 筛选/投资记录 -->
    <div
      class="KXQHeader flex justify-between h-80px py-10px px-30px bg-white z-index-100"
      style="border-bottom: 1px solid #e2e2e2; width: calc(100% - 30px)"
    >
      <!-- <div class="h-80px flex justify-between"> -->
      <!-- 筛选 -->
      <div class="w40 flex items-center justify-between">
        <div @click="showselectPopu" class="flex flex-row items-center">
          <img class="h-35px w-35px" src="../../assets/images/Intelligentselect.png" />
          <span class="font-600 ml-20px">{{ $t('filter') }}</span>
        </div>
        <!-- <div @click="showserch">
              <span class="ml-40px"><img class="h-35px w-35px" src="../../assets/images/Intelligentquery.png" /></span>
              <span class="font-600 ml-20px">{{ $t('search') }}</span>
            </div> -->
      </div>
      <!-- to投资记录 -->
      <div class="KXQHeaderRight flex flex-row items-center" @click="torecordsPage">
        <span class="text-gray-600">{{ $t('Myinvestmenthistory') }}</span>
        <van-icon class="text-gray-500" name="arrow" size="20" />
      </div>
      <!-- </div> -->
      <!-- <div v-show="isshowserch" class="w100 justify-between flex items-center px-10px" style="width: 100%">
          <div class="w-10/13 rounded-xl h-50px bg-gray-200 flex items-center px-20px">
            <van-icon class="text-gray-400" name="search" size="19" />
            <van-field
              style="padding-left: 10px"
              @input="searchHY"
              v-model="data.filterHY"
              placeholder="请输入合约编号或投资币种"
            />
          </div>
          <span @click="showserch" class="text-yellow-400 block w-100px text-right"> 取消 </span>
        </div> -->
    </div>

    <!-- 我是快选区列表 -->
    <div class="KXQCon w100 p-40px">
      <van-pull-refresh v-model="refresh.loading" @refresh="onRefresh" v-if="TZlist.length != 0">
        <van-list
          v-model:loading="Listconfig.loading"
          :loading-text="$t('loading')"
          :finished="Listconfig.finished"
          :finished-text="$t('Nomoreatmoment')"
          @load="onLoad"
        >
          <div v-for="(item, index) in TZlist" :key="index" class="relative TZlistItem h-280px rounded-xl w100 p-20px bg-gray-100 mb-25px">
            <!-- Currency/日收益率 -->
            <div class="flex items-center justify-between pb-20px" style="border-bottom: 1px solid #e2e2e2">
              <!-- Currency -->
              <div class="flex items-center">
                <img class="w-40px h-40px mr-20px" :src="item.bannerImageUrl" />
                <span class="font-600">{{ item.symbol }}</span>
              </div>
              <!-- 日收益率 -->
              <div class="flex items-center">
                <span class="text-gray-500">{{ $t('dailyReturn') }}:{{ (item.releasePercent * 10000) / 100 }}%</span>
                <van-icon class="text-gray-500" name="arrow" size="15" />
              </div>
            </div>
            <!-- 合同编号/筹资额度 -->
            <div class="TZlistItemCon flex w100 h-120px mt-40px border-bottom" style="flex-direction: column">
              <!-- 合同编号 -->
              <div>
                <div class="font-500 text-gray-500">{{ $t('Contractnumber') }}:</div>
                <div style="text-align: start">
                  {{ item.lockedUnit }}
                </div>
              </div>
              <!-- 筹资额度 -->
              <div>
                <span class="font-500 text-gray-500">{{ $t('Fundinglimit') }}: </span>
                <div style="flex: 1; text-align: start"> {{ item.miningInvite }}{{ item.acceptUnit }} </div>
              </div>
            </div>
            <!-- 立即投资button -->
            <van-button
              size="small"
              style="bottom: 10px; right: 20px; background-color: #f99f05; border: none"
              class="absolute rounded-xl font-600 px-4"
              :color="data.buttonColor"
              @click="toInvestment(item)"
              >{{ $t('ImmediateInvestment') }}</van-button
            >
          </div>
        </van-list>
      </van-pull-refresh>

      <!-- if no data -->
      <div v-if="TZlist.length == 0">
        <van-empty :description="$t('noData')" />
      </div>
    </div>
  </div>
  <!-- 我是快选区筛选弹窗 -->
  <van-popup class="p-40px" v-model:show="showBottom" round position="bottom" :style="{ height: '32%' }">
    <div class="popupTitle mb-20px">
      <span class="font-600 text-lg">{{ $t('filter') }}</span>
    </div>
    <div style="width: 100%" class="popupselect mt-20px flex items-center justify-between">
      <div
        :class="popupSelect.activeName == item.unit ? 'popuactive' : ''"
        class="popupselectItem bg-gray-200 w-1/4 h-80px flex items-center justify-center"
        v-for="(item, index) in data.popupselectList"
        @click="changeselect(item.unit)"
        :key="index"
      >
        <span class="text-black-300">{{ item.unit }}</span>
      </div>
    </div>
    <div class="searchBZ mt-20px">
      <div class="searchBZtitle">
        <span class="font-600">{{ $t('SearchCurrency') }}</span>
        <div class="w100 rounded-md h-70px bg-gray-200 flex items-center mt-20px px-30px">
          <van-icon name="search" size="19" />
          <van-field style="padding-left: 10px" v-model="Listdata.lockedUnit" :placeholder="$t('PleaseEnter')" />
        </div>
      </div>
    </div>
    <div class="popupbotton flex justify-between mt-20px">
      <van-button @click="Rest" style="width: 48%" class="mt-3 h-70px font-600 text-xxxl" type="primary" color="#adadad">{{
        $t('reset')
      }}</van-button>
      <van-button @click="searchBZ" style="width: 48%" class="mt-3 h-70px font-600 text-xxxl" type="primary" :color="data.buttonColor">{{
        $t('complete')
      }}</van-button>
    </div>
  </van-popup>
</template>

<script lang="ts" setup name="Intelligent">
  import { getnewactivitypage, getcoinlegal } from '/@/api/Intelligent';
  import { setting } from '/@/api/user';
  import { useRouter } from 'vue-router';
  import { ref, reactive, onMounted } from 'vue';
  import { useToken } from '/@/hooks/useToken';
  import { useI18n } from 'vue-i18n';
  import { popupSelectType, ListdataType, ListconfigType, TZlistItemType, refreshType, dataType } from '/@/types/Intelligent';
  import { showToast, showConfirmDialog } from 'vant';
  const { t } = useI18n();
  const { token } = useToken();
  const showBottom = ref(false);
  const router = useRouter();

  let refresh = reactive<refreshType>({
    loading: true,
  });

  let popupSelect = reactive<popupSelectType>({
    SizeColor: '#555555',
    activeColor: '#FFFFFF',
    activeName: '全部',
  });

  let TZlist = ref<TZlistItemType[]>([]);

  let data = reactive<dataType>({
    popupselectList: [],
    filterHY: '',
    code: '2F2fwedasxdas22344',
    iconColor: '#F9952C',
    buttonColor: '#ec8920',
  });

  onMounted(() => {
    getcoin();
    getList('search');
  });

  const onRefresh = (): void => {
    Listdata.pageNo = 1;
    Listdata.pageSize = 10;
    getList('refresh');
  };

  const Rest = () => {
    Listdata.lockedUnit = '';
  };

  let Listdata = reactive<ListdataType>({
    lockedUnit: '',
    pageNo: 1,
    pageSize: 10,
    unit: '',
  });

  let Listconfig = reactive<ListconfigType>({
    checked: false,
    finished: false,
    loading: false,
  });

  const changeselect = (name) => {
    popupSelect.activeName = name;
  };

  const getcoin = () => {
    getcoinlegal({}).then((res: any) => {
      console.log(res);
      if (res.code == 0) {
        console.log(res.data);
        let selectQuery = {
          unit: t('all'),
        };
        data.popupselectList.push(selectQuery);
        data.popupselectList = data.popupselectList.concat(res.data);
        data.popupselectList = data.popupselectList.filter((item) => {
          return item.unit != 'USDT';
        });
        console.log(data.popupselectList);
      }
    });
  };

  const getList = (type) => {
    getnewactivitypage(Listdata).then((res: any) => {
      if (res.code == 0) {
        // if (type == 'refresh') {
        //   TZlist.value = res.data.content;
        //   console.log(111111);
        //   refresh.loading = false;
        // }
        // if (type == 'onload') {
        //   TZlist.value = TZlist.value.concat(res.data.content);
        // } else {
        //   TZlist.value = res.data.content;
        // }

        // if (res.data.totalPages == Listdata.pageNo) {
        //   Listconfig.finished = true;
        //   Listconfig.loading = false;
        //   refresh.loading = false;
        // } else {
        //   Listconfig.finished = false;
        // }
        if (type == 'refresh') {
          TZlist.value = res.data.content;
          refresh.loading = false;
        }
        if (type == 'onLoad') {
          TZlist.value = TZlist.value?.concat(res.data.content);
          console.log(TZlist.value);
        }
        if (type == 'search') {
          TZlist.value = res.data.content;
        }
        res.data.totalPages <= Listdata.pageNo
          ? ((Listconfig.finished = true), (Listconfig.loading = false))
          : (Listconfig.finished = false),
          (Listconfig.loading = false);
      }
    });
  };

  const searchBZ = () => {
    const isunit: any = popupSelect.activeName == t('all') ? '' : popupSelect.activeName;
    TZlist.value = [];
    Listdata.unit = isunit;
    Listdata.pageNo = 1;
    Listdata.pageSize = 10;
    showBottom.value = false;

    getList('search');
  };

  const onLoad = () => {
    Listdata.pageNo += 1;
    Listdata.pageSize = 10;
    getList('onLoad');
  };

  const showselectPopu = () => {
    Listdata.lockedUnit = '';
    showBottom.value = true;
  };

  // 我是路由跳转
  const torecordsPage = () => {
    if (token) {
      router.push({ path: '/Intelligent/records' });
    } else {
      router.push({
        path: '/login',
      });
    }
  };
  const toInvestment = async (item) => {
    if (token) {
      let res = await gotoWithdraw();
      if (res) {
        router.push({
          name: 'Investmentdetermination',
          params: {
            id: item.id,
            symbol: item.symbol,
            releasePercent: item.releasePercent,
            price: item.price,
            lockedDays: item.lockedDays,
            max: item.maxLimitAmout,
            min: item.minLimitAmout,
          },
        });
      }
      // router.push({ path: '/Intelligent/Investment', query: { ItemData: JSON.stringify(item) } });
    } else {
      router.push({ path: '/login' });
    }
  };

  const getInfo = async () => {
    let res = await setting();
    _data._user = res.data;
  };

  const _data = reactive({
    _user: getInfo(),
  }) as any;
  const { _user } = toRefs(_data);
  async function gotoWithdraw() {
    await getInfo();
    if (_user.value.realVerified == 1) {
      // 实名认证 认证中
      showToast(t('serviceCode.1007'));
      return false;
    } else if (_user.value.realVerified == 0 || _user.value.realVerified == 3) {
      // 未认证
      showConfirmDialog({
        message: t('serviceCode.1030'),
        confirmButtonText: t('confirm'),
        cancelButtonText: t('cancel'),
      }).then(() => {
        router.push('unVerified/');
      });
      return false;
    }
    return true;
  }
</script>

<style lang="scss" scoped>
  // 导航
  .navBarHeader {
    width: 100%;
    height: 90px;
    background: #f5f5f5;

    .header {
      height: 90px;
      display: flex;
      align-items: center;
      justify-content: center;

      span:nth-child(1) {
        font-size: 28px;
        font-weight: 600;
      }
    }
  }

  .popupselectItem :nth-child(1) {
    border-top-left-radius: 20px;
    border-bottom-left-radius: 10px;
  }

  ::v-deep .van-tabs__nav--card .van-tab--card {
    background-color: rgba($color: #000000, $alpha: 0.1);
  }

  ::v-deep .van-field__control {
    color: #555555;
  }

  ::v-deep .van-cell {
    background-color: initial;
  }

  ::v-deep .van-dialog__header {
    color: #333333 !important;
  }

  .popuactive {
    color: #ffffff;
    background-color: #ec8920;
  }

  .IconPopover {
    width: 575px;
    height: 148px;
    background: #ffedd4;
    border-radius: 12px 12px 12px 12px;
    opacity: 1;
  }

  .active {
    background: #ec8920;
    border-radius: 10px;
    color: #ffffff !important;
  }

  .Header {
    display: flex;
    align-items: center;
    padding: 20px;
    padding-bottom: 0;
  }

  .HeaderTab {
    width: 334px;
    height: 62px;
    border-radius: 12px 12px 12px 12px;
    opacity: 1;
    border: 2px solid #ec8920;

    div {
      width: 50%;
      height: 100%;
      color: #ec8920;

      span {
        align-items: center;
      }
    }
  }

  .From {
    height: auto;
    padding-left: 40px;
    padding-right: 40px;
    color: #333333;

    .TZmoneyTitle {
      color: #000000;
      font-size: 30px;
      font-family: PingFangSCBold;
      font-weight: 600;
    }

    .TZmoneyC {
      font-size: 25px;
      font-family: PingFangSCBold;
      color: #777777;
    }

    .USDT {
      justify-content: space-between;
      align-items: center;
      background: rgba($color: #00133c, $alpha: 0.05);
      // opacity: 0.05;
      // box-shadow: 4px 0px 4px 0px #00133c;
      border-radius: 7px 7px 7px 7px;
      height: 64px;

      .USDTIcon {
        font-size: 31px;
        font-weight: 600;
        font-family: PingFangSCBold;
        color: #000000;
      }
    }

    .balance {
      border-bottom: 1px solid rgba($color: #333333, $alpha: 0.3);
    }

    .TZlistItemCon {
      border-bottom: 1px solid rgba($color: #999999, $alpha: 0.3);
    }
  }
</style>
