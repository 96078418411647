import { useUserStore } from '/@/store/modules/user';

export function useToken() {
  const userStore = useUserStore();
  const token = userStore.token;
  const userInfo = userStore.userInfo;
  return {
    token,
    userInfo
  };
}
