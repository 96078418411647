import { langType } from './lang-base';

export const lang: langType = {
  receivingAddress: '수금 주소',
  second: '초',
  minimum: '최소',
  most: '가장 많은',
  minimumSell: '살을',
  ceiling: '지붕을',
  availableBalance: '현재 잔고',
  Nomoreatmoment: '잠시 더 이상은 없다',
  updateSet: '버전 업데이트',
  pleaseEnterTheAmount: '금액을 입력하십시오',
  SearchCurrency: '계약 번호 검색',
  Unabletochangeorder: '주문 상태 바꿀 수 없습니다',
  Yourbalanceisinsufficient: '당신의 잔고가 부족합니다',
  Ordinarysingle: '보통전표',
  DailyDistributions: '매일 수익을 지급하며 예치기간이 끝나면 원금을 돌려준다',
  Resumptionhasbeenactivated: '다시 작업이 시작되었습니다',
  Resumptionhasbeenclosed: '복권이 종료되었습니다',
  orderCount: '주문 수량',
  PleaseEnter: '계약서 번호를 입력하십시오',
  emailNi: '편지함 인증 코드를 입력하십시오',
  existingSES: 'btrok 계정이 있다',
  email: '메일 박스',
  myinvitation: '저의 초대',
  teamValue: '팀 기여도',
  shareValue: '기여액을 나누다',
  allValue: '총기여액',
  myBenefits: '제 기여는 그만한 가치가 있습니다',
  contribution: '기여 가치',
  contributionDetail: '기여액 명세',
  Dailyinterestrate: '일일 이율',
  LendingDays: '대차 일수',
  Financialproductdetails: '질권의 상세',
  Subscriptionwallet: '지갑을 구입하다',
  Subscriptionamount: '인수 신청 금액',
  SubscriptionDetails: '청약 상세 상황',
  Expirationtime: '기한',
  Investmenttype: '투자 유형',
  InvestmentDetails: '투자 상세상황',
  Thesystemwillautomatically: '만기가 된 투자 주문은 새로운 질권 주문과 자동으로 매칭된다.',
  Reswitchingfunction: '재투입 기능',
  description: '설명',
  Investmentquantity: '투자 수량',
  Investmentdetermination: '투자가 확정되다',
  RevenueDetails: '수익 내역',
  InvestmentOrders: '투자 주문서',
  Estimatedtotalrevenue: '예상 총수익',
  Redemptionmethod: '상환방식',
  Dailyrevenue: '일일 수익',
  LockedDays: '잠금 일수',
  Currenttotalinvestmentequivalent: '현재 투자총액 (환산)',
  complete: '완료',
  Fundinglimit: '자금조달포지션',
  Contractnumber: '계약 번호',
  Loanfinancing: '대차재테크',
  Subscriptiontime: '청약 기간',
  Myinvestmenthistory: '내 투자 기록',
  dailyReturn: '일일 수익률',
  Investmentdays: '투자 일수',
  Selfselectionarea: '빠른 선거구',
  Quickconstructionarea: '콰이젠구',
  Smartcontractmatching: '스마트 계약 매칭',
  ImmediateInvestment: '즉시투자',
  sourceTime: '시간',
  viewCode: '코드 보기',
  goWealh: '차이푸의 길',
  fileGo: '파일로 가기',
  videoRequest: '비디오 요청',
  gorequest: '드래그 요청',
  proceeding: '소송',
  pwds: '암호',
  experienceAll: '만별 체험감',
  experienceFive: '오성 체험감',
  sharing: '재부 공유',
  customer: '콜',
  todoList: '업무를 대행하다',
  systemMessages: '시스템 메시지',
  youHave: '당신이',
  totleWait: '처리할 사항이 있으니, 제때에 처리하시기 바랍니다',
  totleBook: '읽지 않은 시스템 메시지 표시',
  message: '소식',
  officialNowGo: '지금 시작하다',
  officialFinace: '블록체인금융을 즐기는 것은 결코 쉬운 일이 아닙니다!',
  officialTechnologyLeft: '전연',
  officialTechnologyRight: '기술',
  superSESMessage: 'btrok 메시지',
  superSES: 'btrok',
  superFomamce: '약정 금융, 향수금융',
  superSecure: '고수익 안전한 블록 체인 투자',
  superParagraphOne:
    'btrok 국제 디지털 암호화 자산 거래 플랫폼은 종합적인 디지털 자산 거래 플랫폼입니다. 당사의 앞선 기술력, 우수한 고객 서비스 및 우수한 사용자 경험은 광범위한 디지털 화폐 애호가들에게 자유로운 온라인 거래 플랫폼을 제공합니다.',
  superParagraphTwo:
    '브룩 (btrok)은 또한 디지털 화폐 백서 기획, 암호화 알고리즘 조정, 디지털 화폐 개발 및 거래 플랫폼 런칭 등의 원스톱 블록 체인 서비스도 제공한다.',
  superParagraphThree: 'Btrok国际数字加密资产交易平台海外运营中心分别设立在新加坡、柬埔寨、马绍尔。',
  Traverser: 'Traverser',
  announceList: '공지 목록',
  announceListDetail: '상세한 상황을 공고하다',
  official: '공식',
  supervise: '노드 감독',
  Source: '오픈 소스',
  report: '감사보고서',
  more: '더 많은',
  deposit: '충전 머니',
  invite: '초대',
  lockMining: '창고를 잠그고 광산을 채굴하다',
  riseRanking: '상승폭차트',
  currency: '한 화폐의 종류',
  recentPrice: '최근 가격',
  gains: '24시간 상승폭',
  welcomes: '어서오세요!',
  home: '첫 페이지',
  messageList: '메시지 목록',
  noData: '불충분한 증거',
  market: '시세',
  financialManagement: '질 권',
  optional: '선택',
  buy: '매입',
  sell: '팔',
  completed: '완료',
  limitOrder: '한정가격위탁',
  marketOrder: '시가 위탁',
  call: '오르다',
  put: '풋',
  success: '성공',
  failure: '실패',
  transaction: '거래',
  contract: '옵션',
  forex: '외환',
  mine: '나의',
  notNull: '비워둘 수 없음',
  optionContract: '옵션계약',
  currentDelivery: '현재 거래',
  historyDelivery: '역사적 거래',
  fee: '수수료',
  openAmount: '창고개설금액',
  bonusQuantity: '보너스 액수',
  predictionDirection: '방향을 예측하다',
  requestFailure: '요청이 실패했습니다. 새로 고침 버튼을 누르십시오',
  predictionResult: '결과를 예측하다',
  submitSuccess: '커밋에 성공했습니다.',
  sharePoster: '포스터 공유',
  cancel: '취소',
  copyLink: '링크 복사',
  saveImage: '그림 저장',
  pleaseLoginFirst: '먼저 로그인하십시오',
  pleaseUseTronWallet: '파장 지갑을 사용하여 충전 링크를 열십시오',
  currentInterest: '당좌 이자',
  fundsSecurity: '자금 안전',
  guarantee: '보장',
  holidayEarnings: '명절 연휴에도 수익이 끊이지 않는다',
  warning: '상술한 주소로 비통화자산을 충당할 수 없습니다. 그렇지 않으면 자산은 회수할 수 없습니다.',
  confirmation:
    '당신이 위의 주소로 충전한 후, 전체 블록 체인 네트워크 노드의 확인이 필요하며, 일반 블록 체인 메인넷은 3개의 네트워크 확인 후 입금됩니다.',
  addressChange:
    '귀하의 충전 주소는 자주 변경되지 않으며, 중복 충전할 수 있습니다.만약 변경될 경우, 저희는 가능한 한 인터넷 사이트 공고나 이메일을 통해서 귀하에게 알려 드리겠습니다.',
  safetyReminder: '정보 변조 및 루설을 방지하기 위해 조작 환경을 반드시 확인하십시오.',
  rechargeUSDT: 'usdt 충전',
  details: '자세 한 정보',
  dayLimit: '일',
  mining: '광산 채굴이 멈추지 않다',
  upcoming: '곧 시작하다',
  inProgress: '진행 중',
  distribution: '배포 중',
  progress: '진도',
  totalAmount: '총 활동량',
  startTime: '시작 시간',
  endTime: '종료 시간',
  lockingMining:
    'lock-box 채굴은 usdt를 플랫폼 슈퍼파워 (platform supercomputing force) 마이터에게 위탁하여 플랫폼 (platform)의 광산 풀에서 채굴 수익을 얻는다',
  productHighlights: '제품의 하이라이트',
  withdrawal: '입금하면 즉시 찾아가다',
  dividendCycle: '배당주기',
  successfulDepositOnDay: '입금 성공 후 당일',
  interestAccrual: '터 이자',
  example: '예를 들어,',
  profitCalculation: '수익계산',
  simplestProfitFormula: '가장 간단한 수익계산공식은',
  profitRateCalculationExample: '례컨대 수익이 2w 이고 본전이 10w 라고 하면 리윤률은 다음과 같다',
  calculationOfPrincipal: '증권계좌는 투자한 원금이 기억나지 않는다면 현재 시가총액과 현재 손익을 계산해 준다',
  currentMarketValueExample: '례를 들면 현재 시가총액이 11w 이고 현재 차익이 2w 라면 원금은 11w-2w = 9w 이며 수익률도 =(2w/9w)이다.',
  penalty: '위약금',
  participate: '저는이 문제에',
  entrustedOrder: '주문 의뢰',
  pledgingInProgress: '질권 중',
  pledgingComplete: '질권이 완성되다',
  earlyRedemption: '앞당겨 되찾다.',
  loading: '불러오는 중',
  pledgeAmount: '질권 금액',
  dailyEarnings: '일일 수익',
  earlyRedemptionDeductionHandlingFee: '앞당겨 상환하고 수수료를 공제하다',
  earlyRedemptionAgain: '앞당겨 되찾다.',
  endOfContent: '이미 끝장을 보았다',
  financialProduct: '질 권',
  fundsUnderManagement: '예탁 중인 자금',
  expectedTodayEarnings: '금일 수익 예측',
  TodayEarnings: '금일 수익',
  accumulatedEarnings: '누적 수익',
  trustOrder: '위탁 중인 주문서',
  buy2: '사러 가다',
  period: '주기',
  switchLanguage: '언어 전환',
  username: '사용자 이름',
  emailPlaceholder: '편지함을 입력하십시오',
  forgotPassword: '비밀번호를 잊어버리다',
  passwordPlaceholder: '비밀번호를 입력하십시오.',
  submit: '제출',
  registerNow: '지금 등록',
  search: '검색',
  high: '높은',
  low: '낮',
  depth: '깊이',
  deal: '거래',
  buyQuantity2: '매입 수량',
  price: '가격',
  quantity: '수량',
  sellQuantity: '매도 수량',
  time: '시간',
  direction: '방향',
  inputPrice: '가격을 입력하십시오',
  tradingVolume: '거래액',
  entrust: '위탁',
  entrustPrice: '위탁 가격',
  entrustQuantity: '위탁량',
  totalEntrustment: '위탁 총액',
  category: '종류',
  confirm: '확정',
  inputCorrectQuantity: '정확한 수량을 입력해 주세요',
  operationSuccessful: '작업 성공',
  revoke: '취소',
  entrustmentCancellationConfirmation: '위탁취소확인',
  areYouSureToCancel: '취소장을 확인하시겠습니까?',
  all: '전부',
  currentEntrustment: '현재 위임',
  completed2: '이미 거래가 성립되다',
  aboutSES: '브룩 (btrok)',
  freeExchange: '광범위한 디지털 화폐 애호가들을 위해 자유로운 온라인 교환 플랫폼을 제공한다',
  modify: '수정',
  bind: '바인딩',
  alipayAccount: '알리페이 계좌',
  wechatAccount: '위챗 계정',
  unbound: '바인딩되지 않음',
  sesPlatformOverview3:
    'btrok 디지털 자산 거래소는 영국 마이 타이 국제 기금 투자 개발의 글로벌 디지털 자산 거래 플랫폼, 영국에 본부를 둔 영국 nfc 감독을 받기 때문에, 구역 별 사슬 분야에서 유럽이 일련의인 프라 건설 사업을하였 으며 영국을 바탕으로 유럽과 아시아, 동남아, 미주 · 호주 · 디지털 자산 구역 별 사슬 업종 바이오 스피어를 만들 수 있다.btrok 디지털 자산 거래소는 글로벌 투자자를 대상으로 하는 국제 디지털 자산 거래소로서 현재 업계 우위와 결합하여 은행 금융 관리 경험이 풍부한 팀, 전문 빅 데이터 개발팀, 세계 최고의 블록 체인 개발팀과 선도적인 블록 체인 기술 서비스 팀을 보유하고 있습니다.사용자에게 효과적이고 안전한 디지털 자산 거래 서비스를 제공하는 것을 목적으로 한다.',
  sesPlatformOverview4:
    '세계 첫 번째 종합 디지털 자산 거래 플랫폼으로서 전 세계에서 블록체인분야의 최고 거래 플랫폼 중 하나로서 전 세계의 디지털 자산 생태 분포를 커버하고 있으며 국제 허가증과 정부 기구의 인가를 보유하고 있다.브룩크는 기술 혁신, 제품 반복, 보안 리스크 제어 시스템, 운영 및 고객 서비스 시스템 등 분야에서 지속적인 개선을 추진할 예정이다. 브룩크는 전 세계 사용자들에게 비트코인, 이더리온 등 주류 디지털 화폐 현물과 파생상품 거래 서비스를 제공하는 동시에 모든 범위의 블록 체인 생태계를 구축하는 데 일조할 것이다.',
  sesPlatformOverview5:
    '안전하고 체험적이며 자유로운 디지털 자산 거래 플랫폼과 전 세계 디지털 화폐 애호가들의 요구를 충족시킬 수 있는 금융 인프라를 구축하여 디지털 상호 연결 시대에 가상 디지털 자산을 거래하고 교류하는 이상적인 커뮤니티를 구축합니다.광범한 투자자들을 위해 안전하고 믿음직하며 안정된 종합써비스거래플랫폼을 제공해줄것입니다.',
  bankCardNumber: '은행 카드 계좌 번호',
  accountSettings: '계정 설정',
  modifyAlipayAccount: '알리페이 계정을 수정했습니다',
  receiveQRCode: '수금 이차원 코드',
  fundPassword: '자금 비밀번호',
  pleaseEnterCorrectFundPassword: '정확한 자금 비밀번호를 입력하세요',
  pleaseEnterAlipayAccount: '알리페이 계좌번호를 입력해주세요',
  myAssets: '나의 자산',
  details2: '명세',
  withdrawalRecords: '화폐 인출 기록',
  totalAssetsApprox: '총자산약정 (usdt)',
  spotAccount: '코인 어카운트',
  searchAssets: '검색 자산',
  hideZeroBalances: '숨겨진 화폐가 0입니다',
  frozen: '동결',
  toRelease: '석방을 기다리다',
  modifyBankCardNumber: '카드 계좌 번호를 수정하다',
  save: '저장',
  bankName: '계좌 개설 은행',
  bankCardNumberConfirm: '카드번호 확인',
  pleaseEnterBankCardNumberAgain: '카드번호를 다시 입력해주세요',
  fundPasswordPrompt: '자금 비밀번호',
  pleaseEnterFundPassword: '자금 비밀번호를 입력하세요',
  modifyFundPassword: '자금 비밀 번호를 수정하다.',
  modifyingFundPassword: '당신은 자금 비밀번호를 바꾸고 있습니다',
  oldFundPassword: '구자금비밀번호',
  newFundPassword: '새로운 자금비밀번호',
  confirmFundPassword: '자금 비밀번호를 확인하다.',
  pleaseEnterSetPassword: '비밀번호를 입력하십시오',
  pleaseEnterConfirmFundPassword: '자금 확인 비밀번호를 입력해 주세요',
  passwordsDoNotMatch: '암호가 일치하지 않습니다.',
  pleaseEnterYourOldFundPassword: '옛 자금 비밀번호를 입력하세요',
  pleaseEnterYourNewFundPassword: '새로운 자금 비밀번호를 입력하세요',
  pleaseEnterA6DigitPassword: '비밀번호 6자를 입력하세요',
  pleaseConfirmNewFundPassword: '새로운 자금 비밀번호를 확인하세요',
  theNewFundPasswordCannotBeTheSameAsTheOldFundPassword: '새로운 자금 비밀번호는 옛 자금 비밀번호와 같을 수 없다',
  pleaseEnterTheVerificationCode: '인증번호를 입력해주세요',
  feedback: '의견이 돌아오다.',
  yourOpinionIsVeryImportantToUs: '당신의 의견은 우리에게 있어서 특히 중요합니다!',
  leaveYourValuableOpinionToHelpUsImprove: '우리가 더 잘 할 수 있도록 당신의 귀중한 의견을 남겨주세요!',
  pleaseLeaveYourValuableOpinion: '귀중한 의견을 남겨주세요',
  submissionSuccessfulThankYouForYourFeedback: '성공적으로 제출했습니다, 피드백 감사합니다',
  submissionFailed: '전송 실패',
  setFundPassword: '자금 비밀번호를 설정하다.',
  setYourPaymentMethod: '귀하의 수금방식을 설정하십시오. 반드시 본인의 실명 계정을 사용하시기 바랍니다',
  newPassword: '새 비밀번호',
  repeatPassword: '비밀번호 반복',
  notLoggedIn: '로그인하지 않음',
  welcome: '브룩에 오신 것을 환영합니다',
  assetManagement: '자산 관리',
  assetFlow: '자산 흐름',
  myInvitation: '저의 초대',
  invitationLink: '초대장 링크',
  firstLevelFriends: '일급 친구',
  secondLevelFriends: '2 급 친구',
  partnerLevel: '개인 레벨',
  myEntrustment: '제 의뢰는',
  securitySettings: '보안 설정',
  pleaseLogInFirst: '먼저 로그인하십시오',
  announcement: '공고',
  changeLoginPassword: '로그인 비밀번호 변경',
  oldLoginPassword: '이전 로그인 비밀번호',
  newLoginPassword: '새 로그인 비밀번호',
  pleaseEnterOldLoginPassword: '이전 로그인 비밀번호를 입력하십시오',
  pleaseEnterNewLoginPassword: '새로운 로그인 비밀번호를 입력하십시오',
  confirmLoginPassword: '로그인 확인',
  emailVerificationCode: '메일박스 인증 코드',
  emailBound: '이미 편지함에 바인딩되어 있습니다',
  verificationCode: '인증 코드',
  sendVerificationCode: '인증 코드 보내기',
  pleaseConfirmInformation: '모든 정보를다 기입했는지 확인하세요',
  pleaseConfirmPassword: '두 번 입력한 비밀번호가 일치하는지 확인해 주세요',
  makeSureTheOldAndNewPasswordsAreDifferent: '이전 비밀번호와 새 비밀번호가 다른지 확인하세요',
  pleaseConfirmNewLoginPassword: '새로운 로그인 비밀번호를 확인하세요',
  teamPerformance: '단체 실적',
  currentLevel: '현재 단계',
  nextLevel: '다음 단계',
  teamNumber: '단체 인원수',
  totalPerformance: '총 실적',
  onlyForUSDT: '이 주소에 대해서만 usdt를 충전할 수 있습니다',
  walletAddress: '지갑 주소',
  securityReminder: '고객님의 계정안전을 위해, 실명인증을 마친후에야 거래하실 수 있습니다!',
  personalAvatar: '개인 얼굴 사진',
  isVerified: '인증됨',
  realNameAuthentication: '실명 인증',
  notVerified: '인증되지 않음',
  receivingAccount: '인출 주소',
  loginPassword: '로그인 비밀번호',
  fundsPassword: '자금 비밀번호',
  settingsCenter: '센터 설정',
  aboutUs: '우리 자신에 대해',
  helptext: '도움말',
  help: '도움',
  logout: '현재 계정 종료',
  confirmLogout: '로그아웃을 하시겠습니까?',
  bindWeChatAccount: '위챗 계정을 연동시키다',
  withdrawalAddress: '인출 주소',
  available: '사용 가능 한',
  minimumWithdrawalAmount: '최소 인출 수량',
  amountReceived: '입금 금액',
  transactionTime: '거래 시간',
  payableHandlingFee: '미지급수수료',
  deductibleHandlingFee: '수수료를 상쇄하다.',
  actualHandlingFee: '실불 수수료',
  feeGenerationTime: '시작과 종료 시간은 수속비 생성시간이다',
  takeIDPhoto: '귀하의 주민등록증 사진을 찍어서 업로드합니다',
  identityAuthentication: '신원 인증',
  uploadIDCardFront: '신분증 앞면 사진 업로드하기',
  uploadIDCardBack: '신분증 뒷면 사진 올리기',
  photoAlbum: '앨범',
  IDCardNumber: '신분증 번호',
  enterIDCardNumber: '주민등록번호를 입력하십시오',
  name: '이름',
  idea: '브룩 (btrok) 개념',
  goal: 'btrok 목표',
  depositRecord: '충전 기록',
  withdrawal2: '티 머니',
  accountOpeningBranch: '계좌 개설 지점',
  quantityInput: '개수를 입력하십시오',
  singleLimit: '1회 한도액',
  investmentAmount: '투자 금액',
  incomePrincipal: '수익/원금',
  password: '암호',
  historicalCommission: '과거 기록 위임',
  type: '유형',
  commissionAmount: '위탁금액',
  filter: '압축',
  pleaseSelect: '선택하십시오',
  tradingPair: '거래쌍',
  reset: '초기화',
  startEndTime: '시작과 마감 시간',
  recharge2: '충전',
  withdraw: '현금 인출',
  transfer: '이체',
  coinTrading: '커런시 트레이드',
  fiatCurrencyPurchase: '법폐 매입',
  fiatCurrencySale: '법화 판매',
  activityReward: '이벤트 보너스',
  promotionReward: '장려를 확대하다',
  dividend: '배당',
  vote: '투표',
  manualRecharge: '수동 충전',
  pairing: '짝을',
  activityExchange: '이벤트 태환',
  ctcPurchase: 'ctc 매입',
  ctcSale: 'ctc 판매',
  redEnvelopeSending: '촌지가 나오다',
  redEnvelopeReceiving: '뇌물을 수령하다.',
  withdrawalCodeWithdrawal: '현금 인출 코드',
  withdrawalCodeRecharge: '현금 코드를 충전하다',
  perpetualContractFee: '계약 수수료',
  perpetualContractProfit: '계약이윤',
  perpetualContractLoss: '계약결손',
  optionContractFailure: '옵션 계약 실패',
  optionContractFee: '옵션계약 수수료',
  optionContractBonus: '옵션계약 보너스',
  contractRebate: '계약리베이트',
  levelReward: '같은 등급으로 장려하다.',
  platformFeeIncome: '플랫폼 수수료 수입',
  forexEntrustTrading: '외국환 위탁 거래',
  forexCancelEntrustTrading: '외환 위탁거래 취소',
  income: '수익',
  promotionIncome: '수익을 확대하다',
  optionsPromotionIncome: '옵션 프로모션',
  liquidationCompensation: '입고하여 배상하다',
  lockedMiningIncome: '단체 이익',
  optionTeamIncome: '옵션팀이익',
  optionParityAward: '옵션 레벨 보너스',
  investmentTeamReturn: '투자팀 수익',
  investmentParityReturn: '투자 동급 수익',
  investmentPromotionIncome: '투자 촉진 수익',
  NEWACTIVITY_IN: '투자 수익',
  NEWACTIVITY_DEDUCT: '투자 환매',
  NEWACTIVITY_FREEZE: '옵션 동결',
  NEWACTIVITY_UNFREEZE: '옵션 동결 해제',
  NEWACTIVITY_REDEEM_UNFREEZE: '옵션 옵션 해제',
  speedContractProfit: '초단계약이익',
  speedContractLoss: '초단차계약손실',
  speedContractCommission: '서브계약 수수료',
  CONTRACT_FAST_FREEZE: '초 계약 동결',
  CONTRACT_FAST_UNFREEZE: '순식간에 계약이 해제되다.',
  confirmUploadAllImages: '사진다 올렸는지 확인해주세요',
  inputRealName: '본명을 입력하십시오',
  idCardPhoto: '신분증 사진',
  congratulations: '축하합니다. 심사에 통과되어 마음 놓고 거래할 수 있게 되었습니다',
  quantity2: '량',
  wechatAccount2: '위챗 계정을 입력해주세요',
  realNameIdentityVerified: '실명이 확인되었습니다',
  withdrawalTime: '현금 인출 시간',
  withdrawalAmount: '현금 인출 수량',
  creditting: '심사 중',
  waitingmoney: '출금을 기다리다',
  dztime: '충전 시간',
  rushaddress: '코인 어드레스',
  rushnumber: '충전 수량',
  regisertitle: '메일박스 등록',
  inputemailcode: '편지함 인증 코드를 입력하십시오',
  inputloginpassword: '비밀번호 (대소문자 조합, 6자 이상)를 입력하십시오.',
  inputreloginpassword: '비밀번호를 다시 입력하십시오',
  inputinvite: '초대번호를 입력해주세요',
  iagree: '나는 이미 읽었고 동의했다',
  useragreement: '사용자 협의',
  nowlogin: '지금 로그인',
  sendcode: '인증 코드 보내기',
  rightemailformat: '올바른 메일박스 형식을 입력하십시오',
  confirmPassword: '비밀번호 확인',
  passwordtip: '비밀번호는 최소한 여섯 자리 이상이어야 합니다',
  nosamepassword: '비밀번호가 일치하지 않습니다',
  noagree: '사용자 동의서가 선택되지 않았습니다',
  emailverification: '메일박스 인증',
  amount: '수량',
  overturn: '이체',
  overturntitle: '자산의 대체 조달',
  from: '에서',
  to: '까지',
  swapcurrency: '계약화폐종류',
  overturnplaceholder: '전송된 횟수를 입력하십시오',
  selectaccount: '계정 선택',
  balance: '자산',
  contractaccount: '계약계정',
  invitecode: '초대 코드',
  notset: '설정되지 않음',
  day: '일',
  contactservice: '고객 서비스 연결',
  appname: 'btrok',
  download: '앱 다운로드',
  orderByHand: '수동으로 주문하다.',
  orderByAuto: '인텔리전트 주문',
  trevenue: '총이익',
  pincome: '수익을 확대하다',
  tincome: '단체 이익',
  dropList: '드롭다운 목록',
  uploaded: '업로드됨',
  youare: '여러분은 지금',
  disabling: '닫기',
  enabling: '열',
  theAutomaticReinvestmentFeatur: '자동 재전송 기능',
  nth: '제',
  phase: '호',
  currentForecastPeriod: '당기의 예측 주기',
  maximum: '최대량',
  openingPrice: '개장 가격',
  currentPrice: '현재 가격',
  profitSharing: '이익배당금',
  CurrentTradingIsRestricted: '현재 거래 제한',
  ToEnsureTheSsecurityOfYourAssetsPleaseCarefullyVerifyTheReceivingWalletAddressBeforeMakingAnyDeposits:
    '모든 자산의 안전을 보장하기 위하여 충전은 수금지갑의 주소를 꼼꼼히 확인하여 주시기 바랍니다.',
  PleaseProceedWithTheDepositOnlyAfterVerifyingThatEverythingIsCorrect: '다.대조가 틀림없어야 충전을 진행할 수 있습니다.',
  PleaseDoNotDepositAnyAssetsOtherThanTheSpecifiedCryptocurrencyToTheAddressMentionedAboveOtherwiseYourAssetsWillNotBeRecoverable:
    '위 주소로 비통화 자산을 충전하지 마십시오. 그렇지 않으면 자산은 반환될 수 없습니다.',
  AfterYouMakeADepositToTheMentionedAddressItRequiresConfirmationFromTheEntireBlockchainNetworkTypicallyItTakesAround30SecondsForTheFundsToBeCreditedToYourAccountAfterThreeConfirmationOnTheBlockchainMainnet:
    '당신이 위의 주소로 충전한 후, 블록 체인 네트워크 노드 전체의 확인이 필요합니다. 일반적으로 블록 체인 메인넷은 3개의 네트워크 확인 후 입금을 합니다. 약 30초 정도 소요됩니다.',
  PleaseEnsureTheSecurityOfYourElectronicDevicesToPreventAnyTamperingOrLeakageOfInformation:
    '정보 변조 및 유출을 방지하기 위하여 반드시 전자 장치 보안을 확인하시오.',
  WithdrawalsAreCurrentlyDisabled: '지금은 인출이 금지되어 있다',
  CurrentWithdrawalAddress: '주어진 화폐 주소를 확인하세요',
  isYourWalletAddress: ' 지갑 주소입니다',
  LinkCopiedSuccessfully: '복사에 성공했습니다',
  LinkCopiedFailed: '복사 실패',
  goLogin: '로그인하기',
  other: '기타',
  SavePicture: '그림 저장',
  SaveLink: '링크 저장',
  languageSettings: '언어 설정',
  simplifiedChinese: '简体中文',
  traditionalChinese: '繁体中文',
  arabic: 'عربي',
  english: 'English',
  japanese: '日本語',
  French: 'Français',
  Spanish: 'Español(España)',
  German: 'Deutsch(Schweiz)',
  Italiano: 'Italiano',
  TotalDailyLoss: '총이익',
  TotalExpenditureToday: '오늘 총 주문금액',
  TotalIncomeToday: '오늘의 총이익',
  CurrentDelivery: '현재 거래',
  HistoricalDelivery: '역사적 거래',
  InitialAmount: '주문 금액',
  ForecastDirection: '방향을 예측하다',
  BonusAmount: '보너스 액수',
  TransactionFee: '수수료',
  OrderDuration: '주문 지속 시간',
  Status: '상태',
  SelectCurrency: '선택 통화',
  EnterAmount: '금액을 입력하다',
  BUY: '사 오',
  SELL: '살 떨 어진',
  WIN: '성공',
  LOSE: '실패',
  Amount: '금액',
  expectedReturn: '예상 수익',
  service: '콜',
  untrade: '현재 거래할 수 없음',
  netError: '네트워크에 문제가 생겼다!',
  questError: '요청 중에 오류가 발생했습니다. 나중에 다시 시도하십시오!',
  failVerified: '인증 실패',
  tiprealverified: '실명 심사시 고객님께 연락 주시기 바랍니다',
  uploadImg: '인증서 업로드',
  collected: '모음집',
  canceld: '취소됨',
  placeholderAddress: '수금할 주소를 입력해 주십시오',
  bindAccountTitle: '수금 주소',
  marketTitle: '시세',
  regiserSuccess: '등록에 성공했습니다',
  setSuccess: '설정 성공',
  updateAddress: '지갑 주소를 수정해야 해요!',
  doingRealName: '실명은 지금 확인 중이니 기다려 주십시오',
  updateSuccess: '성공적으로 수정했습니다',
  uploadSuccess: '업로드에 성공했습니다',
  forgetLpass: '비밀번호를 잊어버리셨다면 고객센터에 연락하셔서 정보 확인 후 처리 하시기 바랍니다. 로그인 비밀번호 초기화',
  forgetApass: '비밀번호를 잊어버리셨다면 고객님께 연락하셔서 정보 확인 후 처리 거래 비밀번호 초기화',
  updateAddressTip: '수금소 수정 고객님께 연락하여 개인정보 확인 처리 후 수금소 초기화하세요',
  a48h: '인출 접수는 완료하였습니다. 인출 신청은 48시간 이내에 처리됩니다. 인내심을 가지고 기다려 주십시오',
  r48h: '충전은 이미 접수하였으며, 충전 주문은 48시간 내에 처리될 것이니 인내심을 가지고 기다려 주십시오',
  accepted: '접수 중',
  notremind: '다시 알림',
  //new
  rate: '이익률',
  mode: '거래 모델',
  num: '오픈포지션 수량',
  openTime: '입고시간',
  phoneNi: '핸드폰 인증번호를 입력해주세요',
  phonePlaceholder: '핸드폰 번호를 입력하세요',
  inputphonecode: '핸드폰 인증번호를 입력해주세요',
  phone: '핸드폰 번호',
  phoneVcode: '핸드폰 인증번호',
  sendSucc: '전송이 완료되었습니다.',
  cScore: '신용 점수',
  wallet: '지갑',
  searchMp: '암호화폐의 이름을 검색합니다',
  clickUploadId: '클릭해서 당신의 신상사진을 올리세요',
  modifyFp: '조정',
  confirmFPassword: '자산 비밀번호 확인',
  pleaseSetYourNewFundPassword: '자금 비밀번호를 설정해 주세요',
  nosamefpassword: '자금 비밀번호가 정확하지 않습니다. 다시 한 번 확인해 주세요.',
  forgotFPassword: '업무 비밀번호를 잊으셨나요?',
  serviceCode: {
    0: '성공',
    500: '실패',
    501: '등록 실패',
    502: '보내기 실패',
    503: '추가 실패',
    504: '쿼리 실패',
    600: '이 화폐는 인출을 지지하지 않는다',
    1001: '인증 코드가 없습니다',
    1003: '인출한 화폐의 양이 최대치보다 큽니다',
    1004: '인출된 화폐의 수량이 최소치보다 작다',
    1005: '가용 잔액이 부족하다',
    1006: '지갑이 잠겼습니다',
    1007: '현재 실명인증제는 심사중에 있습니다. 심사후 조작을 진행하기 전에 기다려 주십시오',
    1008: '먼저 자금 비밀번호를 설정하세요',
    1009: '자금 암호가 잘못되었습니다',
    1010: '자산을 잠글 수 없음',
    1011: '메일함은 이미 바인딩되어 있습니다',
    1012: '이 사용자 이름은 이미 존재합니다',
    1013: '이 사용자 없음',
    1014: '로그인 활성화 메시지가 이미 전송되었습니다. 다시 요청하지 마십시오',
    1015: '먼저 편지함을 바인딩하세요',
    1016: '주문 금액이 0이 되어서는 안 된다',
    1017: '현재 계정이 비활성화되어 있습니다. 고객 서비스에 문의하십시오!',
    1018: '활동이 존재하지 않음',
    1019: '구매금액이 최저금액보다 작다',
    1020: '구매 금액이 최고 금액 이상입니다',
    1021: '지원되지 않는 화폐 종류!',
    1022: '거래쌍 이미 소장함',
    1023: '중복 설정하지 마십시오',
    1024: '자금 암호의 길이가 잘못되었습니다',
    1025: '계약이 부존재하다',
    1026: '예측이 빗나가다',
    1027: '사용자 지갑이 존재하지 않습니다',
    1028: '계좌번호와 비밀번호가 잘못되었습니다',
    1029: '계정이 비활성화 또는 비활성화 상태입니다. 고객 서비스에 문의하십시오',
    1030: '먼저 실명인증 완료해주세요',
    1031: '거래 비밀번호를 설정해 주세요',
    1032: '이미 실명인증하였기 때문에 재인증할 수 없습니다',
    1033: '동일한 주민등록번호로 한번만 인증할 수 있습니다',
    1035: '이전 비밀번호 오류',
    1036: '충전 인증서를 업로드하십시오',
    1037: '정확한 충전 금액을 입력해주세요',
    1038: '계정이 존재하지 않습니다.',
    1039: '암호가 잘못되었습니다',
    1040: '이전 암호가 올바르지 않습니다',
    1041: '거래 비밀번호가 잘못되었습니다. 다시 입력해 주세요',
    1042: '초대장 번호 오류',
    1043: '현금 인출 주소가 이상하다',
    1065: '현재 주문서는 검토 중이니 중복 제출하지 마십시오',
    3000: '로그인 시간이 초과되었습니다',
    888: '먼저 실명인증 완료해주세요',
  },
  korean: '한국어',
  turkish: 'Türkçe',
};
