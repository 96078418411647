import { showToast } from 'vant';
import Clipboard from 'clipboard';
import { i18n } from '/@/i18n';

function clipboardSuccess() {
  // @ts-ignore
  showToast(i18n.global.t('LinkCopiedSuccessfully'));
  // showToast('复制成功');
}

function clipboardError() {
  // @ts-ignore
  showToast(i18n.global.t('LinkCopiedFailed'));
  // showToast('复制失败');
}

export default function handleClipboard(text: string, event) {
  const clipboard = new Clipboard(event.target, {
    text: () => text,
  }) as any;
  clipboard.on('success', () => {
    clipboardSuccess();
    clipboard.destroy();
  });
  clipboard.on('error', () => {
    clipboardError();
    clipboard.destroy();
  });
  clipboard.onClick(event);
}
