<template>
  <!-- head bar -->
  <div class="headBar">
    <var-app-bar :title="$t('lockMining')" color="#1a212a" title-position="center">
      <template #left>
        <var-button color="transparent" text-color="#fff" round text @click="$router.back">
          <var-icon name="chevron-left" :size="24" />
        </var-button>
      </template>
    </var-app-bar>
  </div>
  <div class="under p-2" style="padding-top: 50px">
    <div class="text-lg text-center mt-2"> {{ $t('mining') }} </div>
    <div class="text-sm text-center px-3 mb-4" v-if="content0.data.content" v-html="content0.data.content"> </div>
    <div class="text-sm text-center px-3 mb-4" v-else> {{ $t('lockingMining') }} </div>
    <!-- 产品亮点 -->
    <div class="highlight p-2">
      <div class="text-center text-lg text-yellow-500">{{ $t('productHighlights') }}</div>
      <div class="flex justify-around">
        <div class="flex flex-col items-center">
          <div class="text-sm">{{ $t('withdrawal') }}</div>
          <div class="mt-1 text-xs text-gray-600">{{ $t('dividendCycle') }}</div>
        </div>
        <div class="flex flex-col items-center">
          <div class="text-sm">{{ $t('dailyDistribution') }}</div>
          <div class="mt-1 text-xs text-gray-600">{{ $t('currentInterest') }}</div>
        </div>
      </div>
      <div class="mt-2 flex justify-between item-center">
        <div class="text-center">
          <img class="w-9" :src="mingTip1" alt="" />
          <div class="mt-1 text-xs text-gray-600">100%{{ $t('fundsSecurity') }}{{ $t('guarantee') }}</div>
          <div class="text-xs text-gray-600"></div>
        </div>
        <div class="text-center mt-auto">
          <img class="w-9" :src="mingTip2" alt="" />
          <div class="mt-1 text-xs text-gray-600">{{ $t('holidayEarnings') }}</div>
          <div class="text-xs text-gray-600"></div>
        </div>

        <div class="text-center">
          <img class="w-9" :src="mingTip3" alt="" />
          <div class="mt-1 text-xs text-gray-600">{{ $t('successfulDepositOnDay') }}{{ $t('interestAccrual') }}</div>
          <div class="text-xs text-gray-600"></div>
        </div>
      </div>
    </div>
    <!-- 举个例子 -->
    <div class="example p-2 my-2" v-if="content1.data.content" v-html="content1.data.content"> </div>
    <div class="example p-2 my-2" v-else>
      <div class="text-center text-lg text-yellow-500">{{ $t('example') }}</div>
      {{ $t('profitCalculation') }}
      <ul class="list-none text-xs">
        <li>{{ $t('simplestProfitFormula') }}</li>
        <li>{{ $t('profitRateCalculationExample') }}</li>
        <li>=(2W/10W)*100%=20%</li>
        <li>{{ $t('calculationOfPrincipal') }}</li>
        <li>{{ $t('currentMarketValueExample') }}</li>
      </ul>
    </div>

    <div class="penalty p-2 mb-2" v-if="content2.data.content" v-html="content2.data.content"> </div>
    <!-- 违约金 -->
    <div class="penalty p-2 mb-2" v-else>
      {{ $t('penalty') }}
      <ul class="list-none text-xs">
        <li>{{ $t('simplestProfitFormula') }}: ({{ $t('incomePrincipal') }})*100%</li>
        <li>{{ $t('profitRateCalculationExample') }}</li>
        <li>=(2W/10W)*100%=20%</li>
        <li>{{ $t('calculationOfPrincipal') }}</li>
      </ul>
    </div>
    <!-- 我要参与 -->
    <router-link :to="token ? '/mining/product' : '/login'">
      <var-button block type="warning">{{ $t('participate') }}</var-button>
    </router-link>
  </div>
</template>

<script lang="ts" setup>
import mingTip1 from '/@/assets/images/Group 16@2x.png';
import mingTip2 from '/@/assets/images/Group 15@2x.png';
import mingTip3 from '/@/assets/images/Group 14@2x.png';
import { useToken } from '/@/hooks/useToken';
import { helpDetail } from '/@/api/sets';
const content0 = reactive<any>({ data: {} });
helpDetail({ id: 45 }).then((res) => {
  content0.data = res.data;
});
const content1 = reactive<any>({ data: {} });
helpDetail({ id: 44 }).then((res) => {
  content1.data = res.data;
});
const content2 = reactive<any>({ data: {} });
helpDetail({ id: 43 }).then((res) => {
  content2.data = res.data;
});
const { token } = useToken();
</script>
<style lang="scss">
.headBar {
  border-bottom: 2px solid #13161c;
}

.under {
  // background-color: #13171c;
  background-image: url('/@/assets/images/Group 17@2x.png');
  background-size: contain;
  background-repeat: no-repeat;

  .highlight {
    background-color: #1a212b;
  }

  .example {
    background-color: #1a212b;

    ul {
      padding-left: 0;
    }

    ul li {
      line-height: 35px;
      font-size: 12px;
      color: #999fa7;
    }
  }

  .penalty {
    background-color: #1a212b;

    ul {
      padding-left: 0;
    }

    ul li {
      line-height: 35px;
      font-size: 12px;
      color: #999fa7;
    }
  }
}

//   .van-tabs__nav {
//     background-color: #192128;
//   }
</style>
