import { langType } from './lang-base';

export const lang: langType = {
  receivingAddress: 'Alıcı adresi',
  second: 'saniye',
  minimum: 'En az',
  most: 'en',
  minimumSell: 'Minimum satın alma',
  ceiling: 'Kasket',
  availableBalance: 'Cari denge',
  Nomoreatmoment: 'Daha fazlası değil',
  updateSet: 'Sürüm güncellemeleri',
  pleaseEnterTheAmount: 'Lütfen bir tutar girin',
  SearchCurrency: 'Sözleşme numarasını arayın',
  Unabletochangeorder: 'Siparişin durumunu değiştirmek mümkün değildir',
  Yourbalanceisinsufficient: 'Bakiyeniz yetersiz',
  Ordinarysingle: 'Sıradan bekar',
  DailyDistributions: 'Kazançlar günlük olarak dağıtılır ve kilitlenme süresi sona erdikten sonra anapara iade edilir',
  Resumptionhasbeenactivated: 'Yeniden yatırım açıldı',
  Resumptionhasbeenclosed: 'Yeniden yatırım kapandı',
  orderCount: 'Sipariş miktarı',
  PleaseEnter: 'Lütfen sözleşme numarasını giriniz',
  emailNi: 'Lütfen e-posta doğrulama kodunu girin',
  existingSES: 'Zaten bir Btrok hesabınız var',
  email: 'posta kutusu',
  myinvitation: 'Davetiyem',
  teamValue: 'Ekip Katkı Değeri',
  shareValue: 'Katkı puanlarını paylaşın',
  allValue: 'Toplam Katkı Değeri',
  myBenefits: 'Katkı değerim',
  contribution: 'Katkı değeri',
  contributionDetail: 'Katkı değeri dökümü',
  Dailyinterestrate: 'Günlük faiz oranı',
  LendingDays: 'Ödünç alınan gün sayısı',
  Financialproductdetails: 'Staking detayları',
  Subscriptionwallet: 'Cüzdana abone olun',
  Subscriptionamount: 'Abonelik tutarı',
  SubscriptionDetails: 'Abonelik Detayları',
  Expirationtime: 'Son kullanma süresi',
  Investmenttype: 'Yatırım türü',
  InvestmentDetails: 'Yatırım detayları',
  Thesystemwillautomatically: 'Yatırım vade sonu emirleri için sistem otomatik olarak yeni stake emirlerini eşleştirecektir.',
  Reswitchingfunction: 'Bileşik fonksiyon',
  description: 'Göstermek',
  Investmentquantity: 'Yatırım sayısı',
  Investmentdetermination: 'Yatırımın belirlenmesi',
  RevenueDetails: 'Kazanç dökümü',
  InvestmentOrders: 'Yatırım Emirleri',
  Estimatedtotalrevenue: 'Öngörülen toplam gelir',
  Redemptionmethod: 'Geri Ödeme Yöntemi',
  Dailyrevenue: 'Her gün kazanç',
  LockedDays: 'Lokavt gün sayısı',
  Currenttotalinvestmentequivalent: 'Toplam Mevcut Yatırım (Karşılığı)',
  complete: 'bitirmek',
  Fundinglimit: 'Finansman tutarı',
  Contractnumber: 'Sözleşme numarası',
  Loanfinancing: 'Borç alma ve para yönetme',
  Subscriptiontime: 'Abonelik süresi',
  Myinvestmenthistory: 'Yatırım geçmişim',
  dailyReturn: 'Günlük getiri',
  Investmentdays: 'Yatırım yapılan gün sayısı',
  Selfselectionarea: 'Hızlı seçim',
  Quickconstructionarea: 'Hızlı yapı alanı',
  Smartcontractmatching: 'Akıllı sözleşme eşleştirme',
  ImmediateInvestment: 'Şimdi yatırım yapın',
  sourceTime: 'Saat',
  viewCode: 'Kodu kontrol edin',
  goWealh: 'Zenginliğe giden yol',
  fileGo: 'Dosyalara Git',
  videoRequest: 'Video İstekleri',
  gorequest: 'Çekme istekleri',
  proceeding: 'dava',
  pwds: 'parola',
  experienceAll: 'Yıldızlı bir deneyim',
  experienceFive: 'Beş yıldızlı deneyim',
  sharing: 'Servet paylaşımı',
  customer: 'Müşteri',
  todoList: 'Yap -ılacak işleri',
  systemMessages: 'Sistem mesajları',
  youHave: 'Sahip olduğun',
  totleWait: 'Lütfen zamanında ilgilenin',
  totleBook: 'Okunmamış sistem mesajları',
  message: 'İleti',
  officialNowGo: 'Hemen Başla',
  officialFinace: 'Blockchain finansmanının keyfini çıkarmak hiç bu kadar kolay olmamıştı!',
  officialTechnologyLeft: 'sınır',
  officialTechnologyRight: 'Teknoloji',
  superSESMessage: 'Btrok Bilgileri:',
  superSES: 'Btrok Belediyesi',
  superFomamce: 'Sözleşme finansmanı, finansın tadını çıkarın',
  superSecure: 'Yüksek getirili, güvenli blok zinciri yatırımı',
  superParagraphOne:
    'Btrok Uluslararası Dijital Kripto Varlık Alım Satım Platformu, kapsamlı bir dijital varlık alım satım platformudur, lider teknik güce, yüksek kaliteli müşteri hizmetlerine ve iyi bir kullanıcı deneyimine sahibiz ve dijital para birimi meraklılarının çoğu için ücretsiz bir çevrimiçi değişim platformu sağlıyoruz.',
  superParagraphTwo:
    'Aynı zamanda, Btrok Uluslararası Dijital Kripto Varlık Alım Satım Platformu, dijital para birimi teknik inceleme planlaması, şifreleme algoritması ayarlaması, dijital para birimi araştırma ve geliştirme ve ticaret platformu listeleme gibi tek noktadan blok zinciri hizmetleri de sunmaktadır.',
  superParagraphThree:
    "Btrok uluslararası dijital kripto varlık alım satım platformunun yurt dışı operasyon merkezleri Singapur, Kamboçya ve Marshall'da kuruldu. ",
  Traverser: 'Traverser',
  announceList: 'Duyuru listesi',
  announceListDetail: 'Duyuru detayları',
  official: 'Resmi',
  supervise: 'denetleyici düğüm',
  Source: 'açık kaynak kodu',
  report: 'Denetim Raporu',
  more: 'daha fazla',
  deposit: 'Depozito',
  invite: 'Davet et',
  lockMining: 'Kilitli madencilik',
  riseRanking: 'Kazananlar',
  currency: 'Para birimi',
  recentPrice: 'Son fiyat',
  gains: '24 saatlik kazanç',
  welcomes: 'Hoş geldiniz!',
  home: 'Ev',
  messageList: 'Mesaj listesi',
  noData: 'Veri yok',
  market: 'Alıntılar',
  financialManagement: 'Rehin',
  optional: 'İsteğe bağlı',
  buy: 'Satın al',
  sell: 'Satmak',
  completed: 'Tamamlandı',
  limitOrder: 'Limit Emri',
  marketOrder: 'Piyasa Emri',
  call: 'Boğa',
  put: 'ayı',
  success: 'Başarı',
  failure: 'Başarısızlık',
  transaction: 'Ticaret',
  contract: 'Seçenek',
  forex: 'Forex',
  mine: 'Benim',
  notNull: 'boş olamaz',
  optionContract: 'opsiyon sözleşmesi',
  currentDelivery: 'Cari Teslimat',
  historyDelivery: 'Tarihsel Teslimat',
  fee: 'Komisyon',
  openAmount: 'Açık pozisyon tutarı',
  bonusQuantity: 'İkramiye sayısı',
  predictionDirection: 'Yönü tahmin etmek',
  requestFailure: "İstek başarısız oldu, Yeniden Yükle'yi tıklayın",
  predictionResult: 'Tahmin sonucu',
  submitSuccess: 'Başarıyla gönderildi',
  sharePoster: 'Posteri paylaşın',
  cancel: 'İptal',
  copyLink: 'Bağlantıyı kopyala',
  saveImage: 'Resmi Kaydet',
  pleaseLoginFirst: 'Lütfen önce giriş yapın',
  pleaseUseTronWallet: 'Para yatırma bağlantısını açmak için lütfen TRON cüzdanınızı kullanın',
  currentInterest: 'Güncel Faiz',
  fundsSecurity: 'Fonların güvenliği',
  guarantee: 'Teminat',
  holidayEarnings: 'Tatil kazançları kesintisiz',
  warning: 'Lütfen yukarıdaki adrese para birimi olmayan varlıklar yatırmayın, aksi takdirde varlıklar geri alınmayacaktır. ',
  confirmation:
    'Yukarıdaki adrese para yatırdıktan sonra, tüm blok zinciri ağ düğümünü onaylamanız gerekir ve genel blok zinciri ana ağı 3 ağ onayından sonra ulaşacaktır. ',
  addressChange:
    'Yükleme adresiniz sık sık değişmeyecek ve tekrar tekrar şarj edebilirsiniz ve herhangi bir değişiklik olması durumunda web sitesindeki duyuru veya e-posta yoluyla sizi bilgilendirmek için elimizden gelenin en iyisini yapacağız. ',
  safetyReminder: 'Bilgilerin kurcalanmasını veya sızdırılmasını önlemek için çalışma ortamının güvenli olduğundan emin olun. ',
  rechargeUSDT: 'USDT yatırın',
  details: 'Detaylar',
  dayLimit: 'gün',
  mining: 'Madencilik durmadan kazanıyor',
  upcoming: 'Çok yakında',
  inProgress: 'Devam ediyor',
  distribution: 'Dağıtma ',
  progress: 'İlerleme',
  totalAmount: 'toplam aktivite',
  startTime: 'Başlangıç zamanı',
  endTime: 'Bitiş zamanı',
  lockingMining:
    "Kilitli madencilik, USDT'yi platformun süper bilgi işlem gücü madencilik makinesine emanet ederek platform madenciliği havuzundaki geliri çıkarmaktır",
  productHighlights: 'Ürün Özellikleri',
  withdrawal: 'Kaydet ve Al',
  dividendCycle: 'Temettü Döngüsü',
  successfulDepositOnDay: 'Başarılı para yatırma işleminden sonraki gün',
  interestAccrual: 'Nefes',
  example: 'Örneğin',
  profitCalculation: 'Kazanç hesaplaması',
  simplestProfitFormula: 'Getiriyi hesaplamak için en basit formül ',
  profitRateCalculationExample: 'Örneğin, verim 2W ve anapara 10W ise, verim ',
  calculationOfPrincipal:
    'Menkul kıymet hesapları için, yatırılan anaparayı hatırlamıyorsanız, cari toplam piyasa değeri ve cari kar ve zarar ile hesaplanabilir',
  currentMarketValueExample:
    'Örneğin, mevcut toplam piyasa değeri 11W ve mevcut değişken kazanç 2W, o zaman anapara 11W-2W=9W ve verim = (2W/9W)',
  penalty: 'tasfiye edilen zararlar',
  participate: 'Katılmak istiyorum',
  entrustedOrder: 'sevk emri',
  pledgingInProgress: 'Kazık',
  pledgingComplete: 'Staking tamamlandı',
  earlyRedemption: 'Erken Kefaret',
  loading: 'yükleniyor',
  pledgeAmount: 'Rehin tutarı',
  dailyEarnings: 'Günlük kazanç',
  earlyRedemptionDeductionHandlingFee: 'Erken itfa işlem ücretini düşüyor',
  earlyRedemptionAgain: 'Erken Kefaret',
  endOfContent: 'Zaten son',
  financialProduct: 'Rehin',
  fundsUnderManagement: 'Emanetteki fonlar',
  expectedTodayEarnings: 'Öngörülen bugünkü kazançlar',
  TodayEarnings: 'Bugünün kazançları',
  accumulatedEarnings: 'Kümülatif Getiriler',
  trustOrder: 'Emanetteki siparişler',
  buy2: 'Git satın al',
  period: 'dönem',
  switchLanguage: 'Dil değiştirme',
  username: 'kullanıcı adı',
  emailPlaceholder: 'Lütfen e-posta adresinizi giriniz',
  forgotPassword: 'Şifremi Unuttum',
  passwordPlaceholder: 'Lütfen şifrenizi giriniz',
  submit: 'Gönder',
  registerNow: 'Şimdi kaydolun',
  search: 'Arama',
  high: 'Yüksek',
  low: 'Düşük',
  depth: 'derinlik',
  deal: 'Anlaştık',
  buyQuantity2: 'Alış emri sayısı',
  price: 'Fiyat',
  quantity: 'Miktar',
  sellQuantity: 'Satış Emri Sayısı',
  time: 'Zaman',
  direction: 'Yön',
  inputPrice: 'Lütfen fiyatı giriniz',
  tradingVolume: 'İşlem değeri',
  entrust: 'Yetki Belgesi',
  entrustPrice: 'konsinye fiyatı',
  entrustQuantity: 'sipariş miktarı',
  totalEntrustment: 'Toplam sipariş miktarı',
  category: 'Kategori',
  confirm: 'Tamam',
  inputCorrectQuantity: 'Lütfen doğru miktarı girin',
  operationSuccessful: 'Operasyon Başarılı',
  revoke: 'İptal',
  entrustmentCancellationConfirmation: 'Yetki Devri İptal Onayı',
  areYouSureToCancel: 'Ters ibrazı onaylıyor musunuz?',
  all: 'Hepsi',
  currentEntrustment: 'Mevcut Sipariş',
  completed2: 'Doldurulmuş',
  aboutSES: "Btrok' Hakkında",
  freeExchange: 'Dijital para meraklılarının çoğunluğu için ücretsiz bir çevrimiçi değişim platformu sağlamak',
  modify: 'Değiştir',
  bind: 'bağlayıcı',
  alipayAccount: 'Alipay hesabı',
  wechatAccount: 'WeChat hesabı',
  unbound: 'Bağlantısız',
  sesPlatformOverview3:
    "Btrok Digital Asset Exchange, merkezi Birleşik Krallık'ta bulunan, İngiliz NFC tarafından düzenlenen ve Avrupa blok zinciri endüstrisinde bir dizi altyapı inşaat çalışması yapan İngiliz MAI Uluslararası Vakfı tarafından yatırım yapılan ve geliştirilen küresel bir dijital varlık ticaret platformudur. Küresel yatırımcılar için uluslararası bir dijital varlık borsası olan Btrok Dijital Varlık Borsası, mevcut endüstri avantajlarını birleştirir, üst düzey bir bankacılık ve finansal yönetim ekibine, profesyonel bir büyük veri geliştirme ekibine, dünyanın en iyi blok zinciri geliştirme ekibine ve önde gelen bir blok zinciri teknolojisi hizmet ekibine sahiptir. Kullanıcılara verimli ve güvenli dijital varlık alım satım hizmetleri sunmayı amaçlar.",
  sesPlatformOverview4:
    'Dünyanın ilk kapsamlı dijital varlık alım satım platformu olarak, dünyanın dijital varlık ekolojik düzenini kapsayan, uluslararası lisanslara ve devlet kurumu tanınırlığına sahip, dünyanın blok zinciri alanındaki en iyi ticaret platformlarından biridir. Btrok, küresel kullanıcılara Bitcoin ve Ethereum gibi ana akım dijital para birimleri için spot ve türev ticaret hizmetleri hizmetleri sunarken, teknolojik yenilik, ürün yineleme, güvenlik ve risk kontrol sistemi, operasyon ve müşteri hizmetleri sistemi açısından gelişmeye devam edecek, Btrok zamana ayak uyduruyor ve kapsamlı bir blok zinciri ekosistemi oluşturmaya yardımcı oluyor.',
  sesPlatformOverview5:
    'Güvenli, deneyimsel ve ücretsiz bir dijital varlık alım satım platformu ve dünyanın dört bir yanındaki dijital para birimi meraklılarının ihtiyaçlarını karşılayan bir finansal altyapı oluşturun ve dijital ara bağlantı çağında sanal dijital varlıkların ticareti ve takası için gerçekten ideal bir topluluk oluşturun. Yatırımcıların çoğunluğu için güvenli, güvenilir ve istikrarlı kapsamlı bir hizmet ticaret platformu sağlamak.',
  bankCardNumber: 'Banka kartı hesap numarası',
  accountSettings: 'Hesap Ayarları',
  modifyAlipayAccount: 'Alipay hesabınızı değiştirin',
  receiveQRCode: 'Ödeme almak için QR kodu',
  fundPassword: 'Finansman şifreleri',
  pleaseEnterCorrectFundPassword: 'Lütfen doğru finansman şifresini girin',
  pleaseEnterAlipayAccount: 'Lütfen Alipay hesap numaranızı girin',
  myAssets: 'Varlıklarım',
  details2: 'Şey',
  withdrawalRecords: 'Para çekme kayıtları',
  totalAssetsApprox: 'Toplam varlıklar (USDT)',
  spotAccount: 'Spot hesap',
  searchAssets: 'Varlıkları arama',
  hideZeroBalances: '0 olarak gizlenen para birimleri',
  frozen: 'dondurmak',
  toRelease: 'Serbest bırakılacak',
  modifyBankCardNumber: 'Kart hesap numaranızı değiştirin',
  save: 'Kurtarmak',
  bankName: 'Mevduat bankası',
  bankCardNumberConfirm: 'Kart numarasını onaylayın',
  pleaseEnterBankCardNumberAgain: 'Lütfen kart numaranızı tekrar giriniz',
  fundPasswordPrompt: 'Finansman şifreleri',
  pleaseEnterFundPassword: "Lütfen PIN'inizi girin",
  modifyFundPassword: 'Fon şifresini değiştirme',
  modifyingFundPassword: 'Finansman şifrenizi değiştirme sürecindesiniz',
  oldFundPassword: 'Eski Fonlar Şifresi',
  newFundPassword: 'Yeni Fon Şifresi',
  confirmFundPassword: 'Fon şifresini onaylayın',
  pleaseEnterSetPassword: 'Ayarlamak için lütfen şifreyi girin',
  pleaseEnterConfirmFundPassword: 'Lütfen onay fonu şifresini girin',
  passwordsDoNotMatch: 'Parolalar tutarsız',
  pleaseEnterYourOldFundPassword: 'Lütfen eski fon şifresini girin',
  pleaseEnterYourNewFundPassword: 'Lütfen yeni fon şifrenizi girin',
  pleaseEnterA6DigitPassword: 'Lütfen 6 haneli bir PIN girin',
  pleaseConfirmNewFundPassword: 'Lütfen yeni fon şifresini onaylayın',
  theNewFundPasswordCannotBeTheSameAsTheOldFundPassword: 'Yeni fon şifresi eski fon şifresi ile aynı olamaz',
  pleaseEnterTheVerificationCode: 'Lütfen bir doğrulama kodu girin',
  feedback: 'Geri besleme',
  yourOpinionIsVeryImportantToUs: 'Fikriniz bizim için özellikle önemlidir!',
  leaveYourValuableOpinionToHelpUsImprove: 'Daha iyisini yapmamıza yardımcı olmak için değerli yorumlarınızı bırakın!',
  pleaseLeaveYourValuableOpinion: 'Lütfen değerli yorumlarınızı bırakın',
  submissionSuccessfulThankYouForYourFeedback: 'Gönderim başarılı, geri bildiriminiz için teşekkür ederiz',
  submissionFailed: 'Gönderim başarısız oldu',
  setFundPassword: 'Fonlar için bir şifre belirleyin',
  setYourPaymentMethod: 'Lütfen ödeme yönteminizi ayarlayın ve gerçek adınızı kullandığınızdan emin olun',
  newPassword: 'Yeni şifreler',
  repeatPassword: 'Parolayı tekrarlayın',
  notLoggedIn: 'Giriş yapılmadı',
  welcome: 'Btrok şehrine hoş geldiniz',
  assetManagement: 'Varlık yönetimi',
  assetFlow: 'Varlık akışları',
  myInvitation: 'Davetiyem',
  invitationLink: 'Davet bağlantısı',
  firstLevelFriends: 'Birinci sınıf arkadaşlar',
  secondLevelFriends: 'İkinci seviye arkadaşlar',
  partnerLevel: 'Bireysel seviye',
  myEntrustment: 'Benim komisyonum',
  securitySettings: 'Güvenlik ayarları',
  pleaseLogInFirst: 'Lütfen önce giriş yapın',
  announcement: 'Duyuru',
  changeLoginPassword: 'Giriş şifrenizi değiştirin',
  oldLoginPassword: 'Eski giriş şifresi',
  newLoginPassword: 'Yeni giriş şifresi',
  pleaseEnterOldLoginPassword: 'Lütfen eski giriş şifrenizi girin',
  pleaseEnterNewLoginPassword: 'Lütfen yeni bir giriş şifresi girin',
  confirmLoginPassword: 'Giriş bilgilerinizi onaylayın',
  emailVerificationCode: 'E-posta doğrulama kodu',
  emailBound: 'E-posta adresinizi bağladınız',
  verificationCode: 'Captcha (İngilizce)',
  sendVerificationCode: 'Doğrulama kodu gönderme',
  pleaseConfirmInformation: 'Lütfen tüm bilgileri doldurduğunuzdan emin olun',
  pleaseConfirmPassword: 'İki kez girilen şifrenin aynı olduğundan emin olun',
  makeSureTheOldAndNewPasswordsAreDifferent: 'Eski ve yeni şifrelerin aynı olmadığından emin olun',
  pleaseConfirmNewLoginPassword: 'Lütfen yeni giriş şifresini onaylayın',
  teamPerformance: 'Takım performansı',
  currentLevel: 'Mevcut seviye',
  nextLevel: 'Bir sonraki seviye',
  teamNumber: 'Gruptaki kişi sayısı',
  totalPerformance: 'Toplam Sonuçlar',
  onlyForUSDT: 'Bu adrese sadece USDT yatırılabilir',
  walletAddress: 'Cüzdan adresi',
  securityReminder: 'Hesabınızın güvenliğini sağlamak için lütfen işlem yapmadan önce gerçek ad doğrulamasını tamamlayın!',
  personalAvatar: 'Kişisel avatar',
  isVerified: 'Sertifikalı',
  realNameAuthentication: 'Gerçek adla kimlik doğrulama',
  notVerified: 'Sertifikalı değil',
  receivingAccount: 'Para çekme adresi',
  loginPassword: 'Giriş şifresi',
  fundsPassword: 'Finansman şifreleri',
  settingsCenter: 'Merkezi ayarlama',
  aboutUs: 'Hakkımızda',
  helptext: 'Yardım Notları',
  help: 'Yardım',
  logout: 'Mevcut hesabınızdan çıkış yapın',
  confirmLogout: 'Oturumu kapatmak istediğinizden emin misiniz?',
  bindWeChatAccount: 'WeChat hesabınızı bağlayın',
  withdrawalAddress: 'Para çekme adresi',
  available: 'mevcut',
  minimumWithdrawalAmount: 'Minimum para çekme sayısı gereklidir',
  amountReceived: 'Alınan miktar',
  transactionTime: 'Alım satım saatleri',
  payableHandlingFee: 'Ödenecek işlem ücreti',
  deductibleHandlingFee: 'Ofset işlem ücretleri',
  actualHandlingFee: 'Cepten işlem ücreti',
  feeGenerationTime: 'Başlangıç ve bitiş saatleri, komisyonun oluşturulduğu zamandır',
  takeIDPhoto: 'Kimliğinizin fotoğrafını çekin ve yükleyin',
  identityAuthentication: 'Kimlik doğrulama',
  uploadIDCardFront: 'Kimlik kartınızın ön yüzünün bir resmini yükleyin',
  uploadIDCardBack: 'Kimlik kartınızın arka yüzünün bir resmini yükleyin',
  photoAlbum: 'Fotoğraf Albümü',
  IDCardNumber: 'Kimlik numarası',
  enterIDCardNumber: 'Lütfen kimlik numaranızı giriniz',
  name: 'ad',
  idea: 'Btrok Felsefesi',
  goal: 'Btrok Hedefi',
  depositRecord: 'Şarj geçmişi',
  withdrawal2: 'Para çekme',
  accountOpeningBranch: 'Hesap açma şubesi',
  quantityInput: 'Lütfen adedi giriniz',
  singleLimit: 'Tek İşlem Limiti',
  investmentAmount: 'Yatırılan miktar',
  incomePrincipal: 'Gelir/Anapara',
  password: 'parola',
  historicalCommission: 'Tarihsel komisyonlar',
  type: 'tür',
  commissionAmount: 'Siparişin miktarı',
  filter: 'elemek',
  pleaseSelect: 'Lütfen seçiniz',
  tradingPair: 'Ticaret çiftleri',
  reset: 'sıfırlama',
  startEndTime: 'Başlangıç ve bitiş saatleri',
  recharge2: 'Şarj',
  withdraw: 'Ödeme',
  transfer: 'Aktarmak',
  coinTrading: 'Spot ticaret',
  fiatCurrencyPurchase: 'İtibari para birimi satın alma',
  fiatCurrencySale: 'İtibari para birimi satılır',
  activityReward: 'Etkinlik Ödülleri',
  promotionReward: 'Promosyon Ödülleri',
  dividend: 'Temettü',
  vote: 'oy',
  manualRecharge: 'Manuel yükleme',
  pairing: 'Eşleştirme',
  activityExchange: 'Etkinlik Kullanımı:',
  ctcPurchase: 'CTC Satın Al',
  ctcSale: 'CTC satıyor',
  redEnvelopeSending: 'Kırmızı zarflar verilir',
  redEnvelopeReceiving: 'Kırmızı zarf koleksiyonu',
  withdrawalCodeWithdrawal: 'Para çekme kodu',
  withdrawalCodeRecharge: 'Şarj etmek için kodu geri çekin',
  perpetualContractFee: 'Sözleşme ücreti',
  perpetualContractProfit: 'Sözleşme karlı',
  perpetualContractLoss: 'Sözleşme kaybeder',
  optionContractFailure: 'Opsiyon sözleşmesi başarısız oldu',
  optionContractFee: 'Opsiyon sözleşmesi ücretleri',
  optionContractBonus: 'Opsiyon sözleşmesi bonusları',
  contractRebate: 'Vadeli işlem indirimleri',
  levelReward: 'Kademe Ödülleri',
  platformFeeIncome: 'Platform ücreti geliri',
  forexEntrustTrading: 'Döviz emanet ticareti',
  forexCancelEntrustTrading: 'Konsinye işlemlerin döviz iptali',
  income: 'kazanç',
  promotionIncome: 'Kazançları teşvik edin',
  optionsPromotionIncome: 'Opsiyon promosyonu kazançları',
  liquidationCompensation: 'Tasfiye ödemesi',
  lockedMiningIncome: 'Takım kazançları',
  optionTeamIncome: 'Opsiyon ekibi kazançları',
  optionParityAward: 'Opsiyon paritesi ödülleri',
  investmentTeamReturn: 'Ekip kazançlarına yatırım yapın',
  investmentParityReturn: 'Sabit yatırım getirisi',
  investmentPromotionIncome: 'Yatırım teşvik gelirleri',
  NEWACTIVITY_IN: 'Yatırım geliri',
  NEWACTIVITY_DEDUCT: 'Yatırımın itfası',
  NEWACTIVITY_FREEZE: 'Opsiyon dondurma',
  NEWACTIVITY_UNFREEZE: 'Seçenek çözme',
  NEWACTIVITY_REDEEM_UNFREEZE: 'Opsiyon kullanımı ve dondurma',
  speedContractProfit: 'saniye sözleşme karı',
  speedContractLoss: 'Saniye sözleşme kayıpları',
  speedContractCommission: 'Saniye başına işlem ücreti',
  CONTRACT_FAST_FREEZE: 'Saniye sözleşmesi donuyor',
  CONTRACT_FAST_UNFREEZE: 'saniye sözleşmesi çözülür',
  confirmUploadAllImages: 'Lütfen tüm resimleri yüklediğinizden emin olun',
  inputRealName: 'Lütfen gerçek adınızı giriniz',
  idCardPhoto: 'Kimliğinizin bir fotoğrafı',
  congratulations: 'Tebrikler, incelemeyi geçtiniz ve gönül rahatlığıyla ticaret yapabilirsiniz',
  quantity2: 'ölçmek',
  wechatAccount2: 'Lütfen WeChat hesabınızı girin',
  realNameIdentityVerified: 'Gerçek isim kimliği doğrulandı',
  withdrawalTime: 'Çekilme süresi',
  withdrawalAmount: 'Çekilecek miktar',
  creditting: 'İnceleniyor',
  waitingmoney: 'Madeni paraların serbest bırakılmasını bekleyin',
  dztime: 'Yükleme süresi',
  rushaddress: 'Para yatırma adresi',
  rushnumber: 'Yükleme miktarı',
  regisertitle: 'E-posta kaydı',
  inputemailcode: 'Lütfen e-posta doğrulama kodunu girin',
  inputloginpassword: 'Lütfen şifrenizi girin (6 haneden fazla, büyük ve küçük harflerin birleşimi)',
  inputreloginpassword: 'Lütfen şifrenizi tekrar girin',
  inputinvite: 'Lütfen davet kodunu giriniz',
  iagree: 'Okudum ve kabul ediyorum',
  useragreement: 'Kullanıcı Sözleşmesi',
  nowlogin: 'Şimdi giriş yap',
  sendcode: 'Doğrulama kodu gönderme',
  rightemailformat: 'Lütfen doğru e-posta adresini girin',
  confirmPassword: 'Şifrenizi onaylayın',
  passwordtip: 'Şifre en az 6 basamak içermelidir',
  nosamepassword: 'Parola iki kez tutarsız',
  noagree: 'Kullanıcı Sözleşmesini Kabul Et İşaretlenmemiş',
  emailverification: 'E-posta doğrulama',
  amount: 'miktar',
  overturn: 'Aktarmak',
  overturntitle: 'Varlık transferleri',
  from: 'Kaynak',
  to: 'varmak',
  swapcurrency: 'Sözleşme para birimi',
  overturnplaceholder: 'Lütfen transfer edilecek tutarı girin',
  selectaccount: 'Hesap Seç',
  balance: 'Kıymet',
  contractaccount: 'Vadeli İşlemler Hesapları',
  invitecode: 'Davetiye kodu',
  notset: 'Ayarlanmadı',
  day: 'gök',
  contactservice: 'Müşteri hizmetleri ile iletişime geçin',
  appname: 'Btrok UYGULAMASI',
  download: 'Uygulamayı indirin',
  orderByHand: 'Siparişinizi manuel olarak verin',
  orderByAuto: 'Akıllıca sipariş verin',
  trevenue: 'Toplam Gelir',
  pincome: 'Kazançları teşvik edin',
  tincome: 'Takım kazançları',
  dropList: 'Açılır liste',
  uploaded: 'Yüklenen',
  youare: 'Sizsiniz',
  disabling: 'Kapat',
  enabling: 'Açık',
  theAutomaticReinvestmentFeatur: 'Otomatik çoğaltma',
  nth: 'cümlecik',
  phase: 'Zaman sınırı',
  currentForecastPeriod: 'Cari dönem için tahmin dönemi',
  maximum: 'maksimum',
  openingPrice: 'Açılış',
  currentPrice: 'Güncel fiyat',
  profitSharing: 'Kar temettüleri',
  CurrentTradingIsRestricted: 'Ticaret şu anda kısıtlı',
  ToEnsureTheSsecurityOfYourAssetsPleaseCarefullyVerifyTheReceivingWalletAddressBeforeMakingAnyDeposits:
    'Herkesin varlıklarının güvenliğini sağlamak için, lütfen yeniden yükleme için cüzdan adresini dikkatlice kontrol edin:',
  PleaseProceedWithTheDepositOnlyAfterVerifyingThatEverythingIsCorrect:
    '。 Sadece doğru olup olmadığını kontrol ettikten sonra şarj edebilirsiniz.',
  PleaseDoNotDepositAnyAssetsOtherThanTheSpecifiedCryptocurrencyToTheAddressMentionedAboveOtherwiseYourAssetsWillNotBeRecoverable:
    'Lütfen yukarıdaki adrese para birimi olmayan varlıklar yatırmayın, aksi takdirde varlıklar geri alınmayacaktır.',
  AfterYouMakeADepositToTheMentionedAddressItRequiresConfirmationFromTheEntireBlockchainNetworkTypicallyItTakesAround30SecondsForTheFundsToBeCreditedToYourAccountAfterThreeConfirmationOnTheBlockchainMainnet:
    'Yukarıdaki adrese para yatırdıktan sonra, tüm blok zinciri ağ düğümünü onaylamanız gerekir ve blok zinciri ana ağında 3 ağ onayından sonra gelmesi yaklaşık 30 saniye sürecektir.',
  PleaseEnsureTheSecurityOfYourElectronicDevicesToPreventAnyTamperingOrLeakageOfInformation:
    'Bilgilerin kurcalanmasını veya sızdırılmasını önlemek için lütfen elektronik cihazlarınızın güvenli olduğundan emin olun.',
  WithdrawalsAreCurrentlyDisabled: 'Para çekme işlemleri şu anda yasaktır',
  CurrentWithdrawalAddress: 'Lütfen mevcut para çekme adresini onaylayın ',
  isYourWalletAddress: ' Cüzdan adresiniz olsun',
  LinkCopiedSuccessfully: 'Çoğaltma başarılı',
  LinkCopiedFailed: 'Çoğaltma başarısız oldu',
  goLogin: 'Giriş yap',
  other: 'başka',
  SavePicture: 'Resmi kaydedin',
  SaveLink: 'Bağlantıyı kaydedin',
  languageSettings: 'Dil ayarları',
  simplifiedChinese: '简体中文',
  traditionalChinese: '繁体中文',
  arabic: 'عربي',
  english: 'English',
  japanese: '日本語',
  French: 'Français',
  Spanish: 'Español',
  German: 'Deutsch',
  Italiano: 'Italiano',
  TotalDailyLoss: 'Toplam kâr',
  TotalExpenditureToday: 'Bugün verilen siparişlerin toplam miktarı',
  TotalIncomeToday: 'Bugün toplam kar',
  CurrentDelivery: 'Mevcut teslimat',
  HistoricalDelivery: 'Geçmiş teslimat',
  InitialAmount: 'Siparişin miktarı',
  ForecastDirection: 'Yönü tahmin edin',
  BonusAmount: 'Bonus sayısı',
  TransactionFee: 'Premium',
  OrderDuration: 'Sipariş süresi',
  Status: 'devlet',
  SelectCurrency: 'Bir para birimi seçin',
  EnterAmount: 'Tutarı girin',
  BUY: 'Satın al',
  SELL: 'Aşağı satın al',
  WIN: 'Başarılı',
  LOSE: 'Başarısız',
  Amount: 'miktar',
  expectedReturn: 'Beklenen Faydalar',
  service: 'Müşteri',
  untrade: 'Şu anda ticaret yapmak mümkün değil',
  netError: 'Ağ terk edildi!',
  questError: 'İstekle ilgili bir hata oluştu, lütfen daha sonra tekrar deneyin!',
  failVerified: 'Kimlik doğrulaması başarısız oldu',
  tiprealverified: 'Gerçek isim incelemesi sırasında lütfen müşteri hizmetleri ile iletişime geçin.',
  uploadImg: 'Kimlik bilgilerini karşıya yükleme',
  collected: 'Yer imlerine eklendi',
  canceld: 'İptal edilmiş',
  placeholderAddress: 'Lütfen alıcı adresinizi girin',
  bindAccountTitle: 'Alıcı adresi',
  marketTitle: 'pazar',
  regiserSuccess: 'Kayıt başarılı',
  setSuccess: 'Kurulum başarılı oldu',
  updateAddress: 'Cüzdan adresimi değiştirmek istiyorum!',
  doingRealName: 'Gerçek isim inceleniyor, lütfen sabırlı olun',
  updateSuccess: 'Değişiklik başarılı oldu',
  uploadSuccess: 'Yükleme başarılı',
  forgetLpass:
    'Şifrenizi unutursanız, bilgileri kontrol etmek ve giriş şifrenizi sıfırlamak için lütfen müşteri hizmetleri ile iletişime geçin',
  forgetApass:
    'Şifrenizi unutursanız, bilgileri doğrulamak ve işlem şifresini sıfırlamak için lütfen müşteri hizmetleri ile iletişime geçin.',
  updateAddressTip:
    'Alıcı adresini değiştirmek için lütfen kişisel bilgileri doğrulamak ve alıcı adresini sıfırlamak için müşteri hizmetleri ile iletişime geçin',
  a48h: 'Para çekme işlemi kabul edildi ve para çekme başvurusu 48 saat içinde işleme alınacaktır, lütfen sabırla bekleyin',
  r48h: 'Şarj kabul edildi ve şarj emri 48 saat içinde işlenecek, lütfen sabırla bekleyin',
  accepted: 'Kabul etmek',
  notremind: 'Artık hatırlatıcı yok',
  //new
  rate: 'Karlılık',
  mode: 'Ticaret Modu',
  num: 'Açık pozisyonların sayısı',
  openTime: 'Pozisyonun açıldığı saat',
  phoneNi: 'Lütfen cep telefonunuzdaki doğrulama kodunu girin',
  phonePlaceholder: 'Lütfen cep telefonu numaranızı giriniz',
  inputphonecode: 'Lütfen cep telefonunuzdaki doğrulama kodunu girin',
  phone: 'Cep telefonu numarası',
  phoneVcode: 'Cep telefonu doğrulama kodu',
  sendSucc: 'Gönderme başarılı oldu',
  cScore: 'Kredi puanı',
  wallet: 'cüzdan',
  searchMp: "Rechercher le nom d'une crypto",
  clickUploadId: 'Fotoğraflı kimliğinizi yüklemek için tıklayın',
  modifyFp: 'Ayarlar',
  confirmFPassword: 'Varlık Parolasını Onaylayın',
  pleaseSetYourNewFundPassword: 'Lütfen bir fon şifresi belirleyin',
  nosamefpassword: 'Para şifresi doğru değil, lütfen tekrar onaylayın.',
  forgotFPassword: 'İşlem şifrenizi mi unuttunuz?',
  serviceCode: {
    0: 'Başarılı',
    500: 'Başarısız',
    501: 'Kayıt başarısız oldu',
    502: 'Gönderilemedi',
    503: 'Eklenemedi',
    504: 'Sorgu başarısız oldu',
    600: 'Bu para birimi para çekme işlemlerini desteklemiyor',
    1001: 'Doğrulama kodu mevcut değil',
    1003: 'Para çekme tutarı maksimum tutardan fazla',
    1004: 'Para çekme tutarı minimum tutardan az',
    1005: 'Kullanılabilir bakiye yetersiz',
    1006: 'Cüzdan kilitli',
    1007: 'Gerçek ad doğrulama şu anda inceleniyor, lütfen sabırla bekleyin ve inceleme geçtikten sonra işlemi gerçekleştirin',
    1008: 'Lütfen önce bir fon şifresi belirleyin',
    1009: 'Fon şifresi yanlış',
    1010: 'Varlıklar kilitlenemez',
    1011: 'E-posta adresi bağlandı',
    1012: 'Kullanıcı adı zaten mevcut',
    1013: 'Böyle bir kullanıcı yok',
    1014: 'Kayıt aktivasyon e-postası e-postanıza gönderildi, lütfen isteği tekrarlamayın',
    1015: 'Lütfen önce e-posta adresinizi bağlayın',
    1016: 'Sipariş tutarı 0 olamaz',
    1017: 'Mevcut hesabınız devre dışı bırakıldı, lütfen müşteri hizmetleri ile iletişime geçin!',
    1018: 'Etkinlik mevcut değil',
    1019: 'Satın alma tutarı minimum tutardan az',
    1020: 'Satın alma tutarı maksimum tutardan fazla',
    1021: 'Desteklenmeyen Para Birimleri!',
    1022: 'İşlem çiftleri favorilerdir',
    1023: 'Ayarları tekrarlamayın',
    1024: 'Fon şifresinin uzunluğu geçerli değil',
    1025: 'Sözleşme mevcut değil',
    1026: 'Tahmin yanlış yönde',
    1027: 'Kullanıcı cüzdanı mevcut değil',
    1028: 'Hesap numarası veya şifre yanlış',
    1029: 'Hesap etkin değil/devre dışı, lütfen müşteri hizmetleri ile iletişime geçin',
    1030: 'Lütfen önce gerçek adla kimlik doğrulamasını tamamlayın',
    1031: 'Lütfen bir işlem şifresi belirleyin',
    1032: 'Gerçek ad doğrulaması tamamlandı ve yeniden kimlik doğrulaması yapılamıyor',
    1033: 'Aynı kimlik numarası yalnızca bir kez doğrulanabilir',
    1035: 'Eski şifre yanlış',
    1036: 'Lütfen para yatırma makbuzunuzu yükleyin',
    1037: 'Lütfen doğru yükleme tutarını girin',
    1038: 'Hesap mevcut değil',
    1039: 'Parola yanlış',
    1040: 'Eski şifre yanlış',
    1041: 'İşlem şifresi yanlış, lütfen tekrar girin',
    1042: 'Davet kodu yanlış',
    1043: 'Para çekme adresi anormal',
    1065: 'Mevcut sipariş inceleniyor, lütfen tekrar tekrar göndermeyin',
    3000: 'Oturum açma zaman aşımına uğradı',
    888: 'Lütfen önce gerçek adla kimlik doğrulamasını tamamlayın',
  },
  korean: '한국어',
  turkish: 'Türkçe',
};
