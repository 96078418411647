<script lang="ts">
import { defineComponent } from 'vue'
export default defineComponent({
  name: 'BasicLayoutPage',
})
</script>
<template>
  <div>
    <div class="main-page" :class="{ tabbar: tabbarVisible }">
      <RouterView v-slot="{ Component, route }">
        <keep-alive>
          <component :is="Component" :key="route.name" v-if="route.meta.keepAlive" />
        </keep-alive>
        <component :is="Component" :key="route.name" v-if="!route.meta.keepAlive" />
      </RouterView>
    </div>
    <nut-tabbar
      unactive-color="#ccc"
      active-color="#F0A70A"
      bottom
      placeholder
      v-model="activeTab"
      v-show="tabbarVisible"
      @tab-switch="tabSwitch"
    >
      <nut-tabbar-item v-for="item in tabItem" :key="item.name" :tab-title="$t(item.title)">
        <template #icon="props">
          <img :src="props.active ? item.activeIcon : item.icon" alt="" />
        </template>
      </nut-tabbar-item>
    </nut-tabbar>
  </div>
</template>

<script lang="ts" setup name="BasicLayoutPage">
  import { useRouter } from 'vue-router';
  import { ref, watch } from 'vue';

  const tabItem = [
    { title: 'home', name: 'home', icon: '/img/icon/home_one_no_color.jpg', activeIcon: '/img/icon/home_one_color.jpg' },
    { title: 'financialManagement', name: 'Intelligent', icon: '/img/ai1.png', activeIcon: '/img/ai2.png' },
    { title: 'market', name: 'quotation', icon: '/img/icon/home_two_no_color.jpg', activeIcon: '/img/icon/home_two_color.jpg' },
    { title: 'contract', name: 'contract', icon: '/img/icon/home_four_no_color.jpg', activeIcon: '/img/icon/home_four_color.jpg' },
    { title: 'wallet', name: 'balance', icon: '/img/icon/beifen.png', activeIcon: '/img/icon/beifens.png' },
  ];

  // const user = useUserStore();

  const router = useRouter();

  const activeTab = ref(0);

  const tabbarVisible = ref(true);

  const showBorder = ref(true);

  watch(
    () => router,
    () => {
      const judgeRoute = tabItem.some((item) => item.name === router.currentRoute.value.path.replace('/', ''));
      activeTab.value = tabItem.findIndex((item) => item.name === router.currentRoute.value.path.replace('/', ''));
      tabbarVisible.value = judgeRoute;
      showBorder.value = judgeRoute;
    },
    { deep: true, immediate: true },
  );

  const tabSwitch = (_item, index) => {
    switch (index) {
      case 0:
        router.push('/home');
        break;
      case 1:
        router.push('/Intelligent');
        break;
      case 2:
        router.push('/quotation');
        break;
      case 3:
        router.push('/contract');
        break;

      case 4:
        router.push('/balance');
        break;
    }
    activeTab.value = index;
  };
</script>

<style scoped lang="scss">
::v-deep .nut-badge {
  position: absolute;
  top: 10px;
}
::v-deep .nut-tabbar-item_icon-box_nav-word{
  width: 120px;
  white-space: normal !important;
  // word-wrap: break-word;
}
::v-deep .nut-tabbar {
  height: 105px;
  font-size: 30px;
  border-bottom: none;
  padding-bottom: 5px;
}

::v-deep .nut-tabbar-item_icon-box_icon {
  img {
    width: 40px;
    height: 40px;
  }
}

::v-deep .nut-tabbar-item_icon-box_nav-word {
  font-size: 23px;
  color: #686666;
  font-weight: 600;
  white-space: nowrap;
}

.nut-navbar {
  margin-bottom: 0;
}

.main-page {
  box-sizing: border-box;
  // overflow-x: hidden;
}

// .main-page.tabbar {
//   // padding-bottom: 100px;
// }

.tabbar {
  // height: calc(100vh - 92px);
  // overflow-y: scroll;
  padding-bottom: 92px;
}

.border {
  padding-left: 30px;
  padding-right: 30px;
}
</style>
