<script lang="ts">
import { defineComponent } from 'vue'
export default defineComponent({
  name: 'InvestmentDetails',
})
</script>
<template>
  <div>
    <!-- 行情title -->
    <var-app-bar
      :title="$t('InvestmentDetails')"
      color="#F7F7F7"
      title-position="center"
      style="z-index: 10; color: #333333; font-weight: 600"
    >
      <template #left>
        <div @click="$router.back">
          <van-icon class="text-gray-500" name="arrow-left" size="20" />
        </div>
      </template>
    </var-app-bar>
    <div class="content p-40px pt-120px">
      <!-- <div class="HYBH mb-15px">
        <span class="text-gray-400 font-600" style="font-size: 10px">{{ $t('Contractnumber') }}:{{ Detail.pactNum }}</span>
      </div> -->
      <div class="conTop">
        <div class="relative h-auto rounded-xl w100 p-20px bg-gray-100 mb-25px">
          <div class="TZlistItemHeader flex items-center justify-between pb-20px" style="border-bottom: 1px solid #d6d6d6">
            <!-- 币种 -->
            <div class="HeaderLeft flex items-center">
              <img class="w-40px h-40px mr-20px" :src="Detail.url" />
              <span class="font-600 text-lg">{{ Detail.symbol }}</span>
            </div>
            <!-- 订单状态 -->
            <div class="flex items-center">
              <!-- <span class="font-600">{{ Static[Detail.status] }}</span> -->
              {{
                Detail.status == 1
                  ? $t('inProgress')
                  : Detail.status == 2
                  ? $t('completed')
                  : Detail.status == 3
                  ? $t('earlyRedemption')
                  : ''
              }}
            </div>
          </div>
          <div class="TZlistItemCon flex w100 h-auto mt-25px border-bottom" style="flex-direction: column">
            <div class="w100 flex justify-between">
              <span class="font-600 text-black-500" style="">{{ $t('Subscriptiontime') }}</span>
              <span class="text-gray-500">{{ getdate(Detail.createTime) }}</span>
            </div>
            <div class="mt-20px w100 flex justify-between">
              <span class="font-600 text-black-500" style="">{{ $t('Investmenttype') }}</span>
              <span class="text-gray-500"> {{ $t('Ordinarysingle') }} </span>
            </div>
            <div class="mt-20px w100 flex justify-between">
              <span class="font-600 text-black-500" style="">{{ $t('Expirationtime') }}</span>
              <span class="text-gray-500">{{ getdate(Detail.endTime) }}</span>
            </div>
          </div>
        </div>
        <div class="h-auto rounded-xl w100 p-20px">
          <div class="flex items-center justify-between pb-20px">
            <span class="font-600 text-lg">{{ $t('RevenueDetails') }}</span>
            <!-- <div>
              <span class="font-600 text-yellow-400 mr-10px">{{ $t('RevenueDetails') }}</span>
              <van-icon class="text-gray-500" name="arrow" size="15" />
            </div> -->
          </div>
          <div class="mt-20px" style="border-bottom: 1px solid #e6e6e6">
            <div class="flex items-center justify-between pb-30px">
              <span class="text-me solid-bottom">{{ $t('dailyEarnings') }}</span>
              <span class="text-gray-500">
                <span class="mr-5px">{{ Detail.dayIncome }}</span>
                <!-- {{ Detail.symbol }}  -->
                <span>USDT</span></span
              >
            </div>
            <div class="flex items-center justify-between pb-30px">
              <span class="text-me">{{ $t('accumulatedEarnings') }}</span>
              <!-- {{ Detail.symbol }} -->
              <span class="text-gray-500">{{ Detail.allIncome }} USDT </span>
            </div>
          </div>
        </div>
        <div class="h-auto rounded-xl px-20px mb-25px">
          <div class="flex items-center justify-between pb-20px">
            <span class="text-lg font-600">{{ $t('SubscriptionDetails') }}</span>
          </div>
          <div class="mt-20px" style="border-bottom: 1px solid #e6e6e6">
            <div class="flex items-center justify-between pb-30px">
              <span class="text-me solid-bottom">{{ $t('Subscriptionamount') }}</span>
              <!-- {{ Detail.symbol }} -->
              <span class="text-gray-500">{{ Detail.amount }}<span class="ml-5px">USDT</span> </span>
            </div>
            <!-- <div class="flex items-center justify-between pb-30px">
              <span class="text-me">{{ $t('Subscriptionwallet') }}</span>
              <span class="text-gray-500">{{ codefixed }}</span>
            </div> -->
          </div>
        </div>
        <!-- 理财产品详情 -->
        <div class="h-auto rounded-xl px-20px mb-25px">
          <div class="flex items-center justify-between pb-20px">
            <span class="font-600 text-lg">{{ $t('Financialproductdetails') }}</span>
          </div>
          <div class="mt-20px">
            <div class="flex items-center justify-between pb-30px">
              <span class="text-me solid-bottom">{{ $t('Fundinglimit') }}</span>
              <span class="text-gray-500" style="font-size: 13px;">{{ Detail.miningInvite }} <span>USDT</span></span>
            </div>
            <div class="flex items-center justify-between pb-30px">
              <span class="text-me">{{ $t('LendingDays') }}</span>
              <span class="text-gray-500" style="font-size: 13px;">{{ Detail.days }} {{ $t('day') }}</span>
            </div>
            <div class="flex items-center justify-between pb-30px">
              <span class="text-me">{{ $t('Dailyinterestrate') }}</span>
              <span class="text-gray-500" style="font-size: 13px;">{{ (Detail.releasePercent * 100).toFixed(4) }}%</span>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- <div class="bottom flex justify-center" style="position: absolute; bottom: 20px; width: 100%">
      <van-button style="width: calc(100% - 40px)" class="mt-3 h-70px font-600 text-xxxl" type="primary" :color="data.buttonColor">{{
        $t('complete')
      }}</van-button>
    </div> -->
  </div>
</template>

<script lang="ts" setup name="InvestmentDetails">
  // import { Static } from '/@/types/Intelligent';
  import { getdate } from '/@/types/Intelligent';
  import { detailbyid } from '/@/api/Intelligent';
  // import { getrechange } from '/@/api/Intelligent';
  import { ref, onMounted } from 'vue';
  import { useRoute } from 'vue-router';

  const route = useRoute();
  const Detail = ref<any>({});
  // let Detail = reactiv>({
  //   dayIncome: 0,
  //   releasePercent: 0,
  //   days: '',
  //   miningInvite: '',
  //   symbol: '',
  //   amount: '',
  //   allIncome: '',
  //   endTime: '',
  //   createTime: '',
  //   status: 0,
  //   url: '',
  //   pactNum: 0,
  // });

  // let codefixed = computed((): string => {
  //   return data.code.substring(0, 8) + '...' + data.code.substring(data.code.length - 8);
  // });

  onMounted(() => {
    // rechange();
    getDetail();
  });

  // const rechange = (): void => {
  //   getrechange({
  //     unit: 'USDT',
  //   }).then((res: any) => {
  //     if (res.code == 0) {
  //       data.code = res.data;
  //     }
  //   });
  // };

  const getDetail = () => {
    console.log(route.query.id);
    let params: any = {
      id: route.query.id,
    };
    detailbyid(params).then((reson): any => {
      Detail.value = reson.data;
      console.log(Detail.value);
    });
  };
</script>

<style lang="scss" scoped>
  ::v-deep .var-elevation--3 {
    box-shadow: none;
  }

  .From {
    height: auto;
    padding-left: 40px;
    padding-right: 40px;
    color: #333333;
    .TZmoneyTitle {
      color: #000000;
      font-size: 30px;
      font-family: PingFangSCBold;
      font-weight: 600;
    }
    .TZmoneyC {
      font-size: 25px;
      font-family: PingFangSCBold;
      color: #777777;
    }
    .USDT {
      justify-content: space-between;
      align-items: center;
      background: rgba($color: #00133c, $alpha: 0.05);
      // opacity: 0.05;
      // box-shadow: 4px 0px 4px 0px #00133c;
      border-radius: 7px 7px 7px 7px;
      height: 64px;
      .USDTIcon {
        font-size: 31px;
        font-weight: 600;
        font-family: PingFangSCBold;
        color: #000000;
      }
    }

    .balance {
      border-bottom: 1px solid rgba($color: #333333, $alpha: 0.3);
    }

    .TZlistItemCon {
      border-bottom: 1px solid rgba($color: #999999, $alpha: 0.3);
    }
  }
</style>
