import layout from '/@/layout/index.vue';
import pageHome from '/@/views/home/index.vue';
import pageQuotation from '/@/views/quotation/index.vue';
import pageTransaction from '/@/views/transaction/index.vue';
import pageUser from '/@/views/user/index.vue';

import Intelligent from '/@/views/Intelligent/index.vue';
import records from '/@/views/Intelligent/records/index.vue';
// import revenue from "/@/views/Intelligent/revenue/index.vue"
// import SelectCurrency from "/@/views/Intelligent/SelectCurrency/index.vue"
import Investmentdetermination from '/@/views/Intelligent/Investment/index.vue';
import InvestmentDetails from '/@/views/Intelligent/InvestmentDetails/index.vue';

// import pageContract from '/@/views/contract/index.vue';
import pageContractNew from '/@/views/contract/index new.vue';
import pageUserAssetsRecord from '/@/views/user/assets/record.vue';
import pageUserRecordContract from '/@/views/user/assets/recordContract.vue';
import pageSafe from '/@/views/user/safe.vue';
import pageTransDetail from '/@/views/transaction/detail.vue';
import pagePay from '/@/views/home/pay.vue';
import pageMining from '/@/views/home/mining/index.vue';
import pageDepositRecord from '/@/views/user/assets/depositRecord.vue';
import pageWithdrawlRecord from '/@/views/user/assets/withdrawalRecords.vue';
import pageRegister from '/@/views/register/index.vue';
import pageSets from '/@/views/user/sets.vue';
import pageLangs from '/@/views/user/langs.vue';
import pageLogin from '/@/views/login/index.vue';
import pageBalance from '/@/views/user/balance.vue';
import pageMyincome from '/@/views/user/myincome/index.vue';
// import pageMyincome from '/@/views/user/myincome/index.vue';
export const routes = [
  {
    path: '/',
    redirect: 'home',
    component: layout,
    children: [
      {
        path: 'home',
        component: pageHome,
        meta: {
          title: 'home',
          keepAlive: true,
        },
      },
      {
        name: 'Intelligent',
        path: 'Intelligent',
        component: Intelligent,
        meta: {
          title: 'tabbar.Intelligent',
          keepAlive: false,
        },
      },
      {
        path: 'quotation',
        component: pageQuotation,
        meta: {
          title: 'market',
          keepAlive: true,
        },
      },
      {
        name: 'Contract',
        path: 'contract',
        component: pageContractNew,
        meta: {
          title: 'contract',
          keepAlive: false,
        },
      },
      {
        path: 'balance',
        component: pageBalance,
        meta: {
          title: 'tabbar.balance',
          keepAlive: true,
        },
      },
      {
        name: 'records',
        path: '/Intelligent/records',
        component: records,
        meta: {
          // title: 'mine',
          // keepAlive: true,
          title: 'tabbar.records',
          keepAlive: false,
        },
      },

      {
        path: '/Intelligent/Investment/:id/:symbol/:releasePercent/:price/:lockedDays/:max/:min',
        name: 'Investmentdetermination',
        component: Investmentdetermination,
        meta: {
          title: 'tabbar.Investment',
          keepAlive: false,
        },
      },
      {
        name: 'InvestmentDetails',
        path: '/Intelligent/InvestmentDetails',
        component: InvestmentDetails,
        meta: {
          title: 'tabbar.InvestmentDetails',
          keepAlive: false,
        },
      },

      {
        name: 'Transaction',
        path: 'transaction',
        component: pageTransaction,
        meta: {
          title: 'trade',
          keepAlive: true,
        },
      },
      {
        name: 'transactionDetail',
        path: '/transaction/detail',
        component: pageTransDetail,
        meta: {
          title: '',
          keepAlive: false,
        },
      },
      {
        path: 'user',
        component: pageUser,
        meta: {
          // title: 'mine',
          // keepAlive: true,
          title: 'tabbar.member',
          keepAlive: false,
        },
      },
      {
        name: 'help',
        path: '/help',
        component: () => import('/@/views/user/help/index.vue'),
        meta: {
          title: 'tabbar.help',
          // keepAlive: true,
        },
      },
      {
        name: 'announcementDetail',
        path: '/announcementDetail',
        component: () => import('/@/views/home/announcement/announcementDetail.vue'),
        meta: {
          title: 'tabbar.help',
          keepAlive: false,
        },
      },
      {
        name: 'official',
        path: '/official',
        component: () => import('/@/views/home/official/official.vue'),
        meta: {
          title: 'tabbar.official',
          keepAlive: true,
        },
      },
      {
        name: 'supervise',
        path: '/supervise',
        component: () => import('/@/views/home/supervise/supervise.vue'),
        meta: {
          title: 'tabbar.supervise',
          keepAlive: true,
        },
      },
      {
        name: 'Source',
        path: '/Source',
        component: () => import('/@/views/home/Source/Source.vue'),
        meta: {
          title: 'tabbar.Source',
          keepAlive: true,
        },
      },
      {
        name: 'report',
        path: '/report',
        component: () => import('/@/views/home/report/report.vue'),
        meta: {
          title: 'tabbar.report',
          keepAlive: true,
        },
      },
      //消息
      {
        name: 'messageAnnouncement',
        path: '/messageAnnouncement',
        component: () => import('/@/views/home/messageAnnouncement/messageAnnouncement.vue'),
        meta: {
          title: 'tabbar.messageAnnouncement',
          keepAlive: true,
        },
      },
      //总贡献值
      {
        name: 'allValue',
        path: '/allValue',
        component: () => import('/@/views/user/values/allValue.vue'),
        // meta: {
        //   title: 'tabbar.allValue',
        //   keepAlive: true,
        // },
      },
      //分享贡献值
      {
        name: 'shareValue',
        path: '/shareValue',
        component: () => import('/@/views/user/values/shareValue.vue'),
        meta: {
          title: 'tabbar.shareValue',
          keepAlive: false,
        },
      },
      //团队贡献值
      {
        name: 'teamValue',
        path: '/teamValue',
        component: () => import('/@/views/user/values/teamValue.vue'),
        meta: {
          title: 'tabbar.teamValue',
          keepAlive: false,
        },
      },
      //客服
      {
        name: 'customer',
        path: '/customer',
        component: () => import('/@/views/customer/customer.vue'),
        meta: {
          title: 'tabbar.customer',
          keepAlive: true,
        },
      },
      {
        name: 'report',
        path: '/report',
        component: () => import('/@/views/home/report/report.vue'),
        meta: {
          title: 'tabbar.help',
          keepAlive: true,
        },
      },
      {
        name: 'helpDetail',
        path: '/help/detail/:id',
        component: () => import('/@/views/user/help/detail.vue'),
        meta: {
          title: '',
          keepAlive: false,
        },
      },
      {
        name: 'more',
        path: '/more/:id',
        component: () => import('/@/views/user/help/more/index.vue'),
        meta: {
          title: '',
          keepAlive: false,
        },
      },
      {
        name: 'moreDetail',
        path: '/more/detail/:id',
        component: () => import('/@/views/user/help/more/detail.vue'),
        meta: {
          title: '',
          keepAlive: false,
        },
      },
      {
        path: 'sets',
        component: pageSets,
        meta: {
          title: 'tabbar.sets',
          keepAlive: true,
        },
      },
      {
        path: 'notice',
        component: () => import('/@/views/user/notice.vue'),
        meta: {
          title: 'tabbar.notice',
          keepAlive: true,
        },
      },
      {
        path: 'about',
        component: () => import('/@/views/user/about.vue'),
        meta: {
          title: 'tabbar.about',
          keepAlive: true,
        },
      },
      {
        path: 'feedback',
        component: () => import('/@/views/user/feedback.vue'),
        meta: {
          title: 'tabbar.feedback',
          // keepAlive: true,
        },
      },
      {
        path: 'performance',
        component: () => import('/@/views/user/performance.vue'),
        meta: {
          title: 'tabbar.performance',
          keepAlive: true,
        },
      },
      {
        path: 'langs',
        component: pageLangs,
        meta: {
          title: 'tabbar.langs',
          keepAlive: true,
        },
      },
      {
        path: 'wechat',
        component: () => import('/@/views/user/wechat.vue'),
        meta: {
          title: 'tabbar.wechat',
          keepAlive: true,
        },
      },
      {
        path: 'bank',
        component: () => import('/@/views/user/bank.vue'),
        meta: {
          title: 'tabbar.bank',
          keepAlive: true,
        },
      },
      // {
      //   path: 'account/:id',
      //   component: () => import('/@/views/user/account.vue'),
      //   meta: {
      //     title: 'tabbar.account',
      //     keepAlive: true,
      //   },
      // },
      // {
      //   path: 'usdt/:id',
      //   component: () => import('/@/views/user/usdt.vue'),
      //   meta: {
      //     title: 'tabbar.usdt',
      //     keepAlive: true,
      //   },
      // },
      {
        path: 'alipay',
        component: () => import('/@/views/user/alipay.vue'),
        meta: {
          title: 'tabbar.alipay',
          keepAlive: true,
        },
      },
      {
        path: 'recharge',
        component: () => import('/@/views/user/recharge.vue'),
        meta: {
          title: 'tabbar.recharge',
          keepAlive: true,
        },
      },
      {
        path: 'withdraw',
        component: () => import('/@/views/user/withdraw.vue'),
        meta: {
          title: 'tabbar.withdraw',
          keepAlive: false,
        },
      },
      {
        path: 'currency',
        component: () => import('/@/views/user/currency.vue'),
        meta: {
          title: 'tabbar.currency',
          keepAlive: true,
        },
      },
      // {
      //   path: '/transfer',
      //   component: () => import('../views/user/transfer/index.vue'),
      //   meta: {
      //     title: 'tabbar.transfer',
      //     keepAlive: true,
      //   },
      // },
      // {
      //   path: '/transfer/chooseAccount',
      //   component: () => import('../views/user/transfer/chooseAccount.vue'),
      //   meta: {
      //     title: 'tabbar.chooseAccount',
      //     keepAlive: true,
      //   },
      // },
      // {
      //   path: '/transfer/chooseCurrency',
      //   component: () => import('../views/user/transfer/chooseCurrency.vue'),
      //   meta: {
      //     title: 'tabbar.chooseCurrency',
      //     keepAlive: true,
      //   },
      // },
      {
        path: 'safe',
        component: pageSafe,
        meta: {
          title: 'tabbar.safe',
          keepAlive: true,
        },
      },
      {
        path: 'bindAccount',
        component: () => import('/@/views/user/bindAccount.vue'),
        meta: {
          title: 'tabbar.bindAccount',
          keepAlive: true,
        },
      },
      {
        path: '/verified/:id/:realname/:front/:reverse',
        component: () => import('../views/user/verification/verified.vue'),
        meta: {
          title: 'tabbar.idcard',
          keepAlive: true,
        },
      },
      {
        path: '/idPic',
        component: () => import('../views/user/verification/idPic.vue'),
        meta: {
          title: 'tabbar.idcard',
          keepAlive: true,
        },
      },
      {
        path: '/unVerified',
        component: () => import('../views/user/verification/unVerified.vue'),
        meta: {
          title: 'tabbar.idcard',
          keepAlive: true,
        },
      },
      {
        name: 'setPassword',
        path: '/setPassword',
        component: () => import('../views/user/capitalPassword/setPassword.vue'),
        meta: {
          title: 'tabbar.capital',
          keepAlive: false,
        },
      },
      {
        name: 'changePassword',
        path: '/changePassword',
        component: () => import('../views/user/capitalPassword/changePassword.vue'),
        meta: {
          title: 'tabbar.capital',
          keepAlive: false,
        },
      },
      {
        name: 'loginPassword',
        path: 'loginPassword',
        component: () => import('../views/user/loginPassword.vue'),
        meta: {
          title: 'tabbar.password',
          keepAlive: false,
        },
      },
      {
        name: 'message',
        path: '/message',
        component: () => import('/@/views/home/message.vue'),
        meta: {
          title: '',
          keepAlive: true,
        },
      },
      {
        name: 'announcement',
        path: '/announcement',
        component: () => import('../views/home/announcement/index.vue'),
        meta: {
          title: '',
          keepAlive: false,
        },
      },
      // {
      //   name: 'announceDeatil',
      //   path: '/announcement/detail/:id',
      //   component: () => import('/@/views/home/announcement/detail.vue'),
      //   meta: {
      //     title: '',
      //     keepAlive: false,
      //   },
      // },
      {
        name: 'pay',
        path: '/pay',
        component: pagePay,
        meta: {
          title: '',
          keepAlive: true,
        },
      },
      {
        name: 'invite',
        path: '/invite',
        component: () => import('/@/views/home/invite.vue'),
        meta: {
          title: '',
          keepAlive: true,
        },
      },
      {
        name: 'mining',
        path: '/mining',
        component: pageMining,
        meta: {
          title: '',
          keepAlive: true,
        },
      },
      {
        name: 'product',
        path: '/mining/product',
        component: () => import('/@/views/home/mining/product.vue'),
        meta: {
          title: '',
          keepAlive: true,
        },
      },
      {
        name: 'order',
        path: '/mining/order',
        component: () => import('/@/views/home/mining/order.vue'),
        meta: {
          title: '',
          keepAlive: true,
        },
      },
      {
        name: 'search',
        path: '/quotation/search',
        component: () => import('/@/views/quotation/search.vue'),
        meta: {
          title: '',
          keepAlive: true,
        },
      },

      {
        name: 'detail',
        path: '/mining/detail/:id',
        component: () => import('/@/views/home/mining/detail.vue'),
        meta: {
          title: '',
          keepAlive: true,
        },
      },

      {
        name: 'transactionList',
        path: '/transaction/list',
        component: () => import('/@/views/transaction/list.vue'),
        meta: {
          title: '全部委托',
          keepAlive: true,
        },
      },
      {
        path: 'userAssetsRecord',
        component: pageUserAssetsRecord,
        meta: {
          title: 'assetFlow',
          keepAlive: false,
        },
      },
      {
        path: 'userRecordContract',
        component: pageUserRecordContract,
        meta: {
          title: 'historyDelivery',
          keepAlive: false,
        },
      },
      {
        path: 'depositRecord',
        component: pageDepositRecord,
        meta: {
          title: 'depositRecord',
          keepAlive: false,
        },
      },
      {
        path: 'withdrawalRecords',
        component: pageWithdrawlRecord,
        meta: {
          title: 'withdrawalRecords',
          keepAlive: false,
        },
      },
      {
        name: 'forex',
        path: '/forex',
        component: () => import('/@/views/forex/index.vue'),
        meta: {
          title: '',
          keepAlive: true,
        },
      },
      {
        name: 'forexLogout',
        path: '/forexLogout',
        component: () => import('/@/views/forex/index-logout.vue'),
        meta: {
          title: '',
          keepAlive: true,
        },
      },
      {
        name: 'myIncome',
        path: '/myIncome/:id',
        component: pageMyincome,
        meta: {
          title: '',
          keepAlive: false,
        },
      },
      // {
      //   path: 'bscusdt/:id',
      //   component: () => import('/@/views/user/usdto.vue'),
      //   meta: {
      //     title: '',
      //     keepAlive: false,
      //   },
      // },
      {
        path: 'withdrawSelCoin',
        component: () => import('/@/views/user/withdrawSelCoin.vue'),
        meta: {
          title: '',
          keepAlive: false,
        },
      },
    ],
  },
  {
    name: 'login',
    path: '/login',
    component: pageLogin,
    meta: {
      title: '',
      keepAlive: false,
    },
  },
  {
    name: 'forgetlogin',
    path: '/forgetlogin',
    component: () => import('/@/views/login/forgetLogin.vue'),
    meta: {
      title: '',
      keepAlive: false,
    },
  },
  {
    name: 'register',
    path: '/register',
    component: pageRegister,
    meta: {
      title: '',
      keepAlive: false,
    },
  },
];

export default routes;
