<script lang="ts">
import { defineComponent } from 'vue'
export default defineComponent({
  name: 'TransactionDetail',
})
</script>
<template>
  <!-- 涨幅页面 -->
  <div class="details">
    <!-- 头部导航 -->
    <div class="navBarHeader">
      <div @click="$router.back">
        <var-icon name="chevron-left" :size="30" />
        <span>{{ route.query.symbol }}</span>
      </div>
      <Favorite class="ml-1" :symbol="symbol" />
    </div>
    <!-- 高低能量值 -->
    <div class="EnergyValue">
      <div class="energy">
        <div id="energyLeft">
          <div :class="detailList.ul > 0 ? 'text-green-500' : 'text-red-500'"> {{ detailList.close ? detailList.close -
        0
        : '-' }} </div>
          <div style="font-size: 20px"
            :class="detailList.close - detailList.open > 0 ? 'text-green-500' : 'text-red-500'">
            {{ detailList.close ? (((detailList.close - detailList.open) / detailList.open) * 100).toFixed(2) : '-' }} %
          </div>
        </div>
        <!-- 右侧能量值 -->
        <div class="eneryRight">
          <div id="highs" class="flex justify-between">
            <div>{{ $t('high') }}</div> {{ detailList.high || '-' }}
          </div>
          <div id="highs" class="flex justify-between">
            <div>{{ $t('low') }}</div> {{ detailList.low || '-' }}
          </div>
          <div id="highs" class="flex justify-between">
            <div class="mr-3">{{ $t('quantity2') }}</div>
            {{ detailList.turnover ? (detailList.turnover - 0).toFixed(4) : '-' }}
          </div>
        </div>
      </div>
    </div>
    <!-- tab切换栏 -->
    <van-tabs class="mt-3 pl-0" v-model:active="klinePeriod" @change="periodChange">
      <van-tab v-for="(item, key) in Period" :title="lang === 'en_US' ? key : key" :name="item" :key="key" />
    </van-tabs>
    <!-- 切换图表 -->
    <div class="charts-border mb-4" style="height: 400px;">
      <div id="kLineChart" class="charts"></div>
      <div class="charts-label" style="padding-left: 20px">
        <div style="color: rgb(219, 219, 89)">MA5:{{ MA5 || 0 }}</div>
        <div style="color: #7bf0f7">MA10:{{ MA10 || 0 }}</div>
        <div style="color: #bb97f4">MA30:{{ MA30 || 0 }}</div>
      </div>
      <div class="charts-bar-label" style="padding-top: 10px; padding-left: 20px">
        <div style="color: rgb(219, 219, 89)">MA5:{{ volMA5 || 0 }}</div>
        <div style="color: #7bf0f7">MA10:{{ volMA10 || 0 }}</div>
      </div>
    </div>

    <!-- 深度/成交 -->
    <div class="text-gray-400 text-xs">
      <!-- tab切换 -->
      <var-tabs style="height: 35px" v-model:active="active" @change="clickTabs(active)">
        <var-tab>{{ $t('depth') }}</var-tab>
        <var-tab>{{ $t('deal') }}</var-tab>
      </var-tabs>
      <!-- 内容 -->
      <var-tabs-items v-model:active="active">
        <var-tab-item style="height: 10%">
          <div class="itemTab" v-if="detailDetach">
            <div class="tabOne">
              <span class="font-normal p-1 text-left">{{ $t('buyQuantity2') }}({{ fromCoin }})</span>
              <span class="font-normal p-1 text-center">{{ $t('price') }}({{ toCoin }})</span>
              <span class="font-normal p-1 text-right">{{ $t('sellQuantity') }}({{ fromCoin }})</span>
            </div>
            <div class="tabTwo">
              <div class="listLeft">
                <div class="list" v-for="(item, index) in detailDealAsk" :key="index">
                  <span>{{ index + 1 }}</span>
                  <span> {{ item[1].toFixed(2) }}</span>
                  <span class="text-green-500"> {{ item[0] }}</span>
                </div>
              </div>
              <div class="listRight">
                <div class="list" v-for="(item, index) in detailDealBid" :key="index">
                  <span class="text-red-500"> {{ item[0] }}</span>
                  <span> {{ item[1].toFixed(2) }}</span>
                  <span>{{ index + 1 }}</span>
                </div>
              </div>
            </div>
          </div>

          <div v-else class="h-48 flex justify-center items-center">
            <van-loading />
          </div>
        </var-tab-item>
        <var-tab-item style="height: 10%; padding-bottom: 60px">
          <table v-if="detailDeal.length" class="w-full text-center">
            <colgroup>
              <col width="25%" />
              <col width="25%" />
              <col width="25%" />
              <col width="25%" />
            </colgroup>
            <thead>
              <tr>
                <th class="font-normal p-1">{{ $t('time') }}</th>
                <th class="font-normal p-1">{{ $t('direction') }}</th>
                <th class="font-normal p-1">{{ $t('price') }}({{ toCoin }})</th>
                <th class="font-normal p-1">{{ $t('quantity') }}({{ fromCoin }})</th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="item in detailDeal" :key="item.id">
                <td class="p-1">{{ parseTime(item.ts, '{h}:{i}:{s}') }}</td>
                <td class="p-1" :class="item.direction === 'buy' ? 'text-green-500' : 'text-red-500'">{{
        $t(OrderType[item.direction.toLocaleUpperCase()])
      }}</td>
                <td class="p-1">{{ item.price }}</td>
                <td class="p-1">{{ item.amount.toFixed(4) }}</td>
              </tr>
            </tbody>
          </table>
          <div v-else class="h-48 flex justify-center items-center">
            <van-loading />
          </div>
        </var-tab-item>
      </var-tabs-items>
      <!-- 底部按钮 -->
      <div style="display: none;" class="footer-sticky">
        <div class="flex p-2 items-center" style="background: #f3f3f3">
          <!-- :to="{ path: '/transaction', query: { symbol: symbol, direction: 'BUY' } }" -->
          <div class="flex-1 mr-1">
            <var-button type="success" block @click="showPopup('BUY')">{{ $t('BUY') }}</var-button>
          </div>
          <!-- :to="{ path: '/transaction', query: { symbol: symbol, direction: 'SELL' } }" -->
          <div class="flex-1 ml-1">
            <var-button type="danger" block @click="showPopup('SELL')">{{ $t('SELL') }}</var-button>
          </div>
          <!-- <Favorite class="ml-1" :symbol="symbol" /> -->
        </div>
      </div>
      <!-- <van-sticky :offset-bottom="0" position="bottom">

      </van-sticky> -->
      <!-- 买入卖出弹框 -->
      <van-popup class="van-popup-cus" v-model:show="show" closeable close-icon-position="top-left" position="bottom">
        <div class="bg-white-gray-850 mt-10 p-3 text-base" style="overflow: hidden">
          <!-- 秒钟 & % -->
          <var-row :gutter="30" v-if="tradeList?.length">
            <var-col :span="6" v-for="item in tradeList" :key="item.id" @click="changeId(item)">
              <div class="rounded py-3 text-center flex-1 mb-3"
                :class="item.id === formData.fastId ? 'bg-yellow-400 text-black' : 'bg-white-400 bg-gray-300'">
                <div class="mb-5"> {{ item.closeTimeGap }} {{ $t('second') }}</div>
                {{ item.oods * 100 }} %
              </div>
            </var-col>
          </var-row>

          <!-- 输入框 -->
          <van-cell-group inset>
            <van-field type="number" :label="toCoin + '|'" v-model="formData.amount" :disabled="unuse" :placeholder="`${$t('minimum')}${currentContract?.minLimitAmout || '0'}${$t('minimumSell')}，${$t('most') + currentContract?.maxLimitAmout
        }${$t('ceiling')}`" :autosize="{ maxHeight: 120 }">
              <template #label>
                <div class="flex items-center"> {{ toCoin }} <var-divider vertical /> </div>
              </template>
            </van-field>
          </van-cell-group>
          <!-- 当前余额 / 手续费 -->
          <div class="flex justify-between text-sm py-3">
            {{ $t('availableBalance') }}：{{ unuse ? '-' : balanceUsdt ? balanceUsdt.balance.toFixed(4) : '-' || '-' }}
            {{ toCoin }}
            <div v-if="currentContract"> {{ $t('fee') }}：{{ unuse ? '-' : (currentContract?.fee * 100).toFixed(4) }} %
            </div>
          </div>
          <div class="price-4">
            <div class="item">
              <p>{{ $t('currency') }}</p>
              <p>{{ fromCoin }}</p>
            </div>
            <div class="item">
              <p>{{ $t('direction') }}</p>
              <p>{{ $t(direction) }}</p>
            </div>
            <div class="item">
              <p>{{ $t('price') }}</p>
              <p :class="detailList.ul > 0 ? 'text-green-500' : 'text-red-500'">{{ detailList.close ? (detailList.close
        -
        0).toFixed(2) : '-' }}</p>
            </div>
            <div class="item">
              <p>{{ $t('Amount') }}</p>
              <p>{{ formData.amount }}</p>
            </div>
          </div>

          <!-- 看涨 / 看跌 button -->
          <div class="flex mb-3">
            <var-button class="flex-1 bg-yellow-400 text-white" @click="submitHandle(direction == 'SELL' ? 1 : 0)">{{
        $t('confirm') }}</var-button>
          </div>
          <div class="expected-return" v-if="currentContract?.oods">
            {{ $t('expectedReturn') }}: {{ Number(formData.amount) + Number(formData.amount) * currentContract?.oods }}
          </div>
        </div>
      </van-popup>
    </div>
  </div>
</template>

<script lang="ts" setup name="TransactionDetail">
// 引入文件
import useBalance from '/@/hooks/useBalance';
import { getContractList, getCurrentContractList, OrderParams, walletUSDT } from '/@/api/contract';
import {
  // CoinList,
  Contract,
  ContractOrder,
  DeliveryRefresh,
} from '/@/types/api';

import axios from 'axios';
import Favorite from '/@/components/Favorite/index.vue';
import { parseTime } from '/@/utils';
import { OrderType } from '/@/enum';
// import { getBgWidth } from '/@/utils';
import { useColinList } from '/@/hooks/useColin';
import { useKline } from '/@/hooks/useKline';
// import { TradeTick } from '/@/types/api';
import { ref, nextTick } from 'vue';
import { useRoute } from 'vue-router';
// import { transactionList, transactDepth, transactDeal } from '/@/api/transacDetail';
import { onUnmounted, onMounted, watchEffect } from 'vue';
import { useToken } from '/@/hooks/useToken';
import { showToast } from 'vant';
import { useI18n } from 'vue-i18n';
const { t } = useI18n();
const direction = ref('');

const lang = localStorage.getItem('lang');
const route = useRoute();
const _symbol = <string>route.query.symbol;
const { symbol, toCoin, fromCoin } = useColinList(_symbol);
const { MA10, MA30, MA5, volMA10, volMA5, Period, changePeriod } = useKline({
  isSubDeep: true,
  isSubTrad: true,
  isSubDetail: true,
  fromName: fromCoin.value,
});
const klinePeriod = ref('1min');
const active = ref(0);
// const detailDetachS = ref<DeepTick>();
// const tradeItems = ref<TradeTick[]>([]);
const detailDetach = ref<any>([]);
const detailList = ref<any>([]);
const detailDeal = ref<any>([]);

const router = useRouter();

// 买入卖出弹框展示
const show = ref(false);
const showPopup = (val: string) => {
  if (!tradeList.value?.length) {
    showToast(t('untrade'));
    return;
  }
  show.value = true;
  direction.value = val;
};
const tradeList = ref<Contract[]>();

let { balanceUsdt, getWalletListHanler } = useBalance(fromCoin.value);

const formData = reactive<OrderParams>({
  fastId: 0,
  amount: '',
  direction: 1,
  optionId: '',
  symbol: '',
  startPrice: 0.0
});
const currentContract = ref<Contract>();
const { token } = useToken();
const unuse = ref(false);
const currentData = ref<ContractOrder>();
const percentage = ref(0);
// 买入卖出弹框 changeTab
onActivated(() => {
  total.value = '— ';
  income.value = '— ';
  getCurrentReq();
  init();
});

walletUSDT();
getKlineData();
function getKlineData() {
  let symbol = route.query.symbol as string;
  axios.get('https://api.huobi.pro/market/history/kline?symbol=' + symbol.replace('/', '').toLowerCase() + '&period=1day').then((res) => {
    // @ts-ignore
    res = res.data;
    // @ts-ignore
    if (res.status == 'ok') {
    }
  });
}
function changeId(item: Contract) {
  formData.fastId = item.id;
  currentContract.value = item;
  console.log(currentContract);
}
// 交易 submitHandle
function submitHandle(_direction: number): void {
  // console.log('direction', direction)
  let index = tradeList.value!.findIndex((item) => {
    return item.id == formData.fastId;
  });
  let tempobj: DeliveryRefresh = {
    timeOrder: new Date().valueOf(),
    colseTime: tradeList.value![index].closeTimeGap,
  };
  localStorage.setItem('Delivery', JSON.stringify(tempobj));

  if (!token) {
    router.push('/login');
    return;
  }
  if (formData.amount == '' || parseFloat(formData.amount) <= 0) {
    showToast(t('quantityInput'));
    return;
  }
  if (
    parseFloat(formData.amount) < (currentContract.value?.minLimitAmout || 10) ||
    parseFloat(formData.amount) > (currentContract.value?.maxLimitAmout || 1000000000)
  ) {
    showToast(t('outOfRange'));
    return;
  }
}

// 交易
function init(first = false) {
  getContractList({ symbol: symbol.value }).then((res) => {
    tradeList.value = res.data;
    unuse.value = false;
    if (tradeList.value && tradeList.value.length) {
      formData.fastId = tradeList.value[0].id;
      currentContract.value = tradeList.value[0];
    } else {
      unuse.value = true;
    }
  });

  if (!first && token) {
    getWalletListHanler(fromCoin.value);
  }
}
init(true);
// 当前
let intervalId;
const total = ref('— ');
const income = ref('— ');

function clearTimer() {
  clearInterval(intervalId);
}
function startTimer(seconds: number, split: number, countTime?: number) {
  let count = countTime || 0;
  intervalId = setInterval(function () {
    count++;
    // if (callback) {
    //   callback(count);
    // }
    if (count === seconds) {
      percentage.value = 100;
      currentData.value = undefined;

      clearInterval(intervalId);
    } else {
      percentage.value = parseFloat((percentage.value + split).toFixed(2));
    }
  }, 1000);
}

function getCurrentReq() {
  getCurrentContractList({ symbol: symbol.value }).then((res) => {
    currentData.value = res.data;
    // console.log(currentData.value, '当前交割数据');

    let temp = JSON.parse(localStorage.getItem('Delivery')!);
    // console.log(temp);
    // console.log(intervalId, 'abc');
    if (new Date().valueOf() < temp.timeOrder + temp.colseTime * 1000 && !intervalId && currentData.value) {
      // console.log(((new Date().valueOf() - temp.timeOrder) / 1000));
      // console.log((((new Date().valueOf() - temp.timeOrder) / 1000) / temp.colseTime * 100).toFixed(2));
      const seconds = temp.colseTime;
      const split = 100 / seconds;
      intervalId && clearTimer();
      percentage.value = Number((((new Date().valueOf() - temp.timeOrder) / 1000 / temp.colseTime) * 100).toFixed(2));
      let countTime = parseInt((new Date().valueOf() - temp.timeOrder) / 1000);
      startTimer(seconds, split, countTime);
    }
  });
}

function periodChange(val: string): void {
  // period.value = val;
  changePeriod(val);
}

//获取能量
function listDetail() {
  let symbol = route.query.symbol as string;
  console.log('symbol == ' + symbol);
  if (!symbol) {
    return;
  }
  axios.get('https://api.huobi.pro/market/detail/merged?symbol=' + symbol.replace('/', '').toLowerCase()).then((res) => {
    // @ts-ignore
    res = res.data;
    // @ts-ignore
    if (res.status == 'ok') {
      // @ts-ignore
      if (res.tick.close != detailList.value.close) {
        // @ts-ignore
        detailList.value.ul = res.tick.close - detailList.value.close;
        // @ts-ignore
        detailList.value.close = res.tick.close;
      }
      // @ts-ignore
      detailList.value.open = res.tick.open;
      // @ts-ignore
      detailList.value.high = res.tick.high;
      // @ts-ignore
      detailList.value.low = res.tick.low;
      // @ts-ignore
      detailList.value.turnover = res.tick.vol;
    }
  });
  // transactionList({ symbol }).then((res) => {
  //   detailList.value = res;
  // });
}

// const newDetach = ref<any>([]);
//深度
const detailDealAsk = ref<any>([]);
const detailDealBid = ref<any>([]);
function listDetailUSDT() {
  let symbol = route.query.symbol as string;
  // const size = 20;
  // let data = {
  //   symbol,
  //   size,
  // };
  // ?symbol=btcusdt&depth=5&type=step0
  axios.get('https://api.huobi.pro/market/depth?symbol=' + symbol.replace('/', '').toLowerCase() + '&depth=10&type=step0').then((res) => {
    // @ts-ignore
    res = res.data;
    // @ts-ignore
    if (res.status == 'ok') {
      // @ts-ignore
      detailDealAsk.value = res.tick.asks;
      // @ts-ignore
      detailDealBid.value = res.tick.bids;
    }
  });
  // transactDepth(data).then((res) => {
  //   detailDetach.value = res;

  //   detailDealAsk.value = detailDetach.value.ask.items;
  //   detailDealBid.value = detailDetach.value.bid.items;
  //   // updateDetach();
  // });
}
// 更新数据的函数
// function updateDetach() {
//   // 假设这里是你接收到数据的逻辑
//   newDetach.value = detailDetach.value; // 这里只是一个示例，你需要根据你的实际数据进行处理
//   // 如果数组长度大于 0，删除最后一条数据
//   if (detailDetach.value.length > 0) {
//     detailDetach.value.pop();
//   }
//   // 在数组开头添加新的数据
//   // detailDetach.value.unshift(...newDetach.value);
//   // detailDealAsk.value
// }

// 模拟每秒接收到新数据的函数
function ListdetailDetach() {
  let symbol = route.query.symbol as string;
  // const size = 20;
  // let data = {
  //   symbol,
  //   size,
  // };
  // https://api.huobi.pro/market/trade?symbol=btcusdt 'https://api.huobi.pro/market/trade?symbol='+(symbol.replace('/','').toLowerCase())
  axios.get('https://api.huobi.pro/market/trade?symbol=' + symbol.replace('/', '').toLowerCase()).then((res) => {
    // @ts-ignore
    res = res.data;
    // @ts-ignore
    if (res.status == 'ok') {
      // @ts-ignore
      let _newData = res.tick.data;
      updateData(_newData);
    }
  });
  // transactDeal(data).then((res: any) => {
  //   detailDeal.value = res;
  //   updateData();
  // });
}
const newData = ref<any>([]);
const dealBackup = ref<any>([]);
// 更新数据的函数
function updateData(_newData) {
  // 假设这里是你接收到数据的逻辑
  newData.value = _newData; // 这里只是一个示例，你需要根据你的实际数据进行处理

  // 如果数组长度大于 0，删除最后一条数据
  // if (detailDeal.value.length > 0) {
  //   detailDeal.value.pop();
  // }
  // 在数组开头添加新的数据
  dealBackup.value.unshift(...newData.value);
  detailDeal.value = [];
  nextTick(() => {
    detailDeal.value = dealBackup.value.slice(0, 20);
  });
}
const timerId = ref<any>();
const timerIdDetach = ref<any>();
const timerIdDetail = ref<any>();
function clickTabs(active) {
  if (active == 0) {
    timerIdDetach.value = setInterval(listDetailUSDT, 800);
    clearInterval(timerId.value);
  }
  if (active == 1) {
    // 定时器，每秒更新一次数据
    timerId.value = setInterval(ListdetailDetach, 800);
    clearInterval(timerIdDetach.value);
  }
}

watchEffect(() => {
  // 在这里可以更新页面的显示
  // console.log('Data updated:', detailDeal.value); // 示例：打印更新的数据
});

onMounted(() => {
  listDetail();
  timerIdDetail.value = setInterval(listDetail, 1000);
  timerIdDetach.value = setInterval(listDetailUSDT, 1000);
  ListdetailDetach();
});
onDeactivated(() => {
  clearTimeout(timerId.value);
  clearTimeout(timerIdDetach.value);
  clearTimeout(timerIdDetail.value);
});
onUnmounted(() => {
  clearTimeout(timerId.value);
  clearTimeout(timerIdDetach.value);
});
</script>
<style lang="scss" scoped>
@import './css/detail.scss';
</style>
